import * as React from 'react';
import { VideoModalCard } from '../video-modal-card';
import { namedStyled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { namedObserver } from 'lib/named-observer';
import { EndCardProps } from './end-card-props';

import __ from '@core/lib/localization';

const Wrapper = namedStyled('EndCardWrapper', VideoModalCard, {
  $$landscapeImage: '',
  $$portraitImage: '',
  $$image: '$$portraitImage',
  backgroundColor: '$blue-700',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundImage: `$$image`,
    backgroundPosition: 'center',
    mixBlendMode: 'multiply',
    opacity: 0.2,
    filter: 'grayScale(1) blur(4px)',
    // zIndex: 1,
  },

  '& > .buttons-container': {
    position: 'relative',
    // zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    width: 'calc(100% - 32px)',
    '@medium': {
      width: '280px',
    },
  },

  '@medium': {
    $$image: '$$landscapeImage',
  },
});

// for the non-onboarding mode
export const SimpleEndCard = namedObserver(
  'SimpleEndCard',
  ({ player, model, onDismiss }: EndCardProps) => {
    const video = model.current;
    if (!video) {
      return null;
    }

    return (
      <Wrapper
        className="simple-end-card"
        onTop
        css={{
          $$landscapeImage: `url(${video.imageUrlLandscape})`,
          $$portraitImage: `url(${video.imageUrlPortrait})`,
        }}
      >
        <div className="buttons-container">
          <Button
            onClick={player.play}
            label={__('Replay', 'replay')}
            presentation={'blue'}
            size="large"
            leftIcon={<ReplayIcon />}
            fullWidth
          />
          <Button
            onClick={() => {
              onDismiss();
            }}
            label={__('Close', 'close')}
            presentation={'teal'}
            size="large"
            fullWidth
          />
        </div>
      </Wrapper>
    );
  }
);
