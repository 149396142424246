import * as React from 'react';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager';
// import { AppFactory } from '@app/app-factory';
// import { createLogger } from '@common/log';
import { DownloadedSmallIcon } from '@naan/icons/downloaded-icon';
import { styled } from '@naan/stitches.config';
import { UncontrolledTooltip } from '@naan/primitives/tooltip';
import { Box } from '@naan/primitives/box';

import __ from '@core/lib/localization';

// const log = createLogger('story-debug-info');

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  cursor: 'default',
  '& svg': {
    position: 'relative',
    top: -2,
  },
});

// place holder until we implement prettier UI
export const StoryDownloadedBadge = observer(
  ({ story, withLabel = false }: { story: Story; withLabel?: boolean }) => {
    // log.debug('StoryCacheStateUnguarded - render');

    // const { hasAdminAccess } = AppFactory.root.userManager;
    // if (!hasAdminAccess) {
    //   return null;
    // }

    // if (AppFactory.assetCacher.disabled) {
    //   return <>[asset cacher currently disabled]</>;
    // }

    const isCached = story.isCachedMemoized;

    // log.debug(`StoryCacheStateUnguarded - isCached: ${String(isCached)}`);

    if (!isCached) {
      return null; // should resolve very quickly
    }

    return (
      <UncontrolledTooltip
        event="click"
        placement={'bottom'}
        offset={[0, 20]}
        tipContent={
          <Box
            css={{
              width: 'min(100%-48px, 300px)',
              textAlign: 'left',
              textStyle: 'body',
              whiteSpace: 'break-spaces',
            }}
          >
            {__(
              "This story is downloaded and can be studied even when you're offline.",
              'thisStoryIsDownloaded'
            )}
          </Box>
        }
      >
        <Wrapper>
          <DownloadedSmallIcon />
          {withLabel ? __('Downloaded', 'downloaded') : null}
        </Wrapper>
      </UncontrolledTooltip>
    );
  }
);
