import React from 'react';
import { observer } from 'mobx-react';
import useSWR from 'swr';
import { HStack, VStack } from 'naan/primitives/stack';
import { HSpacer } from 'naan/primitives/spacer';
import { Button } from 'naan/primitives/button';
import { Input } from '@naan/primitives/input';
import { useComboForm } from '@common/hooks/use-comboform';
import { AppFactory } from '@app/app-factory';
import { formatBytes } from '@utils/string-utils';

import { createLogger } from '@common/log';
import { ActionLink } from '@common/ui/action-link';
import { BaseCacher } from 'lib/base-cacher';

const log = createLogger('storage-canary-ui');

const estimateStorage = async (dummy: string): Promise<StorageEstimate> => {
  return await BaseCacher.estimateStorage();
};

const abuseLocalStorage = (kb: number) => {
  localStorage.setItem('dev-tools-canary', 'x'.repeat(kb * 1024));
};

const resetLocalStorageCanary = () => {
  localStorage.removeItem('dev-tools-canary');
};

type FormInput = {
  kb: number;
};

export const StorageCanaryUI = observer(() => {
  let { data: storageEstimate, error: storageError } = useSWR(
    'storage-estimate',
    estimateStorage
  );

  const { appStateCacher, assetCacher } = AppFactory;
  const defaultValues = { kb: 5000 };

  const {
    register,
    // handleSubmit,
    // errors,
    // clearValidationErrors,
    // handleValidationErrors,
  } = useComboForm<FormInput>({
    /*validationSchema: nameSchema,*/ defaultValues,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const kb = Number(form.get('kb').toString());
    log.info(`kb: ${kb}`);
    if (kb > 0) {
      abuseLocalStorage(kb);
      log.info('done');
    } else {
      resetLocalStorageCanary();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <VStack>
          {storageEstimate ? (
            <p>
              storage quota: {formatBytes(storageEstimate.quota)}, usage:{' '}
              {formatBytes(storageEstimate.usage)}; app state{' '}
              {appStateCacher.enabled ? 'enabled' : 'disabled'}{' '}
              <ActionLink
                onPress={() => appStateCacher.toggleDisabled()}
                label="[toggle]"
              />
              ; asset {assetCacher?.enabled ? 'enabled' : 'disabled'}{' '}
              <ActionLink
                onPress={() => assetCacher?.toggleDisabled()}
                label="[toggle]"
              />
            </p>
          ) : null}
          {storageError ? (
            <p>storage estimate error: {String(storageError)}</p>
          ) : null}

          <HStack align={'center'}>
            <HSpacer size={2} />
            <Input name={'kb'} size={10} type="text" {...register('kb')} />
            {/* <CatchAllFormErrors errors={errors} data-test-id="error-message" /> */}
            <HSpacer size={1} />
            <Button label="Abuse local storage kb" />
          </HStack>
        </VStack>
        <VStack>
          <HStack gap={'medium'}>
            <ActionLink
              onPress={async () => {
                // eslint-disable-next-line no-alert
                if (window.confirm('Are you sure?')) {
                  await AppFactory.appStateCacher.purge();
                }
              }}
              label="[Purge app-state cache storage]"
            />

            <ActionLink
              onPress={async () => {
                // eslint-disable-next-line no-alert
                if (window.confirm('Are you sure?')) {
                  await BaseCacher.purgeAllCacheStorage();
                }
              }}
              label="[Purge all cache storage]"
            />
          </HStack>
        </VStack>
      </form>
    </>
  );
});

// // doens't need to be async any longer
// const fetchServerAuthCookie = async (dummy: string): Promise<string> => {
//   // return await AppFactory.root.userManager.getServerCookie(
//   //   USER_TOKEN_COOKIE_KEY
//   // );
//   const result = Cookies.get(USER_TOKEN_COOKIE_KEY);
//   return result;
// };

// export const ServerAuthCookieUI = () => {
//   let { data: authCookie, error } = useSWR(
//     'server-auth-cookie',
//     fetchServerAuthCookie
//   );

//   if (error) {
//     return <>error: {String(error)}</>;
//   }

//   if (authCookie !== undefined) {
//     return <>server auth cookie: {String(authCookie)}</>;
//   } else {
//     return <>loading server auth cookie...</>;
//   }
// };
