import dayjs from 'dayjs';
import { currentLocale } from '@app/app-util';

/**
 * Returns true if the browser supports the `locales` argument in `toLocaleDateString`.
 * This is a workaround for the fact that `Intl.DateTimeFormat` is not available in
 * some versions of Safari, and `toLocaleDateString` is not available in some versions
 * of Internet Explorer.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString#browser_compatibility
 */
function toLocaleDateStringSupportsLocales() {
  return (
    typeof Intl === 'object' &&
    !!Intl &&
    typeof Intl.DateTimeFormat === 'function'
  );
}

const dateFormatOptions: Record<string, Intl.DateTimeFormatOptions> = {
  default: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  short: {
    month: 'short',
    day: 'numeric',
  },
  medium: {
    month: 'long',
    day: 'numeric',
  },
} as const;

type DateFormatOption = keyof typeof dateFormatOptions;

/**
 * Returns date in format "Aug 27, 2020"
 */
export const formatDate = (
  dateStr: string | number | Date | dayjs.Dayjs | null | undefined,
  format: DateFormatOption = 'default'
): string => {
  if (!dateStr) {
    return '';
  }

  const date = dayjs(dateStr);

  if (date.isValid()) {
    const locale = currentLocale(); // only present for spa
    // if (
    //   /// Global hack to keep the date format consistent with the locale
    //   /// while keeping this function shareable
    //   /// this window definition, for the SPA is done in app-factory
    //   typeof (window as any).__currentLocale === 'function' &&

    if (
      locale &&
      /// Check if the browser supports the locales argument in toLocaleDateString
      /// if not, we will use the old non-localized format
      toLocaleDateStringSupportsLocales()
    ) {
      const plainDateObject = date.toDate();
      return localizedFormatDate(
        plainDateObject,
        // (window as any).__currentLocale(),
        currentLocale(),
        format
      );
    }

    return date.format(format === 'short' ? 'MMM D' : 'MMM D, YYYY');
  }
  return dateStr as string;
};

function localizedFormatDate(
  date: Date,
  locale: string,
  format: DateFormatOption = 'default'
) {
  // return date.toLocaleDateString(locale, {
  //   year: 'numeric',
  //   month: 'short',
  //   day: 'numeric',
  // });
  return date.toLocaleDateString(locale, dateFormatOptions[format]);
}

// (window as any).localizedFormatDate = localizedFormatDate;
// (window as any).formatDate = formatDate;
