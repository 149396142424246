import { styled } from '@naan/stitches.config';

export const Container = styled('div', {
  borderBottom: '1px solid $gray-100',
});

export const LeftSide = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});
export const RightSide = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});
export const Centered = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Row = styled('div', {
  padding: '14px 0',
  borderTop: '1px solid $gray-100',
  [`&:has(${LeftSide})`]: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
});

export const Label = styled('div', {
  textStyle: 'small-text-bold',
  color: '$textSecondary',
});

export const Value = styled('div', {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100vw - 6.5rem)',
});
