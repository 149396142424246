import { makeObservable, observable, reaction, runInAction } from 'mobx';
import { Interval, Intervals } from '../intervals/intervals';

import { bugsnagNotify } from '@app/notification-service';
import { createLogger } from '@app/logger';
import { AppFactory } from '@app/app-factory';
import { WrappedAudioElement } from './wrapped-audio-element';
import { OnboardingService } from '@app/onboarding/onboarding-service';
const log = createLogger('audio-transport');

export class TransportState {
  @observable audioPosition = 0;
  @observable lastSeekAudioPosition = 0;
  @observable audioRestartPosition = 0;
  @observable audioLastPlayToPosition = 0;
  @observable isPlaying = false;
  @observable playbackRate = 1.0;
  @observable kerningEnabled = false;
  @observable pendingPause = false;
  @observable pauseAfterTriggeredCount = 0;
  @observable momentaryPause = false;

  constructor() {
    makeObservable(this);
  }
}

const CURRENTTIME_SAMPLING_START_COUNT = -3;
const SYNC_AUDIO_START_COUNT = -5;

export class AudioTransport {
  transportState: TransportState = null;
  maintainAudioRestart: boolean = false; // chaat mode support
  exactPauseAfter: boolean = false; // web player behavior unneeded by chaat
  // __audioSource = null;
  audioElement: WrappedAudioElement = null;
  _audioDuration = 0;
  audioStart = 0;
  audioEnd = 0;
  playbackRate = 1.0;
  timeTweak = 0;

  // hack around ios automatic rewind during restart
  // positionAtLastPlay = 0;

  syncAudioCount = SYNC_AUDIO_START_COUNT;
  syncAudioPositionTimer: number = null;

  audioPositionHandlerAttached = false;
  currentTimeLastUpdateTimestamp = CURRENTTIME_SAMPLING_START_COUNT;
  currentTimeLastUpdatePosition: number = null;

  pauseAfter = 0; // TODO put in TransportState?
  momentaryPauseTimer: number = null;

  kerningEnabled = false;
  kerningUseVolumeRamp = true;
  activeKerningTimer: number = null;
  kerningPauses: number[] = [];
  kerningIntervals: Intervals = null;
  currentKerningInterval: Interval = null;
  kerningLoweringVolume = false;

  constructor(
    transportState: TransportState,
    {
      maintainAudioRestart = false,
      exactPauseAfter = false,
    }: { maintainAudioRestart?: boolean; exactPauseAfter?: boolean }
  ) {
    this.transportState = transportState;
    this.maintainAudioRestart = maintainAudioRestart;
    this.exactPauseAfter = exactPauseAfter;
  }

  // @jason, it feels like there's significant complexity around the audio source stuff only needed by chaat.
  // is that accuratae?
  setAudioSource(audioSource: any) {
    // this.__audioSource = audioSourceRef;
    this._audioDuration = 0;
    const audioElementFunc = audioSource.audioElementFunction;
    this.audioElement = audioElementFunc();
    reaction(
      audioElementFunc,
      element => (this.audioElement = element as any),
      { fireImmediately: true }
    );
  }

  play() {
    this.syncAudioCount = 0;
    this.listenCurrentTimeUpdate();
    this.activateSyncAudioTimer();
    runInAction(() => {
      this.transportState.momentaryPause = false;
      this.transportState.isPlaying = true;
    });
    // this.positionAtLastPlay = this.audioPosition;
    AppFactory.wakeLock?.activate();

    // JRW: this api is intentionally encapsulating complexity around web audio apis so it seems correct we should deal with this here
    this.audioSource.play();
  }

  // beware 'keepPauseAfter' is intertwined with undesired chaat auto-rewind
  pause(keepPauseAfter = false, onceAgain = true) {
    this.clearKerningResumeTimer();
    AppFactory.wakeLock?.deactivate();
    this.audioSource.pause();
    // TODO add conditional behavior
    keepPauseAfter = keepPauseAfter && !!this.pauseAfter;
    if (!keepPauseAfter) {
      this.pauseAfter = 0;
      this.transportState.pendingPause = false;
    }
    if (onceAgain) {
      this.clearSyncAudioTimer(100);
    } else {
      this.clearSyncAudioTimer();
    }

    this.resetCurrentTimeSampleCount();
    this.transportState.isPlaying = false;
  }

  // beware 'keepPauseAfter' must be 'true' to avoid undesired chaat auto-rewind to previous start of play behavior
  pauseThenPlayAt(delay: number, position: number, keepPauseAfter = false) {
    // this.audioTransport.pause(keepPauseAfter);
    // this.seek(position, keepPauseAfter);
    // setTimeout(() => this.play(), delay);

    // pause and seek at lowest level
    this.audioSource.pause();
    this.resetCurrentTimeSampleCount();
    this.resetSyncAudioCount();
    clearInterval(this.syncAudioPositionTimer);
    this.syncAudioPositionTimer = null;
    this.transportState.momentaryPause = true;
    this.seek(position);
    // TODO fix this weird side effect caused by desired behavior in chaat
    if (!keepPauseAfter && this.maintainAudioRestart) {
      this.transportState.audioRestartPosition = position;
    }

    if (this.momentaryPauseTimer) {
      clearTimeout(this.momentaryPauseTimer);
      this.momentaryPauseTimer = null;
    }

    this.momentaryPauseTimer = window.setTimeout(() => {
      this.momentaryPauseTimer = null;
      this.play();
    }, delay);
  }

  seek(time: number, keepPauseAfter = false) {
    // TODO if seek should we cancel an activeKerningTimer and play immediately?
    this.transportState.lastSeekAudioPosition = time;
    this.transportState.audioPosition = time;
    this.currentKerningInterval = null;
    this.resetSyncAudioCount();
    // TODO translate and limit for self audio interval
    this.audioSource.currentTime = (time - this.timeTweak) / 1000;
    if (
      this.maintainAudioRestart &&
      !this.transportState.isPlaying &&
      !this.transportState.momentaryPause &&
      !keepPauseAfter
    ) {
      this.transportState.audioRestartPosition = time;
    }
  }

  rewind() {
    if (this.transportState.audioRestartPosition) {
      this.seek(this.transportState.audioRestartPosition);
    }
  }

  get audioDuration() {
    if (
      !this._audioDuration &&
      // this.__audioSource &&
      this.audioSource &&
      // this.__audioSource.current &&
      // this.__audioSource.current.duration
      this.audioSource &&
      this.audioSource.duration
    ) {
      this._audioDuration = Math.floor(
        // this.__audioSource.current.duration * 1000
        this.audioSource.duration * 1000
      );
    }
    return this._audioDuration;
  }

  get audioSource() {
    //return this.__audioSource.current;
    // return this.__audioSource;
    return this.audioElement;
  }

  setPauseAfter(time: number) {
    // TODO audioTransport.setPauseAfter(Math.min(this.audioPosition + this.peekDuration, this.selfInterval.end));
    this.pauseAfter = time;
    if (time) {
      this.transportState.pendingPause = true;
    }
  }

  clearPauseAfter() {
    this.pauseAfter = 0;
    this.transportState.pendingPause = false;
  }

  resetCurrentTimeSampleCount() {
    this.currentTimeLastUpdateTimestamp = CURRENTTIME_SAMPLING_START_COUNT;
  }

  resetSyncAudioCount() {
    this.syncAudioCount = SYNC_AUDIO_START_COUNT;
  }

  get reallyPlaying() {
    return this.transportState.isPlaying && !this.activeKerningTimer;
  }

  get sufficientCurrentTimeSamples() {
    return this.currentTimeLastUpdateTimestamp > -1;
  }

  incrementCurrentTimeSampleCount() {
    this.currentTimeLastUpdateTimestamp++;
  }

  get audioPosition() {
    // log.debug(
    //   `audioPosition - reallyPlaying: ${String(
    //     this.reallyPlaying
    //   )}, suffCurSam: ${String(this.sufficientCurrentTimeSamples)}, ctlut: ${
    //     this.currentTimeLastUpdateTimestamp
    //   }, tweakTime: ${this.timeTweak}`
    // );
    // TODO if stop considering interpolation then time returned can jump backwards until currentTime updates
    if (
      !this.reallyPlaying ||
      !this.sufficientCurrentTimeSamples ||
      !this.currentTimeLastUpdateTimestamp
    ) {
      const result =
        // Math.round(this.__audioSource.current.currentTime * 1000) +
        Math.round(this.audioSource.currentTime * 1000) + this.timeTweak;
      if (
        !this.sufficientCurrentTimeSamples &&
        this.reallyPlaying &&
        this.syncAudioCount > 40
      ) {
        // waited long time when should be playing but no change pos callback from audio element
        // probably audio element ate play call try playing again
        this.syncAudioCount = 0;
        this.audioSource.play();
        log.warn('retrying audioSource.play()');
      }
      return result;
    } else {
      const result =
        (Date.now() - this.currentTimeLastUpdateTimestamp) * this.playbackRate +
        this.currentTimeLastUpdatePosition +
        this.timeTweak;
      // log.debug(`interpolated result: ${result}`);
      return result;
    }
  }

  listenCurrentTimeUpdate() {
    if (!this.audioPositionHandlerAttached) {
      this.audioSource.addEventListener('timeupdate', () => {
        this.handleAudioCurrentTimeUpdate(
          // this.__audioSource.current.currentTime
          this.audioSource.currentTime
        );
      });
      this.audioPositionHandlerAttached = true;
    }
  }

  activateSyncAudioTimer() {
    // TODO calculate actually setInterval average period when set to 15ms
    if (!this.syncAudioPositionTimer) {
      this.syncAudioPositionTimer = window.setInterval(
        () => this.syncAudioPosition(),
        15
      );
    } else {
      bugsnagNotify(
        'activateSyncAudioTimer: called with audio timer not null, ignoring'
      );
    }
  }

  clearSyncAudioTimer(onceAgain = 0) {
    // TODO add conditional behavior
    if (this.syncAudioPositionTimer) {
      clearInterval(this.syncAudioPositionTimer);
      this.syncAudioPositionTimer = null;
      if (onceAgain) {
        window.setTimeout(() => {
          this.resetCurrentTimeSampleCount();
          this.resetSyncAudioCount();
          this.syncAudioPosition();
        }, onceAgain);
      }
    }
  }

  clearKerningResumeTimer() {
    if (this.activeKerningTimer) {
      clearTimeout(this.activeKerningTimer);
      this.activeKerningTimer = null;
    }
  }

  setAudioRestartPosition(time: number) {
    this.transportState.audioRestartPosition = time;
  }

  clearAudioRestartPosition() {
    if (this.transportState.audioRestartPosition) {
      this.transportState.audioRestartPosition = 0;
    }
  }

  handleAudioCurrentTimeUpdate(time: number) {
    if (!this.transportState.isPlaying || this.activeKerningTimer) {
      // TODO this is key to kerning smooth resume?
      return;
    }
    if (!this.sufficientCurrentTimeSamples) {
      this.incrementCurrentTimeSampleCount();
      return;
    }
    this.currentTimeLastUpdateTimestamp = Date.now(); // todo: rename this
    this.currentTimeLastUpdatePosition = Math.round(time * 1000);
  }

  syncAudioPosition() {
    if (!this.syncAudioPositionTimer) {
      log.info(
        'syncAudioPosition called with timer null should only happen with manual pause'
      );
    }
    const pos1 = this.transportState.audioPosition;
    this.syncAudioCount++;
    const pos2 = this.audioPosition;
    //TODO don't hardcode -7 use half setInterval timer val
    if (
      this.transportState.isPlaying &&
      this.pauseAfter &&
      pos2 > this.pauseAfter - 7
    ) {
      // TODO consider this.audioEnd
      const pausingAt = this.pauseAfter;
      if (this.exactPauseAfter) {
        this.pause(false /*keepPauseAfter*/, false /*onceAgain*/);
      } else {
        this.pause(false /*keepPauseAfter*/, true /*onceAgain*/);
      }
      OnboardingService.instance.onSmartPause();
      runInAction(() => {
        if (this.exactPauseAfter) {
          this.transportState.audioPosition =
            pausingAt /* - 2 time adjustment is no longer desired */;
          // hack to adjust the system audio time after the above pause takes effect
          setTimeout(
            () => this.seek(this.transportState.audioPosition, true),
            400
          );
          if (pos2 > pausingAt + 2000) {
            // todo: support `invariant` usage from shared tikka code
            log.warn('unexpected audio time much later than pauseAfter time');
            // console.trace();
            // bugsnagNotify(
            //   'unexpected audio time much later than pauseAfter time'
            // );
          }
        }
        this.transportState.pauseAfterTriggeredCount =
          this.transportState.pauseAfterTriggeredCount + 1;
      });
      this.rewind();
      return;
    }
    if (
      (this.syncAudioCount < 0 || this.syncAudioCount & 0x1) &&
      pos1 !== pos2
    ) {
      // ignore situation created by ios rewinding before target time
      // hack using !exactPauseAfter to indicate chaat
      if (pos2 > pos1 || !this.exactPauseAfter) {
        this.transportState.audioPosition = pos2;
      } else if (pos2 < pos1 - 500) {
        // todo: use assert once common code better shared into masala
        // throw new Error(
        //   'unexpected audio time much earlier than current audioPosition'
        // );
        // eslint-disable-next-line no-console
        // jason, fyi, this seems to get triggered frequently when jumping around sentences while playing
        // jason, i'm also seeing this often (but not always) triggered and endless spammed when encountering the failure
        // to play audio after initial load
        log.info(
          'unexpected audio time much earlier than current audioPosition'
        );
      }
    }
    if (this.kerningEnabled) {
      this.checkKerning(pos2);
    }
  }

  checkKerning(pos: number) {
    if (this.kerningEnabled && !this.activeKerningTimer) {
      const currentInterval = this.currentKerningInterval;
      if (!currentInterval) {
        const kernIdx = this.kerningIntervals.containing(pos);
        this.currentKerningInterval = this.kerningIntervals.intervalAt(kernIdx);
      } else {
        const intervalStart = currentInterval.begin;
        const intervalEnd = currentInterval.end;
        if (pos > intervalStart && pos < intervalEnd) {
          if (pos > intervalStart + 90 && pos < intervalEnd - 90) {
            if (this.kerningLoweringVolume) {
              this.audioSource.volume = 1;
              this.kerningLoweringVolume = false;
            }
          } else {
            if (!this.kerningLoweringVolume) {
              this.audioSource.volume = 0.1;
              this.kerningLoweringVolume = true;
            }
          }
          return;
        }
        const kernIdx = this.kerningIntervals.containing(pos);
        this.currentKerningInterval = this.kerningIntervals.intervalAt(kernIdx);
        if (pos < currentInterval.begin) {
          return;
        }
        this.activeKerningTimer = window.setTimeout(
          () => this.handleKerningTimer(),
          this.kerningPauses[kernIdx]
        );
        // console.log('pause: ' + this.kerningPauses[kernIdx]);
        this.audioSource.pause();
        this.resetCurrentTimeSampleCount();
        this.resetSyncAudioCount();
        clearInterval(this.syncAudioPositionTimer);
        this.syncAudioPositionTimer = null;
      }
    }
  }

  handleKerningTimer() {
    if (!this.activeKerningTimer) {
      return;
    }
    this.activeKerningTimer = null;
    this.play();
  }

  setPlaybackRate(rate: number) {
    // this value is duplicated on this and in transportState, it's more efficient to read a non-observable
    // but is the difference relevant?
    this.playbackRate = rate;
    this.transportState.playbackRate = rate;
    // this.__audioSource.current.playbackRate = this.playbackRate;
    this.audioSource.playbackRate = this.playbackRate;
  }

  setKerningData(points: number[], pauses: number[]) {
    const kerningPoints = new Intervals(points);
    this.kerningPauses = [1, ...pauses];
    this.kerningIntervals = kerningPoints.fromConvertToIntervals(0, 10000000); // TODO
  }

  setKerningUseVolumeRamp(value: boolean) {
    this.kerningUseVolumeRamp = value;
  }

  kerningEnable(enable: boolean) {
    // this value is duplicated on this and in transportState, it's more efficient to read a non-observable
    // but is the difference relevant?
    this.kerningEnabled = enable;
    this.transportState.kerningEnabled = enable;
  }
}

/* TODO
think about change to?

audioRewindPosition
audioStopAfterPosition

rewindOnPause
rewindOnReachStop
clearStopOnSeek
clearStopOnSeekOutside
notifyReachStop?
 */
