import React from 'react';
import { observer } from 'mobx-react';
import { Responsive } from '@naan/primitives/responsive';
import { MobilePlaybackRateControl } from './mobile-playback-rate-control';
import { DesktopPlaybackRateControl } from './desktop-playback-rate-control';
import {
  SpeedControlTip,
  // SpeedControlTipDialog,
} from 'components/ui/onboarding/speed-control-tip';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';
import { styled } from '@naan/stitches.config';

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: '50%',
    '@large': {
      borderRadius: 12,
    },
  },
});

export const PlaybackRateControl = observer(() => {
  const dismissed = OnboardingService.instance.isDismissed('speedControl');
  const showPopover = OnboardingService.instance.showSpeedControlTip;

  return (
    <>
      <OnboardingPopover
        dismissed={dismissed}
        showPopover={showPopover}
        renderAnchor={({ ref, getProps }) => (
          <ToolipPositioner
            ref={ref}
            {...getProps()}
            className={showPopover ? 'highlight' : null}
          >
            <Responsive
              renderDefault={() => <MobilePlaybackRateControl />}
              renderLargeAndUp={() => <DesktopPlaybackRateControl />}
            />
            {/* <DesktopPlaybackRateControl /> */}
          </ToolipPositioner>
        )}
        renderBody={() => {
          // @armando. this seems to only render from the study player, not the soundbite player
          return <SpeedControlTip />;
        }}
        onResolvePopover={OnboardingService.instance.speedControlTipDismissed}
      />
      {/* <SpeedControlTipDialog /> */}
    </>
  );
});
