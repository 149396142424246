import { styled } from 'naan/stitches.config';
import { paletteKey } from 'study/views/study-palette';

export const ToolbarStyles = styled('div', {
  position: 'sticky',
  bottom: 0,
  borderRadius: 16,
  padding: 8,
  paddingTop: 0,
  background: paletteKey('$$sentence__backgroundColor_current'),

  '& > .toolbar-inner': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '4px 6px',
    borderRadius: 12,
    background: paletteKey('$$sentence_toolbar__backgroundColor'),
    boxShadow: `0px 1px 3px ${paletteKey('$$sentence_toolbar__shadowColor')}`,

    '& .notation-icon-text': {
      fill: paletteKey('$$sentence__backgroundColor_current'),
    },

    '& .spacer': {
      flex: 1,
    },

    '& button': {
      all: 'unset',
      cursor: 'default',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 4,
      padding: '10px 8px',
      $$_color: paletteKey('$$sentence_toolbar__color'),
      color: '$$_color',

      '& .icon': {
        // color: '$colors$gray-800',
        '& svg': {
          width: 28,
          height: 28,
        },
      },
      '& .label': {
        textStyle: 'small-text-bold',
      },

      '&.translation-button': {
        '&.active': {
          $$_color: '$colors$teal-500',
        },
        '&.disabled': {
          $$_color: '$colors$gray-200',
        },
      },

      '&.notation-button': {
        '&.active': {
          $$_color: '$colors$green-500',
        },
        '&.disabled': {
          $$_color: '$colors$gray-200',
        },
      },
    },
  },
});
