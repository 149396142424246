import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { ToastService } from 'common/notifications/toast-service';
import { Toast, ToastTarget } from '../../components/ui/toasts';
import { Navigate, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';
import { usePageTracker } from 'lib/hooks/use-page-tracker';
import { InviteGate } from 'routes/auth/invite-gate';
// import { embeddedMode, postNativeMessage } from '@app/app-util';
// import { FullScreenLoader } from 'components/ds/modals/full-screen-loader';
import { EnsureReady } from 'components/ensure-ready';
import { LoadingScreen } from 'components/ui/loading-screen';
import { DebugStatusBar, PiMenu } from 'components/pi-menu.tsx';

import { createLogger } from '@common/log';
import { welcomePath } from 'components/nav/path-helpers';
// import { L2SwitchGuard } from 'routes/auth/welcome-screen/l2-switch-guard';
const log = createLogger('app-wide-layout');

export const AppWideRouteLayout = observer(() => {
  const { toastService, root } = AppFactory;
  usePageTracker(); // don't track non-authenticated pages until anonymous metrics revisited

  // not entirely sure when this can happen
  if (!root) {
    log.warn('render - root not assigned');
    return null;
  }

  const toast = toastService.current;
  const { userManager, accountCreationDisabled } = root;
  const { authenticated } = userManager;
  // const disallowed = !authenticated && embeddedMode();
  const disallowed = !authenticated && accountCreationDisabled;
  log.debug(
    `render - auth'd: ${String(authenticated)}, disallowed: ${String(
      disallowed
    )}`
  );

  // React.useEffect(() => {
  //   if (anonymousEmbedded) {
  //     log.info(
  //       'embedded anonymous access detected - signally sign-out to wrapper'
  //     );
  //     postNativeMessage({
  //       type: 'string',
  //       payload: 'WEB-VIEW-NATIVE-SIGN-OUT',
  //     });
  //   }
  // }, [anonymousEmbedded]);

  // if (anonymousEmbedded) {
  //   return <FullScreenLoader />;
  //   // todo distinguish from 'loading' state to 'fully loaded, but anonymous' error state
  // }

  if (disallowed) {
    // @armando, what's the right way to do this?
    if (
      !(
        window.location.pathname.startsWith('/welcome') ||
        window.location.pathname.includes('/auth') ||
        window.location.pathname.includes('/dev-tools')
      )
    ) {
      log.warn(`disallowed - navigating to sign-in`);
      return <Navigate to={welcomePath()} />;
    }
  }

  const { inviteNeeded, showingL2SwitchGuard } = AppFactory.root;
  if (inviteNeeded) {
    return <InviteGate />;
  }

  // beware, not currently relevant
  if (showingL2SwitchGuard) {
    // return <L2SwitchGuard />;
    log.warn('l2 switch detected (but allowed for now)');
  }

  return (
    <>
      <EnsureReady>
        <Outlet />
      </EnsureReady>
      <LoadingScreen />
      <PiMenu />
      <DebugStatusBar />
      <ToastTarget
        showNotification={!!toast}
        css={{ '@large': { left: '$$sidebarWidth' }, padding: '$space$2' }}
      >
        {toast ? (
          <Toast
            type={toast.type}
            label={toast.message}
            onDismiss={ToastService.clear}
            action={toast.action}
          />
        ) : null}
      </ToastTarget>
      {AppFactory.dialogPresenter.element}
    </>
  );
});
