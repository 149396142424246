import * as React from 'react';
import * as Onboarding from './components';
import { observer } from 'mobx-react';
import { unwidow } from '@utils/string-utils';
import { styled } from '@naan/stitches.config';
import { OnboardingDialog } from './onboarding-dialogs';
import { OnboardingPopover } from './onboarding-popover';
import { OnboardingService } from '@app/onboarding/onboarding-service';

import __ from 'core/lib/localization';

import illustration from './assets/redaction-tip-illustration.png';

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: '8px',
  },
});

export const RedactionButtonOnboardingDialog = observer(() => {
  const { showRedactionMenuDialog, redactionMenuDialogDismissed } =
    OnboardingService.instance;

  if (!showRedactionMenuDialog) {
    return null;
  }

  return (
    <OnboardingDialog
      body={
        <Onboarding.Container>
          <Onboarding.Image src={illustration} />
          <Onboarding.Title>
            {__(`Where did the words go?`, 'whereDidTheWordsGo')}
          </Onboarding.Title>
          <Onboarding.Content>
            {__(
              "We've hidden some of the words in the script, to help you listen more and read less.",
              'weveHiddenSomeOfTheWordsInTheScript'
            )}
          </Onboarding.Content>
        </Onboarding.Container>
      }
      onResolve={redactionMenuDialogDismissed}
    />
  );
});

export const RedactionButtonOnboardingTip: React.FC = observer(
  ({ children }) => {
    const dismissed =
      OnboardingService.instance.isDismissed('redactionMenuTip');
    const showPopover = OnboardingService.instance.showRedactionMenuTip;
    return (
      <OnboardingPopover
        dismissed={dismissed}
        showPopover={showPopover}
        renderAnchor={({ ref, getProps }) => (
          <ToolipPositioner
            ref={ref}
            {...getProps()}
            className={showPopover ? 'highlight' : null}
          >
            {children}
          </ToolipPositioner>
        )}
        renderBody={() => {
          return (
            <Onboarding.Container>
              <Onboarding.Content>
                {unwidow(
                  __(
                    'You can choose how much of the script you see here.',
                    'youCanChooseHowMuchOfTheScriptYouSeeHere'
                  )
                )}
              </Onboarding.Content>
            </Onboarding.Container>
          );
        }}
        onResolvePopover={OnboardingService.instance.redactionMenuTipDismissed}
      />
    );
  }
);
