import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { Root } from '../root';
import { getBaseRoot } from '../app-root';
import { VideoGuideEngagementStatus } from '../user-manager/video-guide-engagement';
import { __ } from '@core/lib/localization';

export class VideoGuide extends ModelTreeNode {
  static CLASS_NAME = 'VideoGuide' as const;

  static create(snapshot: any) {
    return super.create(VideoGuide, snapshot) as VideoGuide;
  }

  slug: string = ''; // identifier
  // title: string = null;

  videoIdLandscape: string; // Wistia id
  imageUrlLandscape: string; // raw GCS hosted path
  videoIdPortrait: string;
  imageUrlPortrait: string;
  durationSeconds: number;
  position: number; // sorting already handled server side right now

  get root(): Root {
    return getBaseRoot(this);
  }

  // for now assume all videos are onboarding and dashboard featured
  get featured(): boolean {
    return true;
  }

  get onboarding(): boolean {
    return true;
  }

  get engagementStatus(): VideoGuideEngagementStatus {
    return this.root.userManager.userData.videoGuideUserData.statusBySlug(
      this.slug
    );
  }

  get completed(): boolean {
    return this.engagementStatus !== VideoGuideEngagementStatus.UNPLAYED;
  }

  get title(): string {
    // return slugTitleFns[this.slug]?.() || this.slug;
    switch (this.slug) {
      case 'welcome':
        return __('Welcome to Jiveworld', 'videoGuides:title:welcome');
      case 'smart-pause':
        return __('Smart Pause', 'videoGuides:title:smart-pause');
      case 'study-flow':
        return __('Study Flow', 'videoGuides:title:study-flow');
      case 'x-ray':
        return __('X-ray Vision & Quick Look', 'videoGuides:title:x-ray');
      default:
        return this.slug;
    }
  }
}

// this approach hit initialization order issues
// const slugTitleFns: { [index: string]: () => string } = {
//   welcome: __f('Welcome to Jiveworld', 'videoGuides.title.welcome'),
//   'smart-pause': __f('Smart Pause', 'videoGuides.title.smart-pause'),
//   'study-flow': __f('Study Flow', 'videoGuides.title.study-flow'),
//   'x-ray': __f('X-ray Vision & Quick Look', 'videoGuides.title.x-ray'),
// };
