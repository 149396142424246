import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';

import __ from 'core/lib/localization';

// DEFERRED until after 10.x release
export const SwitchToYearlyDialog = observer(
  ({
    onOk,
    onDismiss,
    formattedPrice,
    formattedDate,
    savedPercent,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    formattedPrice: string;
    formattedDate: string;
    savedPercent: number;
  }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__(
            'Switch to yearly and save {{percent}}%',
            'SwitchToYearlyAndSave',
            { percent: savedPercent }
          )}
        </Dialog.Heading>
        <Dialog.Body>
          {__(
            'By continuing, your next charge will be {{amount}} on {{date}}, and every subsequent year until you cancel.',
            'switchToYearlyDialogBody',
            {
              amount: formattedPrice,
              date: formattedDate,
            }
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Cancel', 'cancel')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
              setTimeout(() => {
                onDismiss();
              }, 100);
            }}
            label={__('Switch to yearly', 'switchToYearly')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
