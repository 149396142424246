import * as React from 'react';
import * as Onboarding from './components';
import { PlainMarkdown } from '@naan/primitives/text';

import __ from 'core/lib/localization';

export const VocabListTip = () => {
  return (
    <Onboarding.Container>
      <Onboarding.Title>
        {__('My vocabulary list', 'vocabListTipTitle')}
      </Onboarding.Title>
      <Onboarding.Content>
        <PlainMarkdown
          source={__(
            `Tap here to see your saved vocabulary. It's also on the Story page, after you're done studying.`,
            'vocabListTipBody'
          )}
        />
      </Onboarding.Content>
    </Onboarding.Container>
  );
};
