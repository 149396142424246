import * as React from 'react';
import { Channel } from '@core/models/story-manager/channel';
import { createLogger } from '@common/log';

import mothImage from 'components/learn/dashboard/assets/brand-the-moth.png';
import truthImage from 'components/learn/dashboard/assets/brand-the-truth.png';
import radiolabImage from 'components/learn/dashboard/assets/brand-radiolab.png';
import { Image } from '@naan/primitives/image';
import { styled } from '@stitches/react';

const log = createLogger('channel-badge');

const brandImageMap = {
  moth: mothImage,
  truth: truthImage,
  radiolab: radiolabImage,
};

const getChannelImage = (channel: Channel): string | null => {
  return brandImageMap[channel.slug as keyof typeof brandImageMap] ?? null;
};

const Wrapper = styled('div', {
  display: 'flex',
  borderRadius: 8,
  boxShadow: 'inset 0 0 0 1px $colors$white-alpha-30',
  '&:hover': {
    background: '$colors$black-alpha-06',
  },
  '& > img': {
    height: 40,
  },
});

export const StoryChannelBadge = ({ channel }: { channel: Channel }) => {
  const imageSrc = getChannelImage(channel);
  if (!imageSrc) {
    log.info(`image for channel ${channel.slug} not found`);
    return null;
  }

  return (
    <Wrapper>
      <Image src={imageSrc} />
    </Wrapper>
  );
};
