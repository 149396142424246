import { computed } from 'mobx';
import Cookies from 'js-cookie';
import {
  applySnapshot,
  ModelTreeNode,
  volatile,
  snap,
  Bindery,
  frozen,
  getSnapshot,
} from 'ts-state-tree/tst-core';
import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';
import { track } from 'app/track';
import { AccountData } from './account-data';
import { Root } from '../root';
import { ApiInvoker } from 'core/services/api-invoker';
import { Classroom } from './classroom';
import { License } from './license';
import { Student } from './student';
import { StudentProgress } from './student-progress';
import { Assignment } from './assignment';
import { UserData, UserDataSnapshot } from './user-data';
import { ListeningLog } from './listening-log';
import { ListeningStats } from './listening-stats';
import { LocationPointer } from './location-pointer';
import { PurchasedCoupon } from './purchased-coupon';
import { StoryProgress } from './story-progress';
import { UserSettings } from './user-settings';
import { PaymentData } from './payment-data';
import { ValidationError } from 'core/lib/errors';
import __ from 'core/lib/localization';
import { getBaseRoot } from '../app-root';
import { ClassroomUserData } from './classroom-user-data';
import { normalizedEqual } from 'utils/util';
import { PlayerSettings } from './player-settings';
import { LocaleCode, StringToString } from '@utils/util-types';
import { MILLIS_PER_DAY } from '@utils/date-utils';
import { ReturnNavState } from 'components/nav/return-nav-state';
import {
  embeddedAndroid,
  embeddedBuildNum,
  embeddedIos,
  reload,
} from '@core/lib/app-util';
import { isEmpty, lowerCase, pick } from 'lodash';
import { VideoGuideEngagement } from './video-guide-engagement';
import { VideoGuideUserData } from './video-guide-user-data';
import { AppFactory } from '@app/app-factory';
import {
  alertWarningError,
  bugsnagNotify,
  notifySuccess,
} from '@app/notification-service';
import { notEmpty } from '@utils/conditionals';
import { isNetworkError } from '@core/lib/error-handling';
import { CaliServerInvoker } from '@core/services/cali-server-invoker';
import { getInstallationId } from '../installation-id';
import { deepMergeDiff } from '@utils/deep-merge-diff';
import { Currency } from '@cas-shared/cas-types';
import { NodeAccountData } from './node-account-data';
import { EntitlementData } from './entitlement-data';
import { Plan } from '@cas-shared/plan';
import { Membership } from './membership';

const log = createLogger('user-manager');

export const USER_TOKEN_COOKIE_KEY = 'jw-user-token';
export const USER_TOKEN_TIMESTAMP_COOKIE_KEY = 'jw-user-token-timestamp';
const TRAFFIC_SOURCE_COOKIE_KEY = 'jw-traffic-source';

export const LONG_COOKIE_AGE_DAYS = 400; // 400 days is chrome limit (since v104)

export type SameSiteValues = 'Strict' | 'Lax' | 'None';

const APP_STATE_CACHE_KEY = 'user-manager'; // locally persisted user manager state

// initialized in path-helpers.ts
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    checkoutSuccessUrlFn: () => string;
    checkoutCancelUrlFn: () => string;
  }
}

export const AFFILIATE_LANDING_PAGE_UTM_MEDIUM = 'alp';

export interface TrafficSourceCookie {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  referrer?: string;
}

export class UserManager extends ModelTreeNode {
  static CLASS_NAME = 'UserManager' as const;

  static create(snapshot: any = {}): UserManager {
    return super.create(UserManager, snapshot) as UserManager;
  }

  static bindModels(bindery: Bindery): void {
    bindery.bind(AccountData);
    bindery.bind(NodeAccountData);
    bindery.bind(EntitlementData);
    bindery.bind(Assignment);
    bindery.bind(Classroom);
    bindery.bind(License);
    bindery.bind(ListeningLog);
    bindery.bind(ListeningStats);
    bindery.bind(LocationPointer);
    bindery.bind(PaymentData);
    bindery.bind(PlayerSettings);
    bindery.bind(PurchasedCoupon);
    bindery.bind(StoryProgress);
    bindery.bind(StudentProgress);
    bindery.bind(Student);
    bindery.bind(UserData);
    bindery.bind(ClassroomUserData);
    bindery.bind(UserManager);
    bindery.bind(UserSettings);
    bindery.bind(VideoGuideUserData);
    bindery.bind(VideoGuideEngagement);
  }

  token?: string = null;

  // legacy rails managed and postgres persisted account data
  accountData: AccountData = snap({});

  // node managed and firebase persisted account data
  // todo unify with rails account data and/or persist locally
  // @frozen
  nodeAccountData: NodeAccountData = snap({});

  // deprecated
  lastSyncCheck: number; // timestamp of last account data fetch attempt

  userDataRefreshedAt: number = 0; // Date.now timestamp of last inbound user data. used to drive 'dataReady' state
  accountDataCheckedAt: number = 0; // timestamp of last account data fetch attempt

  userData: UserData = snap({}); //  data to be synced to/from server

  // clone of last fetched data. used as diff basis when persisting updates
  // just stashed here for reference during persist. doesn't need to be observed
  @frozen
  baseUserData: UserDataSnapshot;

  // marked true when sync attempted and skipped while offline and in disconnected fb mode
  @volatile
  deferredSync: boolean;

  // drives network loading indicating while authenticating
  @volatile
  loadingUserData: boolean = false;

  // transient data using during the legacy data import
  @volatile
  migrationUserData: UserData;

  // enables dashboard opt-in dialog
  get newsletterPromptEnabled() {
    return (
      this.authenticated &&
      this.accountData.mailingListPromptNeeded &&
      this.userData.mailingListPromptEnabled
    );
  }

  // hook to update state related to mailing list prompt if needed when study or soundbite player is visited
  handlePlayerVisited() {
    if (
      this.accountData.mailingListPromptNeeded &&
      !this.userData.mailingListPromptEnabled
    ) {
      this.userData
        .updateMailingListPromptEnabled(true)
        .catch(error => bugsnagNotify(error));
    }
  }

  get root(): Root {
    return getBaseRoot(this);
  }

  get apiInvoker(): ApiInvoker {
    return this.root.apiInvoker;
  }

  get authenticated(): boolean {
    return !!this.token;
  }

  // just to make conditions more readable
  get anonymous(): boolean {
    return !this.authenticated;
  }

  get loggedInAndReady(): boolean {
    // return this.authenticated && this.dataReady;
    return this.authenticated && !this.loadingUserData;
  }

  get membershipL2s(): LocaleCode[] {
    const result: LocaleCode[] = [];
    for (const l2 of this.root.availableL2s) {
      const { statusKey } = this.membershipL2(l2);
      if (statusKey !== 'trial') {
        result.push(l2);
      }
    }
    return result;
  }

  get membership(): Membership {
    return this.membershipL2(this.root.l2);
  }

  // @jason, @armando i've factored out a bunch of logic to a separate class
  // and delegating via this hook.
  // do you is this important to cache, and if so, what's the safest way to approach?
  membershipL2(l2: LocaleCode): Membership {
    return new Membership(this, l2); // cache?
  }

  get isTrial(): boolean {
    return this.membership.isTrial;
  }

  get fullAccess(): boolean {
    return this.membership.fullAccess;
  }

  dismissPriceIncreaseAnnouncement(): void {
    this.userData.userSettings.dismissMessage('price-increase-2024');
  }

  // todo: rename to devToolsEnabled, perhaps move to AppRoot
  get hasAdminAccess(): boolean {
    return (
      appConfig.piMenuEnabled ||
      this.accountData.showFutureStories ||
      this.root.localState.forceDevToolsEnabled
    );
  }

  get hasClassrooms(): boolean {
    const { managedL2Classrooms } = this.accountData;
    return managedL2Classrooms && managedL2Classrooms.length > 0;
  }

  get showUglyDevUI(): boolean {
    return this.userData.masalaAdmin;
  }

  get hasNeverListened(): boolean {
    return this.userData.statsChartData.totalPoints === 0;
  }

  get installationId(): string {
    return getInstallationId();
  }

  get reportingContextData(): object {
    return pick(this, [
      'authenticated',
      'installationId',
      'hasAdminAccess',
      'purchaseFlowDisabled',
      'classroomEnabled',
      'userDataRefreshedAt',
    ]);
  }

  get purchaseFlowDisabled(): boolean {
    // We need to use this logic to hide any reference to gift coupon purchase and redeem codes. May be this should be moved to account data.
    // const platform = embeddedPlatform();
    if (
      embeddedIos() &&
      !isEmpty(this.accountData.debugBuildNumber) &&
      this.accountData.debugBuildNumber === String(embeddedBuildNum())
    ) {
      return true;
    }
    return (
      (embeddedIos() && appConfig.iosNoPurchase) ||
      (embeddedAndroid() && appConfig.androidNoPurchase) ||
      appConfig.forceNoPurchase
    );
  }

  async login(email: string, password: string, code?: string): Promise<void> {
    log.info(`login(${email})`);
    track('preauth__email_log_in', { email });

    if (this.authenticated) {
      bugsnagNotify(
        `login - unexpectedly already authenticated - email: ${email}, current: ${this.accountData?.email} `
      );
      await this.reset();
    }

    const kind = notEmpty(code) ? 'code' : 'email';

    const userCredentials = {
      email,
      password,
      code,
    };

    const result = await this.apiInvoker.post<{
      userToken: string;
      userId: string;
    }>('users/auth', {}, { bodyData: userCredentials, networkIndicator: true });

    log.debug(`auth result: ${JSON.stringify(result)}`);

    await this.applyAuthentication(result.userToken, {});
    await this.postAuthenticate();

    track('account__login_successful', { kind });
  }

  /**
   * handle the social logins. will create a user on the fly if needed
   *
   * provider codes:
   *   'google' - google oauth
   *   'facebook' - not yet supported
   *   'mock:[name]' - fake mode for test harness
   */
  async omniauth(provider: string, token: string): Promise<void> {
    const anonymousId = this.installationId;

    log.info(`omniauth(${token})`);

    if (this.authenticated) {
      bugsnagNotify(
        `omniauth - unexpectedly already authenticated - current: ${this.accountData?.email}`
      );
      await this.reset();
    }

    // 'mock' used by mst-web-proto
    if (provider === 'google' || provider === 'mock') {
      track('preauth__google_auth');
    } else {
      bugsnagNotify(`omniauth - invalid provider: ${provider}`);
      track('preauth__unexpected_auth');
    }

    const authParams = {
      provider,
      token,
      anonymous_id: anonymousId,
    };

    const result = await this.apiInvoker.post('users/omniauth', authParams);
    await this.applyAuthentication(result.userToken, {});
    await this.postAuthenticate();
  }

  /**
   * pretends to login via google and create new account if needed with
   * given first name if not already in system
   */
  async mockOmniauth(email: string, name: string): Promise<void> {
    await this.omniauth('mock', `${email}|${name}`);
  }

  async signup(credentials: {
    email: string;
    password: string;
    name: string;
    school_name: string;
    invite_code: string /* no longer relevant*/;
  }): Promise<void> {
    const { l1 } = this.root;
    const anonymousId = this.installationId;

    log.info(`create account, email: ${credentials.email}`);

    const signupUserData = this.userData.snapshot;

    if (this.authenticated) {
      bugsnagNotify(
        `signup - unexpectedly already authenticated - email: ${credentials?.email}, current: ${this.accountData?.email}`
      );
      await this.reset();
    }

    track('preauth__email_sign_up', { email: (credentials as any)?.email });

    const result = await this.apiInvoker.post<{
      userToken: string;
      userId: string;
    }>(
      'users/signup',
      {},
      {
        bodyData: {
          ...credentials,
          l1,
          anonymous_id: anonymousId,
        },
        networkIndicator: true,
      }
    );

    log.debug(`signup result: ${JSON.stringify(result)}`);
    AppFactory.analyticsManager.aliasNewAccount(result.userId);

    await this.applyAuthentication(result.userToken, { signupUserData });

    // reapply after the saved anonymous progress after the authentication
    // applySnapshot(this.userData, anonymousUserData);

    // // await this.persistUserData();
    // await this.storeUserData(); // ensure initial user data sent to firestore, bypass sync logic

    await this.postAuthenticate();

    if (this.autoMailingListOptIn) {
      log.debug(`auto mailing list opt in`);
      this.updateMailingListOptIn(true).catch(bugsnagNotify);
    }

    track('account__sign_up_successful', { kind: 'email' });
  }

  get autoMailingListOptIn(): boolean {
    if (!appConfig.mailingList.autoOptInEnabled) {
      return false;
    }

    const { email } = this.accountData;
    if (
      (email.endsWith('@jiveworld.com') || email.endsWith('@jw.school')) &&
      !email.includes('+ml')
    ) {
      return false;
    }

    return true;
  }

  // // mixpanel 'person' data already being set via rails server calls
  // // and i think we have enough denormalized event data to meet needs on GA
  // analyticsUpdateProfile() {
  //   AppFactory.analyticsManager.setProfileData(this.analyticsProfileData);
  // }

  analyticsIdentify() {
    AppFactory.analyticsManager.identify(this.accountData?.userId);
  }

  analyticsReset() {
    AppFactory.analyticsManager.reset();
  }

  get analyticsProfileData(): object {
    return {
      ...pick(this.accountData, [
        // todo: flush out useful props
        'userId',
        'email',
        'name',
        'fullAccess',
        'membershipState',
      ]),
      distinct_id: this.accountData.userId,
      // $distinct_id: this.accountData.userId,
    };
  }

  async postAuthenticate(appInit: boolean = false): Promise<void> {
    await this.postAuthAttributionCheck();

    // this.ensureWelcomeTipsAssigned();

    // was happening after inital page event, but after context set
    // AppFactory.analyticsManager.signedIn(this.accountData.userId);

    log.debug(
      `post auth root.l2: ${this.root.l2}, userData.selectedL2: ${this.userData.selectedL2}, selectedL1: ${this.userData.selectedL1}`
    );
    if (!this.userData.selectedL1) {
      const l1 = this.root.storyManager.l1;
      log.warn(`no selectedL1 - setting to catalog.l1: ${l1}`);
      await this.userData.selectL1(l1);
    }
    if (!this.userData.selectedL2) {
      log.warn(`no selectedL2 - setting to root.l2: ${this.root.l2}`);
      await this.userData.selectL2(this.root.l2);
    }
    // for now, allow
    // if (this.userData.selectedL2 !== this.root.l2) {
    //   if (appInit && this.authenticated) {
    //     this.root.showL2SwitchGuard();
    //   } else {
    //     // for now, if l2 mismatch while signing in, just silently redirect to the correct l2
    //     ReturnNavState.clearL2Cookie();
    //     window.location.href = `/${this.userData.selectedL2}`;
    //   }
    // }
    ReturnNavState.reset(this.root.l2);

    // // subscribed to by spa to update cookies (still relelvant?)
    // minibus.emit('LOGIN_COMPLETE', this);
  }

  async postAuthAttributionCheck() {
    if (!this.authenticated) {
      alertWarningError({
        error: Error('postAuthenticate call when not authenticated'),
      });
      return;
    }

    // const { affiliateCode } = this.root.localState;
    const affiliateCode = this.resolveAffiliateCode();
    if (affiliateCode) {
      try {
        if (appConfig.crossSiteCookiesSupported) {
          log.debug(`assuming affiliate attribution applied via server`);
          // assumes affiliate attribution and pricing already applied by server via jw-traffic-source
          // cookie data
          if (this.accountData.hasSpecialPricing) {
            // @daniel, what do you think about showing this success toast here?
            notifySuccess(
              __('Discounted pricing activated', 'discountActivated')
            );
          } else {
            log.info(`affiliate pricing apparently not applied after signup`);
          }
        } else {
          await this.applyAffiliateCodeAsCoupon(affiliateCode);
        }
        await this.resetAffiliateCode();
      } catch (error) {
        // don't risk being fatal
        alertWarningError({ error });
      }
    }
  }

  // ensureWelcomeTipsAssigned() {
  //   const { userSettings } = this.userData;
  //   if (userSettings.welcomeTipsEnabled === undefined) {
  //     userSettings.welcomeTipsEnabled = !this.accountData.hasEverPaid;
  //   }
  // }

  async logout(): Promise<void> {
    log.info('logout');
    // await this.reset();
    await this.applyNullAuthentication();
    this.root.applyLocale(); // hopefully this will help weird edgecase related to the l2 switch guard flow
    AppFactory.analyticsManager.reset(); // mixpanel.reset()
    // if (raygunEnabled) {
    //   rg4js('endSession');
    // }
  }

  /**
   * Like login, except that it takes a token instead of email/password
   * Used for deep links (native wrapper, email links) or server cookie auth
   *
   * NOTE: not expected to be used any more
   */
  async autoLogin(token: string): Promise<void> {
    log.info(`autoLogin(${token})`);

    track('preauth__auto_login');

    if (this.authenticated) {
      bugsnagNotify(
        `autoLogin - unexpectedly already authenticated - current: ${this.accountData?.email}`
      );
      await this.reset();
    }

    await this.applyAuthentication(token, {});
    await this.postAuthenticate();

    track('account__auto_login');
  }

  // normal startup flow when we have locally persisted state - invoked async'ly from app init
  async initAuthenticatedWithLocalData() {
    log.info(
      `initWithLocalRootData - token: ${this.token}, ac.email: ${this.accountData?.email}`
    );
    log.info(
      `catalogUrl - ac: ${this.catalogSlug}, sm: ${this.root?.storyManager?.slug}`
    );
    // todo: think this through again
    this.analyticsIdentify(); // just in case mixpanel cookies not left in a clean state from previous session
    try {
      await this.refreshAccountData();
      await this.syncUserData(); // needed?
    } catch (error) {
      if (isNetworkError(error as Error)) {
        log.warn(
          `initWithLocalData.syncFromServer network error: ${error} - ignoring`
        );
      } else {
        // todo: reset account data
        throw error;
      }
    }

    await this.postAuthenticate(true /*appInit*/);
  }

  // async anonymousInit() {
  //   log.info('anonymousInit');
  //   // const data = await this.fetchAccountData();
  //   // await this.applyNewAccountData(data);
  //   await this.applyNullAuthentication();
  // }

  resetLocalUserData() {
    applySnapshot(this.userData, {});
    this.persistLocal().catch(error =>
      alertWarningError({ error, note: 'um.resetLocalUseData' })
    );
  }

  // sync account data from rails server when made visible if not checked within given time
  // (default 5 min)
  async refreshAccountDataIfStale(minimumMills: number = 5 * 60 * 1000) {
    if (Date.now() > this.accountDataCheckedAt + minimumMills) {
      await this.refreshAccountData();
    } else {
      log.debug(
        `refreshAccountDataIfStale - skipped (${
          Date.now() - this.accountDataCheckedAt
        }ms)`
      );
    }
  }

  async reset(): Promise<void> {
    log.info('reset');
    await this.applyNullAuthentication();
  }

  // fetch affiliate welcome message and pricing data
  async applyAffiliateCode(code: string) {
    try {
      const existingAttribution = this.getTrafficSourceCookie();
      if (existingAttribution) {
        log.warn(
          `applyAffiliateCode(${code}) - existing traffice source cookie data found: ${JSON.stringify(
            existingAttribution
          )}`
        );
        return;
      }

      // this cookie is also the source-of-truth for the pending
      // affilate code used to resolve the pricing and welcome message
      await this.setTrafficSourceCookie({
        utmMedium: AFFILIATE_LANDING_PAGE_UTM_MEDIUM,
        utmSource: code,
      });

      if (this.authenticated) {
        if (appConfig.crossSiteCookiesSupported) {
          log.info(
            'applyAffiliateCode - already authenticated - will rely on rails side handling'
          );
        } else {
          // hacked support for test env w/o secure cookies
          await this.applyAffiliateCodeAsCoupon(code);
        }
      }
      // if authenticated assume account data will get updated via the server-side cookie set above,
      // if not, fetches anonymous pricing and dashboard co-branding data via localState data driven api param
      await this.refreshAccountData();
    } catch (error) {
      // don't risk being fatal, and don't report to end-user unexpected errors related to attribution
      alertWarningError({ error });
    }
  }

  async applyAffiliateCodeAsCoupon(code: string) {
    try {
      log.info(`applyAffiliateCodeAsCoupon(${code})`);
      await this.applyCoupon(code);
      if (this.accountData.hasAffiliatePricing) {
        notifySuccess(__('Discounted pricing activated', 'discountActivated'));
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        AppFactory.toastService.open({
          message: error.message, // all validation error should already have a message
          type: 'warning',
        });
      } else {
        throw error;
      }
    }
  }

  async resetAffiliateCode() {
    try {
      // called via dev-tools
      log.info('resetting affiliate attribution cookie and local state');
      await this.deleteServerCookie(TRAFFIC_SOURCE_COOKIE_KEY);
      if (!this.authenticated) {
        await this.refreshAccountData();
      }
    } catch (error) {
      // don't risk being fatal, and don't report to end-user unexpected errors related to attribution
      alertWarningError({ error });
    }
  }

  // todo: separate login/logout flows
  // used for both logging and and out (token=null for logout)
  async applyAuthentication(
    token: string,
    { signupUserData }: { signupUserData?: UserDataSnapshot }
  ): Promise<void> {
    if (!token) {
      await this.applyNullAuthentication();
      return;
    }
    this.loadingUserData = true;
    try {
      this.baseUserData = null; // make sure to not bleed any prior data into new user
      applySnapshot(this.userData, {});
      this.token = token;
      this.root.apiInvoker.setAuthToken(token);

      // if (!this.authenticated) {
      //   this.stopListen(); // stop listening asap when logging out since we have awaits below
      //   this.resetAuthentication();
      // }

      // should never be fatal
      this.setUserTokenCookie(token).catch(bugsnagNotify);

      await this.refreshAccountData();

      // if (this.authenticated) {
      const { userId } = this.accountData;
      AppFactory.analyticsManager.identify(userId);

      if (signupUserData) {
        // create account flow
        applySnapshot(this.userData, signupUserData);
        await this.storeUserData(); // ensure initial user data sent to firestore, bypass sync logic
      } else {
        // login flow
        await this.loadUserData();
      }

      const { storyManager } = this.root;
      storyManager.subscribeToCatalogSlug(this.catalogSlug);

      this.startListen();

      await this.persistLocal();
    } finally {
      this.loadingUserData = false;
    }
  }

  async applyNullAuthentication(): Promise<void> {
    // this.loadingUserData = true;
    // try {
    this.baseUserData = null; // make sure to not bleed any prior data into new user
    applySnapshot(this.userData, {});
    this.token = null;
    this.root.apiInvoker.setAuthToken(null);

    this.stopListen(); // stop listening asap when logging out since we have awaits below
    this.resetAuthentication();

    // should never be fatal
    this.setUserTokenCookie(null).catch(bugsnagNotify);

    await this.refreshAccountData();

    await this.persistLocal();
    // } finally {
    //   this.loadingUserData = false;
    // }
  }

  async setUserTokenCookie(token: string): Promise<void> {
    // // attempt to save to both local cache and server cookie since neither are guaranteed to work in all cases
    // await AppFactory.appStateCacher.storeObject(USER_TOKEN_COOKIE_KEY, token);
    await this.setServerCookie(USER_TOKEN_COOKIE_KEY, token);
    await this.setServerCookie(
      USER_TOKEN_TIMESTAMP_COOKIE_KEY,
      new Date().toISOString()
    );
  }

  getUserTokenCookie(): string {
    return Cookies.get(USER_TOKEN_COOKIE_KEY);
  }

  get userTokenCookieNeedsRefresh(): boolean {
    try {
      const timestampRaw = Cookies.get(USER_TOKEN_TIMESTAMP_COOKIE_KEY);
      if (timestampRaw) {
        const timestamp = new Date(timestampRaw).getTime();

        if (timestamp && timestamp + MILLIS_PER_DAY > new Date().getTime()) {
          return false;
        }
      }
      return true;
    } catch (error) {
      log.error(`userTokenCookieNeedsRefresh error: ${error}`);
      bugsnagNotify(error as Error);
      return false;
    }
  }

  async refreshUserTokenCookieIfNeeded(): Promise<boolean> {
    if (this.authenticated && this.userTokenCookieNeedsRefresh) {
      log.info('refreshing user token cookie');
      await this.setUserTokenCookie(this.token);
      return true;
    } else {
      return false;
    }
  }

  resetAuthentication() {
    applySnapshot(this, {
      token: null,
      accountData: {},
      userData: {},
      updatedTime: undefined,
      updatedGuid: undefined,
      baseUserData: undefined,
    });
    // this.ensureWelcomeTipsAssigned();
  }

  async fetchAccountData(): Promise<AccountData> {
    const code = this.resolveAffiliateCode();
    return await this.apiInvoker.get('users/account', {
      code,
      ts: new Date().getTime(), // ensure not cached
    });
  }

  /**
   * used to update server provided config data before logging in
   */
  async refreshPreauthAccountData(): Promise<void> {
    log.info(`refreshPreauthAccountData`);
    const data = await this.fetchAccountData();
    applySnapshot(this.accountData, data);
  }

  async refreshAccountData(): Promise<void> {
    this.accountDataCheckedAt = Date.now();
    log.debug(
      `refreshAccountData - before fetch - membershipState: ${
        this.accountData.membershipState
      }, now: ${new Date().toISOString()}`
    );
    const accoundData = (await this.fetchAccountData()) as AccountData;
    log.debug(
      `refreshAccountData - after fetch - membershipState: ${
        this.accountData.membershipState
      }, now: ${new Date().toISOString()}`
    );
    await this.applyNewAccountData(accoundData, {});
    await this.refreshNodeAccountData();
    this.accountData.studentAccessFetched = false;
  }

  async refreshNodeAccountData(): Promise<void> {
    try {
      if (this.authenticated) {
        const nodeAccountData =
          await AppFactory.caliServerInvoker.fetchNodeAccountData(
            this.accountData.userDataUuid
          );
        // this.nodeAccountData = nodeAccountData;
        applySnapshot(this.nodeAccountData, nodeAccountData || {});

        log.debug(
          `fetched node account data: ${JSON.stringify(nodeAccountData)}`
        );
      } else {
        applySnapshot(this.nodeAccountData, {});
      }
    } catch (error) {
      log.error(`refreshNodeAccountData error: ${error}`);
      bugsnagNotify(error as Error);
    }
  }

  /**
   * updates the memory state with freshly received account data from the server.
   * shared helper method invoked from the various places that we receive an
   * accountData response.
   * (private)
   */
  async applyNewAccountData(data: AccountData, options: {}): Promise<void> {
    log.info(`applyNewAccountData`);
    applySnapshot(this.accountData, data);
    this.root.setReportingContext();
    this.persistLocal().catch(bugsnagNotify);
  }

  get catalogSlug() {
    return this.root.catalogSlug;
  }

  // TODO: revisit, not sure if still needed or not
  // drives 'dataReady'
  setLastSynced() {
    this.userDataRefreshedAt = Date.now();
  }

  async persistUserData() {
    this.userData.markUpdated();
    this.userData.mirrorReferenceAccountData();

    this.persistLocal().catch(bugsnagNotify); // async
    if (this.authenticated) {
      await this.syncUserData();
    } else {
      log.debug('persistUserData - not autheticated - using local storage');
    }
  }

  async syncIfDeferred() {
    if (this.deferredSync) {
      this.deferredSync = false;
      await this.syncUserData();
    }
  }

  // used after login, when we know it's one-way fetch and it's important to block
  async loadUserData() {
    const { userDataSync } = AppFactory;
    let data = await userDataSync.fetch(this.accountData.userDataUuid);
    if (data) {
      this.baseUserData = data;
      applySnapshot(this.userData, data);
      this.setLastSynced();
      this.persistLocal().catch(bugsnagNotify);
      if (!this.userData.updatedGuid) {
        log.warn(`loadUserData - missing updatedGuid`);
      }
    } else {
      alertWarningError({
        error: Error('loadUserData - none fetched'),
      });
    }
  }

  // used after signup, when we know it's a one-way store
  async storeUserData() {
    if (!this.authenticated) {
      bugsnagNotify(`storeUserData - unexpectedly anonymous`);
      return;
    }

    this.userData.markUpdated();
    this.userData.mirrorReferenceAccountData();
    this.userData.populateTimestampAppVersion();

    const snapshot = this.userData.snapshot;
    this.baseUserData = snapshot;
    this.setLastSynced();

    await AppFactory.userDataSync.store(
      this.accountData.userDataUuid,
      snapshot
    );
  }

  // persist local deltas if any and fetch back latest data
  // assumes local and remote data already exists for the user
  async syncUserData() {
    if (!this.authenticated) {
      // we only care about local persistence when anonymous
      log.warn(`syncUserData - anonymous, skipping`);
      return;
    }

    log.info(`syncUserData`);
    this.persistLocal().catch(bugsnagNotify);

    if (
      AppFactory.root.offline
      // && AppFactory.firebaseConnection.status !== 'READY'
    ) {
      log.info(`syncUserData - offline & disconnected, deferring`);
      this.deferredSync = true;
      return;
    }

    await this.backupPriorIfNeeded();

    if (!this.baseUserData) {
      alertWarningError({
        error: Error('syncUserData - unexpectedly missing base'),
      });
      return;
    }

    this.userData.populateTimestampAppVersion();

    const localDataSnapshot = this.userData.snapshot;
    const [diff, warnings] = deepMergeDiff(
      this.baseUserData, // already in 'snapshot' form
      localDataSnapshot,
      {
        atomic: ['currentPoint', 'furthestPoint'],
        ignoreDelete: [
          'soundbiteUserData',
          'dismissedMessages',
          'welcomeTipsDisabled',
          'welcomeTipsEnabled',
          'favoritedStorySlugs',
          'lastSyncedVersion',
          'assistSettings',
          'destructiveImportPerformed',
          'overrideCatalogSlug',
          'selectedL1',
          'selectedL2',
        ],
      }
    ) as [UserDataSnapshot, object];
    const delta: UserDataSnapshot = diff;
    if (notEmpty(warnings)) {
      const message = `merge diff warnings: ${JSON.stringify(warnings)}`;
      // alertWarningError({ error: Error(message) });
      // bugsnagNotify(message);
      // reducing noisyness at least while we have parallel development branches
      log.warn(message);
    }
    log.debug(`delta: ${JSON.stringify(delta)}`);

    const { userDataSync } = AppFactory;

    // todo: think more about error handling if this fails
    try {
      // beware: this call will block indefinitely while offline
      let newUserData = await userDataSync.mergeSync(
        this.accountData.userDataUuid,
        delta
      );
      if (newUserData) {
        this.baseUserData = newUserData;
        applySnapshot(this.userData, newUserData);
        this.setLastSynced();
        // not entirely if local persist is needed here
        this.persistLocal().catch(bugsnagNotify);
      } else {
        alertWarningError({ error: Error(`mergeSync returned empty result`) });
      }
    } catch (error) {
      // make sure we have visibility
      log.error(`saveUserDataSnapshot error: ${error}`);
      bugsnagNotify(error as Error);
      throw error;
    }
  }

  startListen() {
    if (!this.authenticated) {
      log.debug(`startListen - ignored when anonymous`);
      return;
    }

    if (AppFactory.userDataSync.connectionReady) {
      log.debug('startListen - connectionReady');
      AppFactory.userDataSync.subscribe(
        this.accountData.userDataUuid,
        (userDataSnapshot: UserDataSnapshot) => {
          log.debug(
            `firestore listen - received user data snapshot - snapshot: ${userDataSnapshot?.updatedGuid}, local: ${this.userData.updatedGuid}, base: ${this.baseUserData.updatedGuid}`
          );
          if (
            !userDataSnapshot?.updatedGuid || // not expected for pre 8.x data
            !userDataSnapshot?.playerSettings ||
            !userDataSnapshot?._userId
          ) {
            if (
              // all valid snapshots should already have these
              !userDataSnapshot?.playerSettings ||
              !userDataSnapshot?._userId
            ) {
              log.error(
                `userDataSnapshot suspicious data, full dump: ${JSON.stringify(
                  userDataSnapshot
                )}`
              );
              bugsnagNotify(
                Error('firestore listen - bogus snapshot - ignoring')
              );
            } else {
              log.info(`firestore listen - missing updatedGuid, ignoring`);
            }
            // this was causing an infinite loop under some conditions
            // this.syncUserData().catch(bugsnagNotify);
            return;
          }

          if (this.userData.updatedGuid === userDataSnapshot.updatedGuid) {
            log.debug(`matched snapshot and local - ignoring`);
            return;
          } else {
            if (this.userData.updatedGuid === this.baseUserData.updatedGuid) {
              log.debug(`matched local and base - directly applying snapshot`);
              applySnapshot(this.userData, userDataSnapshot);
              this.baseUserData = userDataSnapshot;
              this.setLastSynced();
              // in case the catalog was changed in a concurrent session
              this.root.storyManager.subscribeToCatalogSlug(this.catalogSlug);
            } else {
              log.warn(
                `local differs from both snapshot and base - performing full sync`
              );
              this.syncUserData().catch(bugsnagNotify);
            }
          }
        }
      );
    } else {
      // todo: review if this is always appropriate here
      log.debug('startListen - disconnected - will perform one-time sync');
      this.syncUserData().catch(error => {
        log.error(`startListen (disconnected) sync data - error: ${error}`);
        bugsnagNotify(error);
      });
    }
  }

  stopListen() {
    AppFactory.userDataSync?.unsubscribe();
  }

  async persistLocal() {
    // const payload = this.stringify;
    // log.info(`persistLocal - ${payload?.length} bytes`);
    log.info(`persistLocal`);
    // return this.root.storeLocalUserData(payload);
    const data = this.snapshot;
    await AppFactory.appStateCacher.storeObject(APP_STATE_CACHE_KEY, data);
  }

  async resetLocalData() {
    await AppFactory.appStateCacher.remove(APP_STATE_CACHE_KEY);
    applySnapshot(this, {});
  }

  async loadLocal(): Promise<boolean> {
    // const data = await this.root.loadLocalUserData();
    const data = await AppFactory.appStateCacher.fetchObject(
      APP_STATE_CACHE_KEY
    );
    if (data) {
      applySnapshot(this, data);
      if (!this.baseUserData) {
        // todo: think about anonymous state
        log.warn(
          `loadLocal - missing baseUserData - cloning just loaded userData snapshot`
        );
        this.baseUserData = getSnapshot(this.userData);
      }
      this.root.apiInvoker.setAuthToken(this.token);

      log.info(
        `loadLocal - email: ${this.accountData?.email}, playbackRate: ${
          this.userData?.playerSettings?.playbackRate
        }, has baseUserData: ${String(!!this.baseUserData)}, membership: ${
          this.accountData.membershipState
        }`
      );
      return true;
    } else {
      log.info('loadLocal - no data');
      return false;
    }
  }

  // set's a Secure server cookie if able
  async setServerCookie(key: string, value: string) {
    try {
      if (appConfig.lambdaFunctions.enabled) {
        log.info(`setServerCookie: ${key}`);
        // await this.apiInvoker.api('users/set_cookie', null, {
        //   method: 'POST',
        //   body: JSON.stringify({ key, value }),
        // });
        const domain = appConfig.crossSiteCookiesSupported
          ? appConfig.website.cookieDomain
          : undefined; // undefined gets cleanly omitted by the JSON.stringify below
        await this.setAppCookie({
          key,
          value,
          domain,
        });
        return;
      }
    } catch (error) {
      log.error(
        `setCookie(${key}) error: ${error} - falling back to client-side cookie`
      );
      bugsnagNotify(error as Error);
    }
    if (value) {
      log.info(`setting cookie [${key}] via client-side api`);
      Cookies.set(key, value, {
        expires: LONG_COOKIE_AGE_DAYS,
        sameSite: 'Strict',
        secure: true, // this prevents localhost stored cookies on safari
      });
      if (Cookies.get(key) !== value) {
        // expected when testing safari against localhost
        log.warn(`fetched cookie mismatch - dropping secure flag`);
        Cookies.set(key, value, {
          expires: LONG_COOKIE_AGE_DAYS,
        });
        if (Cookies.get(key) !== value) {
          log.error(
            `refetched cookie mismatch, key: ${key}, value: ${String(value)}`
          );
        }
      }
    } else {
      log.info(`removing cookie [${key}] via client-side api`);
      Cookies.remove(key);
    }
  }

  // set long-lived cookie via netlify hosted lambda function
  async setAppCookie({
    key,
    value,
    domain,
  }: // sameSite = null, // will default to Strict
  {
    key: string;
    value: string;
    domain: string;
    // sameSite?: SameSiteValues;
  }) {
    log.info(`setAppCookie(${key}) (via lambda function)`);
    const response = await fetch(
      `${appConfig.lambdaFunctions.baseUrl}/set-app-cookie`,
      {
        method: 'POST',
        body: JSON.stringify({ key, value, domain /*, sameSite*/ }),
        // credentials: 'include',
      }
    );
    const text = await response.text();
    log.info(`setAppCookie resp: ${text}`);
  }

  async deleteServerCookie(key: string) {
    await this.setServerCookie(key, undefined);
  }

  resolveAffiliateCode() {
    const cookieData = this.getTrafficSourceCookie();
    if (cookieData?.utm_medium === AFFILIATE_LANDING_PAGE_UTM_MEDIUM) {
      return cookieData.utm_source;
    }
  }

  getTrafficSourceCookie(): TrafficSourceCookie {
    return getTrafficSourceCookie();
  }

  async setTrafficSourceCookie({
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    referrer,
  }: {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
    referrer?: string;
  }) {
    // cookie structure matching old Craft marketing site PHP implementation
    const cookieData = {
      version: 1,
      source: 'spa',
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_term: utmTerm,
      utm_content: utmContent,
      referrer,
    };

    log.info(`setTrafficSourceCookie: ${JSON.stringify(cookieData)}`);
    await this.setServerCookie(
      TRAFFIC_SOURCE_COOKIE_KEY,
      JSON.stringify(cookieData)
    );
  }

  //
  // account page operations
  //

  updateEmail(newEmail: string) {
    return this.updateProfileField('email', newEmail);
  }

  updateName(newName: string) {
    return this.updateProfileField('name', newName);
  }

  /**
   * note, we no longer that the old password is confirmed
   */
  updatePassword(newPassword: string) {
    return this.updateProfileField('password', newPassword);
  }

  updateSchoolName(newName: string) {
    return this.updateProfileField('school_name', newName);
  }

  updateRailsL1(locale: LocaleCode) {
    return this.updateProfileField('selected_l1', locale);
  }

  updateRailsL2(locale: LocaleCode) {
    return this.updateProfileField('selected_l2', locale);
  }

  updateRailsCurrency(currency: Currency) {
    return this.updateProfileField('selected_currency', currency);
  }

  // dev screen convenience
  async toggleClassroomActivation() {
    if (this.accountData.classroomEnabled) {
      await this.updateSchoolName('n/a');
    } else {
      await this.updateSchoolName('abc high');
    }
  }

  get classroomEnabled() {
    return this.accountData.classroomEnabled;
  }

  /**
   * Send update of name, email or password to server
   */
  async updateProfileField(
    key: string,
    value: string
  ): Promise<{ status: string; message: string } /*UpdateProfileFieldResult*/> {
    // const { message, accountData } = await this.apiInvoker.post(
    const result = await this.apiInvoker.post(
      'users/update_field',
      {
        key,
        value,
      },
      { networkIndicator: true }
    );
    const { accountData } = result;

    await this.applyNewAccountData(accountData, {});

    return result;
  }

  async toggleMailingListOptIn() {
    return this.updateMailingListOptIn(!this.accountData.mailingListOptIn);
  }

  async updateMailingListOptIn(value: boolean) {
    await this.updatePreference('mailing_list_opt_in', value);
    return this.userData.updateMailingListPromptEnabled(false);
  }

  /**
   * Update mailing list opt-in/out preference (key=mailing_list_opt_in, value=[boolean])
   * And potentially other server managed attributes in the future)
   */
  async updatePreference(key: string, value: any) {
    const result = await this.apiInvoker.post('users/update_preference', {
      key,
      value,
    });
    const { accountData } = result;

    await this.applyNewAccountData(accountData, {});
    return result;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
  }

  async sendShortAuth(email: string) {
    track('preauth__request_short_auth', { email });
    const endpoint = 'users/send_short_auth';
    const result = await this.apiInvoker.post<{
      message: string;
      messageKey: string;
    }>(
      endpoint,
      {},
      {
        bodyData: {
          email,
        },
        networkIndicator: true,
      }
    );

    // return { ...result, success: true, key: 'sendShortAuth' };
    return result;
  }

  async overrideCatalogSlug(slug: string) {
    this.userData.overrideCatalogSlug = slug;
    // this.userData.selectedL1 = null;
    this.userData.selectedL2 = null;
    await this.persistUserData();
    // const { storyManager } = this.root;
    // storyManager.subscribeToCatalogSlug(this.catalogSlug);
    reload();
  }

  async resetCatalogOverride() {
    this.userData.overrideCatalogSlug = null;
    // this.userData.selectedL1 = null;
    this.userData.selectedL2 = null;
    await this.persistUserData();
    reload();
  }

  async applyCoupon(code: string) {
    track('account__redeem_coupon', { code });

    const specialCouponResult = await this.handleSpecialCoupons(code);
    if (specialCouponResult !== false) {
      return specialCouponResult;
    }

    const l2 = this.root.l2;
    const result = await this.apiInvoker.post(
      'users/redeem_coupon',
      {},
      {
        bodyData: {
          code,
          l2,
        },
        networkIndicator: true,
      }
    );
    // possible result messageKey's:
    // success:
    //   api.applyCode.daysAdded
    //   api.applyCode.discountPricing
    //   api.applyCode.joinedClassroom (params: classroomLabel)
    //   api.applyCode.unlockApplied (not currently used, but potentially will want to support unlocking specific stories again in future)
    //   api.applyCode.applied (unexpected success state)
    //
    // error:
    //   api.applyCode.error.alreadyJoinedClassroom
    //   api.applyCode.error.invalidCode
    //   api.applyCode.error.alreadyRedeemed
    //   api.applyCode.error.discountAlreadyApplied
    //   api.applyCode.error.promotionOnlyNewAccount
    //   api.applyCode.error.disabled
    //   api.applyCode.error.expired
    //   api.applyCode.error.not_yet_valid
    //   api.applyCode.error.used
    //   api.applyCode.error.cannotApplyWithAutorenew (expecting to become obsolete)
    //   api.applyCode.error.codeRequired (ui guarded)
    //
    // internal (test/dev only usage):
    //   api.applyCode.accessExpired
    //   api.applyCode.autoRenewCancelled
    //
    // obsolete code path:
    //   api.join_classroom.invalid_code
    //

    const { accountData, ...extraParams } = result;
    log.debug(`extra params: ${extraParams}`);

    // there's no other way for mst to communicate this {messageKey, daysLeft} variables to the UI
    // getRoot(self).setFlash(extraParams);
    await this.applyNewAccountData(accountData, {});
    await this.refreshNodeAccountData();
    return result;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  }

  async handleSpecialCoupons(code: string) {
    switch (lowerCase(code)) {
      case 'expire':
        return await this.membership.forceExpireAccess();

      case 'crash':
      case 'crash2':
        (code as any).crashTest();
        return {};

      // todo: figure out a better place to put back a test hook for unexpected crashes
      // invariant(code !== 'invariant', 'invariant failure test');

      case 'crash3':
        // eslint-disable-next-line no-throw-literal
        throw 'crash3';

      // if (code === 'warn') {
      //   notifications.alertWarning('this is a test warning alert');
      //   return;
      // }
      // if (code === 'error') {
      //   notifications.alertError('this is a test error alert');
      //   return;
      // }

      case 'netfail':
        await fetch('http://foo.bar');
        return {};

      case 'debug':
        await this.apiInvoker.api(
          'users/debug',
          null, // query param
          {
            // additional fetch params
            method: 'POST',
            body: JSON.stringify({
              data: this.root.stringify,
            }),
          }
        );
        // notifications.notifySuccess('Debug data captured');
        return {};

      case '$success':
        return { message: 'This went well. Hurrah.' };

      default:
        return false;
    }
  }

  async sendCouponInstructions(code: string) {
    log.info('sendCouponInstructions');
    const result = await this.apiInvoker.post(
      'users/send_coupon_instructions',
      { code }
    );
    return result;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  }

  async resolveNodePlans(l2: LocaleCode): Promise<Plan[]> {
    const { currency, l1, dailySubscriptionEnabled } = this.root;
    const { pricingLevel } = this.accountData;
    const planDatas = await AppFactory.caliServerInvoker.resolvePlans({
      l1,
      l2,
      currency,
      pricingLevel,
      includeDaily: dailySubscriptionEnabled,
    });
    const result = planDatas.map(planData => new Plan(planData));
    return result;
  }

  async closeAccount() {
    const result = await this.apiInvoker.post(
      'users/close_account',
      {},
      { networkIndicator: true }
    );
    const { message } = result;
    log.debug(`message: ${message}`);

    // be sure to purge current state to prevent bleeding into new signup
    await this.logout(); //todo: false /*force*/, true /*skipSync*/);
  }

  // records given data into a 'debug' transaction in the rails postgres db
  async sendDebugData(data: object) {
    const result = await this.apiInvoker.post(
      'users/debug',
      {},
      {
        bodyData: {
          data: JSON.stringify(data),
        },
        networkIndicator: true,
      }
    );
    log.debug('result', result);
  }

  async backupPriorIfNeeded() {
    if (this.userData.backupNeeded) {
      try {
        await this.backupPriorData();
      } catch (error) {
        // don't risk being fatal
        alertWarningError({ error });
        // suppress additional attemps if unexpectedly failed
        this.userData.markBackedUp();
      }
    }
  }

  // automatically triggered as needed during sync
  async backupPriorData() {
    // log.info(`backing up to ${docId}`);
    const result = await AppFactory.caliServerInvoker.backupPriorData(
      this.accountData.userDataUuid
    );
    const { timestamp } = result;
    log.info(`backupPriorData timestamp: ${timestamp}`);

    this.userData.markBackedUp();

    return result;
  }

  // ad hoc backup via dev-tools
  async backupCurrentData() {
    // log.info(`backing up to ${docId}`);
    this.userData.markBackedUp();

    const result = await AppFactory.caliServerInvoker.createBackup(
      this.accountData.userDataUuid,
      this.userData.snapshot
    );
    const { timestamp } = result;
    log.info(`created backup timestamp: ${timestamp}`);
    return result;
  }

  async restore(timestamp: string) {
    const data = await this.fetchBackupData(timestamp);

    if (data) {
      applySnapshot(this.userData, data);
      await this.storeUserData();
    } else {
      log.error(`restore - data not found for timestamp: ${timestamp}`);
    }
  }

  async listBackups(limit: number = 10): Promise<{ timestamps: string[] }> {
    const result = await AppFactory.caliServerInvoker.listBackups(
      this.accountData.userDataUuid,
      limit
    );
    const { timestamps } = result;
    log.info(`list backups timestamps: ${timestamps.join(', ')}`);
    return result;
  }

  async fetchBackupData(timestamp: string) {
    const raw = await AppFactory.caliServerInvoker.fetchBackup(
      this.accountData.userDataUuid,
      timestamp
    );
    log.debug(`fetched data: ${JSON.stringify(raw).substring(0, 200)}`);
    return raw?.data;
  }

  async removeBackup(timestamp: string) {
    const response = await AppFactory.caliServerInvoker.removeBackup(
      this.accountData.userDataUuid,
      timestamp
    );
    log.debug(`remove response: ${JSON.stringify(response)}`);
  }

  async restoreUserData(tag: string) {
    const { userDataSync } = AppFactory;
    const { userDataUuid } = this.accountData;
    const docId = [userDataUuid, tag].join('.');
    log.info(`restoring from ${docId}`);
    const data = await userDataSync.fetch(docId);
    if (data) {
      applySnapshot(this.userData, data);
      await this.storeUserData();
    } else {
      log.error(`data not found`);
    }
  }

  async mergeInAllBackups() {
    const { userDataSync } = AppFactory;
    const { userDataUuid } = this.accountData;
    this.stopListen();
    await this.backupCurrentData();
    const { timestamps } = await this.listBackups(100);
    // assume timestamps are already in ascending chronological order
    for (const timestamp of timestamps) {
      const data = await this.fetchBackupData(timestamp);
      // const listeningLogKeys = Array.from(data.listeningLogMap?.keys()) || [];
      const listeningLogKeys = Object.keys(data.listeningLogMap || {});
      log.debug(`ts: ${timestamp}, llm keys: ${listeningLogKeys.join(', ')}`);
      await userDataSync.firestoreMerge(userDataUuid, data);
    }
    await this.syncUserData();
    this.startListen();
  }

  async zorchBaseData() {
    this.baseUserData = undefined;
    await this.persistLocal();
  }

  async importFirebaseUserData({
    apiEnv,
    uuid,
  }: {
    apiEnv: string;
    uuid: string;
  }): Promise<boolean> {
    log.info(`importFirebaseUserData - apiEnv: ${apiEnv}`);

    const importInvoker = new CaliServerInvoker({ apiEnv });

    const ts1 = new Date().getTime();
    const rawData = await importInvoker.fetchUserData(uuid);
    const ts2 = new Date().getTime();
    log.info(
      `importFirebaseUserData - rawData listening logs size: ${
        Object.entries(rawData.listeningLogMap || {}).length
      }, fetch duration: ${ts2 - ts1}ms`
    );

    if (rawData) {
      const ts3 = new Date().getTime();
      applySnapshot(this.userData, rawData);
      const ts4 = new Date().getTime();
      log.info(`applySnapshot duration: ${ts4 - ts3}ms`);
      await this.persistUserData();
      const ts5 = new Date().getTime();
      log.info(`persistUserData duration: ${ts5 - ts4}ms`);
      return true;
    } else {
      log.error(`failed to import data`);
      return false;
    }
  }

  async transplantUserData({
    fromEnv,
    fromEmail,
    toEnv,
    toToken,
  }: {
    fromEnv: string;
    fromEmail: string;
    toEnv: string;
    toToken: string;
  }) {
    if (!fromEnv || !fromEmail || !toEnv || !toToken) {
      throw Error('missing param(s)');
    }

    const fromInvoker = new ApiInvoker({
      apiEnv: fromEnv,
      authToken: null,
    });

    const toInvoker = new ApiInvoker({
      apiEnv: toEnv,
      authToken: toToken,
    });

    const data = await fromInvoker.get('users/data_by_email', {
      email: fromEmail,
      ts: new Date().getTime(), // ensure not cached
    });

    const payload = JSON.stringify(data);

    await toInvoker.api('users/data', null, {
      method: 'POST',
      body: JSON.stringify({
        client_data: payload,
      }),
    });
    // log.info(
    //   `post user data result lsv: ${resultAccountData?.lastSyncedVersion}`
    // );
  }

  async nukeFirestoreUserData() {
    log.info('nukeFirestoreUserData');
    const docId = this.accountData.userDataUuid;
    await this.logout();
    await AppFactory.userDataSync.nukeUserData(docId);
  }

  //
  // story list support
  //

  @computed
  get primaryFilterLabels() {
    const result: StringToString = {
      // these correlate to PrimaryFilterKeys
      all: __('All stories', 'allStories'),
      unplayed: __('Unplayed', 'unplayed'),
      queued: __('Study later', 'studyLater'),
      inProgress: __('In progress', 'inProgress'),
      completed: __('Complete', 'complete'),
    };
    this.accountData.joinedL2Classrooms.forEach(classroom => {
      result[classroom.filterKey] = classroom.label;
    });
    return result;
  }

  primaryFilterLabel(key: string) {
    return this.primaryFilterLabels[key];
  }

  //
  // classroom portal support
  //

  validateClassroomLabelAvailable(label: string) {
    if (this.classroomLabelExists(label)) {
      log.info('Classroom already exists', label);
      throw new ValidationError({
        key: 'classroom',
        message: __('Class name already exists', 'classNameAlreadyExists'),
      });
    }
  }

  classroomLabelExists(label: string) {
    const existingClass = this.accountData.managedL2Classrooms.find(
      classroom => {
        return (
          normalizedEqual(classroom.label, label) &&
          classroom.archived === false
        );
      }
    );

    return !!existingClass;
  }

  async createClassroom(label: string) {
    label = label?.trim(); // quietly ignore any leading/trailing whitespace
    this.validateClassroomLabelAvailable(label);
    log.info('will create Classroom', label);

    const l2 = this.root.l2;
    const result = await this.apiInvoker.post<{
      classroom?: Classroom;
      message: string;
      accountData: any;
    }>('classrooms', {}, { bodyData: { label, l2 }, networkIndicator: true });

    const {
      message,
      // messageKey,
      accountData,
    } = result;
    log.debug(`message: ${message}`);

    await this.applyNewAccountData(accountData, {});
    if (accountData && accountData.managedClassrooms) {
      // todo: have the server explicitly return the new classroom
      const classroom =
        accountData.managedClassrooms[accountData.managedClassrooms.length - 1];
      result.classroom = classroom;
    }
    return result;
  }

  async clearClassroomPortalWelcome() {
    // optimistic update
    //TODO this.accountData.setValue('classroomPortalWelcomePending', false);

    // now, do the persistent update
    const { accountData } = await this.apiInvoker.post(
      'users/clear_classroom_portal_welcome',
      {}
    );
    await this.applyNewAccountData(accountData, {});
  }

  get streakInterstitialEverShown() {
    return this.userData.userSettings.messageIsDismissed(
      'first-streak-interstitial'
    );
  }

  recordStreakInterstitialShow() {
    if (!this.streakInterstitialEverShown) {
      this.userData.userSettings.dismissMessage('first-streak-interstitial');
      this.persistUserData().catch(bugsnagNotify);
    }

    const { currentStreak, longestStreak, completedSoundbitesCount } =
      this.userData;

    track('engagement__streak_shown', {
      currentStreak,
      longestStreak,
      completedSoundbitesCount,
    });
  }

  get showTrialMessage() {
    return (
      !this.accountData.fullAccess &&
      !this.userData.userSettings.messageIsDismissed('trial-message')
    );
  }

  dismissTrialMessage() {
    this.userData.userSettings.dismissMessage('trial-message');
  }

  get showLearnMessage() {
    return (
      !this.classroomEnabled && // never show to teachers
      !this.userData.userSettings.messageIsDismissed('learn-message')
    );
  }

  dismissLearnMessage() {
    this.userData.userSettings.dismissMessage('learn-message');
  }

  get showTeacherMessage() {
    return (
      this.classroomEnabled &&
      !this.userData.userSettings.messageIsDismissed('teacher-message')
    );
  }

  dismissTeacherMessage() {
    this.userData.userSettings.dismissMessage('teacher-message');
  }

  get showDashboardAccountPrompt() {
    if (
      this.userData.userSettings.messageIsDismissed('dashboard-account-prompt')
    ) {
      return false;
    }

    if (this.userData.totalPoints > 50) {
      return true;
    }

    return false;
  }

  dismissDashboardAccountPrompt() {
    this.userData.userSettings.dismissMessage('dashboard-account-prompt');
  }

  //
  // push notification support
  //

  saveUserPushToken({
    deviceInstallId,
    token,
  }: {
    deviceInstallId: string;
    token: string;
  }) {
    // return this.apiInvoker.post('users/save_push_token', {
    //   deviceInstallId,
    //   token,
    // });
    log.info(
      `saveUserPushToken - deviceInstallId: ${deviceInstallId}, token: ${token}`
    );
    this.root.localState.storePushToken(token).catch(bugsnagNotify);
  }
}

export const getTrafficSourceCookie = (): TrafficSourceCookie => {
  // return await this.getServerCookie(TRAFFIC_SOURCE_COOKIE_KEY);
  const raw = Cookies.get(TRAFFIC_SOURCE_COOKIE_KEY);
  if (notEmpty(raw)) {
    try {
      const result = JSON.parse(raw);
      return result;
    } catch (error) {
      alertWarningError({ error, note: 'parsing jw-traffic-source cookie' });
    }
  }
  return null;
};

// // deepmerge strategy appropriate for our UserData merge
// // https://github.com/TehShrike/deepmerge#arraymerge-example-overwrite-target-array
// const overwriteMerge = (
//   destinationArray: any[],
//   sourceArray: any[],
//   options: any
// ) => sourceArray;
