import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
// import { PlayerMode } from '@common/misc-types';
// import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
// import { SoundbitesChapterBadge } from 'components/stories/soundbite-story-badge';
import { keyframes, styled } from '@naan/stitches.config';
import { ChapterSoundbites } from './chapter-item-contents/chapter-soundbites';
import autoAnimate from '@formkit/auto-animate';
import { ChevronUpIcon } from '@naan/icons/chevron-icon';
import { Box } from '@naan/primitives/box';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { PlayerMode } from '@common/misc-types';
import { useChapterPositionUiContext } from './chapter-item-contents/chapter-position-ui-model';
import { ChapterSoundbiteBadge } from './components/chapter-soundbite-badge';
// import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';

import __ from '@core/lib/localization';
import { ChapterItemContents } from './chapter-item-contents/chapter-item-contents';
import { CompletedCheckmark } from './components/completed-checkmark';
import { ChapterReviewCard } from './chapter-item-contents/chapter-review-card';
import { VSpacer } from '@naan/primitives/spacer';
import { ChapterReviewOverflowMenu } from './chapter-item-contents/chapter-review-overflow-menu';

const spinOnceAnimation = keyframes({
  '0%': {
    transform: 'rotateX(90deg)',
  },
  '100%': {
    transform: 'rotateX(0deg)',
  },
});

const Accessory = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  '& .chevron': {
    color: '$gray-400',
    // run animation once
    animation: `${spinOnceAnimation} .2s linear`,
  },
});

// // unfactored this component. It has too many subtle variatons to make it worth having a separate component.
// const BadgeWrapper = styled('div', {
//   all: 'unset',
//   cursor: 'pointer',
//   display: 'flex',
//   alignItems: 'center',
//   color: '$gray-400',
//   textStyle: 'small-text-bold',
// });

export const Completed = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const positionUiModel = useChapterPositionUiContext();
    const expanded =
      positionUiModel.currentChapterPosition === chapter.position;
    const parent = React.useRef<HTMLDivElement>(null);
    const navigateToStudy = useNavigateToStudy();

    React.useEffect(() => {
      parent.current &&
        autoAnimate(parent.current, {
          duration: 150,
          easing: 'ease-out',
        });
    }, [parent]);

    return (
      <div ref={parent} style={{ overflowY: 'clip' }}>
        <div
          onClick={() => {
            positionUiModel.setChapterPosition(chapter.position);
          }}
        >
          <ChapterListHeader
            chapter={chapter}
            stationIcon={<CompletedCheckmark />}
            accessory={
              <Accessory>
                {expanded ? (
                  <div className="chevron">
                    <ChevronUpIcon />
                  </div>
                ) : (
                  <>
                    <ChapterSoundbiteBadge chapter={chapter} />
                    {/* <CheckmarkCircleIcon color="var(--colors-green-500)" /> */}
                  </>
                )}
              </Accessory>
            }
          />
        </div>
        {expanded ? (
          <ChapterItemContents>
            <Box css={{ paddingBottom: 16 }}>
              <ChapterSoundbites chapter={chapter} />
              {/* <Box
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 16,
                }}
              >
                <Button
                  label={__('Relisten from here', 'relistenFromHere')}
                  presentation="grayDark"
                  leftIcon={<ReplayIcon />}
                  size={'small'}
                  onClick={() => {
                    chapter.review();
                    navigateToStudy(chapter, PlayerMode.FLUENT_LISTEN);
                  }}
                />
              </Box> */}
              <VSpacer size={4} />
              <ChapterReviewCard
                overflowMenu={<ChapterReviewOverflowMenu chapter={chapter} />}
                buttonLabel={__('Review from here', 'reviewFromHere')}
                onClick={() => {
                  chapter.review();
                  navigateToStudy(chapter, PlayerMode.FLUENT_LISTEN);
                }}
              />
            </Box>
          </ChapterItemContents>
        ) : null}
      </div>
    );
  }
);
