//
// supports sending all log messages to a memory buffer which can be viewed
// and managed from the dev-tools
//

import { appConfig } from 'app/env';
import { makeObservable, observable } from 'mobx';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    logToNative: (...args: any[]) => void;
  }
}

export const argsToString = (...args: any[]) =>
  args
    .map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : String(arg)))
    .join(' ');

export class BufferLogger {
  // beware, too dangerous to observe the buffer, can lead to cascading rerenders
  buffer: string[] = [];

  // when true, logging forwarded to js console
  @observable
  consoleActivated: boolean = true;

  // when true, log messages captured into a memory buffer
  @observable
  bufferActivated: boolean = false;

  @observable
  nativeRelayActivated: boolean = false;

  bufferLineLimit: number = 2000;
  bufferTrim: number = 0.25; // what percentage to trim from head when over limit

  constructor() {
    makeObservable(this);
    const config = appConfig.logging.bufferLogger;
    this.consoleActivated = config.consoleActivated;
    this.bufferActivated = config.bufferActivated;
    this.nativeRelayActivated = config.nativeRelayActivated;
    this.bufferLineLimit = config.bufferLineLimit;
    this.bufferTrim = config.bufferTrim;
  }

  // arrow notation needed to properly bind 'this'
  log = (...args: any[]) => {
    if (this.consoleActivated) {
      // eslint-disable-next-line no-console
      console.log(...args);
    }

    if (this.bufferActivated) {
      // const message = args
      //   .map(arg =>
      //     typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
      //   )
      //   .join(' ');
      const message = argsToString(...args);
      if (this.buffer.length > this.bufferLineLimit) {
        this.buffer = this.buffer.slice(
          Math.floor(this.bufferLineLimit * this.bufferTrim)
        );
      }
      this.buffer.push(message);
    }

    if (this.nativeRelayActivated && window.logToNative) {
      window.logToNative(...args);
    }
  };

  toggleBuffer() {
    this.bufferActivated = !this.bufferActivated;
  }

  toggleConsole() {
    this.consoleActivated = !this.consoleActivated;
  }

  toggleNativeRelay() {
    this.nativeRelayActivated = !this.nativeRelayActivated;
  }

  clear() {
    this.buffer = [];
  }

  get bufferText(): string {
    return this.buffer.join('\n');
  }
}

// singleton
export const bufferLogger = new BufferLogger();

(window as any).bufferLogger = bufferLogger;
