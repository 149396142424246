import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { FullyStyledMarkdown } from '@naan/primitives/text';
import { StoryProgress } from '@core/models/user-manager/story-progress';
import { bugsnagNotify, notifySuccess } from '@app/notification-service';
import { Story } from '@core/models/story-manager';
import { AppFactory } from '@app/app-factory';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { resolveApiMessage } from '@core/models/user-manager/api-messages';

import __ from '@core/lib/localization';

const handleExportVocab = async (progress: StoryProgress): Promise<void> => {
  // const { message } = await progress.exportVocab();
  const result = await progress.exportVocab();
  const message = resolveApiMessage(result);
  notifySuccess(message);
};

export const presentVocabExportDialog = (story: Story) => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <ExportVocabDialog story={story} onDismiss={onDismiss} />
  ));
};

const dialogCopyFn = () =>
  __(
    `You will receive an email with a data file containing your vocabulary list for this story. It can be imported into popular flashcard apps like Anki and Quizlet.

We recommend you do this after you have finished a story to avoid duplicate entries.`,
    'youWillReceiveAnEmailWithFiles'
  );

const ExportVocabDialog = ({
  story,
  onDismiss,
}: {
  story: Story;
  onDismiss: () => void;
}) => {
  const exportVocab = React.useCallback(() => {
    handleExportVocab(story.progress)
      .then(() => onDismiss())
      .catch(bugsnagNotify);
  }, [onDismiss, story.progress]);

  return (
    <DialogHtmlContainer onDismiss={onDismiss}>
      <Dialog.HtmlHeading>
        {__('Export vocab', 'exportVocab')}
      </Dialog.HtmlHeading>
      <Dialog.Body>
        {<FullyStyledMarkdown source={dialogCopyFn()} />}
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          label={__('Cancel', 'cancel')}
          onClick={onDismiss}
          presentation={'grayTransparent'}
        />
        <Dialog.Button
          label={__('Export', 'export')}
          onClick={exportVocab}
          presentation={'teal'}
        />
      </Dialog.ButtonsContainer>
    </DialogHtmlContainer>
  );
};
