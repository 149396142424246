import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { PointsCard } from '../../components/ui/player-end-card/points-card';
import { Button } from '@naan/primitives/button';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { homePath, learnHomePath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';
import { useSoundbiteCompletionState } from 'components/ui/player-end-card/use-soundbite-completion-state';
import { SoundbiteEndCardHeader } from 'components/ui/player-end-card/end-card-header';
import { EndCardCta } from 'components/cta/end-card-cta';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import __ from '@core/lib/localization';
import { createLogger } from '@common/log';

const log = createLogger('sb-final-card');

// TODO: move into element-ids.ts, but only after we have merged the other open PRs
export const FINAL_CARD_ID = 'final-card;';

const OutWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$transparent',
  '@widerThanScript': {
    paddingTop: '$space$4',
    paddingBottom: '$space$5',
  },
});

const CardWrapper = styled('div', {
  margin: '0 auto',
  background: '$white',
  padding: '0 16px 16px',
  width: '100%',

  '@widerThanScript': {
    width: 600,
    borderRadius: 16,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  },
});

export const SbFinalCard = observer(() => {
  const { fullAccess } = AppFactory.root.userManager;
  const model = AppFactory.soundbiteModel;
  const completionState = useSoundbiteCompletionState();

  const soundbite = model?.soundbite;
  if (!soundbite) {
    log.error('missing soundbite state');
    return null; // attempt to make admin preview more resiliant
  }
  const onboardingActive = OnboardingService.instance.isOnboardingActive;
  const onboardingSoundbite = soundbite.isTheOnboardingSoundbite;
  const onboarding = onboardingActive && onboardingSoundbite;

  const hideEndCardCta = onboarding || fullAccess;
  const hidePointsCard =
    onboarding || completionState === 'previously-completed';

  const navigate = useNavigate();

  if (completionState === 'uncompleted') {
    return null;
  }

  const label = __('Continue', 'continue');

  return (
    <OutWrapper id={FINAL_CARD_ID}>
      <CardWrapper>
        <SoundbiteEndCardHeader
          soundbite={soundbite}
          completionState={completionState}
        />
        {hidePointsCard ? null : <PointsCard />}
        {/* {anonymous ? (
          <OnboardingSection />
        ) : ( */}
        {/* <> */}
        <Button
          label={label}
          presentation={'teal'}
          size={'large'}
          fullWidth
          onClick={() => {
            if (ReturnNavState.canPopFromStudy()) {
              navigate(-1);
            } else {
              navigate(homePath());
            }
          }}
        />
        {hideEndCardCta ? null : (
          <EndCardCta playerType="soundbites" backTo={learnHomePath()} />
        )}
        {/* </> */}
        {/* )} */}
      </CardWrapper>
    </OutWrapper>
  );
});
