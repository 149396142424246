import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import { CompletedCheckmark } from '../components/completed-checkmark';
import { ReviewIcon } from '@naan/icons/review-icon';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$gray-50',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
  paddingTop: 40,
  borderRadius: 16,
  backgroundColor: '$$backgroundColor',
  transition:
    'background-color 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
  overflow: 'auto',
  '& .heading': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 40,

    '& svg': {
      width: 40,
      height: 40,
      marginBottom: 4,
    },
    '& .title': {
      textStyle: 'small-heading',
    },
  },

  '@medium': {
    paddingTop: 56,
    '& .heading': {
      marginBottom: 56,
    },
  },
});

const OverflowMenuWrapper = styled('div', {
  position: 'absolute',
  top: 4,
  right: 8,
});

export const ChapterReviewCard = observer(
  ({
    buttonLabel,
    onClick,
    progress,
    overflowMenu,
  }: {
    progress?: number;
    buttonLabel: string;
    onClick: () => void;
    overflowMenu?: React.ReactNode;
  }) => {
    return (
      <Wrapper>
        <OverflowMenuWrapper className="something">
          {overflowMenu}
        </OverflowMenuWrapper>

        <div className="heading">
          <CompletedCheckmark />
          <span className="title">
            {__('Chapter complete', 'chapterComplete')}
          </span>
        </div>
        <Button
          onClick={onClick}
          label={buttonLabel}
          size="extraLarge"
          presentation={progress ? 'grayDarkProgress' : 'grayDark'}
          fullWidth
          leftIcon={<ReviewIcon />}
          style={
            progress
              ? ({
                  '---currentProgress': `${progress}%`,
                } as any)
              : null
          }
        />
      </Wrapper>
    );
  }
);
