import Dayjs from 'dayjs';
import __ from 'core/lib/localization';
import { ModelTreeNode } from 'ts-state-tree/tst-core';

const localizeLicenseDate = (date: string) => Dayjs(date).format('ll');

export class License extends ModelTreeNode {
  static CLASS_NAME = 'License' as const;

  static create(snapshot: any) {
    return super.create(License, snapshot) as License;
  }

  isActive: boolean = false;
  isExpired: boolean = false;
  startDate: string = null; // never expected to be null - server will default to 'today' if missing from db
  endDate: string = null; // never expected to be null - server will default to 'today' if missing from db

  seatLimit: number = 0;
  studentCount: number = 0;
  isOverSubscribed: boolean = false;

  ownerName: string = null; // not currently used by client
  licenseNotes: string = null; // not currently used by client

  // todo: remove these once new license support fully rolled out
  seatsTaken: number = 0; // beware, should not be used anymore. remove once all other project dependencies are removed
  seatsAvailable: number = 0;
  fixedDurationMonths: number = 0;
  fixedExpirationDate: string = null; // iso8061 formatted date string
  durationDescription: string = null; // no longer displayed within client

  get expirationDateAsDate() {
    // TODO: take timezones into account. That's why UNIX timestamps ftw
    return new Date(this.fixedExpirationDate);
  }

  get localizedExpirationDate() {
    return localizeLicenseDate(this.fixedExpirationDate);
  }

  get licenseDescription() {
    if (this.isExpired) {
      return __('Expired, %{date}', 'expiredDate', {
        date: localizeLicenseDate(this.endDate),
      });
    } else {
      return __('%{startDate}–%{endDate}', 'startDashEnd', {
        startDate: localizeLicenseDate(this.startDate),
        endDate: localizeLicenseDate(this.endDate),
      });
    }
  }

  get seatsDescription() {
    return __('%{taken}/%{limit}', 'takenSlashLimit', {
      taken: this.studentCount,
      limit: this.seatLimit,
    });
  }

  get type() {
    if (this.fixedExpirationDate !== null) {
      return 'fixed-date';
    }

    return 'time-based';
  }
}
