import * as React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { NotFoundScreen } from 'routes/not-found-screen';
import { hideBottomTabBar, showBottomTabBar } from 'components/global-layout';
import { StoryNavState } from './story-nav-state';
import { useEnableUpdatePrompt } from '@common/hooks/use-enable-update-prompt';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { getOnboardingInterstitialComponent } from './onboarding/get-onboarding-interstitials';
import { StoryDetailScreen } from './story-detail-screen';
import { createLogger } from '@common/log';
import { Story } from '@core/models/story-manager';

const log = createLogger('story-detail-screen');

const useOnboardingEffects = (story: Story | undefined) => {
  const { storyManager } = AppFactory.root;

  React.useEffect(() => {
    log.debug('onboarding useEffect');
    if (!story) {
      return; // possible when bad slug presented and not-found screen shown
    }
    const onboardingService = OnboardingService.instance;
    const onboardingStory = storyManager.onboardingStory;
    if (story.slug === onboardingStory?.slug) {
      if (onboardingService.isDismissed('onboardingComplete')) {
        return;
      }
      if (!onboardingService.isDismissed('onboardingInitiated')) {
        // automatically consider complete if story complete
        // if (story.progress.completed) {
        //   log.info('onboarding story already complete - skipping flow');
        // automatically consider complete if first chapter complete
        if (onboardingStory.onboardingStateSatisfied) {
          log.info('onboarding story already complete - skipping flow');
          onboardingService.dismiss('onboardingComplete');
          return;
        }
        if (story.progress.completed) {
          log.info('onboarding story already complete - skipping flow');
          onboardingService.dismiss('onboardingComplete');
          return;
        }
        // first visit after new onboarding flow deployed
        if (!onboardingStory.onboardingStateClean) {
          // automatically reset the state if in-progress
          log.info('onboarding story in-progress - resetting state');
          onboardingService.resetOnboardingState();
        }
        log.info('onboarding initiated ');
        onboardingService.dismiss('onboardingInitiated');
      }
    }
  }, [story, storyManager]);
};

export const StoryDetailOrInterstitialScreen = observer(() => {
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);

  useEnableUpdatePrompt();
  useOnboardingEffects(story);

  React.useEffect(() => {
    StoryNavState.externalLanding = false;
    hideBottomTabBar();

    OnboardingService.instance.onStoryDetail(story);

    return () => {
      StoryNavState.externalLanding = true;
      showBottomTabBar();
    };
  }, [story]);

  if (!story) {
    return <NotFoundScreen reportError={false} />;
  }

  if (story.slug === storyManager.onboardingStorySlug) {
    const OnboardingInterstitialComponent = getOnboardingInterstitialComponent({
      story,
    });
    if (OnboardingInterstitialComponent) {
      return <OnboardingInterstitialComponent story={story} />;
    }
  }

  return <StoryDetailScreen story={story} />;
});
