import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import { Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { useNavigate } from 'react-router-dom';
import { appConfig } from 'app/env';
import { AppFactory } from '@app/app-factory';
import { openUrl } from '@naan/primitives/text/external-link';
import __ from 'core/lib/localization';
import { discoverPath } from 'components/nav/path-helpers';
import { clearSavedScrollPosition } from 'lib/scroll-utils/deep-scroll-restoration';
// import { Dialog } from '@radix-ui/react-dialog';

const StackedDialogButtonWrapper = styled('button', {
  all: 'unset',
  textStyle: 'body-bold',

  padding: '$space$4',
  textAlign: 'center',
  cursor: 'pointer',
  variants: {
    presentation: {
      primary: {
        color: '$teal-500',
        '&:hover': {
          color: '$teal-600',
        },
      },
      secondary: {
        color: '$gray-400',
        '&:hover': {
          color: '$gray-500',
        },
      },
    },
  },
  defaultVariants: { presentation: 'primary' },
});

type StackedDialogButtonProps = React.ComponentProps<
  typeof StackedDialogButtonWrapper
> & {
  label: string;
  testId?: string;
};

export const StackedDialogButton = React.forwardRef(
  (
    {
      label,
      disabled = false,
      testId,
      ...restOfProps
    }: StackedDialogButtonProps,
    ref
  ) => {
    return (
      <StackedDialogButtonWrapper
        disabled={disabled}
        aria-label={label}
        data-testid={testId}
        ref={ref as any}
        {...restOfProps}
      >
        {label}
      </StackedDialogButtonWrapper>
    );
  }
);

const salesEmail = appConfig.website.emails.educationalSales;
const emailSubjectFn = () =>
  __(
    'About teaching with Jiveworld and Jiveworld Classroom',
    'aboutTeachingSubject'
  );
const emailBodyFn = () => {
  const { l2Localized } = AppFactory.root;

  return __(
    // @daniel, can we simplify the l2 qualification here?
    "Tell us what you're interested in. If you're inquiring about a group license for your %{l2Localized} class let us know the best method and time to contact you.",
    'aboutTeachingBody',
    { l2Localized }
  );
};

export const LockedResourceDialog = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const navigate = useNavigate();

    const goToDiscover = React.useCallback(() => {
      const path = discoverPath();
      clearSavedScrollPosition(path);
      navigate(path);
      onDismiss();
    }, [navigate, onDismiss]);

    const contactUs = React.useCallback(() => {
      // TODO: replace with form
      const emailLink = `mailto:${salesEmail}?subject=${emailSubjectFn()}&body=${emailBodyFn()}`;
      openUrl(emailLink);
      onDismiss();
    }, [onDismiss]);

    return (
      <Dialog.Container open={true} onDismiss={onDismiss}>
        <Dialog.Heading>
          {__(
            'Resources for this story are locked',
            'resourcesForThisStoryAreLocked'
          )}
        </Dialog.Heading>
        <Dialog.Body>
          <Text>
            {__(
              'If you are purchasing a license for your classroom, please contact us to unlock all resources.',
              'ifYouArePurchasingForYourClassroom'
            )}
          </Text>
          <VSpacer size={4} />
          <Text>
            {__(
              'Our free story comes with full resources for you to sample and review.',
              'ourFreeStoryComesWithFullResources'
            )}
          </Text>
        </Dialog.Body>
        <Dialog.ButtonsContainer direction={'column'}>
          <Dialog.Button
            presentation={'teal'}
            label={__('Contact us to unlock', 'contactUsToUnlock')}
            onClick={contactUs}
          />
          <Dialog.Button
            presentation={'secondary'}
            label={__('View free story', 'viewFreeStory')}
            onClick={goToDiscover}
          />
          <Dialog.Button
            presentation={'cancel'}
            label={__('Close', 'close')}
            onClick={onDismiss}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
