import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { styled } from '@naan/stitches.config';
import { appConfig } from '@app/config';
import { formatDate } from '@utils/format-date';
import illustrationSrc from './assets/piggybank-illustration.png';
import { Image } from '@naan/primitives/image';
import { daysCount } from '@core/lib/localization-helpers';

import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  background: '$yellow-300',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
  borderRadius: 16,
  gap: 8,
  '& .image': {
    img: {
      width: 80,
      aspectRatio: 1,
    },
  },
  '& .copy': {
    color: '$colors$black-alpha-80',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& h3': {
      textAlign: 'center',
      textStyle: 'small-heading',
    },
  },

  '@medium': {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    gap: 16,
    '& .image': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .copy h3': {
      textAlign: 'left',
    },
  },
});

export const PriceIncreaseSubscribeCta = observer(() => {
  const { userManager, storyManager } = AppFactory.root;
  const { membership } = userManager;
  const showPrinceIncreaseMessage = membership.showPriceIncreaseInlineNotice;

  if (!showPrinceIncreaseMessage) {
    return null;
  }

  const date = formatDate(appConfig.priceIncreaseDate, 'medium');
  const productName = storyManager.productName;
  const remainingDays = membership.remainingFullAccessInDays;

  return (
    <Wrapper>
      <div className="image">
        <Image src={illustrationSrc} />
      </div>
      <div className="copy">
        <h3>{__('Beat the price increases', 'beatThePriceIncrases')}</h3>
        <div className="text-body">
          {productName} costs the same as 3 years ago, but on {date} prices are
          going up.{' '}
          {userManager.fullAccess ? (
            <>
              Extend your membership with a subscription and enjoy 2021 prices
              for as long as you stay with us. You won't be charged until your
              current access expires in {daysCount(remainingDays)}, and you can
              cancel anytime.
            </>
          ) : (
            <>
              Start your journey to Spanish fluency with us now and you'll enjoy
              2021 prices while you remain subscribed.,
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
});
