import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { paletteKey } from '../../../study/views/study-palette';
import { PlainMarkdown } from '@naan/primitives/text';
// import { SimpleNotationsListPanel } from 'study/views/notations/simple-notations-list';
import { SentenceId } from '@tikka/basic-types';
import { SentenceExtrasGeneral } from './sentence-extras';
// import { usePlayerModel } from '../player-model-context';

// const UnredactButton = styled('button', {
//   all: 'unset',
//   display: 'flex',
//   position: 'absolute',
//   width: '48px',
//   height: '36.87px',
//   right: '0',
//   top: '-16px',
//   background: '$colors$white',
//   boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
//   borderRadius: '8px',
//   alignItems: 'center',
//   justifyContent: 'center',
// });

// const RedactionIcon = ({ color = 'currentColor' }: { color?: string }) => {
//   return (
//     <svg
//       width={28}
//       height={28}
//       viewBox="0 0 28 28"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M8.12851 18.6201C8.67517 18.6201 9.11249 18.4184 9.48734 17.8134L9.17497 17.4721C9.00316 17.6738 8.83136 17.8134 8.56584 17.8134C8.22223 17.8134 8.00357 17.5652 8.00357 16.898V13.5935C8.00357 11.6387 7.22263 10.8941 5.58267 10.8941C3.98956 10.8941 2.83378 11.6387 2.53703 12.8178C2.58388 13.2212 2.83378 13.4539 3.27111 13.4539C3.69281 13.4539 3.94271 13.1902 4.08328 12.7092L4.38003 11.7008C4.70802 11.6232 4.98916 11.6077 5.22344 11.6077C6.25427 11.6077 6.6916 11.9801 6.6916 13.4849V13.8728C6.06685 14.0124 5.41086 14.2141 4.92669 14.3848C2.81816 15.1449 2.33398 15.8431 2.33398 16.7274C2.33398 18.0151 3.28672 18.6667 4.42689 18.6667C5.34839 18.6667 5.86381 18.2633 6.72283 17.4255C6.84778 18.1547 7.37882 18.6201 8.12851 18.6201ZM3.72405 16.4947C3.72405 16.0293 3.89585 15.4397 5.25468 14.9122C5.58267 14.7881 6.14494 14.6019 6.6916 14.4468V16.836C5.80133 17.4566 5.50458 17.6427 4.97354 17.6427C4.25508 17.6427 3.72405 17.3169 3.72405 16.4947Z"
//         fill={color}
//       />
//       <path
//         d="M14.4195 17.7824C13.8417 17.7824 13.2013 17.5341 12.5609 16.9291V12.6782C13.4043 11.9801 14.0135 11.7784 14.4976 11.7784C15.669 11.7784 16.4812 12.8488 16.4812 14.8347C16.4812 16.836 15.5285 17.7824 14.4195 17.7824ZM10.0463 18.465L12.3891 18.6201L12.5141 17.5807C13.1857 18.4184 13.9354 18.6667 14.6694 18.6667C16.4968 18.6667 17.8869 17.0997 17.8869 14.7105C17.8869 12.3059 16.5749 10.8941 14.9193 10.8941C14.0603 10.8941 13.2638 11.2664 12.5141 12.0887V9.32713L12.5765 7.12411L12.3579 7L9.96823 7.69814V8.24113L11.249 8.39628V15.207C11.249 15.9051 11.2333 16.9291 11.2177 17.7048L10.0463 17.9065V18.465Z"
//         fill={color}
//       />
//       <path
//         d="M22.9184 11.6077C23.1839 11.6077 23.4651 11.6387 23.7618 11.7318L24.043 12.7558C24.1523 13.1902 24.371 13.5004 24.902 13.5004C25.2925 13.5004 25.5424 13.3143 25.6205 12.9109C25.4174 11.7629 24.2616 10.8941 22.856 10.8941C20.9817 10.8941 19.248 12.3524 19.248 14.8036C19.248 17.1928 20.6225 18.6667 22.7622 18.6667C24.2304 18.6667 25.23 17.9685 25.6673 16.6809L25.2768 16.4792C24.7927 17.2394 24.0117 17.6582 23.1215 17.6582C21.6533 17.6582 20.6225 16.6033 20.6225 14.6485C20.6225 12.6627 21.5908 11.6077 22.9184 11.6077Z"
//         fill={color}
//       />
//     </svg>
//   );
// };

const L2Wrapper = styled('div', {
  position: 'relative',
  borderRadius: '16px',
  transition: 'opacity .3s, background-color .15s, transform .3s',
  textStyle: 'serif',
  cursor: 'default',
  userSelect: 'none',

  '&:not(.current).visited:hover': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_hover'),
  },

  // [`& ${UnredactButton}`]: {
  //   transition: 'opacity .3s',
  //   opacity: 0,
  //   pointerEvents: 'none',
  // },

  // [`&.expose-unredact-sentence ${UnredactButton}`]: {
  //   opacity: 1,
  //   pointerEvents: 'all',
  // },

  '& .l2-and-translation': {
    position: 'relative',
  },

  '& .l2': {
    padding: '13px 16px 15px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
  },

  '& > .card > .ext': {
    display: 'none',
    // display: 'grid',
    // gridTemplateRows: '0fr',
    // transition: 'grid-template-rows .12s',
    // '& > div': {
    //   gridRow: '1 / span 2',
    //   overflow: 'hidden',
    // },
  },

  '#script-scrolling-container:not(.playing) &.current > .card > .ext': {
    display: 'block',
    // gridTemplateRows: '1fr',
    // '& > div': {
    //   overflow: 'auto',
    // },
  },
  '&.current.pending-pause > .card > .ext': {
    display: 'block',
    // gridTemplateRows: '1fr',
    // '& > div': {
    //   overflow: 'auto',
    // },
  },

  '& .translation': {
    textStyle: 'small-text',
    padding: '12px 16px',
    borderRadius: '12px',
    color: paletteKey('$$translation__color'),
    width: 'calc(100% - 16px)',
    position: 'absolute',
    top: '50%',
    left: 'calc(100% + 8px)',
    transform: 'translateY(-50%)',
    opacity: '0',
    transition: 'right .3s, left .3s, opacity .3s, background-color .15s',
    zIndex: '-1',

    '@widerThanScript': {
      width: '100%',
    },
    '@playerMediumAndUp': {
      width: '384px',
      left: 'auto',
      right: '0',
    },
    '@playerLarge': {
      right: '-336px',
    },
  },

  '&.visited .translation': {
    color: '$$translation__color_visited',
  },

  '&.current .translation': {
    color: '$$translation__color_visited',
  },

  '&:not(.current).visited .translation:hover': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_hover'),
  },

  '&.current': {
    backgroundColor: paletteKey('$$sentence__backgroundColor_current'),
    boxShadow: '0px 1px 2px rgb(0 0 0 / 20%)',
    '& .translation': {
      backgroundColor: paletteKey('$$sentence__backgroundColor_current'),
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    },

    /// this makes the translation visible
  },

  '&.show-translations': {
    '& .translation': {
      opacity: 1,
    },
    '@playerSmall': {
      transform: 'translateX(calc(-100% + 8px))',
    },
    '@widerThanScript': {
      transform: 'translateX(calc(-50vw - 286px))',
      '& .translation': {
        left: 'calc(50vw + 286px)',
      },
    },
    '@playerMedium': {
      transform: 'translateX(-196px)',
      '& .translation': {
        left: 'auto',
        right: '-392px',
      },
    },
    '@playerLarge': {
      transform: 'translateX(0)',
      '& .translation': {
        left: 'auto',
        right: '-392px',
      },
    },
  },
});

type WrapperProps = React.ComponentProps<typeof L2Wrapper>;

export const StyledSentence: React.FC<
  WrapperProps & {
    translation: string;
    sentenceId: SentenceId /*onUnredact: () => void*/;
  }
> = ({
  children,
  translation,
  sentenceId,
  onClick,
  /*onUnredact,*/ ...props
}) => {
  return (
    <L2Wrapper
      {...props}
      className={props.className + ' line script-sentence-container'}
    >
      {/* // unredact button no longer needed. toggled be click on current sentence or [enter] */}
      {/* <UnredactButton onClick={onUnredact}>
        <RedactionIcon />
      </UnredactButton> */}
      <div className="card">
        <div className="l2-and-translation">
          <div className="l2" onClick={onClick}>
            {children}
          </div>
          <div className="translation" onClick={onClick}>
            <PlainMarkdown source={translation} />
          </div>
        </div>
        <div className="ext">
          <SentenceExtrasGeneral
            sentenceId={sentenceId}
            translation={translation}
          />
        </div>
      </div>
    </L2Wrapper>
  );
};
