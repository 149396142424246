import React, { useLayoutEffect } from 'react';
import { ContentGridInnerContainer } from 'components/global-layout';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

import * as CatalogStuff from './dev-catalog';
import * as Toggles from './dev-toggles';
import * as VideoGuidesStuff from './dev-videoguides';
import * as GeneralInfoStuff from './dev-general-info';
import * as ErrorStuff from './dev-errors';
import * as UsefulLinks from './dev-links';
import * as DataDump from './dev-data-dump';
import * as Logs from './dev-logs';
// import * as ChargeebeePoc from './dev-chargebee-poc';

import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

const screens = [
  GeneralInfoStuff,
  CatalogStuff,
  Logs,
  Toggles,
  VideoGuidesStuff,
  ErrorStuff,
  UsefulLinks,
  DataDump,
  // ChargeebeePoc,
];

const Tabs = styled('div', {
  display: 'flex',
  padding: '24px 0',
  // background: '$gray-50',
  overflow: 'auto',
  width: '100vw',
  margin: '0 -18px',
  scrollSnapType: 'x mandatory',
  position: 'relative',

  '@medium': {
    margin: 0,
    width: 'auto',
  },

  '& a': {
    all: 'unset',
    textStyle: 'body-bold',
    backgroundColor: '$transparent',
    color: '$textSecondary',
    px: '$space$4',
    py: '$space$2',
    borderRadius: '$space$2',
    cursor: 'pointer',
    transition: 'color 0.15s ease, opacity 0.2s ease',
    whiteSpace: 'nowrap',

    '&:hover': {
      color: '$teal-500',
    },
    '&.selected': {
      color: '$teal-600',
      backgroundColor: '$teal-50',
    },
  },

  '&:hover': {
    '& a:not(:hover)': {
      opacity: 0.5,
    },
  },
});

const Wrapper = styled('div', {
  padding: '24px 12px',
  border: '1px solid $gray-50',
  borderRadius: '$space$2',
  width: 'calc(100vw - 48px)',
  overflowX: 'auto',
  '@medium': {
    overflowX: 'visible',
    width: 'auto',
  },
});

function savePath(path: string) {
  localStorage.setItem('devToolsPath', path);
}

function getPath() {
  return localStorage.getItem('devToolsPath') || 'general';
}

export const DevToolsScreen = observer(() => {
  useLayoutEffect(() => {
    // scroll .selected into view
    const selected = document.querySelector('.selected');
    if (selected) {
      selected.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <ContentGridInnerContainer>
      <Tabs>
        {screens.map((screen, index) => (
          <NavLink
            key={index}
            className={({ isActive }) => (isActive ? 'selected' : '')}
            to={screen.path}
            onClick={() => savePath(screen.path)}
          >
            {screen.label}
          </NavLink>
        ))}
      </Tabs>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Navigate to={getPath()} />} />
          {screens.map((screen, index) => (
            <Route
              key={index}
              path={screen.path}
              element={<screen.Component />}
            />
          ))}
        </Routes>
      </Wrapper>
    </ContentGridInnerContainer>
  );
});
