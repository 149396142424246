import * as React from 'react';
import { Text } from 'naan/primitives/text';
import { Badge } from 'naan/primitives/badge';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
import { SoundbitesClassroomBadge } from 'components/stories/soundbite-story-badge';
import { HSpacer } from '@naan/primitives/spacer';

import __ from 'core/lib/localization';

const VolumeCardMetaWrapper = styled('div', {
  marginBottom: '$2',
  lineHeight: '20px',
  display: 'flex',
  '@small': {
    marginBottom: '0',
  },
});

export const VolumeCardMeta = ({
  story,
  showSoundbitesLabel,
}: {
  story: Story;
  showSoundbitesLabel?: boolean;
}) => {
  const isFree = story.trial;

  return (
    <VolumeCardMetaWrapper>
      {isFree ? <Badge>{__('Free', 'free')}</Badge> : null}
      <Text as="span" textStyle={'small-text'} color={'textSecondary'}>
        {story.classroomDurationDescription}
      </Text>
      <HSpacer size={1} />
      <SoundbitesClassroomBadge story={story} labeled={showSoundbitesLabel} />
    </VolumeCardMetaWrapper>
  );
};
