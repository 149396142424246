import * as React from 'react';
import classNames from 'classnames';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { VSpacer } from '@naan/primitives/spacer';
import { styled } from '@naan/stitches.config';
import { InfoCircleLargeIcon } from '@naan/icons/info-circle-icon';
import { observer } from 'mobx-react';
import { TranslationIcon } from 'player/views/elements-presentations/sentence-toolbar/sentence-toolbar-icons';
import { elementIds } from 'components/dom-utils/element-ids';

import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  padding: 16,

  '& .tip-box': {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    borderRadius: 12,
    gap: 12,
    background: '$colors$black-alpha-06',
    color: '$colors$black-alpha-60',

    '&.playing': {
      background: '$colors$black-alpha-03',
      color: '$colors$black-alpha-10',
    },

    '&.revealed': {
      background: '$colors$white',
      color: '$colors$textPrimary',
      '& .text': {
        display: 'flex',
        flexDirection: 'column',
        color: '$colors$textSecondary',

        '&  .title': {
          color: '$colors$textPrimary',
          textStyle: 'body-bold',
        },
      },
    },
  },

  '&.unrevealed': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 128,
  },

  '@widerThanScript': {
    padding: '16px 0',
    maxWidth: 600,
    margin: '0 auto',
    borderRadius: 12,
    '&.revealed': {
      marginBottom: '0',
    },
  },
});

export const TranslationInlineTip = observer(
  ({ model }: { model: SoundbiteModel }) => {
    const dismissed = OnboardingService.instance.isDismissed('translation');
    if (dismissed) {
      return <VSpacer size="8" />;
    }
    return (
      <Wrapper id={elementIds.INLINE_TRANSLATION_TIP}>
        {model.answerRevealed ? (
          <div className="tip-box revealed">
            <div className="icon">
              <TranslationIcon />
            </div>
            <div className="text">
              <span className="title">
                {__('Translations unlocked', 'translationsUnlocked')}
              </span>
              {__('Tap a sentence to access', 'tapASentenceToAccess')}
            </div>
          </div>
        ) : (
          <div
            className={classNames('tip-box', {
              playing: model.isPlaying,
            })}
          >
            <div className="icon">
              <InfoCircleLargeIcon />
            </div>
            <div className="text">
              {__(
                'Translation will be enabled after answer is revealed',
                'translationWillBeEnabledAfterAnswerIsRevealed'
              )}
            </div>
          </div>
        )}
      </Wrapper>
    );
  }
);
