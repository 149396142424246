import * as React from 'react';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager/story';
import { Image } from '@naan/primitives/image';
import { styled } from '@naan/stitches.config';
import { ChapterCatalogData } from '@core/models/catalog';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  $$thumbnailSize: '64px',
  position: 'absolute',
  left: 24,
  top: 24,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: 12,
  color: '$white',

  '& > .thumbnail-image': {
    width: '$$thumbnailSize',
    borderRadius: 8,
    aspectRatio: '1',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.20)',
  },

  '& > .text': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '& > .title': {
      textStyle: 'small-heading',
    },
    '& > .chapter-position': {
      textStyle: 'small-text',
    },
    '& > .chapter-title': {
      textStyle: 'small-text-bold',
    },
  },

  '@medium': {
    left: 'calc(50% - 220px)',
    $$thumbnailSize: '72px',
    border: '1px solid $white-alpha-30',
    padding: 12,
    borderRadius: 12,
    width: 440,
  },
});

export const StoryFloatingCard = observer(
  ({ story, chapter }: { story: Story; chapter: ChapterCatalogData }) => {
    return (
      <Wrapper>
        <Image src={story.listImageUrl} className="thumbnail-image" />
        <div className="text">
          <div className="title">{story.title}</div>
          <div className="chapter-position">
            {__('Chapter %{position}', 'chapterPosition', {
              position: chapter.position,
            })}
          </div>
          <div className="chapter-title">{chapter.title}</div>
        </div>
      </Wrapper>
    );
  }
);
