import { AppFactory } from '@app/app-factory';
import { __, translateWithoutDefault } from './localization';
import { CmsKeys } from '../../langs/cms-keys';
// import { getLocale } from './localization';
// import { startCase } from 'lodash';

// export const localizedProperty = (object: any, propertyName: string) => {
//   const qualified = `${propertyName}${startCase(getLocale())}`;
//   const fallback = `${propertyName}En`; // hardwired fallback to english for now
//   return object[qualified] || object[fallback];
// };

export function cms(key: CmsKeys) {
  const { l2 } = AppFactory.root;
  return translateWithoutDefault(`cms:${key}:${l2}`);
}

export const storiesCount = (n: number) => {
  return __({ one: '1 story', other: '%{count} stories' }, 'storiesCount', {
    count: n,
  });
};

export const monthsCount = (count: number) =>
  __(
    {
      one: '1 month',
      other: '{{count}} months',
    },
    'monthsCount',
    { count }
  );

export const daysCount = (count: number) =>
  __(
    {
      one: '1 day',
      other: '{{count}} days',
    },
    'daysCount',
    { count }
  );
