/* eslint-disable no-alert */
import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';

import {
  getMessages,
  Message,
} from './account-message-target/get-account-messages';
import { AppFactory } from 'app/app-factory';
import { get } from 'lodash';
// import { devMode } from 'app/platform';
import { Navigate, useNavigate } from 'react-router-dom';
import { giftCouponsPath } from 'components/nav/path-helpers';
import { Box } from '@naan/primitives/box';
import { PlainMarkdown } from '@naan/primitives/text';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { appConfig } from '@app/config';
import { track } from '@app/track';
import { createLogger } from '@common/log';

import __ from 'core/lib/localization';
import { useSwitch2 } from '@naan/hooks/use-switch-2';

const log = createLogger('query-message-dispatcher');

const { emails } = appConfig.website;

const cleanAddressBar = () => {
  var yourCurrentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', yourCurrentUrl);
};

// /// this function was too eager clearing the address bar, thus causing the filter issues
// /// for this particular instance we only care to remove the messageKey and checkout query params
// const cleanAddressBar = (keysToRemove: string[]) => {
//   const baseUrl = window.location.href.split('?')[0];
//   const urlParams = new URLSearchParams(window.location.search);

//   keysToRemove.forEach(key => {
//     urlParams.delete(key);
//   });

//   const newUrl = `${baseUrl}?${urlParams.toString()}`;
//   window.history.replaceState({}, '', newUrl);
// };

function CheckoutErrorMessage() {
  const showSwitch = useSwitch2(true);

  if (!showSwitch.value) {
    return null;
  }

  const dismiss = () => {
    showSwitch.off();
    cleanAddressBar();
  };

  return (
    <Dialog.Container onDismiss={showSwitch.off} open={true}>
      <Dialog.Heading type="error">
        {__('Payment unsuccessful', 'paymentUnsuccessful')}
      </Dialog.Heading>
      <Dialog.Body>
        <Box css={{ '& a': { color: '$teal-500' } }}>
          <PlainMarkdown
            source={__(
              'Your credit card payment was not processed. Please [let us know](%{supportLink}) if you encountered an unexpected error.',
              'yourCreditCardPaymentWasNotProcessedPlease',
              {
                supportLink: `mailto:${emails.support}`,
              }
            )}
            components={{
              a: ({ node, ...props }) => <ExternalLink {...(props as any)} />,
            }}
          />
        </Box>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation="secondary"
          label={__('Close', 'close')}
          onClick={dismiss}
          fullWidth
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
}

function CheckoutMessage({ message }: { message: Message }) {
  const showSwitch = useSwitch2(true);

  if (!showSwitch.value) {
    return null;
  }
  const success = message.type === 'success';
  const dismiss = () => {
    showSwitch.off();
    cleanAddressBar();
  };

  return (
    <Dialog.Container onDismiss={showSwitch.off} open={true}>
      <Dialog.Heading type={message.type}>{message.title}</Dialog.Heading>
      <Dialog.Body>{message.body}</Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={success ? 'teal' : 'secondary'}
          label={success ? __('Done', 'done') : __('Close', 'close')}
          onClick={dismiss}
          fullWidth
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
}

export function QueryMessageDispatcher() {
  const navigate = useNavigate();
  const { accountData, fullAccess } = AppFactory.root.userManager;
  const urlParams = new URLSearchParams(window.location.search);
  const messageKey = urlParams.get('message_key');
  const checkout = urlParams.get('checkout');
  if (messageKey) {
    log.debug(
      `QueryMessageDispatcher render - messageKey: ${messageKey}, accountData: ${!!accountData}`
    );
  }

  React.useEffect(() => {
    // if (!devMode) {
    //   cleanAddressBar(['checkout', 'messageKey']);
    // }
    if (messageKey) {
      track('stripe__checkout_message', { messageKey: messageKey });
    }
  }, [accountData, messageKey, navigate]);

  if (messageKey === 'purchase-gift') {
    return <Navigate to={giftCouponsPath()} />;
  }

  if (checkout === 'error') {
    return <CheckoutErrorMessage />;
  }

  if (!accountData) {
    return null;
  }

  const messages = getMessages(accountData);
  let message = get(messages, messageKey);

  if (
    ['first-full-access', 'first-subscribe-success'].includes(messageKey) &&
    !fullAccess
  ) {
    log.warn(`unexpected no full access for message, forcing`, message);
    accountData.forceFullAccess();
    setTimeout(() => {
      log.info('forcing again');
      accountData.forceFullAccess();
    }, 2000);

    // if (messageKey) {
    //   track('stripe__checkout_message', { messageKey: messageKey });
    // }

    // return null; // show the success message even if we don't have server-confirmed full access yet
  }
  if (message) {
    if (messageKey) {
      track('stripe__checkout_message', { messageKey: messageKey });
    }

    if (messageKey) {
      track('stripe__checkout_message', { messageKey: messageKey });
    }

    return <CheckoutMessage message={message} />;
  }
  return null;
}
