import React from 'react';
import { observer } from 'mobx-react';
import { FullAutoRenew } from './full-auto-renew';
import { FullNoRenew } from './full-no-renew';
import { GroupAccess } from './group-access';
import { AppFactory } from '@app/app-factory';
import { LocaleCode } from '@utils/util-types';
import {
  SectionHeading,
  ThickDivider,
} from 'components/learn/dashboard/dashboard-section-heading';
import { styled } from '@naan/stitches.config';
import { AccountTableSubheading } from '../account-form/account-table';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  paddingTop: 96,
  marginTop: -96,
});

export const MembershipListSection = observer(() => {
  // const { membershipDataList } = AppFactory.root.userManager;
  const { root } = AppFactory;
  const { membershipL2s } = root.userManager;

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('price-increase')) {
      setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 100);
    }
  }, []);

  if (membershipL2s.length === 0) {
    return null;
  }

  return (
    <Wrapper ref={ref}>
      <SectionHeading title={__('My membership', 'myMembership')} />
      <ThickDivider />
      {membershipL2s.map(l2 => (
        <>
          <AccountTableSubheading>
            {root.productNameForL2(l2)}
            {/* {data.productName} */}
            {/* ({data.statusKey}) */}
          </AccountTableSubheading>
          {/* <MembershipTable membershipData={data} /> */}
          <MembershipTable l2={l2} />
        </>
      ))}
    </Wrapper>
  );
});

// @armando, feel free to rename/reslice the components
// const MembershipTable = observer(
//   ({ membershipData }: { membershipData: MembershipData }) => {

const MembershipTable = observer(({ l2 }: { l2: LocaleCode }) => {
  const { statusKey } = AppFactory.root.userManager.membershipL2(l2);

  switch (statusKey) {
    case 'trial':
      return null;

    case 'full-auto-renew':
    case 'suspended':
    case 'paused':
      return <FullAutoRenew l2={l2} />;

    case 'full-no-renew':
    case 'expired':
      return <FullNoRenew l2={l2} />;

    case 'group-access':
      return <GroupAccess l2={l2} />;

    default:
      return null;
  }
});
