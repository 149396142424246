import { AppFactory } from '@app/app-factory';
import { notEmpty } from '@utils/conditionals';
import { CLASSROOM_FILTER_KEY_PREFIX } from 'core/lib/constants/vars';
import { isEmpty, pick } from 'lodash';
import { frozen, ModelTreeNode, volatile } from 'ts-state-tree/tst-core';
import { Story } from '../story-manager/story';
import { Assignment } from './assignment';
import { Classroom } from './classroom';
import { PaymentData } from './payment-data';
import { PurchasedCoupon } from './purchased-coupon';
import { getInstallationId } from '../installation-id';
import { getBaseRoot } from '../app-root';
import { Root } from '../root';
import { PricingLevel } from '@cas-shared/cas-types';
import { RailsPlan } from './rails-plan';
import __ from '@core/lib/localization';
import { LocaleCode } from '@utils/util-types';

export class AccountData extends ModelTreeNode {
  static CLASS_NAME = 'AccountData' as const;

  static create(snapshot: any = {}): AccountData {
    return super.create(AccountData, snapshot) as AccountData;
  }

  userId: string = ''; // EMPTY_USER_ID;
  userDataUuid: string; // firestore user data doc id
  email: string = ''; // displayed in the account screen if changed after first registration and not yet reconfirmed.does not block login ?? string
  name: string = '';
  schoolName?: string = null;
  isClassroomActivated: boolean = false;
  // not currently relevant. was only true when an account was not active until the initial email was confirmed
  // emailConfirmationRequired: boolean = false;
  unconfirmedEmail?: string = null;
  mailingListOptIn: boolean = false;
  mailingListPromptNeeded: boolean = false; // set by rails server during account creation. reset when opt in pref set

  memberSinceYear: number = null;
  lastSyncedVersion: number = 0; // incremented by server last when ever sync'ed in or out
  group: string = null; // nullable - metrics tracking group
  membershipState: string = 'trial';
  fullAccess: boolean = false; // if true, then has access to all stories, if false, then only 'trial == true' stories,
  canAccessAllStoryScripts: boolean = false;
  autoRenew: boolean = false;
  // anonymous: boolean = false;
  fullAccessUntil: string = null; // TODO: how to best handle dates?
  accountAgeDays: number = 0;
  fullAccessSinceDays: number = 0;
  fullAccessSince: string = null;
  // assigned by rails when prior non-renewing access migrated to node
  // shouldn't be needed for any logic, but potentially useful for debugging
  nodeEntitlement: boolean;

  // these probably should have been encoded as numbers in the account data json payload
  // these will eventually move to the global firestore settings doc, we can fix the typing then
  appStoreBuildNumber: string = null;
  playStoreBuildNumber: string = null;
  // debugBuildNumber: string = null; // apple review - coming from firestore now, not
  // betaMode: boolean = false; // rails server flag. drives deltas to dashboard banners

  daysLeft: number = 0;

  // story/volume level access control should not be currently relevant
  unlockedStorySlugs: string[] = [];
  unlockedVolumeSlugs: string[] = [];

  hasEverPaid: boolean = false;
  promoteEnrollment: boolean = false;
  hasAffiliatePricing: boolean = false;
  isStudent: boolean = false; // maps to stu_2020, stu_2021 or edu pricing groups

  // 'kqed' group used for coupon driven concession pricing
  pricingGroup: string; // retail_2021 stu_2021 aff retail_2020 stu_2020 p15 edu kqed habla85
  affiliateWelcomeHeading: string = null;

  // hasApplePaidAccess: boolean = false;
  // legacy rails payment info
  paymentData: PaymentData = null;

  @frozen
  plans: RailsPlan[] = []; // legacy data used for affiliate pricing description
  // giftPlans: Plan[] = [];
  purchasedCoupons: PurchasedCoupon[] = [];

  classroomPortalWelcomePending: boolean = false;
  joinedClassrooms: Classroom[] = [];

  // drives teacher classroom portal view within spa
  managedClassrooms: Classroom[] = [];
  hasManagedClassrooms: boolean = false;
  licensedClassroomLabel: string = null; // name to display on account page if group-access

  // none of these should really be needed by spa, but useful to confirm what account data api returns
  appHomeUrl: string;
  manageAccountUrl: string;
  appSiteBaseUrl: string;
  marketingSiteBaseUrl: string;

  // deprecated, useful only for handy confirmation of what 5.5.3 native app sees
  accountPageUrl: string = null; // fully formed URL
  // accountSiteBaseUrl: string = null; // obsolete
  accountPageLinkName: string = null;

  // still has code references, but likely effectively obsolete
  showFutureStories: boolean = false; // if true, then forward dated stores will be not be omitted from story list views

  // used for server driven debugging of startup failure flows (most likely no longer used)
  debugStatus: string = null;

  // affiliate/utm and referrer attribution as captured from the jw-traffic-source cookie
  // by the rails create-account logic.
  // not currently used aside from testing
  @frozen
  signupTrafficSource: object;

  // set to true once accurate student membership access statuses are fetched from the node tier for all classes
  @volatile
  studentAccessFetched: boolean = false;

  // get catalogSlug() {
  //   return this.catalogV4Slug;
  // }

  // get catalogUrl() {
  //   return this.catalogV4Url;
  // }

  get reportingContextData(): object {
    return pick(this, [
      'userId',
      'email',
      'name',
      'errorReportingUserId',
      'errorReportingName',
      'schoolName',
      'group',
      'fullAccess',
      'fullAccessUntil',
      'autoRenew',
      'membershipState',
      'memberSinceYear',
      'isClassroomActivated',
      'canAccessAllStoryScripts',
      'hasManagedClassrooms',
      'licensedClassroomLabel',
      'mailingListOptIn',
      'mailingListPromptNeeded',
      'resolvedCatalogUrl',
    ]);
  }

  get errorReportingUserId(): string {
    if (this.hasUserId) {
      return this.userId;
    } else {
      // return AppFactory.root?.localState?.installationId;
      return getInstallationId();
    }
  }

  get errorReportingName(): string {
    if (this.hasUserId) {
      return this.name;
    } else {
      return '(anonymous)';
    }
  }

  get hasUserId(): boolean {
    return notEmpty(this.userId); // && this.userId !== EMPTY_USER_ID;
  }

  classroom(id: string) {
    return this.managedL2Classrooms.find(classroom => classroom.id === id);
  }

  // for now only show affiliate welcome when anonymous
  // todo: confirm what's desired
  get showAffiliateWelcome(): boolean {
    // return this.affiliateWelcomeHeading && !this.fullAccess;
    return this.affiliateWelcomeHeading && isEmpty(this.name);
  }

  get showClassroomLicenseNag() {
    return !!this.managedL2Classrooms?.find(c => c.license?.isOverSubscribed);
  }

  // this should be used now instead of the raw server 'isClassroomActivated' flag
  get classroomEnabled() {
    return this.isClassroomActivated && this.schoolName !== 'n/a'; // hack around current server logic
  }

  // get hasJoinedClassrooms() {
  //   return this.joinedL2Classrooms && this.joinedL2Classrooms.length > 0;
  // }

  get joinedL2Classrooms(): Classroom[] {
    return this.joinedClassroomsForL2(this.root.l2);
  }

  joinedClassroomsForL2(l2: LocaleCode): Classroom[] {
    return this.joinedClassrooms.filter(
      classroom => classroom.resolvedL2 === l2
    );
  }

  hasJoinedClassroomsForL2(l2: LocaleCode): boolean {
    return this.joinedClassrooms.some(classroom => classroom.resolvedL2 === l2);
  }

  // license data doesn't exist on the joined classroom data
  // hasL2LicensedClassroom(l2: LocaleCode): boolean {
  //   return this.joinedClassrooms.some(
  //     classroom => classroom.resolvedL2 === l2 && classroom.license?.isActive
  //   );
  // }

  get managedL2Classrooms(): Classroom[] {
    const { l2 } = this.root;
    return this.managedClassrooms.filter(
      classroom => classroom.resolvedL2 === l2
    );
  }

  get joinedClassroomsWithAssignments(): Classroom[] {
    return this.joinedL2Classrooms.filter(
      classroom => classroom.assignmentCount > 0
    );
  }

  joinedClassroomForFilterKey(key: string) {
    const id = key.replace(CLASSROOM_FILTER_KEY_PREFIX, '');
    return this.joinedL2Classrooms.find(classroom => classroom.id === id);
  }

  get assignmentMap() {
    return this.managedL2Classrooms
      .map(c => c.assignments)
      .flat()
      .map(a => a.episodeSlug)
      .reduce((acc, slug) => {
        if (!acc[slug]) {
          acc[slug] = 1;
        } else {
          acc[slug] = acc[slug] + 1;
        }
        return acc;
      }, {} as { [key: string]: number });
  }

  joinedClassroomAssignmentForStory(story: Story): Assignment {
    for (const classroom of this.joinedL2Classrooms) {
      for (const assignment of classroom.assignments) {
        if (story.matchesVolumeOrUnitSlug(assignment.episodeSlug)) {
          return assignment;
        }
      }
    }
    return null;
  }

  get hasSpecialPricing(): boolean {
    // return this.plans[0]?.hasSpecialPricing;
    return this.isStudent || this.hasAffiliatePricing;
  }

  get hasClassroomPricing(): boolean {
    return (
      this.pricingGroup === 'edu' ||
      this.joinedL2Classrooms.some(c => c.classroomPricing)
    );
  }

  get pricingLevel(): PricingLevel {
    if (this.hasClassroomPricing) {
      return 'classroom';
    }
    if (this.isStudent) {
      // stu_2020, stu_2021 or edu pricing groups
      return 'student';
      // return 'concession'; // will split out after product/discounts are resliced
    }
    if (this.pricingGroup === 'kqed') {
      return 'concession';
    }
    return 'retail';
  }

  get pricingDescription(): string {
    switch (this.pricingLevel) {
      case 'classroom':
        return __('Classroom discount', 'classroomDiscount');
      case 'student':
        return __('Student discount', 'studentDiscount');
      case 'concession':
        return __('Select discount', 'selectDiscount');
      case 'retail':
      default:
        return undefined;
    }
  }

  // shown on pricing card ribbon
  // get pricingDescription(): string {
  //   switch (this.pricingLevel) {
  //     case 'retail':
  //       return undefined;
  //     case 'affiliate':
  //     case 'concession':
  //       const railsPlanDescription = this.plans?.[0]?.pricingDescription;
  //       return railsPlanDescription || __('Select discount', 'selectDiscount');
  //     case 'student':
  //       return __('Student discount', 'studentPricing');
  //     case 'classroom':
  //       return __('Classroom discount', 'classroomPricing');
  //     default:
  //       return undefined;
  //   }
  // }

  get root(): Root {
    return getBaseRoot(this);
  }

  // todo: clean this up and move to app-root
  get debugBuildNumber(): string {
    return String(AppFactory.root.globalSettings.debugBuildNumber);
  }

  // moved to userManager.hasAdminAccess
  // get showDevTools() {
  //   return this.showFutureStories;
  // }

  //
  // TODO
  //

  get currentPlan(): any {
    return null;
  }

  // get remainingFullAccessInDays() {
  //   if (this.fullAccessUntil) {
  //     const now = new Date();
  //     const fullAccessUntil = new Date(this.fullAccessUntil);
  //     const diff = fullAccessUntil.getTime() - now.getTime();
  //     const days = diff / (1000 * 3600 * 24);
  //     return days;
  //   }
  //   return 0;
  // }

  // // we no longer ever show pricing cards when subscribed
  // get shouldPromoteRenewal() {
  //   // if (this.autoRenew) {
  //   //   return false;
  //   // }

  //   // const daysOfFullAccess = this.remainingFullAccessInDays;

  //   // if (daysOfFullAccess < 30) {
  //   //   return true;
  //   // }

  //   return false;
  // }

  // get accessExpired() {
  //   return !this.fullAccess && this.hadFullAccess;
  // }

  // get hadFullAccess() {
  //   return notEmpty(this.fullAccessUntil);
  // }

  // hack to deal with temporal stripe flow error
  forceFullAccess() {
    this.fullAccess = true;
  }

  async ensureStudentAccessStatuses() {
    if (this.studentAccessFetched) {
      return;
    }
    this.studentAccessFetched = true;
    for (const classroom of this.managedClassrooms) {
      await classroom.ensureStudentAccessStatuses();
    }
  }
}
