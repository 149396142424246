import * as React from 'react';

export const NotationIcon = ({ text }: { text: string }) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.927 18.7445C24.6148 17.308 25 15.699 25 14C25 7.92487 20.0751 3 14 3C7.92487 3 3 7.92487 3 14C3 20.0751 7.92487 25 14 25C15.699 25 17.308 24.6148 18.7445 23.927L26 26L23.927 18.7445Z"
        fill="currentColor"
      />
      <text
        fill="white"
        xmlSpace="preserve"
        style={{ whiteSpace: 'pre' }}
        fontFamily="Roboto"
        fontSize={15}
        fontWeight={500}
        letterSpacing="0em"
        className={'notation-icon-text'}
      >
        <tspan x="9.7373" y="19.127">
          {text}
        </tspan>
      </text>
    </svg>
  );
};

export const TranslationIcon = () => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8333 6.83763C19.8333 6.9665 19.7288 7.07097 19.5999 7.07097H17.0356C16.0217 9.82683 14.68 12.0791 12.9303 13.8965C13.7475 14.5609 14.6463 15.1504 15.6284 15.657C15.7334 15.7111 15.7814 15.8353 15.74 15.946L15.2177 17.3442C15.1945 17.4062 15.146 17.4555 15.0843 17.4797C15.0226 17.5039 14.9535 17.5008 14.8943 17.471C13.6446 16.8429 12.5232 16.0882 11.5194 15.2111C9.57075 16.8284 7.20152 18.0192 4.40387 18.9195C4.28644 18.9573 4.15988 18.8972 4.11503 18.7822C4.06252 18.6477 3.93684 18.4265 3.78173 18.1936C3.62816 17.963 3.45931 17.7412 3.33031 17.6046C3.27442 17.5454 3.25341 17.4614 3.27485 17.3829C3.29629 17.3044 3.3571 17.2427 3.43531 17.2202C6.13282 16.4425 8.36045 15.3743 10.1896 13.9258C8.45769 12.0437 7.13359 9.74659 6.15261 7.07097H3.71248C3.58361 7.07097 3.47915 6.9665 3.47915 6.83763V5.5493C3.47915 5.42043 3.58361 5.31596 3.71248 5.31596H10.6878V2.56671C10.6878 2.43784 10.7923 2.33337 10.9212 2.33337H12.2496C12.3784 2.33337 12.4829 2.43784 12.4829 2.56671V5.31596H19.5999C19.7288 5.31596 19.8333 5.42043 19.8333 5.5493V6.83763ZM15.0134 7.07096H8.10559C8.9279 9.20126 10.0766 11.0866 11.5772 12.6622C13.0308 11.1487 14.1632 9.30509 15.0134 7.07096ZM21.4655 20.2522C21.564 20.2522 21.6518 20.3141 21.6851 20.4068L22.6796 23.1789C22.7129 23.2716 22.8008 23.3335 22.8993 23.3335H24.5124C24.6756 23.3335 24.7884 23.1702 24.7306 23.0175L20.159 10.9373C20.1247 10.8465 20.0378 10.7865 19.9408 10.7865H18.627C18.5299 10.7865 18.443 10.8466 18.4087 10.9374L13.8454 23.0177C13.7877 23.1703 13.9005 23.3335 14.0637 23.3335H15.6679C15.7666 23.3335 15.8547 23.2714 15.8878 23.1784L16.8742 20.4073C16.9073 20.3143 16.9953 20.2522 17.094 20.2522H21.4655ZM17.7899 18.6542C17.6292 18.6542 17.5166 18.4956 17.5696 18.3439L19.0592 14.0799C19.1321 13.8713 19.427 13.8713 19.4998 14.0799L20.9894 18.3439C21.0424 18.4956 20.9298 18.6542 20.7691 18.6542H17.7899Z"
        fill="currentColor"
      />
    </svg>
  );
};
