import {
  TFilter,
  TFilterQueryParameters,
  filterTypes,
} from '../filtering-core';

const typeToKeyMap: Record<string, string> = {
  channel: 'channelTitles', // always single value, but api expects an array
  topics: 'topicNames',
  countries: 'countryNames',
  themes: 'pedagogicalThemes', // combined apTags, ibTags
  sb: 'soundbiteStatuses',
  search: 'searchableText',
};

export function convertQueryObjectToFilter(
  query: TFilterQueryParameters
): TFilter | null {
  const { filterType, filterValue } = query;

  const method =
    filterType === 'search'
      ? filterTypes.TEXT_SEARCH
      : filterTypes.INCLUDES_ANY;

  if (!filterType || !typeToKeyMap[filterType] || !filterValue) {
    // TODO: log error or throw if the filter query is invalid?
    return null;
  }

  const key = typeToKeyMap[filterType];

  return {
    queryType: filterType,
    key,
    value: filterValue,
    method,
  };
}
