import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { Story } from '@core/models/story-manager';
import { styled } from '@naan/stitches.config';
import { VolumeTagLinks } from 'components/volume-layouts/volume-tag-links';
import { StoryVoicesCreditsModal } from 'components/story-detail/story-voices-credits-modal';
// import { StoryStatusBar } from 'components/story-status/story-status-bar';
// import { Responsive } from '@naan/primitives/responsive';
import { VSpacer } from '@naan/primitives/spacer';
import { StoryDebugInfo } from 'components/admin/masala-admin';
import { observer } from 'mobx-react';
// import { Sticky } from './sticky.styles';
import { MarkSimple } from '@naan/primitives/text';
import { ArrowTopRightExtraSmallIcon } from '@naan/icons/arrow-top-right-icon';
import { Button } from '@naan/primitives/button';
import { discoverStoryPath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';
import { Image } from '@naan/primitives/image';
import { StoryDownloadedBadge } from 'components/stories/story-downloaded-badge';
import { ShortsBadge } from 'components/stories/shorts-badge';
import { TitleStatus } from 'components/stories/story-title';
import { StoryChannelBadge } from 'components/learn/dashboard/channel-badge';
import { StoryChannelModal } from 'components/learn/dashboard/channel-modal';
import { useSwitch2 } from '@naan/hooks/use-switch-2';

import __ from '@core/lib/localization';

function hexToRgba(hex: string, alpha: number) {
  const hexValue = parseInt(hex.slice(1), 16);
  const r = (hexValue >> 16) & 255;
  const g = (hexValue >> 8) & 255;
  const b = hexValue & 255;

  return `rgba(${r},${g},${b},${alpha})`;
}

const Wrapper = styled('div', {
  $$centerColumnWidth: '$$maxWidth',
  $$backgroundColor: 'transparent',
  $$centerColumnGap: '$space$4',
  $$backgroundImage: '',
  $$color1: 'rgba(128,128,128,1.0)',
  $$color2: 'rgba(128,128,128,0.85)',

  backgroundColor: '$$backgroundColor',
  display: 'grid',
  gridTemplateColumns:
    '1fr min($$centerColumnWidth, calc( 100% - calc( $$centerColumnGap * 2) )) 1fr',
  position: 'relative',
  overflow: 'hidden',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    overflow: 'hidden',
    // height: '100%',
    background:
      'linear-gradient(0deg, $$color1 20%, $$color2 100%), $$backgroundImage center/cover',
    filter: 'blur(4px)',
    transform: 'scale(1.05)',
  },

  '& > .center-column-inner': {
    gridColumn: '2',
    // background: 'rgba(0,0,0,.4)',
  },

  '@medium': {
    $$centerColumnGap: '$space$6',
  },

  variants: {
    isolate: {
      true: {
        isolation: 'isolate',
      },
      false: {
        isolation: 'auto',
      },
    },
    narrow: {
      true: {
        $$centerColumnWidth: '600px',
      },
    },
  },

  defaultVariants: {
    isolate: true,
  },
});

const StoryDetailCardWrapper = styled('div', {
  paddingBottom: 40,
  color: '$white',
  '& .thumbnail': {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 72,
    paddingBottom: 12,
    '& img': {
      width: 192,
      height: 192,
      borderRadius: 8,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
      aspectRatio: 1,
    },
  },

  '& .text': {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',

    '& > .tagline': {
      textStyle: 'small-heading',
      marginBottom: '$space$3',
    },
    '& .tags': {
      marginTop: '20px',
    },
  },
  '@medium': {
    paddingBottom: 48,
    '& .thumbnail': {
      '& img': {
        width: 216,
        height: 216,
      },
    },
  },

  // '@medium': {
  //   display: 'flex',
  //   flexDirection: 'row-reverse',
  //   justifyContent: 'space-between',
  //   paddingTop: 24,
  //   paddingBottom: 56,
  //   '& .thumbnail': {
  //     padding: 0,
  //   },
  //   '& > .text': {
  //     maxWidth: 624,
  //     paddingRight: 24,
  //     '& > header': {
  //       alignItems: 'flex-start',
  //       '& > h1': {
  //         textStyle: 'extra-large-heading',
  //       },
  //     },
  //   },
  // },

  // '@extraLarge': {
  //   '& .thumbnail img': {
  //     width: 304,
  //     height: 304,
  //   },
  // },
});

const HeaderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: '40px',
  '& > h1': {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    textStyle: 'medium-heading',
    '& > p': {
      display: 'inline',
    },
  },
  '& > .story-meta': {
    margin: 0,
    marginTop: 4,
    textStyle: 'small-text',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  '& .badge-btn': {
    all: 'unset',
    cursor: 'default',
    transition: 'opacity 0.2s ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
  },
});

// const StoryStatusWrapper = styled(Sticky, {
//   top: '$$smallHeaderHeight',
// });

export const STORY_THUMB_ID = 'story-thumb';

const CardHeader = observer(({ story }: { story: Story }) => {
  const showModal = useSwitch2(false);
  return (
    <HeaderWrapper>
      <h1>
        <TitleStatus story={story} presentation="white" size="medium" />
        <MarkSimple source={story.title} />
      </h1>
      <span className="story-meta">
        {story.isShort ? <ShortsBadge onDark /> : null}
        {story.durationDescription}
        <span className="downloaded-badge">
          <StoryDownloadedBadge story={story} withLabel />
        </span>
      </span>
      <VSpacer size={3} />
      {story?.channel ? (
        <>
          <button onClick={showModal.on} className="badge-btn">
            <StoryChannelBadge channel={story.channel} />
          </button>
          {showModal.value ? (
            <StoryChannelModal
              channel={story.channel}
              onDismiss={showModal.off}
            />
          ) : null}
        </>
      ) : null}
    </HeaderWrapper>
  );
});

export const StoryDetailCard = observer(({ story }: { story: Story }) => {
  const navigate = useNavigate();
  const { classroomEnabled } = AppFactory.root.userManager;

  return (
    <Wrapper
      className="story-detail-card"
      css={{
        $$backgroundColor: story.themeColor,
        $$backgroundImage: `url(${story.listImageUrl})`,
        $$color1: story.themeColor,
        $$color2: hexToRgba(story.themeColor, 0.85),
      }}
      isolate
      narrow
    >
      <div className="center-column-inner">
        <StoryDetailCardWrapper>
          <div className="thumbnail t-x" id={STORY_THUMB_ID}>
            <Image
              src={story.listImageUrl}
              alt={story.title}
              style={{ backgroundColor: story.themeColor }}
              id="story-detail-card-thumbnail"
            />
          </div>
          <div className="text">
            <CardHeader story={story} />
            <p className="tagline">
              <MarkSimple source={story.tagline} />
            </p>

            <MarkSimple source={story.description} />
            <StoryVoicesCreditsModal story={story} />
            <div className="tags">
              <VolumeTagLinks
                tags={story.allTagsExceptShorts}
                linkPrefix="/stories"
                mode={'dark'}
              />
            </div>
            {classroomEnabled ? (
              <>
                <VSpacer size={6} />
                <Button
                  rightIcon={<ArrowTopRightExtraSmallIcon />}
                  size="small"
                  label={__('View in Classrooms', 'viewInClassrooms')}
                  presentation="whiteDarken"
                  onClick={() => {
                    navigate(discoverStoryPath(story)); // TODO: confirm desired behavior with @daniel
                  }}
                />
              </>
            ) : null}

            {/* <StoryCacheStateUgly story={story} /> */}
            <StoryDebugInfo story={story} />
          </div>
        </StoryDetailCardWrapper>
      </div>
    </Wrapper>
  );
});
