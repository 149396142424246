import { DialogPresenter } from './dialog-presenter';
import { action, makeObservable, observable, when } from 'mobx';

export type ConfirmableDialogFn<T> = (
  onResolve: (value: T) => void
) => JSX.Element;

export async function runConfirmableDialog<T>(
  dialogFn: ConfirmableDialogFn<T>
): Promise<T> {
  const resultHandle = new ResultHandle<T>();

  const onResolve = (value: T) => {
    resultHandle.resolve(value);
  };

  DialogPresenter.present(onDismiss =>
    dialogFn(value => {
      onDismiss();
      onResolve(value);
    })
  );

  await when(() => resultHandle.resolved);
  return resultHandle.value;
}

class ResultHandle<T> {
  @observable
  value: T;

  @observable
  resolved: boolean;

  constructor() {
    makeObservable(this);
  }

  @action
  resolve(value?: T) {
    this.value = value;
    this.resolved = true;
  }
}
