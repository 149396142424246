import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { StreakIcon } from '@naan/icons/streak-icon';
import { PointsDisplay } from './points-display';
import { SOUNDBITE_COMPLETION_POINTS } from '@core/models/user-manager/listening-log';

import __ from '@core/lib/localization';
import { VSpacer } from '@naan/primitives/spacer';
import { daysCount } from '@core/lib/localization-helpers';

export const PointsCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$yellow-150',
  padding: '0 16px',
  borderRadius: 12,
  '@widerThanScript': {
    borderRadius: 8,
  },
  '& > .row': {
    display: 'flex',
    alignItems: 'center',
    color: '$textPrimary',
    // justifyContent: 'space-between',
    gap: 4,
    padding: '20px 0',
    textStyle: 'small-heading',
    '&:not(:last-child)': {
      borderBottom: '1px solid $black-alpha-06',
    },

    '& > .title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 8,
    },
    '& > .points': {
      marginLeft: 'auto',
    },
    '& > .icon': {
      '&.streak': {
        color: '$colors$orange-500',
      },
    },
    '&.total': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 16,
      padding: '16px 0',
    },
  },
});

export const StreakRow = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const { currentStreak, streakShowState } = userData;

  return (
    <>
      {streakShowState === 'SHOW_INLINE' ? (
        <div className="row">
          <span className="streak icon">
            <StreakIcon />
          </span>
          <span className="title">{__('Daily streak', 'dailyStreak')}</span>
          <span className="points">{daysCount(currentStreak)}</span>
        </div>
      ) : null}
    </>
  );
});

export const PointsCard = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const { todayPoints, sevenDayPoints, totalPoints } = userData;

  const showTotalRow = totalPoints > SOUNDBITE_COMPLETION_POINTS;

  return (
    <>
      <PointsCardWrapper>
        <StreakRow />

        <div className="row">
          <span className="icon">
            <CheckmarkCircleIcon />
          </span>
          <span className="title">
            {__('Soundbite complete', 'soundbiteComplete')}
          </span>
          <span className="points">
            <PointsDisplay points={SOUNDBITE_COMPLETION_POINTS} size="large" />
          </span>
        </div>

        {showTotalRow ? (
          <div className="row total">
            <PointsDisplay
              points={todayPoints}
              size="small"
              legend={__('today', 'todayLower')}
            />
            <PointsDisplay
              points={sevenDayPoints}
              size="small"
              legend={__('last 7 days', 'lastSevenDays')}
            />
          </div>
        ) : null}
      </PointsCardWrapper>
      <VSpacer size={4} />
    </>
  );
});
