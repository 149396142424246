// useful only in the spa
import { useNavigate } from 'react-router-dom';
import { PlayerMode } from '@common/misc-types';
import { ChapterCatalogData } from '@core/models/catalog/chapter-catalog-data';
import {
  learnStoryPath,
  studyBasePath,
} from '../../components/nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import { ReturnNavState } from 'components/nav/return-nav-state';

export const useNavigateToStudy = () => {
  const navigate = useNavigate();

  const navigateToStudy = (
    chapter: ChapterCatalogData,
    stage: PlayerMode,
    ms: number = 0,
    // used when using a short-cut action from the dashboard or story list
    // allows the study screen to always pop back to the detail screen
    viaDetail: boolean = false
  ) => {
    if (viaDetail) {
      const storyPath = learnStoryPath(chapter.story);
      navigate(storyPath);
    }
    const basePath = studyBasePath(chapter);
    navigate(`${basePath}?stage=${stage}&ms=${ms}`);
  };

  return navigateToStudy;
};

export const useReturnFromStudy = () => {
  const navigate = useNavigate();

  // @armando would React.useCallback be relevant here?
  // (it was previously being used in the end-of-chapter version of this code)
  const returnFromStudy = () => {
    // log.debug(`navigateFromStudy`);
    const model = AppFactory.studyModel;
    model.player.pause();
    // updateProgress(); // moved to player screen use effect disposer

    if (ReturnNavState.canPopFromStudy()) {
      navigate(-1);
    } else {
      const storyPath = learnStoryPath(model.chapter.story);
      navigate(storyPath);
    }
  };

  return returnFromStudy;
};
