import { Image } from '@naan/primitives/image';
import { styled } from 'naan/stitches.config';
import * as React from 'react';
import imageSrc from './jw-for-schools.png';

const Wrapper = styled('div', {
  width: '100%',
  display: 'flex',
  img: {
    maxWidth: '100%',
  },
});

export const ClassroomOnboardingScreenshot = () => {
  return (
    <Wrapper>
      <Image src={imageSrc} alt="Screenshot" />
    </Wrapper>
  );
};
