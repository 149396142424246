import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { ChapterCatalogData } from '@core/models/catalog';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { IconButton } from '@naan/primitives/button';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { observer } from 'mobx-react';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
import { styled } from '@naan/stitches.config';
import { AppFactory } from '@app/app-factory';
import { ChapterSoundbiteBadge } from './components/chapter-soundbite-badge';
import { getElementById } from 'components/dom-utils';
import { elementIds } from 'components/dom-utils/element-ids';
import { CircleIcon } from './components/circle-icon';

import __ from 'core/lib/localization';

const Accessory = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  marginRight: -8,
});

const OverflowMenu = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    return (
      <Menu
        trigger={
          <IconButton
            presentation="grayTransparent"
            icon={<OverflowIcon />}
            testId={`chapter-list-overflow-${chapter.sortingRef}`}
          />
        }
      >
        <MenuItem
          label={__('Skip to chapter', 'skipToChapter')}
          action={() => presentSkipGuardModal(() => chapter.unlock())}
          // action={}
        />
      </Menu>
    );
  }
);

const SkipGuardDialog = ({
  onDismiss,
  onSkip,
}: {
  onDismiss: () => void;
  onSkip: () => void;
}) => {
  return (
    <Dialog.Container open onDismiss={onDismiss}>
      <Dialog.Heading>{__('Skip to chapter', 'skipToChapter')}</Dialog.Heading>
      <Dialog.Body>
        {__(
          'Starting this chapter will mark all previous chapters as complete, but won’t affect your progress statistics or vocab list.',
          'startingThisChapterWillMarkAllPrevious'
        )}
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          tabIndex={-1}
          label={__('Cancel', 'cancel')}
          onClick={onDismiss}
          presentation="grayTransparent"
        />
        <Dialog.Button
          label={__('Skip', 'skip')}
          onClick={() => {
            onSkip();
            onDismiss();
          }}
          presentation="teal"
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

function presentSkipGuardModal(onSkip: () => void) {
  requestAnimationFrame(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <SkipGuardDialog onDismiss={onDismiss} onSkip={onSkip} />
    ));
  });
}

export const Unvisited = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const openSoundbiteModel = React.useCallback(() => {
      const element = getElementById(elementIds.SOUNDBITES_MODAL_BUTTON);
      element?.click();
    }, []);

    return (
      <div style={{ overflowY: 'clip' }}>
        <ChapterListHeader
          chapter={chapter}
          presentation={'unvisited'}
          stationIcon={<CircleIcon />}
          accessory={
            <Accessory>
              <div onClick={openSoundbiteModel}>
                <ChapterSoundbiteBadge chapter={chapter} />
              </div>
              <OverflowMenu chapter={chapter} />
            </Accessory>
          }
        />
      </div>
    );
  }
);
