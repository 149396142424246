// import React from 'react';
import { makeObservable, observable } from 'mobx';
import { AppFactory } from '@app/app-factory';
import { bugsnagNotify } from '@app/notification-service';
import { createLogger } from '@common/log';
import { UserManager } from '@core/models/user-manager';
// import { runOnboardingDialog } from 'components/ui/onboarding/onboarding-dialogs';
// import { SmartPauseTip } from 'components/ui/onboarding/smart-pause-tip';
// import { isStudyModel } from 'player/models/player-model-handle';
import { Story } from '@core/models/story-manager/story';
// import { StudyFlowTip } from 'components/ui/onboarding/study-flow-tip';
import { PlayerModel } from 'player/models/player-model';
import { PlayerMode } from '@common/misc-types';
import { RedactionMode } from 'player/models/redaction-modes';
import { ChapterCatalogData } from '@core/models/catalog';
import { StudyModel } from 'study/models/study-model';

const log = createLogger('onboarding-service');

const tipKeysDict = {
  speedControl: 'speedControl',
  translation: 'translation',
  addToVocab: 'addToVocab',
  vocabList: 'vocabList',
  smartPauseDialog: 'smartPauseDialog',
  smartPauseTip: 'smartPauseTip',

  studyFlow: 'studyFlow',
  naturalListen: 'naturalListen',
  redactionMenuDialog: 'redactionMenuDialog',
  redactionMenuTip: 'redactionMenuTip',
  firstVocabAdded: 'firstVocabAdded',
  storyInterstitial: 'storyInterstitial',
  onboardingInitiated: 'onboardingInitiated',
  onboardingComplete: 'onboardingComplete',
  skipSoundbitesToChapter: 'skipSoundbitesToChapter',
  soundbitesModal: 'soundbitesModal',
  completedStoryCta: 'completedStoryCta', // guards the end-of-story CTA from being seen more than once
  onboardingCompleteModal: 'onboardingCompleteModal',
} as const;

export type TipKey = keyof typeof tipKeysDict;

// beware, keys must be added here and to the TipKey type below
export const tipKeys = Object.keys(tipKeysDict) as TipKey[];

// define a typescript interface for the values of tipKeys
// export type TipKey = typeof tipKeys[number]; // this doesn't seem to work
// export type TipKey =
//   | 'speedControl'
//   | 'translation'
//   | 'addToVocab'
//   | 'vocabList'
//   | 'smartPause'
//   | 'studyFlow'
//   | 'naturalListen'
//   | 'redactionMenu'
//   | 'firstVocabAdded'
//   | 'storyInterstitial'
//   | 'onboardingInitiated'
//   | 'onboardingComplete'
//   | 'skipSoundbitesToChapter'
//   | 'soundbitesModal';

export class OnboardingService {
  userManager: UserManager;

  showSpeedControlTip = false;
  showTranslationTip = false;
  showAddToVocabTip = false;
  showVocabListTip = false;
  showNaturalListenTip = false;
  showRedactionMenuDialog = false;
  showRedactionMenuTip = false;
  showSmartPauseDialogForNewUsers = false;
  showSmartPauseDialogForExistingUsers = false;
  showSmartPauseTip = false;
  showSkipSoundbitesToChapterTip = false;
  showSoundbitesModalTip = false;

  // needed to handle player resume after speed control tip resolved
  pendingResumeFn: () => void;

  private constructor() {
    this.userManager = AppFactory.root.userManager;
    makeObservable(this, {
      showSpeedControlTip: observable,
      showTranslationTip: observable,
      showAddToVocabTip: observable,
      showVocabListTip: observable,
      showNaturalListenTip: observable,
      showRedactionMenuTip: observable,
      showRedactionMenuDialog: observable,
      showSmartPauseDialogForNewUsers: observable,
      showSmartPauseDialogForExistingUsers: observable,
      showSmartPauseTip: observable,
      showSkipSoundbitesToChapterTip: observable,
      showSoundbitesModalTip: observable,
    });

    this.speedControlTipDismissed = this.speedControlTipDismissed.bind(this);
    // this.translationTipDismissed = this.translationTipDismissed.bind(this);
    this.addToVocabTipDismissed = this.addToVocabTipDismissed.bind(this);
    this.vocabListTipDismissed = this.vocabListTipDismissed.bind(this);
    this.naturalListenTipDismissed = this.naturalListenTipDismissed.bind(this);
    this.redactionMenuTipDismissed = this.redactionMenuTipDismissed.bind(this);
    this.redactionMenuDialogDismissed =
      this.redactionMenuDialogDismissed.bind(this);
    this.onSoundbitesSkipDismissed = this.onSoundbitesSkipDismissed.bind(this);
    this.onSoundbitesModalTipDismissed =
      this.onSoundbitesModalTipDismissed.bind(this);

    this.smartPauseDialogDismissed = this.smartPauseDialogDismissed.bind(this);
    this.smartPauseTipDismissed = this.smartPauseTipDismissed.bind(this);
  }

  isDismissed(key: TipKey): boolean {
    return this.userManager.userData.userSettings.tipIsDismissed(key);
  }

  dismiss(key: TipKey): void {
    this.userManager.userData.userSettings.dismissTip(key);
  }

  resetOnboardingState(): void {
    this.userManager.userData.userSettings.resetAllTips();
  }

  get isOnboardingActive(): boolean {
    return (
      this.isDismissed('onboardingInitiated') &&
      !this.isDismissed('onboardingComplete')
    );
  }

  get isOnboardingComplete(): boolean {
    return this.isDismissed('onboardingComplete');
  }

  //
  // Speed control tip
  //
  onPlay({
    model,
    resumeFn,
  }: {
    model: PlayerModel;
    resumeFn: () => void;
  }): boolean {
    if (this.isDismissed('speedControl')) {
      return false;
    }
    if (model.playerMode !== PlayerMode.STUDY) {
      return false;
    }
    if (model.transportState.playbackRate !== 0.8) {
      log.info('automatically dismissing speed control tip');
      const { userSettings } = AppFactory.root.userManager.userData;
      userSettings.dismissTip('speedControl');
      return false;
    } else {
      log.info('triggering speed control tip');
      this.pendingResumeFn = resumeFn;
      this.showSpeedControlTip = true;
      return true; // signal to abort operation
    }
  }

  // speedControlTipResolved(action: OnboardingDialogAction): void {
  speedControlTipDismissed(): void {
    this.showSpeedControlTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    // userSettings.handleTipAction('speedControl', action);
    userSettings.dismissTip('speedControl');
    if (this.pendingResumeFn) {
      this.pendingResumeFn();
    } else {
      bugsnagNotify(
        new Error('speedControlTipResolved: missing pendingResumeFn')
      );
    }
    this.pendingResumeFn = undefined;
  }

  //
  // Translation tip
  //
  onRevealAnswer(): void {
    // if (this.isDismissed('translation')) {
    //   return;
    // }
    // setTimeout(() => {
    //   this.showTranslationTip = true;
    // }, 1000);
    // log.info('triggering translation tip');
  }

  didShowTranslation(): void {
    // this.showTranslationTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    // userSettings.handleTipAction('translation', action);
    userSettings.dismissTip('translation');
  }

  get shouldSHowTranslationTip(): boolean {
    if (this.isDismissed('translation')) {
      return false;
    }
    return this.showTranslationTip;
  }

  // translationTipResolved(action: OnboardingDialogAction): void {
  // translationTipDismissed(): void {
  //   // this.showTranslationTip = false;
  //   // const { userSettings } = AppFactory.root.userManager.userData;
  //   // userSettings.handleTipAction('translation', action);
  //   // userSettings.dismissTip('translation');
  // }

  //
  // Add to vocab tip
  //
  onVocabListSeen(): void {
    if (this.isDismissed('addToVocab')) {
      return;
    }
    log.info('triggering add to vocab tip');
    setTimeout(() => {
      // runOnboardingDialog('addToVocab', <AddToVocabTip />).catch(bugsnagNotify);
      this.showAddToVocabTip = true;
    }, 500);
  }

  // addToVocabTipResolved(action: OnboardingDialogAction): void {
  addToVocabTipDismissed(): void {
    this.showAddToVocabTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    // userSettings.handleTipAction('addToVocab', action);
    userSettings.dismissTip('addToVocab');
  }

  //
  // Vocab list tip
  //
  onVocabAdded(): void {
    if (this.isDismissed('vocabList')) {
      return;
    }
    if (this.isDismissed('firstVocabAdded')) {
      // triggered on second added vocab
      log.info('triggering vocab list tip');
      // runOnboardingDialog('vocabList', <VocabListTip />).catch(bugsnagNotify);
      this.showVocabListTip = true;
    } else {
      // update state needed for subsequent trigger
      this.userManager.userData.userSettings.dismissTip('firstVocabAdded');
    }
  }

  // vocabListTipResolved(action: OnboardingDialogAction): void {
  vocabListTipDismissed(): void {
    this.showVocabListTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    // userSettings.handleTipAction('vocabList', action);
    userSettings.dismissTip('vocabList');
  }

  //
  // Smart pause tip
  //
  // onDoubleTapPause(): void {
  //   if (this.isDismissed('smartPause')) {
  //     return;
  //   }
  //   this.showSmartPauseTip();
  // }

  onSmartPause(): void {
    // if (this.isDismissed('smartPause')) {
    //   return;
    // }
    // if (this.isDismissed('addToVocab')) {
    //   // show smart pause tip if vocab tip already shown
    //   this.showSmartPauseTip();
    //   return;
    // }
    // const { playerModel } = AppFactory;
    // if (isStudyModel(playerModel)) {
    //   log.debug(`notationCount: ${playerModel.notationCount}`);
    //   if (playerModel.notationCount === 0) {
    //     // show smart pause tip if no vocab for current sentence
    //     this.showSmartPauseTip();
    //   }
    // }
  }

  // showSmartPauseTip(): void {
  //   log.info('triggering smart pause tip');
  //   setTimeout(() => {
  //     runOnboardingDialog('smartPause', <SmartPauseTip />).catch(bugsnagNotify);
  //   }, 500);
  // }

  //
  // Study flow tip - OBSOLETE
  //
  onStoryDetail(story: Story): void {
    return; // not relevant with new onboarding flow

    // if (this.isDismissed('studyFlow')) {
    //   return;
    // }
    // const { userData } = AppFactory.root.userManager;
    // if (userData.showStreakInterstitial) {
    //   // don't show tip on top of interstitial
    //   return;
    // }
    // if (story.progressMayBeNull?.currentChapter?.isFirstListenComplete) {
    //   log.info('triggering study flow tip');
    //   setTimeout(() => {
    //     runOnboardingDialog('studyFlow', <StudyFlowTip />).catch(bugsnagNotify);
    //   }, 500);
    // }
  }

  //
  // Natural listen mode tip
  //
  onNaturalListenLaunch(): void {
    if (this.isDismissed('naturalListen')) {
      return;
    }
    setTimeout(() => {
      this.showNaturalListenTip = true;
    }, 500);
  }

  // onResolveNaturalListenTip(action: OnboardingDialogAction): void {
  naturalListenTipDismissed(): void {
    this.showNaturalListenTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    // userSettings.handleTipAction('naturalListen', action);
    userSettings.dismissTip('naturalListen');
  }

  //
  // Redaction menu
  //
  // shown when study player opened at start of chapter and speed control tip
  // already seen and dismissed. will suppress auto-open of chapter notes.
  // (relying on the start-of-chapter filter checked at the screen level)
  onStudyPlayerOpened(model: StudyModel): boolean {
    if (
      // speed control tip must be seen first
      // @elliottjf is this condition still relevant?
      !this.isDismissed('speedControl')
    ) {
      return;
    }

    // https://linear.app/jiveworld/issue/ENG-4160/move-smartpause-onboarding-tip-earlier-and-before-x-ray-tip
    const { playerSettings } = AppFactory.root.userManager.userData;

    const smartPauseDialogNotDismissed = !this.isDismissed('smartPauseDialog');
    const smartPauseDisabled = playerSettings.smartPauseEnabled === false;
    const isOnboardingComplete = this.isDismissed('onboardingComplete');
    const isFluentListenMode = model.fluentListenMode;

    // console.log('PAUSE: preconditions', {
    //   smartPauseDialogNotDismissed,
    //   smartPauseDisabled,
    //   isOnboardingComplete,
    //   isFluentListenMode,
    // });

    /// Block for both smart pauses
    if (smartPauseDialogNotDismissed && smartPauseDisabled) {
      if (isOnboardingComplete) {
        playerSettings.enableSmartPause();
        setTimeout(() => {
          this.showSmartPauseDialogForExistingUsers = true;
        }, 500);
        return true;
      } else if (isFluentListenMode) {
        playerSettings.enableSmartPause();
        setTimeout(() => {
          this.showSmartPauseDialogForNewUsers = true;
        }, 500);
        return true;
      }
    }
    /// https://jiveworld.slite.com/app/docs/UIQLBCIm2PGnzx/Onboarding-tip-updates
    const redactionConditions =
      !this.isDismissed('redactionMenuDialog') &&
      model.chapter.position > 1 &&
      model.redactionMode === RedactionMode.SHOW_ALL;

    if (redactionConditions) {
      setTimeout(() => {
        model.setRedactionMode(RedactionMode.SHOW_SOME);
        this.showRedactionMenuDialog = true;
      }, 500);
      return true; // signal to suppress auto-show of chapter notes
    }

    /// not sure if somethign like this is even necessary :S
    if (!this.isDismissed('redactionMenuTip')) {
      return true;
    }
  }

  redactionMenuDialogDismissed(): void {
    this.showRedactionMenuDialog = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('redactionMenuDialog');
    setTimeout(() => {
      this.showRedactionMenuTip = true;
    }, 500);
  }

  redactionMenuTipDismissed(): void {
    this.showRedactionMenuTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('redactionMenuTip');
  }

  smartPauseDialogDismissed(): void {
    this.showSmartPauseDialogForNewUsers = false;
    this.showSmartPauseDialogForExistingUsers = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('smartPauseDialog');
    setTimeout(() => {
      this.showSmartPauseTip = true;
    }, 500);
  }

  smartPauseTipDismissed(): void {
    this.showSmartPauseTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('smartPauseTip');
  }

  onChapterComplete({ chapter }: { chapter: ChapterCatalogData }): void {
    // if this particular tip has been dismissed, ignore
    if (this.isDismissed('skipSoundbitesToChapter')) {
      return;
    }

    // if the onboarding hasn't been completed, ignore.
    if (!this.isDismissed('onboardingComplete')) {
      return;
    }

    // if the chapter has no soundbites or all the soundbites are complete, ignore
    if (
      chapter.chapterSoundbites.length === 0 ||
      chapter.allChapterSoundbitesCompleted ||
      chapter.position < 3
    ) {
      return;
    }

    // otherwise, show the tip
    this.showSkipSoundbitesToChapterTip = true;
  }

  onSoundbitesSkipDismissed(): void {
    this.showSkipSoundbitesToChapterTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('skipSoundbitesToChapter');
    log.info('onSoundbitesSkipDismissed');

    // window.setTimeout(() => {
    if (this.isDismissed('soundbitesModal')) {
      log.info('soundbitesModal already dismissed');
      return;
    } else {
      log.info('triggering soundbitesModal tip');
      this.showSoundbitesModalTip = true;
    }
    // }, 300);
  }

  onSoundbitesModalTipDismissed(): void {
    this.showSoundbitesModalTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('soundbitesModal');
  }

  private static _instance: OnboardingService;

  static get instance() {
    if (!OnboardingService._instance) {
      OnboardingService._instance = new OnboardingService();
    }
    return OnboardingService._instance;
  }
}

(window as any).OnboardingService = () => OnboardingService.instance;
