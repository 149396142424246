import * as React from 'react';
import { SentenceId } from '@tikka/basic-types';
import { StudyModel } from 'study/models/study-model';
import { isStudyModel } from 'player/models/player-model-handle';
import { observer } from 'mobx-react';

import {
  TranslationButton,
  NotationsButton,
  StudyFromHereButton,
  ReplayButton,
  OverflowButton,
} from './sentence-toolbar-buttons';
import { ToolbarStyles } from './toolbar-styles';
import { positionExpandedSentence } from 'study/views/fx/scrolling';

const StudyOnlyButtons: React.FC<{
  model: StudyModel;
  sentenceId: SentenceId;
}> = observer(({ model, sentenceId }) => {
  // console.log(`isStudyModel: ${String(isStudyModel(model))}`);
  // @armando, not sure why, but this guard wasn't working even when isStudyModel was appearing to always return false
  const notationCount = isStudyModel(model)
    ? model.getNotationCountForSentence(sentenceId)
    : 0;

  const showTranslation =
    model.shouldDisplaySentenceInlineTranslation(sentenceId);
  const onShowTranslation = () => {
    model.toggleCurrentSentenceInlineTranslation();
    positionExpandedSentence();
  };
  const showNotation = model.shouldDisplaySentenceInlineNotations(sentenceId);
  const onShowNotation = () => {
    model.toggleCurrentSentenceInlineNotations();
    positionExpandedSentence();
  };

  return (
    <>
      <TranslationButton active={showTranslation} action={onShowTranslation} />
      <NotationsButton
        active={showNotation}
        action={onShowNotation}
        notationCount={notationCount}
      />
    </>
  );
});

const ListenOnlyButtons: React.FC<{
  model: StudyModel;
  // sentenceId: SentenceId;
}> = observer(({ model /* sentenceId */ }) => {
  return (
    <>
      <StudyFromHereButton model={model} />
    </>
  );
});

export const StudySentenceToolbar: React.FC<{
  model: StudyModel;
  sentenceId: SentenceId;
}> = observer(({ model, sentenceId }) => {
  return (
    <ToolbarStyles>
      <div className="toolbar-inner">
        {model.studyMode ? (
          <StudyOnlyButtons model={model} sentenceId={sentenceId} />
        ) : null}
        {model.fluentListenMode ? <ListenOnlyButtons model={model} /> : null}
        <span className="spacer" />
        <ReplayButton model={model} />
        <OverflowButton model={model} sentenceId={sentenceId} />
      </div>
    </ToolbarStyles>
  );
});
