import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { styled } from '@naan/stitches.config';
import { StoryHeader } from 'story/story-header';
import { learnHomePath } from 'components/nav/path-helpers';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { StoryDetailCard } from 'components/story-detail/story-detail-card';
import { CurrentStoryProvider } from 'components/story-detail/current-story-context';
import { StoryOverflowMenu } from 'components/story-detail/story-overflow-menu';
import { StoryDetailCardAssignment } from 'components/stories/story-detail-card-assignment';
import { DownloadControlTemp } from 'components/stories/download-control-temp';
import { Box } from '@naan/primitives/box';
import { bugsnagNotify } from '@app/notification-service';
import { StoryTab } from './story-tab';
import { StoryLockedActions } from 'story/story-locked-actions';
import { useStoryThumbScrollingFx } from '../scrolling-fx';
import { elementIds } from 'components/dom-utils/element-ids';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { useEnableUpdatePrompt } from '@common/hooks/use-enable-update-prompt';
import { BodyProxy } from 'components/ui/body-proxy';
import { VocabListStoryButton } from 'components/story-status/vocab-list-button';
import { SoundbitesModalButton } from 'components/story-detail/soundbites-modal/soundbites-modal-button';
import { Story } from '@core/models/story-manager';
import { NotFoundScreen } from 'routes/not-found-screen';
// import { createLogger } from '@common/log';
// const log = createLogger('story-detail');

const StoryDetailScreenStyles = styled('div', {
  $$storyHeaderHeight: '56px',
  $$assignmentBannerHeight: '0px',
  $$tabbarHeight: '0px',
  $$tabbarTop: 'calc($$assignmentBannerHeight + $$storyHeaderHeight)',

  position: 'relative',
  isolation: 'isolate',

  minHeight: 'calc(100vh - $$storyHeaderHeight - $$assignmentBannerHeight )',

  '& > .story-header-container': {
    position: 'sticky',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 99,
    // pull story details up behind header
    marginBottom: '-$$storyHeaderHeight',
  },
  '& .unitHeader': {
    textStyle: 'small-heading',
    marginBottom: '$space$4',
    marginTop: '$space$10',
  },
  '& .unitDuration': {
    textStyle: 'small-text',
    marginLeft: '$space$2',
    color: '$colors$textSecondary',
  },
  '&:has(.story-assignment)': {
    $$assignmentBannerHeight: '64px',
  },
  // '&:has(.story-tab-bar)': {
  //   // extra 1px on each of these is for bottom border
  //   $$tabbarHeight: '81px',
  //   '@small': {
  //     $$tabbarHeight: '65px',
  //   },
  // },
});

const StoryChapterList = styled('div', {
  minHeight: 'calc(100vh - $$storyHeaderHeight)',
});

StoryDetailScreenStyles.defaultProps = {
  id: 'story-detail-screen',
};

export const StoryDetailScreen = observer(({ story }: { story: Story }) => {
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  useEnableUpdatePrompt();
  const navigate = useNavigate();

  (window as any).currentStory = story; // console convenience

  useStoryThumbScrollingFx();

  React.useEffect(() => {
    if (story) {
      story.ensureCacheState().catch(bugsnagNotify);
      storyDetailUiModel.setStory(story);
    }

    storyDetailUiModel.executeAutoscroll();

    return () => {
      storyDetailUiModel.reset();
    };
  }, [story]);

  if (!story) {
    // @armando, previously this screen kept the navigataion, but that's gone now
    // i.e. https://app.jiveworld.com/app/learn/es/stories/foo/story
    // that regressed with changes related to the interstitials?
    return <NotFoundScreen reportError={false} />;
  }
  const canGoBack = ReturnNavState.canPopFromStoryDetail();
  // log.debug(`canGoBack: ${String(canGoBack)}`);
  const assignment = story.joinedClassroomAssignment;
  const showTempDownloadControls = AppFactory.root.userManager.showUglyDevUI;

  return (
    <CurrentStoryProvider story={story}>
      <BodyProxy className={'__no-scroll'} />
      <StoryDetailScreenStyles>
        <div
          className="story-header-container"
          id={elementIds.STORY_HEADER_CONTAINER}
        >
          <StoryHeader
            backAction={() =>
              canGoBack ? navigate(-1) : navigate(learnHomePath())
            }
            accessory={
              <>
                <SoundbitesModalButton />

                {story.started ? (
                  <VocabListStoryButton
                    story={story}
                    textColor={story.themeColor}
                    iconColor="white"
                    responsive
                  />
                ) : null}
                <StoryOverflowMenu story={story} />
              </>
            }
          />
        </div>
        <StoryDetailCard story={story} />
        <StoryChapterList>
          <StoryDetailCardAssignment assignment={assignment} />

          {showTempDownloadControls ? (
            <Box css={{ padding: '$space$2' }}>
              <DownloadControlTemp story={story} />
            </Box>
          ) : null}

          <StoryLockedActions />
          <StoryTab />
        </StoryChapterList>
      </StoryDetailScreenStyles>
    </CurrentStoryProvider>
  );
});
