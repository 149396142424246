import React from 'react';
import { observer } from 'mobx-react';
// import { AppFactory } from 'app/app-factory';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import { InVocabLargeIcon } from '@naan/icons/in-vocab-icon';
import { IconButton } from '@naan/primitives/button/icon-button';
import { AddToVocabLargeIcon } from '@naan/icons/add-to-vocab-icon';
import { PlainMarkdown } from '@naan/primitives/text';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';
import { AddToVocabTip } from 'components/ui/onboarding/add-to-vocab-tip';
import { OnboardingService } from '@app/onboarding/onboarding-service';

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 36px',
  padding: '8px 0 12px',
  '& .head': {
    cursor: 'default',
    '& dt': {
      textStyle: 'serif',
      // color: 'blue',
      marginBottom: -2,
    },
    '& dd': {
      textStyle: 'body',
      color: '$colors$textSecondary',
    },
  },
  '& .actions': {
    display: 'flex',
    alignItems: 'center',
  },
});

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: '50%',
  },
});

const ItemButton = observer(
  ({
    notation,
    addVocab,
    removeVocab,
    vocabExists,
  }: {
    notation: ClientNotation;
    addVocab: (slug: string) => void;
    removeVocab: (slug: string) => void;
    vocabExists: (slug: string) => boolean;
  }) => {
    return (
      <>
        {vocabExists(notation.id) ? (
          <IconButton
            presentation={'greenTransparent'}
            size={'large'}
            onClick={() => removeVocab(notation.id)}
            icon={<InVocabLargeIcon />}
            testId={`list-remove-from-vocab-${notation.id}`}
          />
        ) : (
          <IconButton
            presentation={'grayTransparent'}
            size={'large'}
            onClick={() => addVocab(notation.id)}
            icon={<AddToVocabLargeIcon />}
            testId={`list-add-to-vocab-${notation.id}`}
          />
        )}
      </>
    );
  }
);

export const NotationsListItem = observer(
  ({
    notation,
    addVocab,
    removeVocab,
    vocabExists,
    withOnboardingTip: withOnboardingtip,
  }: {
    notation: ClientNotation;
    addVocab: (slug: string) => void;
    removeVocab: (slug: string) => void;
    vocabExists: (slug: string) => boolean;
    withOnboardingTip?: boolean;
  }) => {
    // const model = AppFactory.studyModel;

    // todo: factor with vocab review and notatil detail
    const text = !!notation.usageText
      ? `${notation.usageText} (${notation.headword})`
      : notation.headword;

    const popoverDismissed =
      OnboardingService.instance.isDismissed('addToVocab');
    const showPopover = OnboardingService.instance.showAddToVocabTip;

    return (
      <Wrapper>
        <div
          className="head"
          // onClick={() => model.selectNotationId(notation.id)}
        >
          <dl>
            <dt>
              <PlainMarkdown source={text} />
            </dt>
            {notation.note ? (
              <dd>
                <PlainMarkdown source={notation.note} />
              </dd>
            ) : null}
          </dl>
        </div>

        <div className="actions">
          {withOnboardingtip ? (
            <OnboardingPopover
              showPopover={showPopover}
              dismissed={popoverDismissed}
              onResolvePopover={
                OnboardingService.instance.addToVocabTipDismissed
              }
              renderAnchor={({ ref, getProps }) => (
                <ToolipPositioner
                  ref={ref}
                  {...getProps()}
                  data-id="popover-wrapper"
                  className={showPopover ? 'highlight' : null}
                >
                  <ItemButton
                    notation={notation}
                    addVocab={addVocab}
                    removeVocab={removeVocab}
                    vocabExists={vocabExists}
                  />
                </ToolipPositioner>
              )}
              renderBody={() => {
                return <AddToVocabTip />;
              }}
            />
          ) : (
            <ItemButton
              notation={notation}
              addVocab={addVocab}
              removeVocab={removeVocab}
              vocabExists={vocabExists}
            />
          )}
        </div>
      </Wrapper>
    );
  }
);
