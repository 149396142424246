import * as React from 'react';

import { useTitle } from 'common/hooks/use-title';
import { FilteringProvider } from 'common/filtering/filtering-provider';
import { FiltersUi } from 'components/discover/filters-ui';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { FilteredStories } from 'components/stories/filtered-stories';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  hasPrimaryFilter,
  PrimaryFilterKeys,
} from '@core/models/story-manager/story-manager';
import { notEmpty } from '@utils/conditionals';
import { createLogger } from 'app/logger';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { PageHeadingWithStoryFilters } from 'components/page-heading/page-heading-with-story-filters';
import __ from 'core/lib/localization';
import { useEnableUpdatePrompt } from '@common/hooks/use-enable-update-prompt';
import { BodyProxy } from 'components/ui/body-proxy';
import { ChannelFilterBranding } from './channel-filter-branding';
import { Responsive } from '@naan/primitives/responsive';

const log = createLogger('story-list-screen');

const LayoutWrapper = styled('section', {
  gridColumn: '1/-1',
});

// const ChannelBrandingWrapper = styled('div', {
//   $$centerColumnWidth: '$$maxWidth',

//   '@small': {
//     $$centerColumnGap: '$space$6',
//     display: 'grid',
//     marginTop: 16,
//     gridTemplateColumns:
//       '1fr min($$centerColumnWidth, calc( 100% - calc( $$centerColumnGap * 2) )) 1fr',
//     isolation: 'isolate',

//     '& > .center-column-inner': {
//       gridColumn: '2',
//     },
//   },
// });

export const StoryListScreen = observer(() => {
  useTitle(__('Stories', 'stories'));
  useEnableUpdatePrompt();

  // track when it's safe to pop back from detail or study view
  ReturnNavState.storyListVisited = true;

  const location = useLocation();

  const [queryParams /*, setSearch*/] = useSearchParams();
  const primaryParam = queryParams.get('primary');

  log.debug('primary', primaryParam);
  const primaryFilterKey = notEmpty(primaryParam)
    ? (primaryParam as PrimaryFilterKeys)
    : PrimaryFilterKeys.ALL;
  log.info('primaryFilterKey', primaryFilterKey);

  return (
    <>
      <BodyProxy className={'__no-scroll'} />
      <DeepScrollRestoration pageKey={location.pathname} />
      <FilteringProvider>
        <CenterColumnLayout isolate>
          {/* <TeachWelcomeMessage /> */}
          <ContentGridInnerContainer>
            <PageHeadingWithStoryFilters primaryFilterKey={primaryFilterKey} />
            {hasPrimaryFilter(primaryFilterKey) ? null : <FiltersUi />}
          </ContentGridInnerContainer>
          <Responsive
            renderDefault={() => null}
            renderSmallAndUp={() => <ChannelFilterBranding />}
          />
          <ContentGridInnerContainer>
            <ContentGrid>
              <LayoutWrapper>
                <FilteredStories primaryFilterKey={primaryFilterKey} />
              </LayoutWrapper>
            </ContentGrid>
          </ContentGridInnerContainer>
        </CenterColumnLayout>
      </FilteringProvider>
    </>
  );
});
