import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { formatDate } from 'utils/format-date';
import { LocaleCode } from '@utils/util-types';

import __ from 'core/lib/localization';

export const CancelSubscriptionDialog = observer(
  ({
    onOk,
    onDismiss,
    l2,
    fullAccessUntil,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    l2: LocaleCode;
    fullAccessUntil: string;
  }) => {
    // const productName = AppFactory.root.productNameForL2(l2);

    const body = !!fullAccessUntil
      ? __(
          "By canceling your subscription you won't receive any further charges, and your access will end on {{date}}",
          'cancelSubscriptionBodyWithAccess',
          {
            date: formatDate(fullAccessUntil),
          }
        )
      : __(
          "By canceling your subscription you won't receive any further charges.",
          'cancelSubscriptionBodyWithoutAccess'
        );

    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Are you sure you want to cancel?', 'areYouSureYouWantToCancel')}
        </Dialog.Heading>
        <Dialog.Body>{body} </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
              setTimeout(() => {
                onDismiss();
              }, 100);
            }}
            label={__('Cancel subscription', 'cancelSubscription')}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
