import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { GiftCouponsScreen } from './gift-coupons-screen';
import { CloseAccountScreen } from './close-account-screen';
import { AccountClosedScreen } from './account-closed-screen';
import { CreateAccountScreen } from './auth/create-account-screen';
import { SignInLayout } from './auth/sign-in-layout';
import { SignInPasswordScreen } from './auth/sign-in-password-screen';
import { SignInCodeScreen } from './auth/sign-in-code-screen';
import { EmailCodeScreen } from './auth/email-code-screen';
import { RedirectToStripeScreen } from './redirect-to-stripe-screen';
import { RedeemCode } from './redeem';

import { ClassroomsScreen } from './classrooms-screen';
import { SchoolActivationScreen } from './school-activation-screen';
import { NotFoundScreen } from './not-found-screen';
// 'noop' version substituted for staging, beta, live deployments
import { ShowcaseScreen } from './showcase/showcase-screen-__INTERNAL__';
import { AppWideRouteLayout } from './layouts/app-wide-route-layout';
import { AppRouteLayout } from './layouts/app-route-layout';
import { AuthRouteLayout } from './layouts/auth-route-layout';
import { FavoritesScreen } from './favorites-screen';
import { StoryListScreen } from './learn/story-list-screen';
import { StoryDetailOrInterstitialScreen } from './learn/story-detail-screen';
import { DashboardScreen } from './learn/dashboard-screen';
import { StudyScreen } from './learn/study-screen';
import { DevToolsScreen } from './dev-tools';

// disabling dynamic importing until we figure out what's going on
import AccountScreen from './account-screen';
import ClassroomAssignmentScreen from './classroom-assignment-screen';
import ClassroomScreen from './classroom-screen';
import DiscoverScreen from './discover-screen';
import VolumeDetailScreen from './volume-detail-screen';
import { ProgressScreen } from './progress-screen';
import { ProfileLayout } from 'components/layouts/profile-layout';
import { TeachLayout } from 'components/layouts/teach-layout';
import { LearnLayout } from 'components/layouts/learn-layout';
import {
  defaultPath,
  homePath,
  signInPath,
  teachHomePath,
} from 'components/nav/path-helpers';
import { ClassroomsRedirection } from './classrooms-redirection';
import { AdminLayout } from 'components/layouts/admin-layout';
import { ExcerptListScreen } from './admin/excerpt-list-screen';
import { CaliScriptScreen } from './teach/cali-script-screen';
import { MasalaPreviewScreen } from './admin/masala-preview-screen';
import { CollectionListScreen } from './learn/collection-list-screen';
import { CollectionDetailScreen } from './learn/collection-screen';
import { SelectCatalog } from './admin/select-catalog';
import { SubscribeScreen } from './profile/subscribe-screen';
import { LogoutHandler } from './logout-handler';
import { SupportScreen } from './support-screen';
import { SyncCatalogUrls } from './admin/sync-catalog-urls';
import { AffiliateAttribution } from './affiliate-attribution';
import { EnableVocabExport } from './profile/enable-vocab-export';
import { CalendarScreen } from './learn/soundbites/calendar-screen';
import { AdminSoundbiteScreen } from './admin/admin-soundbite-screen';
import { SlugSoundbiteScreen } from './learn/soundbites/slug-soundbite-screen';
import { ExitSurveyScreen } from './forms/exit-survey-screen';
import { SignInGoogleScreen } from './auth/sign-in-google-screen';
import { SignUpNameScreen } from './auth/sign-up/sign-up-name-screen';
import { SignUpEmailScreen } from './auth/sign-up/sign-up-email-screen';
import { SyncStripeData } from './admin/sync-stripe-data';

const redeemRoutePaths = [
  '/redeem/:code',
  '/redeem-nav-gift/:code',
  '/students/join/:code',
];

// // todo: these redirects need to be moved to l2-router.tsx
// const redirects: Record<string, string> = {
//   // auth redirects
//   '/sign-in': '/auth/sign-in',
//   '/create-account': '/auth/create-account',
//   '/reset-password': '/auth/reset-password',
//   '/create-password': '/auth/create-password',

//   // profile redirects
//   '/account': '/app/profile/account', // should only be used by campaign monitor manage-account links now
//   // '/account': '/app/learn/es/home', // tentatively redirect our deep link accounts urls to the new dashboard
//   '/gift-coupons': '/app/profile/gift-coupons',
//   '/schools': '/app/profile/schools',
//   '/close-account': '/app/profile/close-account',

//   // classroom redirects
//   '/discover': '/app/teach/es/discover',
//   '/stories': '/app/learn/es/stories',
// };

const FallbackRoute = () => {
  // const location = useLocation();

  // const redirectPath = redirects[location.pathname];

  // if (redirectPath) {
  //   return (
  //     <Navigate
  //       to={createPath({ ...location, pathname: redirectPath })}
  //       replace={true}
  //     />
  //   );
  // }

  return <NotFoundScreen />;
};

export const SpaRoutes = () => {
  return (
    <Routes>
      <Route element={<AppWideRouteLayout />}>
        {/* this is the route we render when the user visits the root path directly `/` */}
        <Route index element={<Navigate to={defaultPath()} replace />} />

        {/* These are a bunch of `redeem` something routes, all rendering the same thing */}
        {redeemRoutePaths.map(path => (
          <Route path={path} element={<RedeemCode />} key={path} />
        ))}

        {/* store affiliate code to LocalState, then redirect to branded dashboard */}
        <Route path="/aff/:code" element={<AffiliateAttribution />} />

        {/* auth routes */}
        {/* <Route path="/welcome" element={<WelcomeScreen />} /> */}
        <Route
          path="/welcome"
          element={<Navigate to={defaultPath()} replace />}
        />
        <Route path="/auth" element={<AuthRouteLayout />}>
          <Route index element={<Navigate to={signInPath()} replace />} />
          <Route element={<SignInLayout />}>
            <Route path="sign-in" element={<SignInPasswordScreen />} />
            <Route path="sign-in-google" element={<SignInGoogleScreen />} />
            <Route path="email-code" element={<EmailCodeScreen />} />
            <Route path="sign-in-code" element={<SignInCodeScreen />} />
          </Route>
          <Route path="create-account" element={<CreateAccountScreen />} />

          {/* <Route path="reset-password" element={<ResetPasswordScreen />} /> */}
          {/* <Route path="create-password" element={<CreatePasswordScreen />} /> */}
        </Route>
        <Route path="/auth/create-account">
          <Route path="name" element={<SignUpNameScreen />} />
          <Route path="email" element={<SignUpEmailScreen />} />
        </Route>

        {/* handle marketing site link, which redirects differently based on auth/user state */}
        <Route path="/classrooms" element={<ClassroomsRedirection />} />

        <Route path="/app" element={<Navigate to={homePath()} replace />} />

        <Route path="app/profile/subscribe" element={<SubscribeScreen />} />

        <Route
          path="/app/profile"
          element={<AppRouteLayout component={ProfileLayout} />}
        >
          <Route index element={<Navigate to="account" replace />} />
          <Route path="account" element={<AccountScreen />} />
          {/* <Route path="subscribe" element={<SubscribeScreen />} /> */}
          <Route path="gift-coupons" element={<GiftCouponsScreen />} />
          <Route path="schools" element={<SchoolActivationScreen />} />
          <Route path="close-account" element={<CloseAccountScreen />} />
          <Route path="dev-tools/*" element={<DevToolsScreen />} />
          <Route path="enable-vocab-export" element={<EnableVocabExport />} />
        </Route>

        <Route
          path="/app/page"
          element={<AppRouteLayout component={ProfileLayout} />}
        >
          <Route path="exit-survey" element={<ExitSurveyScreen />} />
        </Route>

        <Route
          path="/app/support"
          // @armando, the LearnLayout expects an :l2 path element
          // i'll hack around it for now, but this deserves a bit more thought
          element={<AppRouteLayout component={LearnLayout} />}
        >
          <Route index element={<SupportScreen />} />
        </Route>

        <Route
          path="/app/teach"
          element={<AppRouteLayout component={TeachLayout} />}
        >
          {/* for now will redirect to either ./discover or ./classrooms */}
          <Route index element={<Navigate to={teachHomePath()} replace />} />
          <Route path="classrooms">
            <Route index element={<ClassroomsScreen />} />
            <Route path=":id">
              <Route index element={<ClassroomScreen />} />
              <Route
                path="assignments/:slug"
                element={<ClassroomAssignmentScreen />}
              />
            </Route>
          </Route>
          <Route path="discover" element={<DiscoverScreen />} />
          <Route path="discover/v/:slug" element={<VolumeDetailScreen />} />
          <Route path="favorites" element={<FavoritesScreen />} />
          <Route path="favorites/v/:slug" element={<VolumeDetailScreen />} />
        </Route>

        <Route
          path="/app/learn"
          element={<AppRouteLayout component={LearnLayout} />}
        >
          <Route index element={<Navigate to="home" replace />} />
          <Route path="home" element={<DashboardScreen />} />
          <Route path="stories" element={<StoryListScreen />} />
          <Route
            path="stories/:slug/*"
            element={<StoryDetailOrInterstitialScreen />}
          />
          <Route
            path="stories/:slug/study/:unitNumber/:chapterPosition"
            element={<StudyScreen />}
          />

          <Route path="soundbites" element={<CalendarScreen />} />
          <Route
            path="soundbites/:soundbiteSlug"
            element={<SlugSoundbiteScreen />}
          />

          <Route
            path="stories/:storySlug/soundbites/:soundbiteSlug"
            element={<SlugSoundbiteScreen />}
          />

          <Route path="progress" element={<ProgressScreen />} />
          <Route path="collections" element={<CollectionListScreen />} />
          <Route
            path="collections/:slug"
            element={<CollectionDetailScreen />}
          />
        </Route>

        <Route path="/scripts">
          <Route path={':flavor/:slug'} element={<CaliScriptScreen />} />
          {/* <Route path={'scaffolded/:slug'} element={<StoryGuideScreen />} /> */}
        </Route>

        <Route path="/logout" element={<LogoutHandler />} />
        {/* <Route path="/switch" element={<L2SwitchGuard />} /> */}
        {/* <Route path="/skip-auth" element={<SkipAuthHandler />} /> */}

        <Route path="/account-closed" element={<AccountClosedScreen />} />

        {/* <Route
              path="/appsupport/back-to-app"
              element={<BackToAppScreen />}
            /> */}

        <Route>
          <Route
            path="/appsupport/subscribe"
            element={<RedirectToStripeScreen />}
          />
          <Route
            path="/appsupport/subscribe/:token"
            element={<RedirectToStripeScreen />}
          />
        </Route>
        <Route path="/showcase/*" element={<ShowcaseScreen />} />

        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<Navigate to="excerpts" replace />} />
          {/* <Route index element={<AdminHomeScreen />} /> */}
          <Route path="excerpts" element={<ExcerptListScreen />} />
          <Route path="preview" element={<AdminSoundbiteScreen />} />
          <Route path="sync-catalog-urls" element={<SyncCatalogUrls />} />
          <Route path="sync-stripe-data" element={<SyncStripeData />} />
        </Route>

        <Route path="/soundbite-demo" element={<Navigate to="/" replace />} />
        <Route path="/try-soundbite" element={<Navigate to="/" replace />} />
        <Route path="/daily-soundbite" element={<Navigate to="/" replace />} />
        <Route
          path="/soundbite-orientation"
          element={<Navigate to="/" replace />}
        />

        {/* // used to preview editorial content independent of selected catalog */}
        <Route path="/masala-preview" element={<MasalaPreviewScreen />} />
        <Route path="/admin/select-catalog/:slug" element={<SelectCatalog />} />

        <Route path="*" element={<FallbackRoute />}></Route>
      </Route>
    </Routes>
  );
};
