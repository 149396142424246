import { ModelTreeNode, identifier, frozen } from 'ts-state-tree/tst-core';
import { ChapterCatalogData } from './chapter-catalog-data';
import { getBaseRoot } from '../app-root';
import { Notation, Chapter } from '@tikka/client/client-aliases';
import { IElement, StringToString } from '@tikka/basic-types';
import { fromIntervals } from '@tikka/intervals/intervals';
import {
  millisToMinutes,
  minutesToPrettyDuration,
} from '@core/lib/pretty-duration';
import { createLogger } from '@common/log';
import { isBogotaVocabSlug } from '../story-manager/story';

const log = createLogger('unit-catalog-data');

// correlates to UnitCaliData masala schema
export class UnitCatalogData extends ModelTreeNode {
  static CLASS_NAME = 'UnitCatalogData' as const;

  @identifier
  slug: string = '';
  volumeSlug: string = ''; // parent ref

  unitNumber: number = 0;
  // partTitleSuffix: string = null;
  durationMillis: number = 0;

  chapters: ChapterCatalogData[] = [];
  elements: /*IElement*/ object[] = []; // data needed to display vocab from lupa web story details

  @frozen
  bogotaVocabMigrationMap: StringToString = {};
  // bogotaVocabMigrationMap: TSTStringMap<string> = snap({});

  static create(snapshot: any) {
    return super.create(UnitCatalogData, snapshot) as UnitCatalogData;
  }

  get story() {
    const { storyManager } = getBaseRoot(this);
    if (!storyManager) return null;
    return storyManager.story(this.storySlug);
  }

  // resolve the slug of the first unit if we're in a multi-unit story
  get storySlug() {
    return this.volumeSlug;
  }

  get partLabel() {
    // return this.partSuffix ? `Part ${this.partSuffix}` : null;
    return `Part ${this.unitNumber}`; // todo: support an override / localization when needed
  }

  get chapterCount() {
    return this.chapters?.length || 0;
  }

  get chaptersWithSoundbites() {
    return this.chapters.filter(ch => ch.chapterSoundbites.length > 0);
  }

  // todo: consider resolving during ingestion or catalog load
  get isLastUnit() {
    return this.unitNumber === this.story.unitCount + 1;
  }

  get durationMinutes(): number {
    return millisToMinutes(this.durationMillis);
  }

  get durationDescription(): string {
    const duration = minutesToPrettyDuration(this.durationMinutes);
    return duration;
  }

  // todo: figure out how to deal with the json schema gen
  get iElements(): IElement[] {
    return this.elements as IElement[];
  }

  get notations(): Notation[] {
    const chapters = this.iElements.filter(
      el => el.kind === 'CHAPTER'
    ) as Chapter[];
    const chapterIntervals = fromIntervals(
      chapters.map(ch => ({ begin: ch.address, end: ch.endAddress }))
    );
    const notations = this.iElements.filter(
      el => el.kind === 'NOTATION'
    ) as Notation[];
    for (const notation of notations) {
      notation.unit = this.unitNumber;
      notation.chapter = chapterIntervals.containing(notation.address) + 1; // transform "chapterPosition" to ChapterRef prop
    }
    return notations;
  }

  // async fetchDataAndMigrateVocabSlugs(vocabSlugs: string[]): Promise<string[]> {
  //   log.info(`fetchDataAndMigrateVocabSlugs(${this.slug})`);
  //   try {
  //     // todo: get ride of this uglyness disconnected TST nodes
  //     const fullVolumeData =
  //       await AppFactory.root.storyManager.loadVolumeDataUrl(
  //         this.story.volumeDataUrl
  //       );
  //     await this.story.ensureVolumeDetailData();
  //     const fullUnitData = fullVolumeData.unitDataBySlug(this.slug);
  //     return fullUnitData.migrateBogotaVocabSlugs(vocabSlugs);
  //   } catch (error) {
  //     log.error(
  //       `fetchDataAndMigrateVocabSlugs - error fetching data for unit: ${this.slug}`,
  //       error
  //     );
  //     bugsnagNotify(error as Error);
  //     if (isNetworkError(error)) {
  //       throw error;
  //     }
  //     return vocabSlugs;
  //   }
  // }

  migrateBogotaVocabSlugs(vocabSlugs: string[]): string[] {
    const result = vocabSlugs.map(slug =>
      bogotaToCaliVocabSlug(slug, this.bogotaVocabMigrationMap)
    );
    return result;
  }

  // bogotaToCaliVocabSlug(slug: string) {
  //   if (isBogotaVocabSlug(slug)) {
  //     const candidate = this.bogotaVocabMigrationMap[slug];
  //     if (candidate) {
  //       return candidate;
  //     }
  //     const candidates = this.fuzzyMatchedVocabSlugs(slug);
  //     if (candidates.length === 1) {
  //       const candidate = candidates[0];
  //       log.warn(
  //         `fuzzy matching unique for bogota slug: ${slug}, matched: ${candidate}`
  //       );
  //       return candidate;
  //     } else {
  //       log.warn(
  //         `fuzzy matching non-unique for bogota slug: ${slug}, matches: ${candidates.length} - ignoring`
  //       );
  //     }
  //   }
  //   return slug;
  // }

  // fuzzyMatchedVocabSlugs(slug: string): string[] {
  //   const slugWord = slug.split('-')[1];
  //   const result: string[] = [];
  //   for (const [key, value] of Object.entries(this.bogotaVocabMigrationMap)) {
  //     const keyWord = key.split('-')[1];
  //     if (slugWord === keyWord) {
  //       result.push(value);
  //     }
  //   }
  //   return result;
  // }
}

export const migrateBogotaVocabSlugs = (
  vocabSlugs: string[],
  migrationMap: StringToString
): string[] => {
  const result = vocabSlugs.map(slug =>
    bogotaToCaliVocabSlug(slug, migrationMap)
  );
  return result;
};

const bogotaToCaliVocabSlug = (slug: string, migrationMap: StringToString) => {
  if (isBogotaVocabSlug(slug)) {
    const candidate = migrationMap[slug];
    if (candidate) {
      // return candidate;
      return sanitizeDirtyMasalaElementId(candidate);
    }
    const candidates = fuzzyMatchedVocabSlugs(slug, migrationMap);
    if (candidates.length === 1) {
      const candidate = candidates[0];
      log.warn(
        `fuzzy matching unique for bogota slug: ${slug}, matched: ${candidate}`
      );
      // return candidate;
      return sanitizeDirtyMasalaElementId(candidate);
    } else {
      log.warn(
        `fuzzy matching non-unique for bogota slug: ${slug}, matches: ${candidates.length} - ignoring`
      );
    }
  }
  // return slug;
  // not sure exactly how, but we seem to have ended up with dirty id's in some of our live migration maps
  return sanitizeDirtyMasalaElementId(slug);
};

const fuzzyMatchedVocabSlugs = (
  slug: string,
  migrationMap: StringToString
): string[] => {
  const slugWord = slug.split('-')[1];
  const result: string[] = [];
  for (const [key, value] of Object.entries(migrationMap)) {
    const keyWord = key.split('-')[1];
    if (slugWord === keyWord) {
      result.push(value);
    }
  }
  return result;
};

// thought this was only an issue with vocab saved in the new system prior to
// the recent catalog sanitization, but this bit us again with real users after launch

// there were 20 or so stories polluted by the early masala code with undesirable characters
// in the generated element ids. this was only dealt with after the vocab migration maps were
// generated, so it's easiest to clean them up here right after fetching for the outdated maps.
export const sanitizeDirtyMasalaElementId = (str: string): string => {
  if (!str?.includes(':')) return str;
  const parts = str.split(':');
  const tail = parts[parts.length - 1];
  if (hasDirtyIdChars(tail)) {
    parts[parts.length - 1] = sanitizeDirtyId(tail);
    const result = parts.join(':');
    log.warn(`sanitizing dirty vocab id: ${str} -> ${result}`);
    return result;
  } else {
    return str;
  }
};

// some old element ids seems to have `+`s and `_`s,
// so flatten to `0`s during ingestion
const sanitizeDirtyId = (str: string) => {
  return str?.replace(/[+_]/g, '0');
};

export const isDirtyMasalaElementId = (str: string): boolean => {
  if (!str?.includes(':')) return false;
  const parts = str.split(':');
  const tail = parts[parts.length - 1];
  return hasDirtyIdChars(tail);
};

const hasDirtyIdChars = (str: string): boolean => {
  return str?.includes('+') || str?.includes('_');
};
