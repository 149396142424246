import React from 'react';
// import { useMediaQuery } from 'react-responsive';
import { styled } from '@naan/stitches.config';

// import __ from 'core/lib/localization';

const VocabBadge = styled('div', {
  '& .badge': {
    textStyle: 'body-bold',
    width: '48px',
    padding: '2px 8px',
    borderRadius: '14px',
    textAlign: 'center',
    color: '$colors$white',
    background: '$colors$green-500',
  },
  '&.is-zero': {
    opacity: '0.3',
  },

  '@media screen and (max-width: 549px)': {
    display: 'flex',
    flexDirection: 'row',
    span: {
      marginLeft: '8px',
      color: '$colors$green-500',
    },
  },
});

export const VocabCountCell = ({ vocabCount }: { vocabCount: number }) => {
  // const isMobile = useMediaQuery({
  //   query: '(max-device-width: 550px)',
  // });

  return (
    <VocabBadge className={vocabCount === 0 ? 'is-zero' : ''}>
      <div className="badge">{vocabCount}</div>
      {/* // localization complexity not justified */}
      {/* {isMobile ? <span>{__('vocab', 'vocab')}</span> : null} */}
    </VocabBadge>
  );
};
