import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';

import classNames from 'classnames';
import { styled } from '@naan/stitches.config';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { SnailIcon } from '@naan/icons/snail-icon';
import { CopySmallIcon } from '@naan/icons/copy-icon';
import { Text } from '@naan/primitives/text';
import { StudyModel } from 'study/models/study-model';
import { AppFactory } from '@app/app-factory';
import { copyToClipboard } from '@common/hooks/use-copy-powers';
import { NotificationService } from '@app/notification-service';
import { Responsive } from '@naan/primitives/responsive';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { EyeIcon } from '@naan/icons/eye-icon';
import {
  NotationIcon,
  TranslationIcon,
} from 'player/views/elements-presentations/sentence-toolbar/sentence-toolbar-icons';
import { SentenceId } from '@tikka/basic-types';

import {
  isSoundbiteModel,
  isStudyModel,
} from 'player/models/player-model-handle';

import __ from '@core/lib/localization';

type StudyOrSoundbiteModel = StudyModel | SoundbiteModel;

const MenuItemWrapper = styled('ul', {
  display: 'flex',
  borderTop: '1px solid $$borderColor',
  '& > button': {
    all: 'unset',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '16px 18px',
    transition: 'all 0.12s ease',
    color: '$$buttonTextColor',
    '&[disabled]': {
      opacity: 0.5,
    },

    '&:active': {
      backgroundColor: '$$buttonActiveBackgroundColor',
    },

    '& .icon': {
      marginLeft: -16,
      marginRight: 12,
      '& svg': {
        width: 24,
        height: 24,
      },
    },
    '& .label': {
      // textStyle: 'small-text-bold',
    },
  },
});

const MenuActionItem = ({
  action,
  icon,
  label,
  disabled,
}: {
  action: () => void;
  icon: React.ReactNode;
  label: string;
  disabled?: boolean;
}) => {
  return (
    <MenuItemWrapper>
      <button
        onClick={e => {
          e.preventDefault();
          action();
        }}
        disabled={disabled}
      >
        <span className="icon">{icon}</span>
        <span className="label">{label}</span>
      </button>
    </MenuItemWrapper>
  );
};

const MenuContext = React.createContext<{
  model: StudyOrSoundbiteModel;
  onDismiss: () => void;
  sentenceId: SentenceId;
} | null>(null);

const MenuWrapper = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: '0 16px var(--sab)',
  $$borderColor: '$colors$gray-100',
  $$buttonTextColor: '$colors$textPrimary',
  $$buttonActiveBackgroundColor: '$colors$gray-20',

  '&.dark': {
    $$borderColor: '$colors$gray-700',
    $$buttonTextColor: '$colors$white',
    $$buttonActiveBackgroundColor: '$colors$gray-700',
  },
});

const CopyActionItem = () => {
  // (window as any).model = model;

  const { model, onDismiss } = React.useContext(MenuContext)!;

  const handleCopy = React.useCallback(() => {
    copyToClipboard(model.currentSentenceText)
      .then(
        () => {
          NotificationService.open({
            type: 'success',
            message: __('Copied to clipboard', 'copiedToClipboard'),
          });
        },
        () => {
          NotificationService.open({
            type: 'error',
            message: __(
              'Failed to copy to clipboard',
              'failedToCopyToClipboard'
            ),
          });
        }
      )
      .finally(() => {
        onDismiss();
      });
  }, [model, onDismiss]);

  return (
    <MenuActionItem
      action={() => handleCopy()}
      icon={<CopySmallIcon />}
      label={__('Copy', 'copy')}
    />
  );
};

const ReplayActionItem = () => {
  const { model, onDismiss } = React.useContext(MenuContext)!;

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.replayCurrentSentence();
      }}
      icon={<ReplayIcon />}
      label={__('Replay', 'replay')}
    />
  );
};

const SlowReplayActionItem = () => {
  const { model, onDismiss } = React.useContext(MenuContext)!;

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.snailReplayCurrentSentence();
      }}
      icon={<SnailIcon />}
      label={__('Slow replay', 'slowReplay')}
    />
  );
};

const StudyFromHereActionItem = () => {
  const { model, onDismiss } = React.useContext(MenuContext)!;

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.togglePlayerMode();
      }}
      icon={<EyeIcon />}
      label={__('Study from here', 'studyFromHere')}
    />
  );
};

const TranslationActionItem = () => {
  const { model, onDismiss, sentenceId } = React.useContext(MenuContext)!;

  const active = model.shouldDisplaySentenceInlineTranslation(sentenceId);

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.toggleCurrentSentenceInlineTranslation();
      }}
      icon={<TranslationIcon />}
      label={
        active
          ? __('Hide translation', 'hideTranslation')
          : __('Show translation', 'showTranslation')
      }
    />
  );
};

/*

export const NotationsButton = ({
  notationCount,
  active,
  action,
}: {
  active: boolean;
  action: () => void;
  notationCount: number;
}) => {
  const hasNotations = notationCount > 0;
  const notationLabel = String(notationCount);

  const handleNotationClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (hasNotations) {
        action();
      }
    },
    [hasNotations, action]
  );

  return (
    <button
      className={classNames('notation-button', {
        active,
        disabled: !hasNotations,
      })}
      onClick={handleNotationClick}
    >
      <span className="icon">
        <NotationIcon text={notationLabel} />
      </span>
      <span className="label">{__('Vocab', 'vocab')}</span>
    </button>
  );
};
*/

const NotationActionItem = () => {
  const { model, onDismiss, sentenceId } = React.useContext(MenuContext)!;

  // to keep ts happy
  if (!isStudyModel(model)) {
    return null;
  }
  const notationCount = isStudyModel(model)
    ? model.getNotationCountForSentence(sentenceId)
    : 0;
  const active = model.displayNotationsInlineSentenceId === sentenceId;
  const hasNotations = notationCount > 0;
  const notationLabel = String(notationCount);

  return (
    <MenuActionItem
      action={() => {
        onDismiss();
        model.toggleCurrentSentenceInlineNotations();
      }}
      disabled={!hasNotations}
      icon={<NotationIcon text={notationLabel} />}
      label={
        active ? __('Hide vocab', 'hideVocab') : __('Show vocab', 'showVocab')
      }
    />
  );
};

type PlayerType = 'soundbite' | 'study' | 'listen';

const getModelPlayerType = (model: StudyOrSoundbiteModel): PlayerType => {
  if (isSoundbiteModel(model)) {
    return 'soundbite';
  } else if (isStudyModel(model) && model.studyMode) {
    return 'study';
  } else {
    return 'listen';
  }
};

const SoundbiteModalContent = () => {
  return (
    <>
      <ReplayActionItem />
      <SlowReplayActionItem />
      <CopyActionItem />
    </>
  );
};

const StudyModalContent = () => {
  return (
    <>
      <TranslationActionItem />
      <NotationActionItem />
      <ReplayActionItem />
      <SlowReplayActionItem />
      <CopyActionItem />
    </>
  );
};

const ListenModalContent = () => {
  return (
    <>
      <StudyFromHereActionItem />
      <ReplayActionItem />
      <SlowReplayActionItem />
    </>
  );
};

const SentenceModalContent = ({
  model,
  onDismiss,
}: {
  model: StudyOrSoundbiteModel;
  onDismiss: () => void;
}) => {
  const playerType = getModelPlayerType(model);

  return (
    <MenuWrapper className={classNames({ dark: model?.fluentListenMode })}>
      {playerType === 'soundbite' ? <SoundbiteModalContent /> : null}
      {playerType === 'study' ? <StudyModalContent /> : null}
      {playerType === 'listen' ? <ListenModalContent /> : null}
    </MenuWrapper>
  );
};

const SentenceSheet = ({
  model,
  onDismiss,
}: {
  model: StudyOrSoundbiteModel;
  onDismiss: () => void;
}) => {
  return (
    <Dialog.Container
      open
      onDismiss={onDismiss}
      flavor="sheet"
      css={
        model.fluentListenMode
          ? {
              backgroundColor: '$colors$gray-800',
            }
          : undefined
      }
    >
      <Dialog.Heading>
        <Text textStyle="small-caps" color="textSecondary">
          {__('Sentence actions', 'sentenceActions')}
        </Text>
      </Dialog.Heading>
      <Dialog.CloseButton
        presentation={model.fluentListenMode ? 'whiteTransparent' : undefined}
      />
      <SentenceModalContent model={model} onDismiss={onDismiss} />
    </Dialog.Container>
  );
};

const SentenceModal = ({
  model,
  onDismiss,
}: {
  model: StudyOrSoundbiteModel;
  onDismiss: () => void;
}) => {
  return (
    <Dialog.Container
      open
      onDismiss={onDismiss}
      css={
        model.fluentListenMode
          ? {
              backgroundColor: '$colors$gray-800',
            }
          : undefined
      }
    >
      <Dialog.Heading>
        <Text
          textStyle="small-caps"
          color={model.fluentListenMode ? 'white' : 'textSecondary'}
        >
          {__('Sentence actions', 'sentenceActions')}
        </Text>
      </Dialog.Heading>
      <Dialog.CloseButton
        presentation={model.fluentListenMode ? 'whiteTransparent' : undefined}
      />
      <SentenceModalContent model={model} onDismiss={onDismiss} />
    </Dialog.Container>
  );
};

export const presentSentenceActions = (
  model: StudyOrSoundbiteModel,
  sentenceId: SentenceId
) => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <Responsive
      renderDefault={() => (
        <MenuContext.Provider value={{ model, onDismiss, sentenceId }}>
          <SentenceSheet onDismiss={onDismiss} model={model} />
        </MenuContext.Provider>
      )}
      renderMediumAndUp={() => (
        <MenuContext.Provider value={{ model, onDismiss, sentenceId }}>
          <SentenceModal onDismiss={onDismiss} model={model} />
        </MenuContext.Provider>
      )}
    />
  ));
};
