// codemod-ignore-file
import * as React from 'react';
import { AppFactory } from 'app/app-factory';
// import { GlobalMessageWrapper } from './global-message-wrapper';
import { Navigate, NavLink, Outlet } from 'react-router-dom';
import { homePath } from 'components/nav/path-helpers';

export const AdminLayout: React.FC = ({ children }) => {
  const { hasAdminAccess } = AppFactory.root.userManager;
  if (!hasAdminAccess) {
    return <Navigate to={homePath()} replace />;
  }

  return (
    <>
      <p>
        <NavLink to="/app">home</NavLink>
      </p>
      <h3>Admin</h3>
      {/* <ResponsiveGridLayout className="x-global-grid">
        <ResponsiveMainContainer> */}
      {/* @armando: this somehow broke things so the page body wasn't rendering
      (which broke soundbite previews for the editorial team) */}
      {/* <GlobalMessageWrapper> */}
      <Outlet />
      {/* </GlobalMessageWrapper> */}
      {/* </ResponsiveMainContainer>
        <ResponsiveNavContainer>
          <TeachNavigation />
        </ResponsiveNavContainer>
      </ResponsiveGridLayout> */}
    </>
  );
};
