import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { VocabListHeading } from './vocab-list-heading';
import { colors } from '@naan/tokens/colors';
import { ZeroStateView } from 'components/ui/zero-state-view';
import { VocabIcon } from '@naan/icons/vocab-icon';

import __ from '@core/lib/localization';
import { unwidow } from '@utils/string-utils';

const Wrapper = styled('div', {
  overflowY: 'auto',
  marginBottom: '$4',

  '& > .vocab-rows': {
    // borderTop: '1px solid $gray-100',
    '& > * + *': {
      borderTop: '1px solid $gray-100',
    },
  },
});

Wrapper.displayName = 'VocabListWrapper';

export const VocabListZeroState = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    return (
      <>
        <VocabListHeading count={0} onClose={onDismiss} />
        <ZeroStateView
          icon={<VocabIcon height={40} width={40} color={colors['gray-400']} />}
          title={unwidow(
            __(
              `You haven't saved any vocabulary for this story yet`,
              'noSavedVocabularyForStory'
            )
          )}
          description={__(
            'Tap the "+" button next to a vocabulary item to add it to this list.',
            'tapThePlusButtonNextToAVocabularyItem'
          )}
        ></ZeroStateView>
      </>
    );
  }
);
