import React from 'react';
import { observer } from 'mobx-react';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
// import { SoundbiteEtAlWidgetGroup } from './soundbite-et-al-widget-group';
import { useNavigate } from 'react-router-dom';
// import { SampleStories } from './sample-stories';
import { subscribePath } from 'components/nav/path-helpers';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { AppFactory } from '@app/app-factory';
import { presentPurchaseDisabledAlert } from 'components/account/account-cta/purchase-flow-disabled';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';

import subscribeImgDesktop from './assets/subscribe-desktop.png';
import subscribeImgMobile from './assets/subscribe-mobile.png';

import __, { translateWithoutDefault } from '@core/lib/localization';
import { Responsive } from '@naan/primitives/responsive';
import { LocaleCode } from '@utils/util-types';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: 32,
  // borderBottom: '1px solid $black-alpha-10',
  gap: 16,
  marginBottom: 32,
  backgroundColor: '$red-50',

  '& .text': {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 4,
    '& .heading': {
      textStyle: 'medium-heading',
    },
    '& .copy': {
      textStyle: 'body-text',
      color: '$black-alpha-60',
      width: 'min(100% - 32px, 480px)',
      textAlign: 'center',
    },
  },
  '@medium': {
    paddingTop: 56,
    marginBottom: 40,
    '& .text': {
      '& .heading': {
        textStyle: 'large-heading',
      },
    },
  },
});
const ImageWrapper = styled('div', {
  backgroundColor: '$red-50',
  display: 'flex',
  justifyContent: 'center',
  '& .img': {
    width: '100%',
    maxWidth: 960,
  },
});

const bodyCopy = (l2: LocaleCode) => {
  // switch (l2) {
  //   case 'en':
  //     return __(
  //       'Listen to and understand real English as spoken by ...',
  //       'subscribeCtaBlurb.en'
  //     );
  //   default:
  //     return __(
  //       'Listen to and understand real Spanish as spoken by hundreds of people from over twenty countries.',
  //       'listenToAndUnderstandRealSpanishAsSpokenByHundredsOfPeople'
  //     );
  // }
  return translateWithoutDefault(`cms:subscribeCtaBlurb:${l2}`);
};

export const SubscribeCta = observer(() => {
  const navigate = useNavigate();
  const { userManager, storyManager, l2 } = AppFactory.root;
  const { purchaseFlowDisabled } = userManager;
  const { productName } = storyManager;

  // const freeStory = AppFactory.root.storyManager.storyForUnitSlug('miedo');

  return (
    <>
      <CenterColumnLayout backgroundColor="#ffeded">
        <Wrapper>
          <div className="text">
            <h3 className="heading">
              {__('Unlock  %{productName}', 'unlockProductName', {
                productName,
              })}
            </h3>
            <div className="copy">{bodyCopy(l2)}</div>
          </div>
          <div>
            <Button
              presentation={'teal'}
              label={__('Subscribe now', 'subscribeNow')}
              size={'large'}
              rightIcon={<ArrowRightIcon />}
              onClick={() => {
                if (purchaseFlowDisabled) {
                  presentPurchaseDisabledAlert();
                } else {
                  navigate(subscribePath());
                }
              }}
            />
          </div>
        </Wrapper>
      </CenterColumnLayout>
      <ImageWrapper>
        <Responsive
          renderDefault={() => (
            <img className="img" src={subscribeImgMobile} alt="" />
          )}
          renderMediumAndUp={() => (
            <img className="img" src={subscribeImgDesktop} alt="" />
          )}
        ></Responsive>
      </ImageWrapper>
    </>
  );
});
