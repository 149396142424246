import * as React from 'react';
import __ from 'core/lib/localization';
import { PlainMarkdown } from '@naan/primitives/text';
import * as Onboarding from './components';
import { AppFactory } from '@app/app-factory';

export const SpeedControlTip = () => {
  const l2 = AppFactory.root.l2Localized;
  return (
    <Onboarding.Container>
      <Onboarding.Title>
        {__('Check your speed', 'speedControlTipTitle')}
      </Onboarding.Title>
      <Onboarding.Content>
        <PlainMarkdown
          source={__(
            `Jiveworld features real, naturally spoken %{l2} — what you'll encounter in the real world. We've slowed the speed down to 0.8x, but you can change it here.`,
            'speedControlTipBody',
            { l2 }
          )}
        />
      </Onboarding.Content>
    </Onboarding.Container>
  );
};
