import { LocaleCode } from '@utils/util-types';
import {
  PricingLevel,
  BillingInterval,
  Currency,
  PlanSlug,
  PricingGeneration,
  ActivePricingGenerations,
  L1s,
} from './cas-types';
import { Plan } from './plan';
import { appConfig } from '@app/config';

const activePricingGenerations: ActivePricingGenerations =
  appConfig.activePricingGenerations;

const BRL_USD = 5;

const activePricingGeneration = (l2: LocaleCode): PricingGeneration =>
  activePricingGenerations[l2];

const normalSpanishUsdPrice = (interval: BillingInterval) =>
  interval === 'month'
    ? ES_USD_RETAIL_MONTH
    : interval === 'year'
    ? ES_USD_RETAIL_MONTH * 12
    : 0; /* daily testing */

const buildSpanishPlan = ({
  pricing,
  interval,
  usdPrice,
}: {
  pricing: PricingLevel;
  interval: BillingInterval;
  usdPrice: number;
}) => {
  const result: Plan[] = [];
  for (const l1 of L1s) {
    result.push(
      new Plan({
        l1,
        l2: 'es',
        currency: 'usd',
        pricingLevel: pricing,
        pricingGeneration: activePricingGeneration('es'),
        billingInterval: interval,
        price: usdPrice,
        normalPrice: normalSpanishUsdPrice(interval),
      })
    );
    result.push(
      new Plan({
        l1,
        l2: 'es',
        currency: 'brl',
        pricingLevel: pricing,
        pricingGeneration: activePricingGeneration('es'),
        billingInterval: interval,
        price: usdPrice * BRL_USD,
        normalPrice: normalSpanishUsdPrice(interval) * BRL_USD,
      })
    );
  }
  return result;
};

const normalEnglishBrlPrice = (interval: BillingInterval) =>
  interval === 'month' ? EN_BRL_RETAIL_MONTH : EN_BRL_RETAIL_MONTH * 12;

const buildEnglishPlan = ({
  pricing,
  interval,
  brlPrice,
}: {
  pricing: PricingLevel;
  interval: BillingInterval;
  brlPrice: number;
}) => {
  const result: Plan[] = [];
  for (const l1 of L1s) {
    result.push(
      new Plan({
        l1,
        l2: 'en',
        currency: 'brl',
        pricingLevel: pricing,
        pricingGeneration: activePricingGeneration('en'),
        billingInterval: interval,
        price: brlPrice,
        normalPrice: normalEnglishBrlPrice(interval),
      })
    );
    result.push(
      new Plan({
        l1,
        l2: 'en',
        currency: 'usd',
        pricingLevel: pricing,
        pricingGeneration: activePricingGeneration('en'),
        billingInterval: interval,
        price: brlPrice / BRL_USD,
        normalPrice: normalEnglishBrlPrice(interval) / BRL_USD,
      })
    );
  }
  return result;
};

//
// Jiveworld Espanol
//

const spanish2021Pricing = activePricingGeneration('es').includes('2021');

const ES_USD_RETAIL_MONTH = spanish2021Pricing ? 12 : 15.95;

const buildSpanishPlans = (): Plan[] => {
  if (spanish2021Pricing) {
    return buildSpanish2021Plans();
  } else {
    return buildSpanish2024Plans();
  }
};

//
// 2021 plans
//
const buildSpanish2021Plans = (): Plan[] => [
  ...buildSpanishPlan({
    pricing: 'retail',
    interval: 'month',
    usdPrice: ES_USD_RETAIL_MONTH, // 12
  }),
  ...buildSpanishPlan({
    pricing: 'retail',
    interval: 'year',
    usdPrice: 99,
  }),
  ...buildSpanishPlan({
    pricing: 'retail',
    interval: 'day',
    usdPrice: 1,
  }),
  ...buildSpanishPlan({
    pricing: 'concession', // included for completeness/testing
    interval: 'month',
    usdPrice: 10,
  }),
  ...buildSpanishPlan({
    pricing: 'concession',
    interval: 'year',
    usdPrice: 84,
  }),
  // for 2021, student and classroom prices are the same with different discount labels
  ...buildSpanishPlan({
    pricing: 'student',
    interval: 'month',
    usdPrice: 8,
  }),
  ...buildSpanishPlan({
    pricing: 'student',
    interval: 'year',
    usdPrice: 59,
  }),
  ...buildSpanishPlan({
    pricing: 'classroom',
    interval: 'month',
    usdPrice: 8,
  }),
  ...buildSpanishPlan({
    pricing: 'classroom',
    interval: 'year',
    usdPrice: 59,
  }),
];

//
// 2024 pricing
//
const buildSpanish2024Plans = (): Plan[] => [
  ...buildSpanishPlan({
    pricing: 'retail',
    interval: 'month',
    usdPrice: ES_USD_RETAIL_MONTH, // 15.95
  }),
  ...buildSpanishPlan({
    pricing: 'retail',
    interval: 'year',
    usdPrice: 129,
  }),
  ...buildSpanishPlan({
    pricing: 'retail',
    interval: 'day',
    usdPrice: 1,
  }),
  // for 2024, concession and student prices are the same with different discount labels
  ...buildSpanishPlan({
    pricing: 'concession',
    interval: 'month',
    usdPrice: 11.95,
  }),
  ...buildSpanishPlan({
    pricing: 'concession',
    interval: 'year',
    usdPrice: 99,
  }),
  ...buildSpanishPlan({
    pricing: 'student',
    interval: 'month',
    usdPrice: 11.95,
  }),
  ...buildSpanishPlan({
    pricing: 'student',
    interval: 'year',
    usdPrice: 99,
  }),
  ...buildSpanishPlan({
    pricing: 'classroom',
    interval: 'month',
    usdPrice: 8,
  }),
  ...buildSpanishPlan({
    pricing: 'classroom',
    interval: 'year',
    usdPrice: 66,
  }),
];

//
// Jiveworld English
//
const EN_BRL_RETAIL_MONTH = 59;

const buildEnglishPlans = (): Plan[] => [
  ...buildEnglishPlan({
    pricing: 'retail',
    interval: 'month',
    brlPrice: EN_BRL_RETAIL_MONTH, // 59
  }),
  ...buildEnglishPlan({
    pricing: 'retail',
    interval: 'year',
    brlPrice: 479,
  }),
  ...buildEnglishPlan({
    pricing: 'concession',
    interval: 'month',
    brlPrice: 44,
  }),
  ...buildEnglishPlan({
    pricing: 'concession',
    interval: 'year',
    brlPrice: 359,
  }),
  // not expected to be relevant, but included for completeness/testing
  ...buildEnglishPlan({
    pricing: 'student',
    interval: 'month',
    brlPrice: 44,
  }),
  ...buildEnglishPlan({
    pricing: 'student',
    interval: 'year',
    brlPrice: 359,
  }),
  ...buildEnglishPlan({
    pricing: 'classroom',
    interval: 'month',
    brlPrice: 30,
  }),
  ...buildEnglishPlan({
    pricing: 'classroom',
    interval: 'year',
    brlPrice: 240,
  }),
];

const plans: Plan[] = [...buildSpanishPlans(), ...buildEnglishPlans()];

type PlanLookup = {
  // eslint-disable-next-line no-unused-vars
  [key in PlanSlug]?: Plan;
};

const planLookup: PlanLookup = {};
plans.forEach(plan => {
  planLookup[plan.slug] = plan;
});

export const allPlans = () => plans;

export const getPlanBySlug = (slug: PlanSlug): Plan => {
  return planLookup[slug];
};

// const mapPricingLevel = (
//   pricingLevel: PricingLevel,
//   l2: LocaleCode
// ): PricingLevel => {
//   if (l2 === 'es' && spanish2021Pricing) {
//     switch (pricingLevel) {
//       case 'classroom':
//         return 'student';
//       case 'concession':
//         return 'affiliate';
//       default:
//         return pricingLevel;
//     }
//   }
//   // 2024 pricing
//   if (pricingLevel === 'classroom') {
//     return pricingLevel;
//   }
//   if (pricingLevel.includes('retail')) {
//     // price group
//     return 'retail';
//   } else {
//     // should 'student' be preserved as a pricing level to drive distinct labeling?
//     return 'concession';
//   }
// };

export const getPlans = ({
  l1,
  l2,
  pricingLevel,
  currency,
  includeDaily,
}: {
  l1: LocaleCode;
  l2: LocaleCode;
  pricingLevel: PricingLevel;
  currency: Currency;
  includeDaily?: boolean;
}): Plan[] => {
  // pricingLevel = mapPricingLevel(pricingLevel, l2);
  const result = plans.filter(plan => {
    return (
      plan.l1 === l1 &&
      plan.l2 === l2 &&
      plan.pricingLevel === pricingLevel &&
      plan.currency === currency &&
      plan.pricingGeneration === activePricingGeneration(l2) &&
      (includeDaily || plan.billingInterval !== 'day')
    );
  });
  return result;
};

// const buildPlan = ({
//   l2,
//   currency,
//   pricing,
//   interval,
//   price,
//   normalPrice,
// }: {
//   l2: LocaleCode;
//   currency: Currency;
//   pricing: PricingLevel;
//   interval: BillingInterval;
//   price: number;
//   normalPrice?: number;
// }) =>
//   new Plan({
//     l2,
//     currency,
//     pricingLevel: pricing,
//     pricingGeneration: activePricingGeneration(l2),
//     billingInterval: interval,
//     price,
//     normalPrice,
//   });

// const plans: Plan[] = [
//   // Jiveworld Espanol
//   buildPlan({
//     l2: 'es',
//     currency: 'usd',
//     pricing: 'retail',
//     interval: 'month',
//     price: USD_RETAIL_MONTH,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'usd',
//     pricing: 'retail',
//     interval: 'year',
//     price: USD_RETAIL_YEAR,
//     normalPrice: USD_RETAIL_MONTH * 12,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'usd',
//     pricing: 'affiliate',
//     interval: 'month',
//     price: USD_AFF_MONTH,
//     normalPrice: USD_RETAIL_MONTH,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'usd',
//     pricing: 'affiliate',
//     interval: 'year',
//     price: USD_AFF_YEAR,
//     normalPrice: USD_RETAIL_MONTH * 12,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'usd',
//     pricing: 'student',
//     interval: 'month',
//     price: USD_STU_MONTH,
//     normalPrice: USD_RETAIL_MONTH,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'usd',
//     pricing: 'student',
//     interval: 'year',
//     price: USD_STU_YEAR,
//     normalPrice: USD_RETAIL_MONTH * 12,
//   }),

//   // we need BRL pricing for Jiveworld Espanol to avoid blowing up for the unexpected case of
//   // an English product customer attempts to also subscribe to the Spanish
//   buildPlan({
//     l2: 'es',
//     currency: 'brl',
//     pricing: 'retail',
//     interval: 'month',
//     price: USD_RETAIL_MONTH * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'brl',
//     pricing: 'retail',
//     interval: 'year',
//     price: USD_RETAIL_YEAR * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'brl',
//     pricing: 'affiliate',
//     interval: 'month',
//     price: USD_AFF_MONTH * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'brl',
//     pricing: 'affiliate',
//     interval: 'year',
//     price: USD_AFF_YEAR * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'brl',
//     pricing: 'student',
//     interval: 'month',
//     price: USD_STU_MONTH * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'es',
//     currency: 'brl',
//     pricing: 'student',
//     interval: 'year',
//     price: USD_STU_YEAR * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
//   }),

//   //
//   // Jiveworld English
//   //
//   // USD
//   //
//   buildPlan({
//     l2: 'en',
//     currency: 'usd',
//     pricing: 'retail',
//     interval: 'month',
//     price: USD_RETAIL_MONTH,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'usd',
//     pricing: 'retail',
//     interval: 'year',
//     price: USD_RETAIL_YEAR,
//     normalPrice: USD_RETAIL_MONTH * 12,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'usd',
//     pricing: 'affiliate',
//     interval: 'month',
//     price: USD_AFF_MONTH,
//     normalPrice: USD_RETAIL_MONTH,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'usd',
//     pricing: 'affiliate',
//     interval: 'year',
//     price: USD_AFF_YEAR,
//     normalPrice: USD_RETAIL_MONTH * 12,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'usd',
//     pricing: 'student',
//     interval: 'month',
//     price: USD_STU_MONTH,
//     normalPrice: USD_RETAIL_MONTH,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'usd',
//     pricing: 'student',
//     interval: 'year',
//     price: USD_STU_YEAR,
//     normalPrice: USD_RETAIL_MONTH * 12,
//   }),

//   //
//   // BRL
//   //
//   buildPlan({
//     l2: 'en',
//     currency: 'brl',
//     pricing: 'retail',
//     interval: 'month',
//     price: USD_RETAIL_MONTH * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'brl',
//     pricing: 'retail',
//     interval: 'year',
//     price: USD_RETAIL_YEAR * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'brl',
//     pricing: 'affiliate',
//     interval: 'month',
//     price: USD_AFF_MONTH * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'brl',
//     pricing: 'affiliate',
//     interval: 'year',
//     price: USD_AFF_YEAR * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'brl',
//     pricing: 'student',
//     interval: 'month',
//     price: USD_STU_MONTH * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * BRL_USD,
//   }),
//   buildPlan({
//     l2: 'en',
//     currency: 'brl',
//     pricing: 'student',
//     interval: 'year',
//     price: USD_STU_YEAR * BRL_USD,
//     normalPrice: USD_RETAIL_MONTH * 12 * BRL_USD,
//   }),

//   // //
//   // // testing
//   // //
//   // buildPlan({
//   //   l2: 'en',
//   //   currency: 'usd',
//   //   pricing: 'retail',
//   //   interval: 'day',
//   //   price: 1,
//   // }),
//   // buildPlan({
//   //   l2: 'en',
//   //   currency: 'brl',
//   //   pricing: 'retail',
//   //   interval: 'day',
//   //   price: 5,
//   // }),
//   // buildPlan({
//   //   l2: 'es',
//   //   currency: 'usd',
//   //   pricing: 'retail',
//   //   interval: 'day',
//   //   price: 2,
//   // }),
//   // buildPlan({
//   //   l2: 'es',
//   //   currency: 'brl',
//   //   pricing: 'retail',
//   //   interval: 'day',
//   //   price: 6,
//   // }),
// ];
