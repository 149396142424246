import React from 'react';
import { observer } from 'mobx-react';
import {
  PauseControlIcon,
  PlayControlIcon,
  SimplePauseControlIcon,
} from '../../../study/views/player-icons';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';
import { createLogger } from 'app/logger';
import { useReaction } from '@common/hooks/use-reaction';

const log = createLogger('player-controls:play-pause-button');

function getCurrentSentence(): HTMLElement | null {
  return document.querySelector('.script-sentence-container.current');
}

const PendingPauseDynamicIcon = observer(() => {
  const model = usePlayerModel();
  const {
    pauseDurationInMs,
    currentSentencePercentagePlayedInPendingPaused: initialPercentage,
  } = model;

  return (
    <PauseControlIcon
      durationInMs={pauseDurationInMs}
      initialPorcentage={initialPercentage}
    />
  );
});

export const PlayModeIcon = observer(({ complex }: { complex: boolean }) => {
  const model = usePlayerModel();
  const {
    player: { transportState },
  } = model;

  const paused = !transportState.isPlaying;
  const pendingPause = transportState.pendingPause;

  if (paused) {
    return <PlayControlIcon />;
  }

  if (pendingPause) {
    return <PendingPauseDynamicIcon />;
  }

  return <SimplePauseControlIcon />;
});

export const PlayPauseButton = observer(({ complex }: { complex: boolean }) => {
  const model = usePlayerModel();
  const {
    player: { transportState },
    playActionDisabled,
  } = model;

  // this reaction needs to live at this level since pending-pause responsiveness is needed
  // for the 'replay' actions even when there's no smart-pause button UI
  useReaction(
    () => transportState.pendingPause,
    pendingPause => {
      if (pendingPause) {
        getCurrentSentence()?.classList.add('pending-pause');
        // ref.current?.classList.add('playing');
      } else {
        getCurrentSentence()?.classList.remove('pending-pause');
        // ref.current?.classList.remove('playing');
      }
    }
  );

  React.useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('pause', function () {
        log.info('activated hardware pause');
        model.pause();
      });
      navigator.mediaSession.setActionHandler('play', function () {
        log.info('activated hardware play');
        model.play();
      });

      return () => {
        navigator.mediaSession.setActionHandler('pause', null);
        navigator.mediaSession.setActionHandler('play', null);
      };
    }
  }, [model, transportState]);

  return (
    <ControlButton
      onClick={() => model.playPauseAction()}
      disabled={playActionDisabled}
    >
      <PlayModeIcon complex={complex} />
    </ControlButton>
  );
});
