import {
  BasePlayerModel,
  PlayerSessionData,
  PlayerType,
  TranslationButtonState,
} from 'player/models/base-player-model';
import { ExcerptData } from '@tikka/client/catalog-types';
import { createLogger } from 'app/logger';
import { makeObservable, observable, runInAction, untracked } from 'mobx';
import { LoadingStatus } from 'player/models/player-model';
import { Speaker } from '@core/models/catalog/speaker';
import { Story } from '@core/models/story-manager/story';
import { AppFactory } from 'app/app-factory';
import { bugsnagNotify } from '@app/notification-service';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { ChapterRef } from '@core/models/user-manager/location-pointer';
import { ElementId } from '@tikka/basic-types';

const log = createLogger('soundbite-model');

export enum AnswerState {
  // eslint-disable-next-line no-unused-vars
  initial,
  // eslint-disable-next-line no-unused-vars
  disabled,
  // eslint-disable-next-line no-unused-vars
  unrevealed,
  // eslint-disable-next-line no-unused-vars
  revealed,
}

export class SoundbiteModel extends BasePlayerModel {
  playerType = PlayerType.SOUNDBITE;

  story: Story;
  soundbite: Soundbite;

  @observable.ref
  answerRevealed = false; // once revealed, then always stays revealed

  @observable.ref displayTranslationInlineSentenceId: ElementId = null;

  constructor() {
    super();
    makeObservable(this);
  }

  async initFromData(data: ExcerptData) {
    log.debug('initFromData');

    if (!data) {
      throw Error(`Missing soundbite data`);
    }
    this.forceDisablePlayback = true; // enabled once script actions are scrolled into view
    await this.initFromPlayerData(data);

    const { storyManager } = AppFactory.root;
    log.info(`data.volumeSlug: ${data.volumeSlug}`);

    this.soundbite = storyManager.soundbite(data.slug);
    if (!this.soundbite) {
      log.warn(`storyManager.soundbite(${data.slug}) not resolved`);
    }

    this.answerRevealed = this.soundbite?.completed ?? false;
    if (this.soundbite?.completed) {
      this.forceAllVisitedFurthestTime(this.lastSentenceEndTime);
    }

    this.story = storyManager.storyForVolumeOrUnitSlug(
      data.volumeSlug || data.unitSlug
    );
    log.info(
      `resolved volume slug story: ${this.story?.title}, image url: ${this.story?.listImageUrl}`
    );

    if (!this.story) {
      log.warn(
        'failed to resolve story from story manager - assuming editorial preview usage and directly fetching related story/volume data'
      );
      if (data.volumeDataUrl) {
        this.story = await storyManager.loadVolumeDataUrl(data.volumeDataUrl);
      } else {
        bugsnagNotify(`failed to resolve story for soundbite: ${data.slug}`);
      }
    }

    log.debug('themeColor: ', this.story?.themeColor);
    await this.awaitPlayerAudioLoaded(data.slug);

    log.debug('initFromData complete');
  }

  async resetSession() {
    this.setStatus(LoadingStatus.UNINITIALIZED);
    return this.initFromData(this.excerptData);
  }

  get excerptData(): ExcerptData {
    return this.data as ExcerptData;
  }

  get dataSourceUrl(): string {
    return this.excerptData.dataUrl;
  }

  // latest decision is to completely omit speaker labels for the soundbite player
  resolveSpeaker(label: string): Speaker {
    return null;
  }

  // dismissIntro() {
  //   this.setOnloadModalNeeded(false);

  //   const { userManager } = AppFactory.root;
  //   userManager.userData.playerSettings.setIntroShown(true);
  //   // still need to suppress network animation for some operations, but this should only affected signed in users testing try-soundbites
  //   userManager.persistUserData().catch(bugsnagNotify);
  // }

  // play() {
  //   super.play();
  // }

  // handleChangeMilestoneStatus() {
  //   // must be processed first because the base version updates completionReached
  //   // if (this.afterNotionalCompletion && !this.completionReached) {
  //   //   const { soundbiteUserData } = AppFactory.root.userManager.userData;
  //   //   soundbiteUserData.recordEngagement({
  //   //     slug: this.data.slug,
  //   //     status: SoundbiteEngagementStatus.FINISHED,
  //   //   });
  //   // }
  //   super.handleChangeMilestoneStatus();
  // }

  revealAnswer() {
    this.answerRevealed = true;
    this.track('answer_revealed');
    // const { soundbiteUserData } = AppFactory.root.userManager.userData;
    // soundbiteUserData.recordEngagement({
    //   slug: this.data.slug,
    //   status: SoundbiteEngagementStatus.REVEALED,
    // });
    AppFactory.root.userManager.userData.recordSoundbiteCompletion(
      this.data.slug
    );
    OnboardingService.instance.onRevealAnswer();
  }

  // editorial review support
  resetCompletion() {
    this.answerRevealed = false;
    AppFactory.root.userManager.userData.resetSoundbiteCompletion(
      this.data.slug
    );
  }

  get answerState(): AnswerState {
    // log.info(`answer state: `, touch);
    if (this.answerRevealed) {
      return AnswerState.revealed;
    }
    if (this.completionReached) {
      return AnswerState.unrevealed;
    }
    if (this.hasBeenPlayed) {
      return AnswerState.disabled;
    }
    return AnswerState.initial;
  }

  // no-op needed to satisfy currently shared keyboard controls
  togglePlayerMode() {}

  get fluentListenMode(): boolean {
    return false;
  }

  // get complexPlayActionEnabled(): boolean {
  //   return false;
  // }

  get playActionDisabled(): boolean {
    return this.forceDisablePlayback;
  }

  // playPauseAction() {
  //   this.simplePlayPauseAction();
  // }

  // control never shown for soundbite player
  get backToFurthestUI(): boolean {
    return false;
  }

  // not needed for soundbite player
  get progressBarUI(): boolean {
    return false;
  }

  get translationButtonState(): TranslationButtonState {
    if (this.answerState === AnswerState.revealed) {
      return TranslationButtonState.enabled;
    } else {
      return TranslationButtonState.disabled;
    }
  }

  // disables ability to click on an unvisited sentence
  get skipForwardAllowed(): boolean {
    return false;
  }

  get metricsPrefix(): string {
    return 'soundbite';
  }

  get chapterRef(): ChapterRef {
    return {
      unit: this.soundbite.unitNumber,
      chapter: this.soundbite.chapterPos,
    };
  }

  get storySlug(): string {
    return this.story?.slug;
  }

  get sessionData(): PlayerSessionData {
    const result = {
      playerType: this.playerType,
      storySlug: this.storySlug,
      chapterRef: this.chapterRef,
      // sessionCounter: 0,
      completionReached: false, // this.completionReached,
      furthestMillis: this.furthestMillis,
      millisPlayed: this.millisPlayed,
      timestampIso: new Date().toISOString(),
    };
    return result;
  }

  toggleCurrentSentenceInlineTranslation() {
    const currentSentenceId = this.currentSentenceId;
    if (
      this.displayTranslationInlineSentenceId === currentSentenceId &&
      !this._translationsShown
    ) {
      this.displayTranslationInlineSentenceId = null;
    } else {
      runInAction(() => {
        OnboardingService.instance.didShowTranslation();
        this.displayTranslationInlineSentenceId = currentSentenceId;
        // TODO jfe consider
        this._translationsShown = false;
      });
    }
  }

  shouldDisplaySentenceInlineTranslation(sentenceId: ElementId): boolean {
    // TODO factor with below
    if (sentenceId) {
      if (this.translationsShown) {
        return false;
      }
      const id = this.displayTranslationInlineSentenceId;
      if (sentenceId === id) {
        return true;
      } else {
        if (id && id !== this.currentSentenceId) {
          untracked(() => {
            this.displayTranslationInlineSentenceId = null;
          });
        }
      }
    }
    return false;
  }
}
