import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';
import { LifebuoyIcon } from '@naan/icons/lifebuoy-icon';
import { appConfig } from '@app/env';
import { contactUs, profileHomePath } from 'components/nav/path-helpers';

import __ from '@core/lib/localization';
import { AppFactory } from '@app/app-factory';

const Wrapper = styled('div', {
  position: 'relative',
  background: '$colors$yellow-100',
  padding: '16px 16px 20px 52px',
  borderRadius: 12,
  '& > .icon': {
    position: 'absolute',
    left: 16,
    top: 16,
    color: '$colors$orange-500',
  },
  '& > h3': {
    textStyle: 'small-heading',
    marginBottom: 8,
  },
  a: {
    color: 'inherit',
  },
  '@medium': {
    padding: '24px 24px 24px 60px',
    '& > .icon': {
      left: 24,
      top: 24,
    },
  },
});

export const SupportMessage = () => {
  const { purchaseFlowDisabled } = AppFactory.root.userManager;

  return (
    <Wrapper>
      <div className="icon">
        <LifebuoyIcon />
      </div>
      <h3>{__('Need help?', 'needHelpQuestionMark')}</h3>
      <div className="content">
        {purchaseFlowDisabled ? (
          <PlainMarkdownWithLinks
            source={__(
              `[Visit our Help Center](%{helpCenterUrl}) for answers to common problems. Still need help?
[Get in touch](%{contactUrl}).`,
              'visitOurHelpCenterForAnswersPurchaseDisabled',
              {
                helpCenterUrl: appConfig.website.urls.support,
                contactUrl: contactUs(),
              }
            )}
          />
        ) : (
          <PlainMarkdownWithLinks
            source={__(
              `[Visit our Help Center](%{helpCenterUrl}) for answers to common problems. Still need help?
[Get in touch](%{contactUrl}). You can also manage your subscription from [your account](%{accountUrl}).`,
              'visitOurHelpCenterForAnswers',
              {
                helpCenterUrl: appConfig.website.urls.support,
                contactUrl: contactUs(),
                accountUrl: profileHomePath(),
              }
            )}
          />
        )}
      </div>
    </Wrapper>
  );
};
