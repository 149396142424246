import { DiscountScheme } from './cas-types';

const discounts: DiscountScheme[] = [
  // a generic 'Coupon' will be displayed by stripe if stripeLabel not set
  // todo: do we need distinct strings for pricing cards. localization?
  { percentOff: 10, stripeLabel: 'Developer testing', slug: 'jfedev-10' },
  { percentOff: 20, stripeLabel: 'Dev testing', slug: 'jfedev-20' },
  { percentOff: 17, stripeLabel: 'Dev', slug: 'jfedev-17' },
];

export function allDiscounts() {
  return discounts;
}

export function getDiscountBySlug(slug: string) {
  return discounts.find(d => d.slug === slug);
}
