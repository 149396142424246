import * as React from 'react';
import { ArrowLeftExtraSmallIcon } from '@naan/icons/arrow-left-icon';
import { ArrowRightExtraSmallIcon } from '@naan/icons/arrow-right-icon';
import { ArrowUpExtraSmallIcon } from '@naan/icons/arrow-up-icon';
import { ArrowDownExtraSmallIcon } from '@naan/icons/arrow-down-icon';
import { ShortcutItem } from './ui/shortcut-item';
import { ShortcutSection } from './ui/shortcut-section';

import __ from '@core/lib/localization';

export const ListenShortcuts = () => {
  return (
    <>
      {/* <ShortcutSection title={__('Mode', 'mode')}>
        <ShortcutItem
          title={__('Switch to Study mode', 'switchToStudyMode')}
          shortcut="Shift + Tab"
        />
      </ShortcutSection> */}

      <ShortcutSection title={__('Playback', 'playback')}>
        <ShortcutItem
          title={__('Play/pause', 'playSlashPause')}
          shortcut="Space"
        />
        <ShortcutItem
          title={__('Rewind/fast-forward', 'rewindSlashFastForward')}
          shortcut={[<ArrowLeftExtraSmallIcon />, <ArrowRightExtraSmallIcon />]}
        />

        <ShortcutItem
          title={__('Previous/next sentence', 'previousSlashNextSentence')}
          shortcut={[<ArrowUpExtraSmallIcon />, <ArrowDownExtraSmallIcon />]}
        />
      </ShortcutSection>

      <ShortcutSection title={__('Help', 'help')}>
        <ShortcutItem
          title={__('Hide/show this panel', 'hideSlashShowThisPanel')}
          shortcut="K"
        />
      </ShortcutSection>
    </>
  );
};
