import Dayjs from 'dayjs';
import { applySnapshot } from 'ts-state-tree/tst-core';
import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';
import { track } from 'app/track';
import { AccountData } from './account-data';
import { Root } from '../root';
import __ from 'core/lib/localization';
import { getBaseRoot } from '../app-root';
import { LocaleCode } from '@utils/util-types';
import { AppFactory } from '@app/app-factory';
import { bugsnagNotify } from '@app/notification-service';
import { notEmpty } from '@utils/conditionals';
import { formatMoney } from '@cas-shared/format-money';
import {
  Currency,
  DiscountScheme,
  BillingInterval,
} from '@cas-shared/cas-types';
import { getDiscountBySlug } from '@cas-shared/discount-data';
import { NodeAccountData } from './node-account-data';
import { EntitlementData } from './entitlement-data';
// import { Plan } from '@cas-shared/plan';
// import { getPlans } from '@cas-shared/plan-data';
import { UserManager } from './user-manager';
import { UserData } from './user-data';

const log = createLogger('user-manager');

export type MembershipStatus =
  | 'trial'
  | 'full-auto-renew'
  | 'full-no-renew'
  | 'group-access'
  | 'expired'
  | 'paused'
  | 'suspended';

export class Membership {
  l2: LocaleCode;
  userManager: UserManager;

  constructor(userManager: UserManager, l2: LocaleCode) {
    this.userManager = userManager;
    this.l2 = l2;
  }

  get root(): Root {
    return getBaseRoot(this.userManager);
  }

  get accountData(): AccountData {
    return this.userManager.accountData;
  }

  get nodeAccountData(): NodeAccountData {
    return this.userManager.nodeAccountData;
  }

  get userData(): UserData {
    return this.userManager.userData;
  }

  get apiInvoker() {
    return this.userManager.apiInvoker;
  }

  // get membershipStatus(): MembershipStatus {
  //   return this.membershipStatusL2(this.root.l2);
  // }

  get isTrial(): boolean {
    return this.membershipStatus === 'trial';
  }

  // get membershipL2s(): LocaleCode[] {
  //   const result: LocaleCode[] = [];
  //   for (const l2 of this.root.availableL2s) {
  //     const data = this.membershipDataL2(l2);
  //     if (data?.statusKey !== 'trial') {
  //       result.push(l2);
  //     }
  //   }
  //   return result;
  // }

  // get membershipData(): MembershipData {
  //   return this.membershipDataL2(this.root.l2);
  // }

  get dump() /*: MembershipData*/ {
    const nodeEntitlement = this.nodeEntitlement;
    return {
      l2: this.l2,
      productName: this.root.productNameForL2(this.l2),
      statusKey: this.membershipStatus,
      // statusDisplay: this.membershipDisplayL2(l2),
      membershipTypeDisplay: this.membershipTypeDisplay,
      fullAccess: this.fullAccess,
      autoRenew: this.autoRenew,
      accessExpired: this.accessExpired,
      hadFullAccess: this.hadFullAccess,
      autoRenewInterval: this.autoRenewInterval,
      autoRenewAmount: this.autoRenewAmount,
      autoRenewCurrency: this.autoRenewCurrency,
      autoRenewAmountDisplay: this.autoRenewAmountDisplay,
      showAccountPageCheckout: this.showAccountPageCheckout,
      remainingFullAccessInDays: this.remainingFullAccessInDays,
      showSwitchPlanInterval: this.showSwitchPlanInterval,
      fullAccessUntil: this.fullAccessUntil,
      remainingFullAccessDays: this.remainingFullAccessInDays,
      autoRenewFailed: this.autoRenewFailed,
      paymentFailureMessage: this.paymentFailureMessage,
      licensedClassroomLabel: this.licensedClassroomLabel,
      discountScheme: this.discountScheme, // l2 agnostic
      // properties only relevant to node entitlements
      pausedUntil: nodeEntitlement?.pausedUntil,
      billingResumesOn: nodeEntitlement?.billingResumesOn,
      // only relevant to legacy rails entitlements
      // hasApplePaidAccess: this.accountData.hasApplePaidAccess,
    };
  }

  // deprecated
  get membershipStatus(): MembershipStatus {
    return this.statusKey;
  }

  get statusKey(): MembershipStatus {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      if (
        nodeState.pausedUntil &&
        this.root.storyManager.isTodaySameOrBefore(Dayjs(nodeState.pausedUntil))
      ) {
        return 'paused';
      }
      // if (this.fullAccessL2(l2)) {
      //   return this.autoRenewL2(l2) ? 'full-auto-renew' : 'full-no-renew';
      // } else {
      //   // return !nodeState.stripeSubscriptionId ? 'trial' : 'suspended';
      //   if (!!nodeState.stripeSubscriptionId) {
      //     return 'suspended';
      //   }
      // }
    }

    const accountDataState = this.accountData
      .membershipState as MembershipStatus;
    if (accountDataState === 'group-access') {
      // assume licensed if joined classroom exists for given l2
      const licensed = this.accountData.hasJoinedClassroomsForL2(this.l2);
      if (licensed) {
        return 'group-access';
      }
    }
    const fullAccess = this.fullAccess;
    const accessUntil = this.fullAccessUntil;
    const autoRenew = this.autoRenew;
    if (fullAccess) {
      return autoRenew ? 'full-auto-renew' : 'full-no-renew';
    } else {
      if (autoRenew) {
        return 'suspended';
      }
      return !!accessUntil ? 'expired' : 'trial';
    }
  }

  get membershipTypeDisplay(): string {
    switch (this.membershipStatus) {
      case 'trial':
        return __('Trial', 'trial'); // probably never displayed now
      case 'expired':
        return __('Expired', 'expired');
      case 'full-auto-renew':
        return this.subscriptionInterval;
      case 'paused':
        return __('Paused', 'paused'); // probably qualify once actually exposed
      case 'suspended':
        return __('Suspended', 'suspended') + ` (${this.subscriptionInterval})`;
      case 'full-no-renew':
        return __('Full access (no auto-renew)', 'fullAccessNoAutoRenew');
      case 'group-access':
        return __('Group access', 'groupAccess');
      default:
        return this.membershipStatus; // unexpected, should probably log error
    }
  }

  get subscriptionInterval(): string {
    const interval = this.autoRenewInterval;
    switch (interval) {
      case 'month':
        return __('Monthly subscription', 'monthlySubscription');
      case 'year':
        return __('Yearly subscription', 'annualSubscription');
      case 'day':
        return 'Daily subscription'; // internal testing only, doesn't need localizing
      default:
        return undefined;
    }
  }

  // get fullAccess(): boolean {
  //   return this.fullAccessL2(this.root.l2);
  // }

  get fullAccess(): boolean {
    if (appConfig.forceFullAccess) {
      return true;
    }

    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return (
        nodeState.accessUntil &&
        // todo: this date logic technically belongs in the server, but this is convenient for testing
        this.root.storyManager.isTodaySameOrBefore(Dayjs(nodeState.accessUntil))
      );
    } else {
      if (this.l2 === 'es') {
        return this.accountData.fullAccess;
      } else {
        return false;
      }
    }
  }

  get autoRenew(): boolean {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return !!nodeState.stripeSubscriptionId;
    } else {
      if (this.l2 === 'es') {
        return this.accountData.autoRenew;
      } else {
        return false;
      }
    }
  }

  get accessExpired(): boolean {
    return !this.fullAccess && this.hadFullAccess;
  }

  get hadFullAccess() {
    return notEmpty(this.fullAccessUntil);
  }

  get autoRenewAmountDisplay(): string {
    const amount = this.autoRenewAmount;
    const currrency = this.autoRenewCurrency;
    return amount ? formatMoney(amount, currrency) : '';
  }

  get autoRenewAmount(): number {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      const price = Number(nodeState.price);
      if (nodeState.percentOff) {
        return price * (1 - nodeState.percentOff / 100);
      } else {
        return price;
      }
    } else {
      const amount = this.accountData.paymentData?.autoRenewAmount;
      return amount;
    }
  }

  get autoRenewInterval(): BillingInterval {
    if (!this.autoRenew) {
      return undefined;
    }

    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return nodeState.billingInterval;
    } else {
      // legacy rails subscriptions assumed to be monthly
      return 'month';
    }
  }

  get autoRenewCurrency(): Currency {
    // only one currency can be used per account
    return this.nodeAccountData?.currency || 'usd';
  }

  get discountScheme(): DiscountScheme {
    // todo: honor expiry
    const discountSlug = this.nodeAccountData?.discountSlug;
    return discountSlug ? getDiscountBySlug(discountSlug) : undefined;
  }

  get checkoutPercentOff(): number {
    const scheme = this.discountScheme;
    if (scheme) {
      return scheme.percentOff || 0;
    } else {
      return 0;
    }
  }

  // will expose the checkout flow for full-access w/o auto-renew from account screen
  get showAccountPageCheckout() {
    if (this.autoRenew || this.userManager.purchaseFlowDisabled) {
      return false;
    }

    const daysOfFullAccess = this.remainingFullAccessInDays;
    // avoid potential trial period barfage if too far out
    if (daysOfFullAccess > 370) {
      return false;
    }

    // omit the checkout flow for the non-active product
    if (this.l2 !== this.root.l2) {
      return false;
    }

    return true;
  }

  get remainingFullAccessInDays() {
    const { today } = this.root;
    const fullAccessUntil = this.fullAccessUntil;
    if (fullAccessUntil) {
      const untilDayjs = Dayjs(fullAccessUntil);
      if (untilDayjs.isAfter(today)) {
        const days = untilDayjs.diff(today, 'day');
        log.debug(`remainingFullAccessInDays: ${days}`);
        return days;
      }
    }
    return 0;
  }

  // this flow is DEFERRED until after 10.x release
  get showSwitchPlanInterval(): boolean {
    return false;

    // const nodeState = this.nodeEntitlementL2(l2);
    // if (nodeState) {
    //   // return nodeState.planSlug.endsWith('-month');
    //   const status = this.membershipStatusL2(l2);
    //   return (
    //     status === 'full-auto-renew' && nodeState.billingInterval === 'month'
    //   );
    // } else {
    //   return false;
    // }
  }

  get fullAccessUntil(): string {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return nodeState.accessUntil;
    } else {
      if (this.l2 === 'es') {
        return this.accountData.fullAccessUntil;
      } else {
        return undefined;
      }
    }
  }

  get pausedUntil(): string {
    return this.nodeEntitlement?.pausedUntil;
  }

  get billingResumesOn(): string {
    return this.nodeEntitlement?.billingResumesOn;
  }

  get autoRenewFailed(): boolean {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return !!nodeState.failureMessage || !!nodeState.failureCode;
    } else {
      if (this.l2 === 'es') {
        return !!this.accountData.paymentData?.autoRenewFailed;
      } else {
        return false;
      }
    }
  }

  get paymentFailureMessage(): string {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return nodeState.failureMessage || nodeState.failureCode;
    } else {
      return undefined; // message not available for legacy subscriptions
    }
  }

  get licensedClassroomLabel(): string {
    // todo: this logic should be tightened up, but that will likely require rails-side changes
    // and this should be sufficiet for any real situations for the foreseeable future
    if (this.fullAccess) {
      return this.accountData.licensedClassroomLabel;
    } else {
      return undefined;
    }
  }

  get showPriceIncreaseBanner(): boolean {
    if (this.userData.userSettings.messageIsDismissed('price-increase-2024')) {
      return false;
    }

    return this.showPriceIncreaseInlineNotice;
  }

  get showPriceIncreaseInlineNotice(): boolean {
    if (!Boolean(appConfig.priceIncreaseDate)) {
      return false;
    }

    if (!this.showAccountPageCheckout) {
      return false;
    }

    if (this.l2 !== 'es') {
      return false;
    }

    const statusKey = this.membershipStatus;

    if (
      statusKey === 'trial' ||
      statusKey === 'full-no-renew' ||
      statusKey === 'expired' ||
      statusKey === 'suspended'
    ) {
      return true;
    }

    return false;
  }

  dismissPriceIncreaseAnnouncement(): void {
    this.userData.userSettings.dismissMessage('price-increase-2024');
  }

  // confirm if needed
  get hasNodeEntitlementActiveL2(): boolean {
    return !!this.nodeAccountData?.entitlements?.get(this.root.l2);
  }

  get hasNodeEntitlement(): boolean {
    return !!this.nodeEntitlement;
  }

  get nodeEntitlement(): EntitlementData {
    // const { l2 } = this.root;
    // const nodeState = this.nodeAccountData?.entitlements?.[l2];
    const nodeState = this.nodeAccountData?.entitlements?.get(this.l2);
    return nodeState;
  }

  // resolveLocalPlans(l2: LocaleCode): Plan[] {
  //   log.debug(`resolvePlans - l2: ${l2}`);
  //   const { currency, l1, dailySubscriptionEnabled } = this.root;
  //   const { pricingLevel } = this.accountData;

  //   const params = {
  //     l1,
  //     l2,
  //     currency,
  //     pricingLevel,
  //     includeDaily: dailySubscriptionEnabled,
  //   };
  //   const result = getPlans(params);
  //   return result;
  // }

  async initiateCheckout(plan: any /* Plan - todo: fix schema gen */) {
    const { slug: planSlug, l2, l1 } = plan;
    const successUrl = window.checkoutSuccessUrlFn();
    const cancelUrl = window.checkoutCancelUrlFn();

    log.info(`nodeInitiateCheckout - successUrl: ${successUrl}`);
    track('stripe__initiate_checkout', { plan: plan.slug });

    if (this.autoRenew) {
      // todo: confirm UX if we somehow hit this
      throw Error(
        'Unexpected attempt to resubscribe with an existing subscription'
      );
    }

    const trialDays = this.remainingFullAccessInDays;

    const result = await AppFactory.caliServerInvoker.initiateCheckout({
      uuid: this.accountData.userDataUuid,
      planSlug,
      l1,
      l2,
      trialDays,
      successUrl,
      cancelUrl,
    });

    // todo: include new account data in api result
    await this.userManager.refreshNodeAccountData();
    return result;
  }

  // obsolete in favor of CaliInvokerService version
  // async initiateCheckoutRails(plan: any /* Plan - todo: fix schema gen*/) {
  //   const successUrl = window.checkoutSuccessUrlFn();
  //   const failureUrl = window.checkoutFailureUrlFn();
  //   log.info(
  //     `initiateCheckout - successUrl: ${successUrl}, failureUrl: ${failureUrl}`
  //   );
  //   track('stripe__initiate_checkout', { plan: plan.slug });
  //   const result = await this.apiInvoker.post<{
  //     interstitialMessageKey: string;
  //     stripeSessionId: string;
  //     successMessageKey: string;
  //   }>(
  //     'users/initiate_checkout',
  //     {
  //       planSlug: plan.slug,
  //       successUrl,
  //       failureUrl,
  //     },
  //     { networkIndicator: true }
  //   );

  //   return result;
  // }

  async createStripePortalSession(returnUrl: string): Promise<{ url: string }> {
    let result: { url: string };
    if (this.hasNodeEntitlementActiveL2) {
      const returnUrl = appConfig.website.accountUrl;
      const uuid = this.accountData.userDataUuid;

      const { l1, l2 } = this.root;
      result = await AppFactory.caliServerInvoker.createPortalSession({
        uuid,
        l1,
        l2,
        returnUrl,
      });
    } else {
      result = await this.createRailsStripePortalSession(returnUrl);
    }
    return result;
  }

  async createRailsStripePortalSession(returnUrl: string) {
    log.info(`createStripePortalSession`);

    if (!returnUrl) {
      returnUrl = appConfig.website.accountUrl;
    }

    const result = await this.apiInvoker.post<{
      url: string;
    }>('users/create_stripe_portal_session', {
      return_url: returnUrl,
    });

    // console.log(result);
    return result;
  }

  async cancelAutoRenew({
    ignoreError = false,
  }: { ignoreError?: boolean } = {}) {
    let result: any;
    if (this.hasNodeEntitlement) {
      result = await this.cancelNodeSubscription();
    } else {
      result = await this.cancelRailsAutoRenew({ ignoreError });
    }
    return result;
  }

  async cancelNodeSubscription(): Promise<{ message: string }> {
    // const { l2 } = this.root;
    log.info(`cancelNodeSubscription - l2: ${this.l2}`);

    // todo: adapt to either old or new stripe implementation for existing subscription

    // todo: tracking
    // track('stripe__initiate_checkout', { plan: plan.slug });

    const result = await AppFactory.caliServerInvoker.cancelSubscription({
      uuid: this.accountData.userDataUuid,
      l2: this.l2,
    });
    const { status, accountData } = result;

    // await this.refreshNodeAccountData();
    if (status === 'success') {
      // this.nodeAccountData = accountData;
      applySnapshot(this.nodeAccountData, accountData || {});
      // if (this.fullAccess) {
      //   return { message: __('') };
      // } else {
      //   return { message: __('') };
      // }

      // null message will bypass the toast. shouldn't be needed now that we have the exit survey screen
      return { message: null };
    } else {
      // unexpected flow. any stripe or network error should result in an exception
      const message = `cancelNodeSubscription - unexpected result - userId: ${
        this.accountData.userId
      }, status: ${String(status)}`;
      log.error(`${message} - ${JSON.stringify(result)}`);
      bugsnagNotify(message);
    }
  }

  async cancelRailsAutoRenew({ ignoreError = false } = {}) {
    ignoreError = ignoreError || appConfig.stripe.ignoreCancelAutoRenewErrors;
    log.info(`cancelRailsAutoRenew - ignoreError: ${ignoreError}`);
    track('account__cancel_auto_renew');
    const result = await this.apiInvoker.post('users/cancel_auto_renew', {
      ignoreError,
    });
    const { /*message,*/ accountData } = result;

    await this.userManager.applyNewAccountData(accountData, {});
    return result;
  }

  // internal / testing
  async forceExpireAccess() {
    const result = await AppFactory.caliServerInvoker.forceExpireAccess({
      uuid: this.accountData.userDataUuid,
      l2: this.l2,
    });
    // could apply the result.accountData directly if refactored
    await this.userManager.refreshNodeAccountData();
    return result;
  }

  async switchPlanInterval() {
    const { l2 } = this.root;
    log.info(`switchPlanInterval - l2: ${l2}`);

    const result = await AppFactory.caliServerInvoker.switchPlanInterval({
      uuid: this.accountData.userDataUuid,
      l2,
    });

    await this.userManager.refreshNodeAccountData();
    return result;
  }

  async pauseSubscription({ months }: { months: number }) {
    const { storyManager } = this.root;
    const effectiveDate = storyManager.currentDate;
    log.info(`pauseSubscription - l2: ${this.l2}`);

    // const days = months * 30; // todo: use dayjs to accurately count out the months
    const result = await AppFactory.caliServerInvoker.pauseSubscription({
      uuid: this.accountData.userDataUuid,
      l2: this.l2,
      months,
      effectiveDate,
    });

    await this.userManager.refreshNodeAccountData();
    return result;
  }

  async resumeSubscription() {
    const { storyManager } = this.root;
    const effectiveDate = storyManager.currentDate;
    log.info(
      `resumeSubscription - l2: ${this.l2}, effectiveDate: ${effectiveDate}`
    );

    const result = await AppFactory.caliServerInvoker.resumeSubscription({
      uuid: this.accountData.userDataUuid,
      l2: this.l2,
      effectiveDate, // allows honoring the date override when testing
    });

    await this.userManager.refreshNodeAccountData();
    return result;
  }
}
