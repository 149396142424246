import * as React from 'react';
import { StoryTree } from './story-tree';
import { Glossary } from './glossary';
import { ChapterProvider } from './chapter-context';
import { Notes } from './notes';
import { ChapterModel } from '../cali/script-model';
import { ElementNode } from '@tikka/client/client-aliases';
import { MarkSimple } from '@naan/primitives/text';
import { globalCss, styled } from '@naan/stitches.config';
import __ from 'core/lib/localization';

const globalStyles = globalCss({
  '*': {
    margin: '0',
    padding: '0',
    border: 'none',
  },

  '*, *:before, *:after': {
    boxSizing: 'border-box',
  },

  a: {
    textDecoration: 'none',
  },
  'ul li': {
    listStyle: 'none',
  },
});

export const GlobalStyles: React.FC = () => {
  globalStyles();
  return null;
};

const Wrapper = styled('div', {
  borderTop: '1px solid var(--gray100)',
  padding: '24px 0 0',
  marginBottom: '80px',

  '&.position--1': {
    pageBreakBefore: 'always',
    '@media print': {
      marginTop: '0',
      borderTop: 'none',
      paddingTop: '0',
    },
  },

  '& .heading': {
    font: 'var(--medium-heading-font)',
    marginBottom: '56px',
  },

  '& .lines': {
    backgroundImage: 'repeating-linear-gradient(',

    backgroundSize: '42px 100%',

    marginBottom: '18px //40px;',

    '& .lines-content': {
      background: 'white',

      '@media print, screen and (min-width: 700px)': {
        width: 'calc(50% + 16px)',
        paddingRight: '16px',
      },
    },
  },
});

export const ScaffoldedChapter = ({
  chapterModel,
}: {
  chapterModel: ChapterModel;
}) => {
  const chapter = chapterModel.chapterData;
  const title = __('%{position}. %{title}', 'positionDotTitle', chapter);

  return (
    <>
      <GlobalStyles />
      <Wrapper
        id={`chapter-${chapterModel.key}`}
        className={`position--${chapter.position}`}
      >
        {/* // provider is likely no longer needed */}
        <ChapterProvider value={chapterModel}>
          <h2 className="heading">
            <MarkSimple source={title} />
          </h2>
          <Notes chapterModel={chapterModel} />
          <div className="lines">
            <div className="lines-content">
              <StoryTree
                node={
                  chapterModel as unknown as ElementNode /* satisfies 'children' interface */
                }
              />
            </div>
          </div>
          <Glossary
            notations={chapterModel.notations.values}
            id={chapterModel.key}
          />
        </ChapterProvider>
      </Wrapper>
    </>
  );
};
