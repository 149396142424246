import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog/chapter-catalog-data';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import { styled } from '@naan/stitches.config';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { ChapterSoundbiteCard } from './chapter-soundbite-card';
import { useChapterRowUiContext } from './chapter-row-ui-model';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import autoAnimate from '@formkit/auto-animate';
import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$gray-50',
  backgroundColor: '$$backgroundColor',
  padding: 8,
  borderRadius: 16,

  '&.open': {
    backgroundColor: '$colors$purple-100',
  },

  '& .heading': {
    alignItems: 'center',
    gap: 4,
    textStyle: 'small-caps',
    // background: 'yellow',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    borderRadius: 16,
    padding: '10px 8px 10px 12px',
    position: 'relative',
    '& .card-title': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      color: '$black-alpha-50',
    },
    '& .card-accessory': {
      // background: 'blue',
      position: 'absolute',
      right: 8,
    },
  },

  '& .progress-badge': {
    textStyle: 'small-text-bold',
    // background: '$colors$purple-500',
    display: 'flex',
    gap: 4,
    // padding: '4px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    // borderRadius: 16,
    color: '$purple-500',
  },

  '&.completed': {
    color: '$textSecondary',
  },

  '& .soundbite-cards': {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginTop: 8,
  },
});

export const ChapterSoundbites = observer(
  ({
    chapter,
    forceOpen = false,
  }: {
    chapter: ChapterCatalogData;
    forceOpen?: boolean;
  }) => {
    const model = useChapterRowUiContext();
    const open = forceOpen === true || model.currentStage === 'soundbites';
    const parent = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      parent.current &&
        autoAnimate(parent.current, {
          duration: 150,
          easing: 'ease-out',
        });
    }, [parent]);

    React.useEffect(() => {
      // const firstIncompleteSoundbiteIndex = chapter.chapterSoundbites.findIndex(
      //   (soundbite: Soundbite) => {
      //     return !soundbite.completed;
      //   }
      // );
      const index = chapter.firstIncompleteSoundbiteIndex;
      model.setCurrentSoundbiteIndex(index);
    }, [chapter.firstIncompleteSoundbiteIndex, model]);

    if (!chapter.hasSoundbites) {
      return null;
    }

    return (
      <Wrapper
        ref={parent}
        className={classNames({ open })}
        onClick={() => {
          model.setCurrentStage('soundbites');
        }}
      >
        <div className={classNames(['heading', { open }])}>
          <div className="card-title">
            {/* <SoundbiteIconExtraSmall /> */}
            {/* Soundbites */}
            {__('Listening warm-up', 'listeningWarmUp')}
          </div>
          {open ? null : (
            <div className="card-accessory">
              {chapter.allChapterSoundbitesCompleted ? (
                <CheckmarkCircleIcon color="var(--colors-green-500)" />
              ) : (
                <div className="progress-badge">
                  <SoundbiteIcon />
                  {chapter.soundbiteDisplayProgressBadge}
                </div>
              )}
            </div>
          )}
        </div>
        {open ? (
          <div className="soundbite-cards">
            {chapter.chapterSoundbites.map(
              (soundbite: Soundbite, index: number) => {
                return (
                  <ChapterSoundbiteCard
                    soundbite={soundbite}
                    index={index}
                    key={index}
                  />
                );
              }
            )}
          </div>
        ) : null}
      </Wrapper>
    );
  }
);
