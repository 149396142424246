import React from 'react';
import { observer } from 'mobx-react';
import { createLogger } from 'app/logger';
import { NotationsListItem } from './notations-list-item';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import { StoryProgress } from '@core/models/user-manager/story-progress';
import __ from '@core/lib/localization';

const log = createLogger('notations-panel');

const ListWrapper = styled('div', {
  paddingLeft: '$space$4',
  paddingRight: '$space$4',
  paddingBottom: '$space$1',

  // '& > * + *': {
  //   borderTop: '1px solid $colors$gray-100',
  // },
  '& > * ': {
    borderTop: '1px solid $colors$gray-100',
  },
});

const ZeroStateWrapper = styled('div', {
  height: '100%',
  display: 'grid',
  placeItems: 'center',
  textAlign: 'center',
  textStyle: 'body-bold',
  color: '$colors$gray-300',
  flex: 1,
});

const ZeroState = observer(() => {
  return (
    <ZeroStateWrapper>
      {__('No vocabulary for this sentence', 'noVocabularyForThisSentence')}
    </ZeroStateWrapper>
  );
});

export const NotationsList = observer(
  ({
    notations,
    progress,
  }: {
    notations: ClientNotation[];
    progress: StoryProgress;
  }) => {
    const addVocab = React.useCallback(
      (slug: string) => {
        log.info(`addVocab(${slug})`);
        progress.addVocab(slug, { persist: true });
      },
      [progress]
    );

    const removeVocab = React.useCallback(
      (slug: string) => {
        log.info(`removeVocab(${slug})`);
        progress.removeVocab(slug, { persist: true });
      },
      [progress]
    );

    const vocabExists = React.useCallback(
      (slug: string): boolean => {
        return progress.vocabExists(slug);
      },
      [progress]
    );

    if (notations.length === 0) {
      return <ZeroState />;
    }

    return (
      <ListWrapper>
        {notations.map((notation: ClientNotation, index: number) => {
          // // @joseph: It doesn't look like the designs consider any other kind of notation.
          // // am I missing something?
          // if (notation.subKind !== 'VOCAB') {
          //   return null;
          // }

          return (
            <NotationsListItem
              key={notation.id}
              notation={notation}
              addVocab={addVocab}
              removeVocab={removeVocab}
              vocabExists={vocabExists}
              withOnboardingTip={index === 0}
            />
          );
        })}
      </ListWrapper>
    );
  }
);
