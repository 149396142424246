// codemod-ignore-file
import React from 'react';
import { AppFactory } from '@app/app-factory';
import { styled } from '@naan/stitches.config';
import __ from 'core/lib/localization';
import { marketingSite } from 'components/nav/path-helpers';
import { ExternalLink } from '@naan/primitives/text/external-link';

const Wrapper = styled('div', {
  font: 'var(--body-font)',
  textAlign: 'center',
  color: 'var(--red)',
  marginTop: '56px',
  padding: '8px 12px',
  border: '1px solid var(--pink)',
  breakInside: 'avoid-page',

  '& .learn-more a': {
    color: 'var(--text-secondary)',
  },
});

export const StoryGuideLicense = ({ learnMore = false }) => {
  const { userManager } = AppFactory.root;
  const { email } = userManager?.accountData ?? {};
  return (
    <Wrapper>
      {__(
        'This guide is licensed to %{email} for use with the Jiveworld app in a classroom setting',
        'thisGuideIsLicensedTo',
        { email }
      )}
      {learnMore ? (
        <div className="learn-more">
          Learn about Jiveworld for Schools at{' '}
          <ExternalLink href={`${marketingSite()}/schools`}>
            jiveworld.com/schools
          </ExternalLink>
        </div>
      ) : null}
    </Wrapper>
  );
};
