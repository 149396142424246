import { LocaleCode } from '@utils/util-types';

export type PricingLevel = 'retail' | 'concession' | 'student' | 'classroom';
// export type PricingGeneration = | '2021' | '2024' | 'dev' // | 'foo' // | 'foo2' | 'devA2021' | 'devA2024';
export type PricingGeneration = string;
export type BillingInterval = 'month' | 'year' | 'day';
export type Currency = 'usd' | 'brl';
export type PlanSlug =
  `${LocaleCode}-${PricingLevel}-${PricingGeneration}-${Currency}-${BillingInterval}-${LocaleCode}`;

export type StripeProductId = `${LocaleCode}-${PricingLevel}-${LocaleCode}`;

export type ActivePricingGenerations = {
  // eslint-disable-next-line no-unused-vars
  [l2 in LocaleCode]?: PricingGeneration;
};

export const PricingLevels: PricingLevel[] = [
  'retail',
  'concession',
  'student',
  'classroom',
]; // as const;
export const L2s: LocaleCode[] = ['es', 'en'];
export const L1s: LocaleCode[] = ['en', 'pt'];

export const allCurrencies = (): Currency[] => ['usd', 'brl'];

export type StripeMetadata = {
  planSlug: string;
  l2: LocaleCode;
  pricingLevel: PricingLevel;
  pricingGeneration: PricingGeneration;
  billingInterval: BillingInterval;
  currency: Currency;

  discountSlug?: string;
  percentOff?: number;

  userId?: string; // only on checkout
  stripeSubscriptionId?: string; // convenient to merge in to subscriptionStarted
};

export interface NodeAccountDataType {
  _docId: string; // uuid
  userId: string; // server qualified
  email: string; // beware, this is denormalized from the userData and not currently sync'd when changed

  stripeCustomerId?: string;
  currency?: Currency;

  createdAt?: string; // iso8601 date // todo: migrate from rails

  entitlements: {
    // eslint-disable-next-line no-unused-vars
    [l2 in LocaleCode]?: EntitlementDataType;
  };

  // reflects offered discount for potential checkout
  discountSlug?: string;
  discountExpiry?: string; // iso8601 date for which offered discount pricing expires

  // chargebeeCustomerId?: string;
  // _timestamp: string; // iso8601, beware, not existing in data prior to new backup logic
  // the rest of the user data props can remain opaque
  [index: string]: unknown;
}

// export interface EntitlementState {
//   l2: LocaleCode;
//   accessUntil: string; // iso8601 date
//   accessSince: string; // iso8601 date
//   subscriptionState?: SubscriptionState;
// }

// consider renaming this flat/fat structure to EntitlementState
export interface EntitlementDataType {
  l2: LocaleCode;

  _fullAccess?: boolean; // denormalized evaluation of the full access status, used to sync state to rails/campaign monitor
  accessUntil?: string; // iso8601 date
  accessSince?: string; // iso8601 date

  planSlug?: PlanSlug;
  stripeSubscriptionId?: string;

  failureCode?: string;
  failureMessage?: string;

  pausedUntil?: string;
  pausedSince?: string;
  billingResumesOn?: string;
  // set when we detect a surpluss of access when receiving a payment
  // checked after checkout complete so auto-renew can be appropriately deferred
  pendingDeferBilling?: boolean;

  billingInterval?: BillingInterval;
  currency?: Currency;
  price?: number;
  priceLevel?: PricingLevel;
  priceGeneration?: PricingGeneration;

  // reflects applied discount during checkout
  discountSlug?: string;
  percentOff?: number;

  renewalCount?: number; // todo update this

  // // should this be scoped to L2 or global?
  // discountPercentage?: number;
  // discountExpiry?: string; // iso8601 date for which offered discount expires
  // discountLabel?: string; // pricing card string

  railsMigrated?: boolean;
}

export interface DiscountState {
  percentageOff: number;
  expiry: string; // iso8601 date
  label: string; // pricing card branding
  attribution: string; // ? aff code to attribute/reward when checkout performed
}

// corresponds to stripe coupon instance
export interface DiscountScheme {
  slug: string; // used as stripe coupon id
  percentOff: number;
  stripeLabel: string; // shown in stripe checkout and receipts
}

//
// api param typing
//

export interface ResolvePlansParams {
  // uuid: string;
  l1: LocaleCode;
  l2: LocaleCode;
  currency: Currency;
  pricingLevel: PricingLevel;
  includeDaily?: boolean;
}

export interface PauseSubscriptionParams {
  uuid: string;
  l2: LocaleCode;
  months: number;
  effectiveDate?: string; // debug param to override effective current date
}

export interface InitiateStripeCheckoutParams {
  uuid: string;
  planSlug: PlanSlug;
  l1: LocaleCode;
  l2: LocaleCode;
  trialDays?: number; // used to resubscribe when there's existing non-auto-renew access remaining
  successUrl: string;
  cancelUrl: string;
}

export interface CancelSubscriptionParams {
  uuid: string;
  l2: LocaleCode;
}

export interface SwitchPlanIntervalParams {
  uuid: string;
  l2: LocaleCode;
  // todo: should probably also explicitly pass the new interval
}

export interface ResumeSubscriptionParams {
  uuid: string;
  l2: LocaleCode;
  effectiveDate?: string; // debug param to override effective current date
}

export interface CreateStripePortalSessionParams {
  uuid: string;
  l1: LocaleCode;
  l2: LocaleCode;
  returnUrl: string;
}

export interface ExtendAccessParams {
  userId: string;
  l2: LocaleCode;
  interval?: BillingInterval;
  intervalCount?: number;
  assignResumeBilling: boolean;
  incrementRenewalCount?: boolean;
}

export interface ResumeBillingParams {
  userId: string;
  l2: LocaleCode;
}

export interface GrantFreeAccessParams {
  userId: string;
  l2: LocaleCode;
  interval?: BillingInterval;
  intervalCount?: number;
}

export interface ForceExpireAccessParams {
  uuid: string;
  l2: LocaleCode;
}

export interface RailsGrantAccessParams {
  uuid: string;
  userId: string; // redundancy in case missing from userData
  email: string;
  l2: LocaleCode;
  days: number;
  // capture  more context for logs
}

export interface RailsGrantDiscountParams {
  uuid: string;
  userId: string; // redundancy in case missing from userData
  email: string;
  discountSlug: string;
}

export interface RailsMigrateAccessParams {
  uuid: string;
  userId: string; // redundancy in case missing from userData
  email: string;
  accessUntil: string;
  accessSince: string;
}

//
// MailerService
//

export interface ExportedVocabParams {
  locale: LocaleCode;
  l2: LocaleCode;
  email: string;
  name: string;
  storySlug: string;
  storyTitle: string;
  vocabData: string[][];
}
