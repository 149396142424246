import * as React from 'react';
import { VStack } from 'naan/primitives/stack';

import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { MediumHeading } from '@naan/primitives/text';
import { pagePath } from 'components/nav/path-helpers';
import { ActionLink } from 'common/ui/action-link';
import { runSimpleConfirmation } from 'components/ui/simple-dialogs';
import { createLogger } from '@common/log';
const log = createLogger('dev-links');

export const path = 'links';
export const label = 'Useful links';

export const Component = observer(() => {
  return (
    <VStack css={{ my: '$5' }}>
      <MediumHeading>Useful links</MediumHeading>
      <VStack gap="medium">
        <Link to="/app/learn/es/stories?filter_type=sb&filter_value=Stories%20with%20Soundbites">
          Stories with Soundbites
        </Link>
        <Link to="/app/learn/es/stories?filter_type=sb&filter_value=Stories%20with%20completed%20Soundbites">
          Stories wit completed Soundbites
        </Link>
        <Link to="/app/learn/es/stories?filter_type=sb&filter_value=Stories%20with%20unstarted%20Soundbites">
          Stories with unplayed Soundbites
        </Link>
        <Link to="/showcase">Go to Components Showcase &rarr;</Link>
        <ExternalLink href="https://radioambulante.org">
          Go to radio ambulante &rarr;
        </ExternalLink>
        <Link to={pagePath('exit-survey')}>Exit Survey &rarr;</Link>
        <ActionLink
          onPress={async () => {
            const confirmed = await runSimpleConfirmation('Are you sure?');
            log.info('confirmed', confirmed);
          }}
          label={'Simple Confirm'}
        ></ActionLink>
      </VStack>
    </VStack>
  );
});
