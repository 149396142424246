import React from 'react';
import { isEmpty } from 'lodash';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { Button } from '@naan/primitives/button';
// import { NoLink } from '@naan/primitives/text/no-link';

// copied from masala-console.
// @armando, better home for this?

export const prevent =
  (action: () => void) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    action();
  };

// export const ActionLink = ({
//   onPress = () => {},
//   onClick,
//   className = '',
//   title = '',
//   label,
//   children,
// }: {
//   onPress?: () => any;
//   onClick?: () => any; // alias to align with Button props
//   className?: string;
//   title?: string;
//   label?: string; // alias to align with Button props
//   children?: React.ReactNode;
// }) => {
//   return (
//     // eslint-disable-next-line jsx-a11y/anchor-is-valid
//     <NoLink className={className} onClick={prevent(onClick ?? onPress)}>
//       {/* fontSize={1} mr={2}> */}
//       {label ?? title}
//       {children}
//     </NoLink>
//   );
// };

export const ActionLink: React.FC<{ onPress: () => void; label?: string }> = ({
  children,
  onPress,
  label,
}) => {
  const text = label || (children as string).replace(/^\[(.+)\]$/, '$1');
  return (
    <Button
      size="small"
      presentation="grayLight"
      label={text}
      onClick={onPress}
      radius="squared"
    />
  );
};

export const A = ({
  href,
  className = '',
  children = null,
}: {
  href: string;
  className?: string;
  children?: React.ReactNode;
}) => {
  if (isEmpty(href)) {
    return null; //children;
  }

  const body = isEmpty(children) ? href : children;
  return (
    <ExternalLink href={href} className={className}>
      {body}
    </ExternalLink>
  );
};
