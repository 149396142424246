import { Story } from '@core/models/story-manager/story';
import { StoryInterstitial } from './story-interstitial';
import { AppFactory } from '@app/app-factory';
import { StudyInterstitial } from './study-interstitial';
import { ListenInterstitial } from './listen-interstitial';
import { BeforeAuthInterstitial } from './before-auth-interstitial';
import { OnboardingService } from '@app/onboarding/onboarding-service';

export const getOnboardingInterstitialComponent = ({
  story,
}: {
  story: Story;
}) => {
  const { userManager } = AppFactory.root;

  if (
    OnboardingService.instance.isDismissed('onboardingInitiated') &&
    !OnboardingService.instance.isDismissed('onboardingComplete')
  ) {
    if (story.firstChapter.completedSoundbitesCount === 0) {
      // return <SoundbiteInterstitial story={story} />;
      return StoryInterstitial;
    }

    if (
      !userManager.loggedInAndReady /* ||
        !onboardingService.isDismissed('storyInterstitial') */
    ) {
      return BeforeAuthInterstitial;
      // return <StoryInterstitial story={story} />;
    }
    if (story.progress.furthestPoint.iteration === 1) {
      return StudyInterstitial;
    }
    if (story.progress.furthestPoint.iteration === 2) {
      return ListenInterstitial;
    }
  }

  // return <OnboardingCompleteInterstitial story={story} />;
  return undefined;
};
