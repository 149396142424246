import * as React from 'react';
import { FilterButton } from './filter-button';
import { Menu, MenuItem } from 'naan/primitives/menus';

import { styled } from 'naan/stitches.config';
import { useFiltering } from '@common/filtering/use-filtering';
import { SoundbiteFilterValues } from '@core/models/story-manager/story';

const Carrot = styled('span', {
  marginTop: '-2px',
  display: 'block',
  px: '$1',
  transitionProperty: 'transform',
  transitionDuration: '400ms',
  variants: {
    selected: {
      true: {
        color: '$teal-500',
        transitionDuration: '200ms',
        transform: 'rotate(-180deg) ',
      },
    },
  },
});

const carrot = (
  <svg
    width={8}
    height={7}
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.2002 1.40039L4.0002 4.60039L0.800196 1.40039"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

export const SoundbitesDropdownButton = ({
  onMenuOpen,
  inClassroomMode = false,
}: {
  onMenuOpen: () => void;
  inClassroomMode?: boolean;
}) => {
  const { addFilter } = useFiltering();

  const { allFn, withUnstartedFn, withCompletedFn } = SoundbiteFilterValues;
  const items = inClassroomMode
    ? [allFn()]
    : [allFn(), withUnstartedFn(), withCompletedFn()];

  return (
    <Menu
      offset={-12}
      onOpenChange={open => {
        if (open) {
          onMenuOpen();
        }
      }}
      trigger={
        <FilterButton
          selected={false}
          label={'Soundbites'}
          rightIcon={<Carrot selected={false}>{carrot}</Carrot>}
        />
      }
    >
      {items.map(label => (
        <MenuItem
          action={() => {
            addFilter('sb', label);
          }}
          label={label}
        />
      ))}
    </Menu>
  );
};
