import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { ChapterCatalogData } from '@core/models/catalog';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { IconButton } from '@naan/primitives/button';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { ellipsis } from '@utils/string-utils';
import { observer } from 'mobx-react';
import { useSwitch2 } from '@naan/hooks/use-switch-2';

import __ from '@core/lib/localization';

const ResetChapterDialog = observer(
  ({ onReset, onCancel }: { onCancel: () => void; onReset: () => void }) => {
    return (
      <Dialog.Container open onDismiss={onCancel}>
        <Dialog.Heading>{__('Reset chapter', 'resetChapter')}</Dialog.Heading>
        <Dialog.Body>
          {__(
            "This will reset your listening progress, but won't affect your vocabulary or completed Soundbites.",
            'thisWillResetYourListeningProgress'
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            label="Cancel"
            onClick={onCancel}
            presentation={'grayTransparent'}
          />
          <Dialog.Button
            label={__('Reset', 'reset')}
            onClick={onReset}
            presentation={'teal'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

export const ChapterOverflowMenu = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const dialogSwitch = useSwitch2(false);
    const isResetable =
      chapter.currentMillis > 0 ||
      chapter.currentPoint.studyComplete ||
      chapter.currentPoint.listenComplete;
    // const { fullAccess } = AppFactory.root.userManager;
    return (
      <>
        <Menu
          trigger={
            <IconButton
              presentation="grayTransparent"
              icon={<OverflowIcon />}
              testId={`chapter-list-overflow-${chapter.sortingRef}`}
            />
          }
        >
          {chapter.isCompleted ? null : (
            <MenuItem
              label={__('Mark chapter complete', 'markChapterComplete')}
              action={() => {
                chapter.markComplete();
                // maybePresentEndOfStoryCtaDialog();
              }}
            />
          )}
          {isResetable ? (
            <MenuItem
              label={__('Reset chapter', 'resetChapter') + ellipsis}
              action={() => {
                window.setTimeout(() => {
                  dialogSwitch.on();
                }, 100);
              }}
            />
          ) : null}
        </Menu>
        {dialogSwitch.value ? (
          <ResetChapterDialog
            onReset={() => {
              chapter.restartChapter();
              dialogSwitch.off();
            }}
            onCancel={() => {
              dialogSwitch.off();
            }}
          />
        ) : null}
      </>
    );
  }
);
