import React from 'react';
import { AppFactory } from '@app/app-factory';
import { observer } from 'mobx-react';

import { Responsive } from '@naan/primitives/responsive';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
// import { subBrand } from '@core/lib/app-util';
import { styled } from '@stitches/react';
import { JwLogo } from 'components/branding/jw-logo';
import { DashboardAuthButtons } from './dashboard-auth-buttons';

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 12,
  marginBottom: 40,
});

export const UnauthenticatedDashboardNav = observer(() => {
  const { userManager, storyManager } = AppFactory.root;
  const { authenticated } = userManager;

  if (authenticated) {
    return null;
  }

  return (
    <CenterColumnLayout>
      <Wrapper>
        <Responsive
          renderDefault={() => (
            <JwLogo subBrand={storyManager.subBrand('short')} l2Switch />
          )}
          renderSmallAndUp={() => (
            <JwLogo subBrand={storyManager.subBrand()} l2Switch />
          )}
          renderLargeAndUp={() => ''}
        />
        <DashboardAuthButtons />
      </Wrapper>
    </CenterColumnLayout>
  );

  // return (
  //   <Responsive
  //     renderDefault={() => <TrialDashboardNavSmall />}
  //     renderMediumAndUp={() => <TrialDashboardNavMedium />}
  //     renderLargeAndUp={() => <TrialDashboardNavLarge />}
  //   />
  // );
});
