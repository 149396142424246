import * as React from 'react';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { SoundbitesModal } from '.';
import { Button } from '@naan/primitives/button';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import { useCurrentStory } from '../current-story-context';
import { Responsive } from '@naan/primitives/responsive';
import { useLocation } from 'react-router-dom';
import { elementIds } from 'components/dom-utils/element-ids';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { SoundbitesModalTip } from 'components/ui/onboarding/soundbites-modal-tip';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

const ToolipPositioner = styled('span', {
  zIndex: 1,
  marginRight: -8,
  '@large': {
    marginRight: 0,
  },
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: 12,
  },
});

export const SoundbitesModalButton = () => {
  const story = useCurrentStory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const soundbitesParam = queryParams.get('soundbites');
  const showingModal = useSwitch2(!!soundbitesParam);

  const dismissed = OnboardingService.instance.isDismissed('soundbitesModal');
  const showPopover = OnboardingService.instance.showSoundbitesModalTip;

  if (!story.hasVisibleSoundbites) {
    return null;
  }

  return (
    <>
      <OnboardingPopover
        dismissed={dismissed}
        showPopover={showPopover}
        renderAnchor={({ ref, getProps }) => (
          <ToolipPositioner
            ref={ref}
            {...getProps()}
            className={showPopover ? 'highlight' : null}
          >
            <Button
              leftIcon={<SoundbiteIcon />}
              onClick={showingModal.toggle}
              testId={'soundbites-button'}
              id={elementIds.SOUNDBITES_MODAL_BUTTON}
              label={
                (
                  <Responsive
                    renderDefault={() => story.soundbiteDisplayProgress()}
                    renderLargeAndUp={() =>
                      __('%{count} Soundbites', 'nSoundbites', {
                        count: story.soundbiteDisplayProgress(),
                      })
                    }
                  />
                ) as any
              }
              presentation="whiteTransparent"
            />
            {/* <DesktopPlaybackRateControl /> */}
          </ToolipPositioner>
        )}
        renderBody={() => {
          // @armando. this seems to only render from the study player, not the soundbite player
          return <SoundbitesModalTip />;
        }}
        onResolvePopover={
          OnboardingService.instance.onSoundbitesModalTipDismissed
        }
      />

      {showingModal.value ? (
        <SoundbitesModal onDismiss={showingModal.toggle} />
      ) : null}
    </>
  );
};
