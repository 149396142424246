import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import {
  ChapterStage,
  ChapterStudyMode,
} from '@core/models/user-manager/story-progress';
import { ChapterCatalogData } from '@core/models/catalog';

// todo: rename this to ChapterRowUiModel
export class ChapterRowUiModel {
  // public currentChapterPosition: number = 0;
  public currentSoundbiteIndex: number = 0;
  public currentStage: ChapterStage = 'soundbites';
  public currentStudyMode: ChapterStudyMode = 'study';

  // constructor(story: Story) {
  //   makeObservable(this, {
  //     // currentChapterPosition: observable,
  //     currentSoundbiteIndex: observable,
  //     currentStage: observable,
  //     currentStudyMode: observable,
  //   });

  //   this.initWithStory(story);
  // }

  // public initWithStory(story: Story) {
  //   const { progress } = story;
  //   // this.setChapterPosition(progress.currentChapterPosition);
  //   this.setCurrentSoundbiteIndex(progress.firstIncompleteSoundbiteIndex);
  //   this.setCurrentStage(progress.currentStage);
  //   this.setCurrentStudyMode(progress.currentStudyMode);
  // }

  constructor(chapter: ChapterCatalogData) {
    makeObservable(this, {
      // currentChapterPosition: observable,
      currentSoundbiteIndex: observable,
      currentStage: observable,
      currentStudyMode: observable,
    });

    this.initWithChapter(chapter);
  }

  public initWithChapter(chapter: ChapterCatalogData) {
    const { progress } = chapter.story;
    this.setCurrentSoundbiteIndex(chapter.firstIncompleteSoundbiteIndex);
    if (progress.currentChapterPosition === chapter.position) {
      this.setCurrentStage(progress.currentStage);
      this.setCurrentStudyMode(progress.currentStudyMode);
    } else {
      this.setCurrentStudyMode('listen');
      if (chapter.hasSoundbites) {
        this.setCurrentStage('soundbites');
      } else {
        this.setCurrentStage('study');
      }
    }
  }

  public setCurrentSoundbiteIndex(index: number) {
    this.currentSoundbiteIndex = index;
  }

  public setCurrentStage(stage: ChapterStage) {
    this.currentStage = stage;
  }

  public setCurrentStudyMode(mode: ChapterStudyMode) {
    this.currentStudyMode = mode;
  }

  // public setChapterPosition(position: number) {
  //   // if the current chapter is clicked, close it
  //   if (this.currentChapterPosition === position) {
  //     this.currentChapterPosition = -1;
  //     return;
  //   }
  //   this.currentChapterPosition = position;
  // }
}

const ChapterRowUiContext = React.createContext<ChapterRowUiModel | null>(null);

export const useChapterRowUiContext = () => {
  const context = React.useContext(ChapterRowUiContext);
  if (!context) {
    throw new Error(
      'useChapterUiContext must be used within a ChapterUiProvider'
    );
  }
  return context;
};

export const ChapterRowUiProvider: React.FC<{ chapter: ChapterCatalogData }> =
  ({ children, chapter }) => {
    const model = new ChapterRowUiModel(chapter);
    // (window as any).uiModel = uiModel;
    return (
      <ChapterRowUiContext.Provider value={model}>
        {children}
      </ChapterRowUiContext.Provider>
    );
  };
