// Incomplete list of element Id's we use to target DOM elements directly for scrolling, measuring and other effects.

export const elementIds = {
  STORY_ASSIGNMENT_BAR: 'story-assignment-bar',
  STORY_HEADER_CONTAINER: 'story-header-container',
  STORY_TAB_BAR: 'story-tab-bar',
  QUESTION_AREA: 'question-area',
  AFTER_QUESTION_BEACON: 'after-question-beacon',
  END_AREA: 'end-area',
  SOUNDBITE_VOCABULARY_BUTTON: 'soundbite-vocabulary-button',
  SOUNDBITES_MODAL_BUTTON: 'soundbites-modal-button',
  HELP_DIALOG_BUTTON: 'help-dialog-button',
  CURRENT_CHAPTER_ID: 'current-chapter',
  CHAPTER_LIST_ID: 'chapter-list',
  VOCAB_BUTTON: 'vocab-button',
  SCRIPT_HEADER_TITLE: 'script-header-title',
  ANSWER_CARD: 'answer-card',
  INLINE_TRANSLATION_TIP: 'inline-translation-tip',
} as const;

export type ElementId = typeof elementIds[keyof typeof elementIds];

export const STORY_DETAIL_TOP_ELEMENTS: ElementId[] = [
  elementIds.STORY_HEADER_CONTAINER,
  elementIds.STORY_ASSIGNMENT_BAR,
];
