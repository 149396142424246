import { isEmpty } from 'lodash';
import { createLogger } from 'app/logger';
import {
  UserData,
  UserDataSnapshot,
} from '@core/models/user-manager/user-data';
import { CatalogMeta } from './catalog-meta-sync';
import { GlobalSettings } from '@core/models/global-settings';
import { MixpanelProperties } from '@common/analytics/analytics-utils';
import { deepNoEmptyObject } from '@utils/deep-merge-diff';
import {
  CancelSubscriptionParams,
  CreateStripePortalSessionParams,
  ExportedVocabParams,
  ForceExpireAccessParams,
  InitiateStripeCheckoutParams,
  NodeAccountDataType,
  PauseSubscriptionParams,
  ResolvePlansParams,
  ResumeSubscriptionParams,
  SwitchPlanIntervalParams,
} from '@cas-shared/cas-types';
import { GenericError } from '@core/lib/errors';
import __ from '@core/lib/localization';
import { bugsnagNotify } from '@app/notification-service';
import { PlanData } from '@cas-shared/plan';
// import * as Sentry from '@sentry/react';

const log = createLogger('cali-server-invoker');

// parallel to cali-app-server reporting-service.ts
// derived from browser sdk typing
//   https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/mixpanel/index.d.ts

export interface TrackBatchParams {
  // clientKey: string; // todo: app api security
  agentPlatform?: string;
  agentVersion?: string;
  events: MixpanelEvent[];
}

export interface MixpanelEvent {
  event: string;
  properties: MixpanelProperties;
}

/**
 * Helper object to provide an abstraction to access the api
 * with baked-in loading flag.
 */
export class CaliServerInvoker {
  apiEnv: string;

  constructor({ apiEnv }: { apiEnv: string }) {
    this.apiEnv = apiEnv;
    // this.authToken = authToken;
  }

  async fetchNodeAccountData(uuid: string): Promise<NodeAccountDataType> {
    const apiUrl = `${this.baseUrl}/account/${uuid}`;
    log.info(`invoking: GET ${apiUrl}`);

    // todo: manage network indicator
    // { networkIndicator: true }

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      // body: JSON.stringify(params),
    });
    const json = (await response.json()) as {
      result: NodeAccountDataType;
      error: any;
    };
    // const text = await response.text();
    // const json = JSON.parse(text) as {
    //   result: NodeAccountData;
    //   error: any;
    // };
    log.info(`fetchData resp: ${JSON.stringify(json)}`);
    // if (!json.result) {
    //   throw Error(JSON.stringify(json));
    // }
    return json.result;
  }

  async resolvePlans(params: ResolvePlansParams): Promise<PlanData[]> {
    const apiUrl = `${this.baseUrl}/account/resolvePlans`;
    log.info(`invoking: POST ${apiUrl}`);

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`resp: ${JSON.stringify(json)}`);
    if (isEmpty(json.result)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async initiateCheckout(
    params: InitiateStripeCheckoutParams /*{
    uuid: string;
    planSlug: string;
    successUrl: string;
    failureUrl: string;
  }*/
  ) {
    const apiUrl = `${this.baseUrl}/account/initiateStripeCheckout`;
    log.info(`invoking: POST ${apiUrl}`);

    // todo: manage network indicator
    // { networkIndicator: true }

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = (await response.json()) as {
      result: {
        interstitialMessageKey?: string; // hopefully no longer relevant
        stripeSessionId: string;
        successMessageKey: string;
      };
      error: any;
    };
    log.info(`initiateCheckout resp: ${JSON.stringify(json)}`);
    // if (!isEmpty(json.error)) {
    if (isEmpty(json.result)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async cancelSubscription(
    params: CancelSubscriptionParams /*{
    uuid: string;
    l2: LocaleCode;
  }*/
  ): Promise<{
    status: string;
    accountData: NodeAccountDataType;
    error?: any;
  }> {
    const apiUrl = `${this.baseUrl}/account/cancelSubscription`;
    log.info(`invoking: POST ${apiUrl}`);

    // todo: manage network indicator
    // { networkIndicator: true }

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`resp: ${JSON.stringify(json)}`);
    if (isEmpty(json.result)) {
      // throw Error(JSON.stringify(json));

      const sourceErrorMessage = json.error?.message;
      const message = `cancelSubscription - unexpected result: ${sourceErrorMessage}`;
      log.error(`${message} - ${JSON.stringify(json)}`);
      bugsnagNotify(message);
      // Sentry.captureException(message, { extra: { json } }); // todo: confirm if preferable

      throw new GenericError(
        `Error canceling subscription - ${sourceErrorMessage}`,
        {
          // todo: should perhaps be a modal, not a toast. would need a new error decorator/handler to drive that
          userMessage: __(
            'There was an error canceling your subscription. Customer service has been alerted.',
            'errorCancelingSubscription'
          ),
        }
      );
    }
    return json.result;
  }

  async switchPlanInterval(
    params: SwitchPlanIntervalParams /*{
    uuid: string;
    l2: LocaleCode;
  }*/
  ): Promise<{
    status: string;
    accountData: NodeAccountDataType;
    error?: any;
  }> {
    const apiUrl = `${this.baseUrl}/account/switchPlanInterval`;
    log.info(`invoking: POST ${apiUrl}`);

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`resp: ${JSON.stringify(json)}`);
    if (isEmpty(json.result)) {
      const sourceErrorMessage = json.error?.message;
      const message = `switchPlanInterval - unexpected result: ${sourceErrorMessage}`;
      log.error(`${message} - ${JSON.stringify(json)}`);
      bugsnagNotify(message);

      throw new GenericError(
        `Error calling switchPlanInterval - ${sourceErrorMessage}`,
        {
          userMessage: __(
            'There was an error updating your subscription. Customer service has been alerted.',
            'errorUpdatingSubscription'
          ),
        }
      );
    }
    return json.result;
  }

  async pauseSubscription(
    params: PauseSubscriptionParams /*{
    uuid: string;
    l2: LocaleCode;
    months: number;
    effectiveDate?: string;
  }*/
  ) {
    const apiUrl = `${this.baseUrl}/account/pauseSubscription`;
    log.info(`invoking: POST ${apiUrl}`);

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`resp: ${JSON.stringify(json)}`);
    if (isEmpty(json.result)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async resumeSubscription(
    params: ResumeSubscriptionParams /*{
    uuid: string;
    l2: LocaleCode;
    effectiveDate?: string;
  }*/
  ) {
    const apiUrl = `${this.baseUrl}/account/resumeSubscription`;
    log.info(`invoking: POST ${apiUrl}`);

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`resp: ${JSON.stringify(json)}`);
    if (isEmpty(json.result)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async createPortalSession(
    params: CreateStripePortalSessionParams /*{
    uuid: string;
    returnUrl: string;
  }*/
  ): Promise<{ url: string }> {
    const apiUrl = `${this.baseUrl}/account/createPortalSession`;
    log.info(`invoking: POST ${apiUrl}`);

    // todo: manage network indicator
    // { networkIndicator: true }

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`createPortalSession resp: ${JSON.stringify(json)}`);
    if (isEmpty(json.result)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async forceExpireAccess(
    params: ForceExpireAccessParams
  ): Promise<{ url: string }> {
    const apiUrl = `${this.baseUrl}/account/forceExpireAccess`;
    log.info(`invoking: POST ${apiUrl}`);

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`forceExpireAccess resp: ${JSON.stringify(json)}`);
    if (isEmpty(json.result)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async exportVocabPoc() {
    const locale = 'pt';
    const email = 'joseph@jiveworld.com';
    const name = 'JE';
    const storyTitle = 'El coyote';
    const storySlug = 'el-coyote';
    const vocabData = [
      ['how', 'now'],
      ['brown', 'frog'],
    ];
    await this.exportVocab({
      locale,
      l2: 'pt',
      email,
      name,
      storyTitle,
      storySlug,
      vocabData,
    });
  }

  async exportVocab(params: ExportedVocabParams) {
    const apiUrl = `${this.baseUrl}/mailer/vocab`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.info(`export vocab resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  // todo: consider always fetching and merging before persist
  // async syncUserData(uuid: string, data: object): Promise<void> {
  //   const remoteData = await this.fetchUserData(uuid);
  // }

  async fetchUserData(
    uuid: string
  ): Promise<UserData> /* todo: snapshot typing */ {
    return await this.fetchResource('userDatas', uuid);
  }

  async storeUserData(uuid: string, data: object): Promise<void> {
    const apiUrl = `${this.baseUrl}/userDatas/${uuid}`;
    log.info(`invoking: PUT ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    // log.info(`store user data resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async mergeSyncUserData(
    uuid: string,
    delta: object
  ): Promise<UserDataSnapshot> {
    const apiUrl = `${this.baseUrl}/userDataAux/${uuid}/mergeSync`;
    log.info(`invoking: POST ${apiUrl}`);
    if (!deepNoEmptyObject(delta)) {
      // still possible to have nested empty properties with current diff logic
      log.debug(
        `found empty subobject in mergeSyncUserData: ${JSON.stringify(delta)}`
      );
      // bugsnagNotify(`mergeSyncUserData - empty subobject`);

      // can't be fatal until deep diff is behaving correctly
      // throw Error('found empty subobject in mergeSyncUserData');
    }
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ delta }),
    });
    const json = await response.json();
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async fetchCatalogMeta(slug: string): Promise<CatalogMeta> {
    return await this.fetchResource('catalogs', slug);
  }

  async fetchGlobalSettings(): Promise<GlobalSettings> {
    return await this.fetchResource('settings', 'global');
  }

  async fetchResource<T>(collectionPath: string, docId: string): Promise<T> {
    const apiUrl = `${this.baseUrl}/${collectionPath}/${docId}`;
    log.info(`invoking: GET ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json',
      //   'Access-Control-Allow-Origin': '*',
      // }
    });
    const json = await response.json();
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  // todo: better factor
  async listCatalogMetas(): Promise<CatalogMeta[]> {
    const apiUrl = `${this.baseUrl}/catalogs`;
    log.info(`invoking: GET ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'GET',
      // headers: {
      //   'Content-Type': 'application/json',
      //   'Access-Control-Allow-Origin': '*',
      // }
    });
    const json = await response.json();
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async stripeSyncCatalog(): Promise<any> {
    const apiUrl = `${this.baseUrl}/stripe/syncCatalog`;
    log.info(`invoking: GET ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
    });
    const json = await response.json();
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async trackBatch(params: TrackBatchParams) {
    const apiUrl = `${this.baseUrl}/reporting/trackBatch`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    log.debug(`track batch resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  // async track(event: MixpanelEvent) {
  //   const apiUrl = `${this.baseUrl}/reporting/track`;
  //   log.info(`invoking: POST ${apiUrl}`);
  //   const response = await fetch(apiUrl, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //     },
  //     body: JSON.stringify({ event }),
  //   });
  //   const json = await response.json();
  //   log.info(`track resp: ${JSON.stringify(json)}`);
  //   if (!isEmpty(json.error)) {
  //     throw Error(JSON.stringify(json));
  //   }
  //   return json.result;
  // }

  async backupPriorData(uuid: string): Promise<{ timestamp: string }> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/backupPriorData`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json', // json content type w/o body barfed nestjs
        'Access-Control-Allow-Origin': '*',
      },
    });
    const json = await response.json();
    log.info(`backupPriorData resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async createBackup(
    uuid: string,
    data: object
  ): Promise<{ timestamp: string }> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/backup`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ data }),
    });
    const json = await response.json();
    log.info(`createBackup resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async listBackups(
    uuid: string,
    limit: number = 10
  ): Promise<{ timestamps: string[] }> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/list?limit=${limit}`;
    log.info(`invoking: GET ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    const json = await response.json();
    log.info(`listBackups resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async fetchBackup(
    uuid: string,
    timestamp: string
  ): Promise<{ data: UserDataSnapshot }> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/fetch?timestamp=${timestamp}`;
    log.info(`invoking: GET ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    const json = await response.json();
    // log.info(`fetchBackup resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  async removeBackup(
    uuid: string,
    timestamp: string
  ): Promise<UserDataSnapshot> {
    const apiUrl = `${this.baseUrl}/userDataBackups/${uuid}/remove?timestamp=${timestamp}`;
    log.info(`invoking: POST ${apiUrl}`);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    const json = await response.json();
    log.info(`removeBackup resp: ${JSON.stringify(json)}`);
    if (!isEmpty(json.error)) {
      throw Error(JSON.stringify(json));
    }
    return json.result;
  }

  get baseUrl() {
    const result = apiUrlForEnv(this.apiEnv);
    if (isEmpty(result)) {
      throw Error(`invalid apiEnv key: ${this.apiEnv}`);
    }
    return result;
  }
}

const apiHosts: { [index: string]: string } = {
  local: 'http://localhost:3030',
  // jfedev: 'https://jfedev.ngrok.io',
  devtest: 'https://cali-app-server-devtest.jiveworld.app',
  staging: 'https://cali-app-server-staging.jiveworld.app',
  beta: 'https://cali-app-server-beta.jiveworld.app',
  LIVE: 'https://node.jiveworld.com',
};

const apiUrlForEnv = (apiEnv: string): string => {
  return apiHosts[apiEnv];
};
