import { styled } from 'naan/stitches.config';

export const PricingCardsWrapper = styled('div', {
  width: '100%',
  '& > * + * ': {
    marginTop: '16px',
    '@small': {
      marginTop: '0px',
    },
  },
  '@small': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    gap: '16px',
    maxWidth: 'none',
  },
});
