import * as React from 'react';
import { BookIcon } from '@naan/icons/book-icon';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import { styled } from '@naan/stitches.config';

import { cms } from '@core/lib/localization-helpers';
// import { LocaleCode } from '@utils/util-types';
// import { AppFactory } from '@app/app-factory';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  marginBottom: 56,
  '.item': {
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    gap: 16,
    '& .icon': {
      '& svg': {
        width: 40,
        height: 40,
      },
      '&.soundbite': {
        color: '$colors$purple-300',
      },
      '&.stories': {
        color: '$colors$yellow-300',
      },
    },
    '& .text': {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      color: '$colors$white',
      textStyle: 'body',
      '& > h3': {
        textStyle: 'small-heading',
      },
    },
  },
});

// const storiesBlurb = (l2: LocaleCode) => {
//   // switch (l2) {
//   //   case 'en':
//   //     return __(
//   //       'These stories capture every facet of life ...',
//   //       'subscribeBenefitsStoriesBlurb.en'
//   //     );
//   //   default:
//   //     return __(
//   //       `These Radio Ambulante stories capture every facet of life in Latin America. You'll listen and understand real Spanish as spoken by 100s of people — young and old, famous and everyday — from over 20 countries.`,
//   //       'theseRadioAmbulanteStoriesCaptureEveryFacetOfLifeInLatinAmerica'
//   //     );
//   // }
//   return translateWithoutDefault(`cms:subscribeBenefitsStoriesBlurb:${l2}`);
// };

export const SubscriptionBenefits = () => {
  // const { l2 } = AppFactory.root;
  return (
    <Wrapper>
      <div className="item">
        <span className="stories icon">
          <BookIcon />
        </span>
        <div className="text">
          <h3>{cms('subscribeBenefitsStoriesHeading')}</h3>
          <p>{cms('subscribeBenefitsStoriesBlurb')}</p>
        </div>
      </div>
      <div className="item">
        <span className="soundbite icon">
          <SoundbiteIcon />
        </span>
        <div className="text">
          <h3>{cms('allSoundbitesAcross8CategoriesHeading')}</h3>
          <p>{cms('allSoundbitesAcross8CategoriesBlurb')}</p>
        </div>
      </div>
    </Wrapper>
  );
};
