import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { Table } from 'components/ui/tables';
import { ClassroomSection } from './classroom-section';
import { LicenseBadge } from './license-badge/license-badge';
import { MobileOnlyTableHeader } from './mobile-only-table-header';
import { StudentAccessCell } from './table-cells/student-access-cell';
import { StudentEmailCell } from './table-cells/student-email-cell';
import { StudentNameCell } from './table-cells/student-name-cell';
import { StudentsZeroState } from './students-zero-state';
import { Classroom, Student } from 'core/models/user-manager';
import { HSpacer, VSpacer } from '@naan/primitives/spacer';
import { Text } from '@naan/primitives/text';
import { IconButton } from '@naan/primitives/button';
import { MinusCircleSmallIcon } from '@naan/icons/minus-circle-icon';
import { HStack, VStack } from '@naan/primitives/stack';
import { AppFactory } from '@app/app-factory';
import { Responsive } from '@naan/primitives/responsive';
import { Box } from '@naan/primitives/box';

import __ from 'core/lib/localization';
import { bugsnagNotify } from '@app/notification-service';

const DropStudentConfirmationDialog = ({
  student,
  onDismiss,
  okAction,
}: {
  student: Student;
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    <Dialog.Container open={true} onDismiss={onDismiss}>
      <Dialog.Heading>{__('Drop student', 'dropStudent')}</Dialog.Heading>
      <Dialog.Body>
        <VStack>
          <Text>
            {__(
              'Do you want to drop %{name} (%{email}) from this class?',
              'dropStudentConfirmation',
              {
                name: student.name,
                email: student.email,
              }
            )}
          </Text>
          <VSpacer size={1} />
        </VStack>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={'cancel'}
          label={__('No, go back', 'noGoBack')}
          data-test-id="okButton"
          onClick={onDismiss}
        />
        <Dialog.Button
          presentation={'teal'}
          label={__('Yes, drop', 'yesDrop')}
          data-test-id="okButton"
          onClick={handleOkAndDismiss}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

export const ClassroomStudents = observer(
  ({ classroom, onInvite }: { classroom: Classroom; onInvite: () => void }) => {
    const { students } = classroom;
    const hasStudents = students.length > 0;

    const handleDropStudent = React.useCallback(
      (student: Student) => {
        AppFactory.dialogPresenter.present(onDismiss => {
          return (
            <DropStudentConfirmationDialog
              student={student}
              onDismiss={onDismiss}
              okAction={() => classroom.dropStudent(student.email)}
            />
          );
        });
      },
      [classroom]
    );

    React.useEffect(() => {
      classroom?.ensureStudentAccessStatuses().catch(bugsnagNotify);
    }, [classroom]);

    return (
      <ClassroomSection
        title={__('Students', 'students')}
        control={<LicenseBadge classroom={classroom} />}
        withHeadingRule={false}
      >
        {hasStudents ? (
          <>
            <MobileOnlyTableHeader>
              {__(
                {
                  one: '%{count} student',
                  other: '%{count} students',
                },
                'studentsCount',
                {
                  count: students.length,
                }
              )}
            </MobileOnlyTableHeader>
            <Table
              data={students}
              cells={[
                {
                  headerLabel: __('Name', 'name'),
                  renderCell: (student: Student) => (
                    <Responsive
                      renderDefault={() => (
                        <HStack justify={'center'}>
                          <StudentNameCell name={student.name} />
                          <HSpacer expand />
                          <Box css={{ paddingTop: 8 }}>
                            <IconButton
                              presentation={'grayTransparent'}
                              icon={<MinusCircleSmallIcon />}
                              onClick={() => handleDropStudent(student)}
                              testId={`drop-student-default-${student.email}`}
                            />
                          </Box>
                        </HStack>
                      )}
                      renderMediumAndUp={() => (
                        <StudentNameCell name={student.name} />
                      )}
                    />
                  ),
                },
                {
                  headerLabel: __('Email', 'email'),
                  renderCell: ({ email }: Student) => (
                    <StudentEmailCell email={email} />
                  ),
                },
                {
                  headerLabel: __('Access type', 'accessType'),
                  fieldName: 'fullAccess',
                  renderCell: (student: Student) => (
                    <StudentAccessCell student={student} />
                  ),
                  show: !classroom.license,
                },
                {
                  showHeader: false,
                  className: 'text-right',
                  renderCell: (student: Student) => (
                    <Responsive
                      renderDefault={() => null}
                      renderMediumAndUp={() => (
                        <HStack justify={'end'}>
                          <IconButton
                            presentation={'grayTransparent'}
                            icon={<MinusCircleSmallIcon />}
                            onClick={() => handleDropStudent(student)}
                            testId={`drop-student-medium-${student.email}`} // todo: should include userId in student schema
                          />
                        </HStack>
                      )}
                    />
                  ),
                },
              ]}
            />
            <VSpacer size={6} />
          </>
        ) : null}
        <StudentsZeroState classroom={classroom} onInvite={onInvite} />
      </ClassroomSection>
    );
  }
);
