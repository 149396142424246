import { ModelTreeNode } from 'ts-state-tree/tst-core';
import {
  BillingInterval,
  Currency,
  PlanSlug,
  PricingGeneration,
  PricingLevel,
} from '@cas-shared/cas-types';
import { LocaleCode } from '@utils/util-types';

export class EntitlementData extends ModelTreeNode {
  static CLASS_NAME = 'EntitlementData' as const;

  static create(snapshot: any = {}): EntitlementData {
    return super.create(EntitlementData, snapshot) as EntitlementData;
  }

  l2: LocaleCode;

  accessUntil?: string; // iso8601 date
  accessSince?: string; // iso8601 date

  planSlug?: PlanSlug;
  stripeSubscriptionId?: string;

  failureCode?: string;
  failureMessage?: string;

  pausedUntil?: string;
  pausedSince?: string;
  billingResumesOn?: string;
  // set when we detect a surpluss of access when receiving a payment
  // checked after checkout complete so auto-renew can be appropriately deferred
  pendingDeferBilling?: boolean;

  billingInterval?: BillingInterval;
  currency?: Currency;
  price?: number;
  priceLevel?: PricingLevel;
  priceGeneration?: PricingGeneration;

  // reflects applied discount during checkout
  discountSlug?: string;
  percentOff?: number;

  // // should this be scoped to L2 or global?
  // discountPercentage?: number;
  // discountExpiry?: string; // iso8601 date for which offered discount expires
  // discountLabel?: string; // pricing card string

  railsMigrated?: boolean;
}
