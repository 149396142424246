// DEFERRED until after 10.x release
import * as React from 'react';
import { observer } from 'mobx-react';
import { formatDate } from 'utils/format-date';
import { Button } from 'naan/primitives/button';
import { AppFactory } from 'app/app-factory';
import { alertSevereError } from '@app/notification-service';
import { profileHomePath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';

import { resolveApiMessage } from '@core/models/user-manager/api-messages';
import { LocaleCode } from '@utils/util-types';
import { switchToYearlyData } from '@cas-shared/subscription-helpers';
import { formatMoney } from '@cas-shared/format-money';
import { SwitchToYearlyDialog } from '../dialogs/switch-to-yearly';

export const SwitchToYearlyButton = observer(({ l2 }: { l2: LocaleCode }) => {
  const { userManager } = AppFactory.root;
  const membership = userManager.membershipL2(l2);
  const entitlementData = membership.nodeEntitlement;

  const navigate = useNavigate();

  const handleSwitchToYearly = () => {
    const { newPrice, currency, newAccessUntil, savedPercent } =
      switchToYearlyData(entitlementData);
    const formattedPrice = formatMoney(newPrice, currency);
    const formattedDate = formatDate(newAccessUntil);

    AppFactory.dialogPresenter.present(onDismiss => (
      <SwitchToYearlyDialog
        onOk={() => {
          onDismiss();
          membership
            .switchPlanInterval()
            .then(result => {
              navigate(profileHomePath()); // todo: figure out responsive issue
              // todo: confirm if response typing is correct and if the response includes a message
              const message = resolveApiMessage(result as any);
              if (message) {
                AppFactory.toastService.open({
                  type: 'success',
                  message,
                });
              }
            })
            .catch(error => {
              const message = resolveApiMessage(error) || error.userMessage;
              if (message) {
                AppFactory.toastService.open({
                  type: 'error',
                  message,
                });
              } else {
                alertSevereError({ error, note: 'cancelAutoRenew' });
              }
            });
          // setTimeout(() => {
          //   DialogService.closeDialog();
          // }, 100);
        }}
        onDismiss={onDismiss}
        formattedPrice={formattedPrice}
        formattedDate={formattedDate}
        savedPercent={savedPercent}
      />
    ));
  };

  return (
    <Button
      onClick={handleSwitchToYearly}
      label={'Switch to yearly'}
      size={'small'}
      presentation={'grayLight'}
      data-test-id="switch-plan-interval"
      css={{
        margin: '-4px 0',
      }}
    />
  );
});
