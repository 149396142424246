import * as React from 'react';
import classNames from 'classnames';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { StudyModel } from 'study/models/study-model';
// import { scrollToCurrentLine } from 'study/views/fx/scrolling';
import { presentSentenceActions } from 'study/views/sentence-modal';
import { EyeIcon } from '@naan/icons/eye-icon';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { NotationIcon, TranslationIcon } from './sentence-toolbar-icons';

import __ from '@core/lib/localization';
import { SentenceId } from '@tikka/basic-types';

type StudyOrSoundbiteModel = StudyModel | SoundbiteModel;

export const StudyFromHereButton: React.FC<{
  model: StudyModel;
}> = ({ model }) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      // scrollToCurrentLine();
      model.togglePlayerMode();
    },
    [model]
  );

  return (
    <button className={'translation-button'} onClick={handleClick}>
      <span className="icon">
        <EyeIcon />
      </span>
      <span className="label">{__('Study from here', 'studyFromHere')}</span>
    </button>
  );
};

export const TranslationButton: React.FC<{
  disabled?: boolean;
  active: boolean;
  action: () => void;
}> = ({ active, action, disabled = false }) => {
  const handleTranslationClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      // scrollToCurrentLine();
      action();
    },
    [action]
  );

  return (
    <button
      className={classNames('translation-button', {
        active: active,
        disabled: disabled,
      })}
      onClick={handleTranslationClick}
    >
      <span className="icon">
        <TranslationIcon />
      </span>
      {/* TODO: actual l1 label */}
      <span className="label">EN</span>
    </button>
  );
};

export const NotationsButton = ({
  notationCount,
  active,
  action,
}: {
  active: boolean;
  action: () => void;
  notationCount: number;
}) => {
  const hasNotations = notationCount > 0;
  const notationLabel = String(notationCount);

  const handleNotationClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (hasNotations) {
        action();
      }
    },
    [hasNotations, action]
  );

  return (
    <button
      className={classNames('notation-button', {
        active,
        disabled: !hasNotations,
      })}
      onClick={handleNotationClick}
    >
      <span className="icon">
        <NotationIcon text={notationLabel} />
      </span>
      <span className="label">{__('Vocab', 'vocab')}</span>
    </button>
  );
};

export const ReplayButton = ({ model }: { model: StudyOrSoundbiteModel }) => {
  const handleReplayClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      model.replayCurrentSentence();
    },
    [model]
  );

  return (
    <button onClick={handleReplayClick}>
      <span className="icon">
        <ReplayIcon />
      </span>
      <span className="label">{__('Replay', 'replay')}</span>
    </button>
  );
};

export const OverflowButton: React.FC<{
  model: StudyOrSoundbiteModel;
  sentenceId: SentenceId;
}> = ({ model, sentenceId }) => {
  const handleOverflowClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      presentSentenceActions(model, sentenceId);
    },
    [model, sentenceId]
  );

  return (
    <button>
      <span className="icon" onClick={handleOverflowClick}>
        <OverflowIcon />
      </span>
    </button>
  );
};
