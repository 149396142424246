import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { AppFactory } from 'app/app-factory';
import { alertSevereError } from '@app/notification-service';
import { pagePath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';
import { resolveApiMessage } from '@core/models/user-manager/api-messages';
import { LocaleCode } from '@utils/util-types';
import { CancelSubscriptionDialog } from '../dialogs/cancel-subscription';

import __ from 'core/lib/localization';

export const CancelButton = observer(({ l2 }: { l2: LocaleCode }) => {
  const { userManager } = AppFactory.root;
  const membership = userManager.membershipL2(l2);
  const { fullAccessUntil } = membership;

  const navigate = useNavigate();

  const handleCancelAutoRenew = () => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <CancelSubscriptionDialog
        onOk={() => {
          onDismiss();
          membership
            .cancelAutoRenew()
            .then(result => {
              navigate(pagePath('exit-survey'));
              const message = resolveApiMessage(result);
              if (message) {
                AppFactory.toastService.open({
                  type: 'success',
                  message,
                });
              }
            })
            .catch(error => {
              const message = resolveApiMessage(error) || error.userMessage;
              if (message) {
                AppFactory.toastService.open({
                  type: 'error',
                  message,
                });
              } else {
                alertSevereError({ error, note: 'cancelAutoRenew' });
              }
            });
          // setTimeout(() => {
          //   DialogService.closeDialog();
          // }, 100);
        }}
        onDismiss={onDismiss}
        l2={l2}
        fullAccessUntil={fullAccessUntil}
      />
    ));
    // }
  };

  return (
    <Button
      onClick={handleCancelAutoRenew}
      label={__('Cancel subscription', 'cancelSubscription')}
      size={'small'}
      presentation={'text-only-red'}
      data-test-id="auto-renew-off"
    />
  );
});
