import * as React from 'react';
import { observer } from 'mobx-react';
import { Story } from 'core/models/story-manager';
import { VocabListModel } from '@core/models/ui/vocab-list-model';
import { notEmpty } from '@utils/conditionals';
import { styled } from '@naan/stitches.config';
import { VocabListHeading } from './vocab-list-heading';
import { VocabListSectionHeading } from './vocab-list-section-header';
import { VocabRow } from './vocab-list-row';
import { FullScreenError } from 'components/error-boundary';
import { FullScreenLoader } from 'components/ds/modals';
import { useVocabListData } from './use-vocablist-data';
import { VocabListZeroState } from './vocablist-zero-state';
import { bugsnagNotify, sanitizeErrorMessage } from '@app/notification-service';
import { isNetworkError } from '@core/lib/error-handling';
import { createLogger } from '@common/log';
import { presentSimpleAlert } from 'components/ui/simple-dialogs';

const log = createLogger('vocablist-content');

const Wrapper = styled('div', {
  overflowY: 'auto',

  '& > .vocab-rows': {
    // borderTop: '1px solid $gray-100',
    '& > * + *': {
      borderTop: '1px solid $gray-100',
    },
  },
});

Wrapper.displayName = 'VocabListWrapper';

const VocabList = observer(
  ({
    story,
    model,
    onDismiss,
  }: {
    model: VocabListModel;
    story: Story;
    onDismiss: () => void;
  }) => {
    const { progress } = story;

    const applyVocabChanges = React.useCallback(() => {
      const { removalSlugs } = model;
      if (notEmpty(removalSlugs)) {
        progress.removeVocabs(removalSlugs, { persist: true });
      }
    }, [model, progress]);

    const handleDismiss = React.useCallback(() => {
      applyVocabChanges();
      onDismiss();
    }, [applyVocabChanges, onDismiss]);

    return (
      <>
        <VocabListHeading count={model.count} onClose={handleDismiss} />
        <Wrapper>
          {model.sections.map(section => (
            <React.Fragment key={section.chapterData.position}>
              <VocabListSectionHeading section={section} />
              <div className="vocab-rows">
                {section.rows.map(data => (
                  <VocabRow data={data} key={data.slug} />
                ))}
              </div>
            </React.Fragment>
          ))}
        </Wrapper>
      </>
    );
  }
);

const DummyVocabList = observer(({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <>
      <VocabListHeading count={0} onClose={onDismiss} />
      <Wrapper></Wrapper>
    </>
  );
});

export const VocabListModalContent = observer(
  ({ story, onDismiss }: { story: Story; onDismiss: () => void }) => {
    const { progress } = story;

    // log.debug('volumeDataUrl: ', story.volumeDataUrl);
    const {
      model,
      error: failure,
      loading,
    } = useVocabListData(story, progress.vocabList);

    if (!progress.hasVocab) {
      return <VocabListZeroState onDismiss={onDismiss} />;
    }

    if (loading) {
      return <FullScreenLoader />; // todo: confirm if we need any loading ui state
    }

    if (failure) {
      // beware, duplicated code from study-screen error handling
      const error = failure instanceof Error ? failure : Error(String(failure));

      log.error(`prepare vocab list data failed: ${error.stack}`);
      if (!isNetworkError(error)) {
        bugsnagNotify(error);
      }
      const message = sanitizeErrorMessage(error);
      presentSimpleAlert(<>{message}</> /*, onErrorAlertDismiss*/);

      // @armando, please think about how better handle an error here
      // the navigation attempt didn't play nicely with the modal, but i'm sure there's
      // a better solution than this dummy modal body component
      // return <Navigate to={learnStorySlugPath(story.slug)} replace />;
      return <DummyVocabList onDismiss={onDismiss} />;
    }

    if (model.volumeData == null) {
      return <FullScreenError message="No volume data" />;
    }

    return <VocabList model={model} story={story} onDismiss={onDismiss} />;
  }
);
