import * as React from 'react';
import { observer } from 'mobx-react';
import { VSpacer } from '@naan/primitives/spacer';
import {
  createAccountPath,
  marketingSite,
  signInPath,
  subscribePath,
} from 'components/nav/path-helpers';
import { styled } from '@naan/stitches.config';
import { AnonPricingCards } from 'components/account/pricing-cards/pricing-cards';
import { Button } from '@naan/primitives/button';
import { AdaptiveLink, PlainMarkdown } from '@naan/primitives/text';
import { AnonymousPlainCouponLink } from 'components/coupons/coupon-buttons';
import { useNavigate } from 'react-router-dom';
import __ from 'core/lib/localization';
import { AppFactory } from '@app/app-factory';

const AnonWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  color: '$colors$white',
  alignItems: 'center',
  justifyContent: 'center',

  '& .anon-cta': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',

    '@medium': {
      flexDirection: 'row',
    },

    '& .or-sign-in': {
      color: 'white',
      '& a': {
        color: 'inherit',
      },
    },
  },

  '& > .copy': {
    color: '$colors$white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& a': {
      color: 'inherit',
    },
  },
});

// profile/subscribe
export const AnonymousFlow = observer(() => {
  const navigate = useNavigate();
  const { l2 } = AppFactory.root;
  return (
    <AnonWrapper>
      <AnonPricingCards l2={l2} />
      <VSpacer size={8} />
      <div className="anon-cta">
        <Button
          label={__('Create an account', 'createAnAccount')}
          presentation={'green'}
          fullWidthInMobile
          size="large"
          onClick={() =>
            // navigate(`/auth/create-account?after=${profileHomePath()}`)
            navigate(createAccountPath({ after: subscribePath() }))
          }
        />

        <span className="or-sign-in">
          <PlainMarkdown
            components={{
              a: ({ node, ...props }) => <AdaptiveLink {...props} />,
            }}
            source={__(
              'or [sign in](%{url}) to choose your plan',
              'orSignInToChooseYourPlanLower',
              // { url: `/auth/sign-in?after=${profileHomePath()}` }
              { url: signInPath({ after: subscribePath() }) }
            )}
          />
        </span>
      </div>
      <VSpacer size={12} />
      <div className="copy">
        <AnonymousPlainCouponLink />
      </div>
      <VSpacer size={6} />
      <div className="copy">
        <div>
          <b>
            <PlainMarkdown
              // @daniel, is it okay to flatten this to the singular 'Student discount'?
              source={__('Student discounts', 'studentDiscounts')}
            />
          </b>
          <PlainMarkdown
            components={{
              a: ({ node, ...props }) => <AdaptiveLink {...props} />,
            }}
            source={__(
              '[Sign up with your school email](%{signupUrl}) to see student prices. For educators, [learn about our classroom licenses](%{classroomUrl})',
              'signUpWithYourSchoolEmailToSeeStudentPrices',
              {
                // signupUrl: `/auth/create-account?after=${profileHomePath()}`,
                signupUrl: createAccountPath({ after: subscribePath() }),
                classroomUrl: `${marketingSite()}/schools/plans`,
              }
            )}
          />
        </div>
      </div>
    </AnonWrapper>
  );
});
