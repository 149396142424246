import { scrollContainerToTop } from 'lib/scroll-utils';
import {
  scrollToCurrentChapter,
  // scrollToTabBar,
} from 'routes/learn/scrolling-fx';
import { Story } from '@core/models/story-manager';
import { action, makeObservable, observable } from 'mobx';

// import { createLogger } from 'app/logger';

// const log = createLogger('story-detail-ui-model');

// const autoscrollTypes = ['top', 'tabBar', 'chapter', 'min'];

/// Lifter up the state because there was a rerender that was ruining the scrolling effect
class StoryDetailUiModel {
  public story: Story | null = null;
  public showTitleInHeader = true;
  public solidHeader = true;
  // public currentTab: string | null = null;
  // public firstLanding: boolean = true;

  // public nextAutoscroll: typeof autoscrollTypes[number] = 'top';

  constructor() {
    makeObservable(this, {
      showTitleInHeader: observable,
      setShowTitleInHeader: action,
      solidHeader: observable,
      setSolidHeader: action,
      // currentTab: observable,
      story: observable,
      setStory: action,
    });
  }

  public setShowTitleInHeader(showTitleInHeader: boolean) {
    this.showTitleInHeader = showTitleInHeader;
  }

  public setSolidHeader(solidHeader: boolean) {
    this.solidHeader = solidHeader;
  }

  public executeAutoscroll() {
    const started = this.story?.started;
    if (started) {
      scrollToCurrentChapter();
    } else {
      scrollContainerToTop();
    }
  }

  public setStory(story: Story) {
    this.story = story;
  }

  public reset() {
    this.story = null;
    this.showTitleInHeader = true;
    this.solidHeader = true;
    // this.currentTab = null;
    // this.firstLanding = true;
  }
}

export const storyDetailUiModel = new StoryDetailUiModel();

// (window as any).storymodel = storyDetailUiModel;
