import * as Dialog from '@naan/primitives/modals/dialog';
import * as React from 'react';
import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from '@naan/stitches.config';
import { useNavigate } from 'react-router-dom';
import { learnHomePath, subscribePath } from 'components/nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import { Image } from '@naan/primitives/image';
import { CompletedCheckmark } from 'story/chapter-list-item/components/completed-checkmark';

import __, { translateWithoutDefault } from '@core/lib/localization';
import { LocaleCode } from '@utils/util-types';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  '& .figure': {
    display: 'flex',
    justifyContent: 'center',
    margin: '16px 0 8px',
    width: 144,
    height: 144,
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
    '& .thumbnail': {
      width: 144,
      height: 144,
    },
    '& .checkmark-container': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 144,
      height: 144,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0,0,0,.05)',
      '& svg': {
        width: 56,
        height: 56,
      },
    },
  },
  '& h2': {
    textStyle: 'small-heading',
    width: '100%',
  },
  '& .copy': {
    textStyle: 'body',
    '& p': {
      marginBottom: 16,
    },
  },
});

const endOfStoryCtaBody = (l2: LocaleCode) => {
  //   switch (l2) {
  //     case 'en':
  //       return __(
  //         `Don't stop now — unlock full-length English stories designed to help you achieve true fluency...`,
  //         'endOfStoryCtaBody.en'
  //       );
  //     default:
  //       return __(
  //         `Don't stop now — unlock full-length Spanish stories designed to help you achieve true fluency.

  // * 50+ hours of real, human stories from across Latin America
  // * 100+ Soundbites — mini-lessons that dive into language and culture`,
  //         'dontStopNowUnlockFullLengthSpanishStories'
  //       );
  //   }
  return translateWithoutDefault(`cms:endOfStoryCtaBody:${l2}`);
};

// const strings: {
//   title: string;
//   body: string;
// }[] = [
//   {
//     title: __('Congrats, you completed the story!', 'congratsCompletedStory'),
//     body: dontStopNowUnlockCopy('es'),
//   },
// ];

export const EndOfStoryCtaDialog = ({
  onDismiss,
}: {
  onDismiss: () => void;
}) => {
  const { storyManager, l2 } = AppFactory.root;
  // const { title, body } = strings[Math.floor(Math.random() * strings.length)];
  const title = __(
    'Congrats, you completed the story!',
    'congratsCompletedStory'
  );
  const body = endOfStoryCtaBody(l2);
  const story = storyManager.onboardingStory;
  const navigate = useNavigate();

  return (
    <Dialog.Container open onDismiss={onDismiss}>
      <Dialog.Body>
        <Wrapper>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <div className="figure">
            <Image
              src={story.listImageUrl}
              role="presentation"
              className="thumbnail"
            />
            <div className="checkmark-container">
              <CompletedCheckmark />
            </div>
          </div>
          <h2>{title}</h2>
          <div className="copy">
            <PlainMarkdown source={body} />
          </div>
        </Wrapper>
      </Dialog.Body>
      <Dialog.ButtonsContainer direction={'column'}>
        <Dialog.Button
          onClick={() => {
            navigate(subscribePath());
            onDismiss();
          }}
          label={__('Subscribe now', 'subscribeNow')}
          presentation={'teal'}
        />
        <Dialog.Button
          onClick={() => {
            navigate(learnHomePath());
            onDismiss();
          }}
          label={__(
            'Browse all Jiveworld stories',
            'browseAllJiveworldStories'
          )}
          presentation={'grayLight'}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

export const presentEndOfStoryCtaDialog = () => {
  return AppFactory.dialogPresenter.present(onDismiss => (
    <EndOfStoryCtaDialog onDismiss={onDismiss} />
  ));
};

// loose dependency invoked from story-progress.ts
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    presentEndOfStoryCtaDialog: () => void;
  }
}

window.presentEndOfStoryCtaDialog = presentEndOfStoryCtaDialog;

// export const maybePresentEndOfStoryCtaDialog = () => {
//   const storyManager = AppFactory.root.storyManager;
//   const currentStory = storyManager.currentStory;
//   const showCtaDialog =
//     currentStory.isTheOnboardingStory &&
//     currentStory.trial &&
//     !AppFactory.root.userManager.fullAccess;

//   if (showCtaDialog) {
//     presentEndOfStoryCtaDialog();
//   }
// };
