import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';

import steps1 from '../assets/steps-1.svg';
import steps2 from '../assets/steps-2.svg';
import steps3 from '../assets/steps-3.svg';
import steps4 from '../assets/steps-4.svg';

import { Image } from '@naan/primitives/image';

const StepsWrapper = styled('div', {
  display: 'flex',
  gap: 20,
  marginBottom: 4,

  '& img': {
    width: 182,
  },
});

// type StepStage = 'warmup' | keyof typeof chapterStudyIcons;

// const stages: StepStage[] = ['warmup', 'study', 'listen', 'completed'];

const steps = [steps1, steps2, steps3, steps4];

export const StepsIndicator = observer(({ stage }: { stage: number }) => {
  return (
    <StepsWrapper>
      <Image src={steps[stage]} />
    </StepsWrapper>
  );
});
