import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { SwitchButton } from 'components/ds/switch-button';
import { usePlayerModel } from 'player/views/player-model-context';
import { RedactionMode } from 'player/models/redaction-modes';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { StudyModel } from 'study/models/study-model';
import { EarIcon } from '@naan/icons/ear-icon';
import { VSpacer } from '@naan/primitives/spacer';
import { AppFactory } from '@app/app-factory';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  '& > .row': {
    padding: '16px 0',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: 16,
    '&:last-child': {
      paddingBottom: '8px',
    },
    '&.stack-on-small': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto',
      '@medium': {
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: 'auto',
      },
    },
    '& + &': {
      borderTop: '1px solid $colors$gray-100',
    },
    '& > .left': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 4,
      '& > .title': {
        textStyle: 'body',
        color: '$textPrimary',
      },
      '& > .hint': {
        textStyle: 'small-text',
        color: '$textSecondary',
      },
      '& > .extra': {
        '& > a': {
          color: '$textSecondary',
          textStyle: 'small-text',
        },
      },
    },
    '& > .bottom': {
      gridColumn: 'span 2',
    },
  },
});

const exampleWords = 'Lorem ipsum dolor sit amet'.split(' ');
const XRaySwitchWrapper = styled('div', {
  display: 'flex',

  flexDirection: 'column',
  padding: '0 16px 4px 16px',
  borderRadius: 12,
  background: '$colors$gray-50',
  '& > .switch-row': {
    $$radioBorderColor: '$colors$gray-200',
    $$indicatorColor: '$colors$transparent',
    display: 'flex',
    gap: 16,
    cursor: 'default',

    '&:hover': {
      $$radioBorderColor: '$colors$teal-500',
    },

    '& .left': {
      width: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > .radio-button': {
        width: 24,
        height: 24,
        borderRadius: 12,
        border: '2px solid $$radioBorderColor',
        position: 'relative',
        transition: 'border-color 0.2s ease-out',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 3,
          top: 3,
          width: 14,
          height: 14,
          borderRadius: '50%',
          background: '$$indicatorColor',
        },

        '&.selected': {
          $$radioBorderColor: '$colors$teal-500',
          $$indicatorColor: '$colors$teal-500',
        },
      },
    },
    '& .right': {
      flex: 1,
      padding: '12px 0',
      position: 'relative',
      borderBottom: '1px solid $colors$gray-200',

      '& > .label': {
        textStyle: 'small-text-bold',
      },
      '& > .preview': {
        textStyle: 'serif',
        fontSize: 16,
        lineHeight: '24px',
        display: 'inline-flex',
        gap: 4,
        '& > .word': {
          position: 'relative',
          '& > .text': {},
          '& > .redaction': {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 9,
            bottom: 7,
            background: '$colors$gray-400',
            borderRadius: 3,
            opacity: 0,
          },
          '&.redacted > .text': {
            opacity: 0,
          },
          '&.redacted > .redaction': {
            opacity: 1,
          },
        },
      },
    },
    '&:last-child .right': {
      borderBottom: 'none',
    },
  },
});

const ListenNoticeWrapper = styled('div', {
  background: '$gray-600',
  padding: '12px 16px',
  borderRadius: 12,
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  color: '$white',
  '& > .icon': {
    '& > svg': {
      width: 28,
      height: 28,
    },
  },
  '& > .label': {
    textStyle: 'small-text-bold',
  },
});

const NaturalListenNotice = observer(() => {
  const model = usePlayerModel() as StudyModel | SoundbiteModel;
  if (model?.fluentListenMode) {
    return (
      <>
        <ListenNoticeWrapper>
          <div className="icon">
            <EarIcon />
          </div>
          <div className="label">
            {__(
              'Words are always hidden in Natural Listen mode',
              'wordsAreAlwaysHiddenInNaturalListenMode'
            )}
          </div>
        </ListenNoticeWrapper>
        <VSpacer size={2} />
      </>
    );
  }

  return null;
});

const XRayControl = observer(
  ({
    label,
    redactionMask,
    selected,
    onClick,
  }: {
    label: string;
    redactionMask: 'none' | 'some' | 'all';
    selected?: boolean;
    onClick: () => void;
  }) => {
    return (
      <div
        className="switch-row"
        onClick={() => {
          onClick();
        }}
      >
        <div className="left">
          <div className={classNames('radio-button', { selected: selected })} />
        </div>
        <div className="right">
          <div className="label">{label}</div>
          <div className="preview">
            {exampleWords.map((word, index) => {
              const redacted =
                redactionMask === 'all' ||
                (redactionMask === 'some' && (index === 0 || index === 2));

              return (
                <span className={classNames('word', { redacted })}>
                  <span className="text">{word}</span>
                  <span className="redaction" />
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

const XRayModeSwitch = observer(() => {
  // const model = usePlayerModel() as StudyModel | SoundbiteModel;
  const { playerSettings } = AppFactory.root.userManager.userData;
  const select = React.useCallback(
    (mode: RedactionMode) => {
      playerSettings.setRedactionMode(mode);
    },
    [playerSettings]
  );
  return (
    <>
      <NaturalListenNotice />
      <XRaySwitchWrapper>
        <XRayControl
          label="Show all words"
          redactionMask="none"
          onClick={() => select(RedactionMode.SHOW_ALL)}
          selected={playerSettings.redactionMode === RedactionMode.SHOW_ALL}
        />
        <XRayControl
          label="Hide some words"
          redactionMask="some"
          onClick={() => select(RedactionMode.SHOW_SOME)}
          selected={playerSettings.redactionMode === RedactionMode.SHOW_SOME}
        />
        <XRayControl
          label="Hide all words"
          redactionMask="all"
          onClick={() => select(RedactionMode.SHOW_NONE)}
          selected={playerSettings.redactionMode === RedactionMode.SHOW_NONE}
        />
      </XRaySwitchWrapper>
    </>
  );
});

export const PlayerSettingsTab = observer(() => {
  const { playerSettings } = AppFactory.root.userManager.userData;
  return (
    <>
      <Wrapper>
        <div className="row">
          <div className="left">
            <div className="title">{__('X-Ray mode', 'xRayMode')}</div>
            <div className="hint">
              {__(
                'Challenge yourself by hiding some (or all) of the words in the transcript.',
                'challengeYourselfByHidingSomeOrAllOfTheWordsInTheTranscript'
              )}
            </div>
          </div>
          <div className="bottom">
            <XRayModeSwitch />
          </div>
        </div>
        <div className="row">
          <div className="left">
            <div className="title">{__('Smart Pause', 'smartPause')}</div>
            <div className="hint">
              {__(
                'Waits until the end of a sentence before stopping. Tap twice to stop immediately.',
                'waitsUntilTheEndOfASentenceBeforeStopping'
              )}
            </div>
          </div>
          <div className="right">
            <SwitchButton
              onChange={() => {
                playerSettings.toggleSmartPause();
              }}
              active={playerSettings.smartPauseEnabled}
            />
          </div>
        </div>
      </Wrapper>
    </>
  );
});
