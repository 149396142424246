import React from 'react';
import { observer } from 'mobx-react';
import { styled } from 'naan/stitches.config';
import { FullyStyledMarkdown, MarkSimple, Text } from 'naan/primitives/text';
import { Divider } from 'naan/primitives/divider';
import { HSpacer, VSpacer } from 'naan/primitives/spacer';
import dayjs from 'dayjs';
import __ from 'core/lib/localization';
import { Story } from 'core/models/story-manager';
import { DateSmallIcon } from '@naan/icons/date-icon';
import { HStack } from '@naan/primitives/stack';
import { GlobeSmallIcon } from '@naan/icons/globe-icon';
import { A } from '@common/ui/action-link';
import { colors } from 'naan/tokens/colors';

const Wrapper = styled('div', {
  gridColumn: '1/-1',
  '@medium': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    columnGap: '$space$4',
  },

  '@media print': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    columnGap: '$space$4',
  },
});

const Item = styled('div', {
  marginTop: '$space$3',
});

const SectionHeader = styled('div', {
  gridColumn: '1/-1',
});

const WebLink = styled(A, {
  color: '$textSecondary',
  textDecoration: 'none',
});

const formatDate = (date: string) => dayjs(date).format('MMM D, YYYY');

// used from learn story detail, and classroom detail and assignments screens
export const DatesVoicesCredits = observer(({ story }: { story: Story }) => {
  const { releaseDate, originalBroadcastDate } = story;

  return (
    <>
      <VSpacer size={6} />
      <Wrapper>
        <SectionHeader>
          <HStack align={'center'}>
            <DateSmallIcon color={colors['gray-400']} />
            <HSpacer size={1} />
            <Text color="textSecondary">
              {__('Released %{date}', 'released', {
                date: formatDate(releaseDate),
              })}
            </Text>
          </HStack>
          <HStack>
            <HSpacer size={6} />
            <Text color="textSecondary">
              {__('Originally broadcast on %{date}', 'originallyBroadcastOn', {
                date: formatDate(originalBroadcastDate),
              })}
            </Text>
          </HStack>
        </SectionHeader>
        <VSpacer size={4} />
        <SectionHeader>
          {story.hasWeblink ? (
            <HStack align={'center'}>
              <GlobeSmallIcon color={colors['gray-400']} />
              <HSpacer size={1} />
              <WebLink href={story.weblink}>
                {__('View on %{domain}', 'viewOnDomain', {
                  domain: story.weblinkDomain,
                })}
              </WebLink>
            </HStack>
          ) : null}
        </SectionHeader>
      </Wrapper>
      <VSpacer size={10} />
      <VoicesCredits story={story} />
      <VSpacer size={8} />
    </>
  );
});

export const VoicesCredits = observer(({ story }: { story: Story }) => {
  const { voices, sortedCredits: credits } = story;

  return (
    <Wrapper>
      <SectionHeader>
        <Text textStyle={'small-heading'}>{__('Voices', 'voices')}</Text>
        <VSpacer size={2} />
        <Divider />
      </SectionHeader>
      {voices.map((voice, i) => {
        return (
          <Item key={i}>
            <Text>
              {voice.label}
              {voice.accent ? ` (${voice.accent})` : null}
            </Text>
            <FullyStyledMarkdown
              source={voice.bio}
              css={{ color: '$textSecondary' }}
            />
          </Item>
        );
      })}
      <VSpacer size={10} css={{ gridColumn: '1/-1' }} />
      <SectionHeader>
        <Text textStyle={'small-heading'}>{__('Credits', 'credits')}</Text>
        <VSpacer size={2} />
        <Divider />
      </SectionHeader>
      {credits.map(({ labelL1, name }) => {
        return (
          <Item key={labelL1}>
            <Text color={'textSecondary'}>{labelL1}</Text>
            <Text>
              <MarkSimple source={name} />
            </Text>
          </Item>
        );
      })}
    </Wrapper>
  );
});
