import { LocaleCode } from '@utils/util-types';
import { PricingLevel, StripeProductId } from './cas-types';
import { StripeProduct, getStripeProduct } from './stripe-product';
import {
  BillingInterval,
  Currency,
  PlanSlug,
  PricingGeneration,
} from './cas-types';

// import { createLogger } from '@common/log';
// const log = createLogger('plan');

export interface PlanData {
  slug?: PlanSlug;
  l1: LocaleCode;
  l2: LocaleCode;
  currency: Currency;
  pricingLevel: PricingLevel;
  pricingGeneration: PricingGeneration;
  billingInterval: BillingInterval;
  price: number;
  normalPrice?: number;
}

//
// drives spa pricing cards and also stripe product catalog sync of 'Price' entity
//
export class Plan {
  slug: PlanSlug;
  l1: LocaleCode; // UI locale
  l2: LocaleCode; // = 'es';
  currency: Currency; // = 'usd';
  pricingLevel: PricingLevel; // = 'retail';
  pricingGeneration: PricingGeneration; // i.e. '2023'
  billingInterval: BillingInterval; // = 'month';

  price: number;
  normalPrice?: number;

  // pricingDescription?: string;
  // pricingDescriptionKey?: string;
  // discountPercentage?: number;
  // hasSpecialPricing: boolean = false;

  // // these are only relevant for gift coupon plans
  // gift: boolean = false;
  // cardDescriptionText?: string;
  // cardDescriptionKey?: string;
  // cardFootnoteText?: string;
  // cardFootnoteKey?: string;

  constructor(
    {
      slug,
      l1,
      l2,
      pricingLevel,
      pricingGeneration,
      currency,
      billingInterval,
      price,
      normalPrice,
    }: PlanData /*{
    l1: LocaleCode;
    l2: LocaleCode;
    currency: Currency;
    pricingLevel: PricingLevel;
    pricingGeneration?: PricingGeneration;
    billingInterval: BillingInterval;
    price: number;
    normalPrice?: number;
  }*/
  ) {
    this.slug =
      slug ||
      Plan.buildKey({
        l1,
        l2,
        pricingLevel,
        pricingGeneration,
        currency,
        billingInterval,
      });
    this.l1 = l1;
    this.l2 = l2;
    this.pricingLevel = pricingLevel;
    this.pricingGeneration = pricingGeneration;
    this.billingInterval = billingInterval;
    this.currency = currency;
    this.price = price;
    if (normalPrice !== price) {
      this.normalPrice = normalPrice;
    }
  }

  static buildKey({
    l1,
    l2,
    pricingLevel,
    pricingGeneration,
    currency,
    billingInterval,
  }: {
    l1: LocaleCode;
    l2: LocaleCode;
    pricingLevel: PricingLevel;
    pricingGeneration: PricingGeneration;
    currency: Currency;
    billingInterval: BillingInterval;
  }): PlanSlug {
    return `${l2}-${pricingLevel}-${pricingGeneration}-${currency}-${billingInterval}-${l1}`;
  }

  get hasSpecialPricing() {
    return this.normalPrice !== undefined;
  }

  get discountPercentage() {
    if (!this.normalPrice || !this.price) return undefined;
    return Math.round(
      ((this.normalPrice - this.price) / this.normalPrice) * 100
    );
  }

  get interval(): BillingInterval {
    return this.billingInterval;
  }

  get isMonthly(): boolean {
    return this.billingInterval === 'month';
  }

  get isYearly(): boolean {
    return this.billingInterval === 'year';
  }

  // testing only
  get isDaily(): boolean {
    return this.billingInterval === 'day';
  }

  get hasDiscountPerc(): boolean {
    return this.discountPercentage !== null && this.discountPercentage > 0;
  }

  get isDiscounted(): boolean {
    return (
      this.hasDiscountPerc ||
      (this.isYearly && // todo should this be an || here
        this.normalPrice &&
        this.normalPrice !== this.price)
    );
  }

  /// main prefixing is now handled by format-money.ts
  // get currencyPrefix(): string {
  //   // todo: improve this
  //   return toUpper(this.currency);
  // }

  // only used for the small 'US' in front of the bolded price
  get diminutiveCurrencyPrefix(): string {
    return this.currency === 'usd' ? 'US' : undefined;
  }

  // need to pull from accountData in order to support the affiliate attribution
  // get pricingDescription(): string {
  //   switch (this.pricingLevel) {
  //     case 'retail':
  //       return undefined;
  //     case 'affiliate':
  //       return 'Select discount';
  //     case 'student':
  //       return 'Student pricing';
  //     default:
  //       return undefined;
  //   }
  // }

  get stripePriceKey(): string {
    return this.slug;
  }

  get stripeProduct(): StripeProduct {
    return getStripeProduct({
      l1: this.l1,
      l2: this.l2,
      pricingLevel: this.pricingLevel,
    });
  }

  get stripeProductId(): StripeProductId {
    return this.stripeProduct?.id;
  }

  // get stripeInterval(): 'month' | 'year' {
  //   switch (this.billingInterval) {
  //     case 'monthly':
  //       return 'month';
  //     case 'yearly':
  //       return 'year';
  //     default:
  //       return undefined;
  //   }
  // }

  get stripeAmount(): number {
    return Math.round(this.price * 100);
  }

  get stripeMetadata() {
    return {
      planSlug: this.slug,
      l2: this.l2,
      currency: this.currency,
      price: this.price,
      pricingLevel: this.pricingLevel,
      pricingGeneration: this.pricingGeneration,
      billingInterval: this.billingInterval,
    };
  }

  get toJson(): PlanData {
    return {
      slug: this.slug,
      l1: this.l1,
      l2: this.l2,
      currency: this.currency,
      pricingLevel: this.pricingLevel,
      pricingGeneration: this.pricingGeneration,
      billingInterval: this.billingInterval,
      price: this.price,
      normalPrice: this.normalPrice,
    };
  }

  // get checkoutSku(): string {
  //   // todo: include pricing qualifier in data
  //   return `${this.l2}-${this.pricing}-2021-${this.currency}-${this.interval}`;
  // }

  // async select(options?: string[] /*urls*/) {
  //   const { userManager } = getBaseRoot(this);
  //   return await userManager.initiateCheckout(this, options);
  // }
}
