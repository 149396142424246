import * as React from 'react';
import * as Interstitial from '../onboarding-interstitial';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager/story';
import { styled } from '@naan/stitches.config';
import { StoryFloatingCard } from './story-floating-card';
import { StepsIndicator } from './steps-indicator';
import __ from '@core/lib/localization';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';

const Container = styled('div', {
  $$backgroundColor: 'red',
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '$$backgroundColor',

  '@medium': {
    justifyContent: 'center',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    overflow: 'hidden',
    // height: '100%',
    background: '$$backgroundImage center/cover',
    filter: 'blur(4px)',
    transform: 'scale(1.05)',
    opacity: 0.1,
  },
});

const ScrollWrapper = styled('div', {
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

const ContentWrapper = styled('div', {
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '128px 0 28px',
  position: 'relative',
  '@medium': {
    justifyContent: 'center',
    padding: '170px 0 72px',
  },
});

const StepCardAndCopy = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& .copy': {
    textStyle: 'small-heading',
    color: '$white',
    margin: '24px 16px',
    textAlign: 'center',
    width: 'calc(100% - 64px)',
    maxWidth: 360,
  },
  '@medium': {
    flex: 0,
    '& .copy': {
      textStyle: 'small-heading',
      margin: '40px 0',
      maxWidth: 440,
    },
  },
});

const StepCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 270,
  height: 188,
  borderRadius: 16,
  background: '$white',
  textAlign: 'center',
  padding: '0 32px',
  transform: 'rotate(-3deg)',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  '& .title': {
    textStyle: 'medium-handwritten-heading',
    color: '$gray-700',
  },
  '@small': {
    width: 324,
    height: 226,
    '& .title': {
      fontSize: 34,
      lineHeight: 1,
    },
  },
});

const WarmUpCard = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: 270,
  height: 188,
  borderRadius: 16,
  background: '$white-alpha-70',
  padding: '14px 14px 0 20px',
  transform: 'rotate(-6deg)',
  margin: '0 0 -140px -22px',
  '& .title': {
    textStyle: 'medium-handwritten-heading',
    fontSize: 20,
    color: '$gray-700',
  },
  '& svg': {
    width: 28,
    height: 28,
  },
  '@small': {
    width: 324,
    height: 226,
    margin: '0 0 -170px -28px',
    '& .title': {
      fontSize: 24,
      lineHeight: 1,
    },
  },
});

const ButtonWrapper = styled('div', {
  width: 'min(100% - 48px, 440px)',
});

export const SteppedInterstitialLayout = observer(
  ({
    story,
    title,
    copy,
    stage,
    renderButton,
  }: {
    story: Story;
    title: string;
    copy: string;
    stage: number;
    renderButton: () => React.ReactNode;
  }) => {
    const { themeColor } = story;

    return (
      <Container
        css={{
          $$backgroundColor: themeColor,
          $$backgroundImage: `url(${story.listImageUrl})`,
        }}
      >
        <Interstitial.CloseButton />
        <ScrollWrapper>
          <ContentWrapper>
            <StoryFloatingCard story={story} chapter={story.firstChapter} />
            <StepCardAndCopy>
              {stage > 0 && (
                <WarmUpCard>
                  <h2 className="title">
                    {__('Listening warm-up', 'listeningWarmUp')}
                  </h2>
                  <CheckmarkCircleIcon color="var(--colors-green-700)" />
                </WarmUpCard>
              )}
              <StepCard>
                <StepsIndicator stage={stage} />
                <h2 className="title">{title}</h2>
              </StepCard>
              <div className="copy">{copy}</div>
            </StepCardAndCopy>
            <ButtonWrapper>{renderButton()}</ButtonWrapper>
          </ContentWrapper>
        </ScrollWrapper>
      </Container>
    );
  }
);
