import * as React from 'react';
import { VStack } from 'naan/primitives/stack';

import {
  version,
  versionCheck,
  hash,
  commit,
  commitTimestampIso,
  buildTimestampIso,
  // @ts-expect-error
} from '@jw-spa-version';

import { VSpacer } from 'naan/primitives/spacer';
import { AppFactory } from 'app/app-factory';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import {
  embeddedPlatform,
  embeddedBuildNum,
  postNativeMessage,
  resetEmbeddedState,
  setEmbeddedBuildNum,
  setEmbeddedPlatform,
} from '@core/lib/app-util';

import { UserManager } from '@core/models/user-manager';
import {
  // attemptReregistration,
  hasServiceWorker,
  hasSwController,
  isServiceWorkerRegistered,
  trySkipWaiting,
} from '../../pwa/window/update-manager';
import { flushWorkerLogs } from '../../pwa/window/log-relay';

import { Definition } from './components/definition';
import { MediumHeading } from '@naan/primitives/text';
import {
  checkOnceForUpdate,
  checkVersionTextForUpdate,
} from 'pwa/window/update-checker';
import { CRASH_TEST_MESSAGE_TYPE } from 'pwa/shared';
import { appConfig } from '@app/config';
import { CurrencySelector, LocaleToggle } from './dev-toggles';
import { EmbeddedUrlSelection } from 'components/admin/embedded-url-selection';
import { Insets } from 'native-support/insets';
import { ReturnNavState } from 'components/nav/return-nav-state';

export const path = 'general';
export const label = 'General info';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager, storyManager } = root;

  const buildTimestampLocal = new Date(buildTimestampIso).toString();
  const commitTimestampLocal = new Date(commitTimestampIso).toString();

  // strip down for easier browsing
  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  return (
    <VStack>
      <p>
        ui locale: {String(root.locale)}
        <br />
        selectedL1: {String(userManager.userData.selectedL1)} - catalog.l1/l2:{' '}
        {storyManager.l1}/{storyManager.l2} - immersive:{' '}
        {String(userManager.userData.userSettings.immersiveViewEnabled)}
        <br />
        selectedL2: {String(userManager.userData?.selectedL2)}
        {' - '}
        <ActionLink
          onPress={async () => {
            await userManager.userData.selectL2('en');
            window.location.href = '/en';
          }}
        >
          [EN]
        </ActionLink>
        {' - '}
        <ActionLink
          onPress={async () => {
            await userManager.userData.selectL2('es');
            window.location.href = '/es';
          }}
        >
          [ES]
        </ActionLink>
      </p>
      <p>
        <br />
        nav state L2: {String(ReturnNavState.l2)}
        {' - '}
        <ActionLink
          onPress={() => {
            ReturnNavState.clearL2Cookie();
            window.location.href = '/';
            // setTimeout(() => {
            //   window.location.reload();
            // }, 100);
          }}
        >
          [Reset cookie]
        </ActionLink>
        {' - '}
        <ActionLink
          onPress={() => {
            ReturnNavState.clearL2Cookie();
            window.location.href = '/en';
          }}
        >
          [EN]
        </ActionLink>
        {' - '}
        <ActionLink
          onPress={() => {
            ReturnNavState.clearL2Cookie();
            window.location.href = '/es';
          }}
        >
          [ES]
        </ActionLink>
      </p>
      <LocaleToggle />
      <CurrencySelector />
      <VSpacer size={4} />
      <MediumHeading>General Info</MediumHeading>
      <VSpacer size={6} />
      <Definition label="install flavor" value={root.installFlavor} />
      <Definition label="init href" value={root.initHref} />
      <Definition label="appConfig website" value={appConfig.website.baseUrl} />
      <Definition
        label="update prompt pending"
        value={String(root.updatePromptPending)}
      />
      <Definition
        label="service worker registered"
        value={String(isServiceWorkerRegistered())}
      />
      {hasServiceWorker() ? (
        <>
          <Definition
            label={'service worker'}
            value={
              <>
                <p>
                  <ActionLink onPress={() => checkOnceForUpdate()}>
                    [Check for update (SW)]
                  </ActionLink>
                  {' - '}
                  <ActionLink onPress={trySkipWaiting}>
                    [Skip waiting]
                  </ActionLink>
                  {' - '}
                  <ActionLink onPress={async () => flushWorkerLogs()}>
                    [Drain SW logs]
                  </ActionLink>
                  {' - '}
                  {/* <ActionLink onPress={async () => attemptReregistration()}>
          [Attempt reregister]
        </ActionLink> */}
                  <ActionLink
                    onPress={async () =>
                      AppFactory.workbox.messageSW({
                        type: CRASH_TEST_MESSAGE_TYPE,
                      })
                    }
                  >
                    [SW crash test]
                  </ActionLink>
                  {' - '}
                  <ActionLink onPress={() => checkVersionTextForUpdate()}>
                    [Check for update (version.txt)]
                  </ActionLink>
                </p>
                <p>
                  {root.serviceWorkerLogs.map((log, index) => (
                    <React.Fragment key={index}>
                      {log}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </>
            }
          />
          <Definition
            label="controller"
            value={
              hasSwController()
                ? 'true (offline ready)'
                : 'false (either still installing or unsupported api)'
            }
          />
        </>
      ) : (
        <Definition
          label={'no service worker'}
          value={
            <ActionLink onPress={() => checkOnceForUpdate()}>
              [Check for update (version.txt)]
            </ActionLink>
          }
        />
      )}
      <Definition label={'app slug'} value={appConfig.appSlug} />
      <Definition label={'package.json version'} value={version} />
      <Definition
        label={'version.txt'}
        value={
          <>
            build: {versionCheck}
            <br />
            fetched: {(window as any).fetchedVersionTxt}
          </>
        }
      />
      <Definition
        label={'last commit'}
        value={
          <ul>
            <li>{hash}</li>
            <li>{commit}</li>
            <li>{commitTimestampIso}</li>
            <li>{commitTimestampLocal}</li>
          </ul>
        }
      />
      <Definition
        label={'build timestamp'}
        value={
          <ul>
            <li>{buildTimestampIso}</li>
            <li>{buildTimestampLocal}</li>
          </ul>
        }
      />
      <Definition
        label={'env'}
        value={`config: __CONFIG_ENV__; api: ${appConfig.apiEnv}`}
      />
      <Definition
        label={'Safe Area Insets'}
        value={
          <>
            Insets: &uarr; {Insets.top} / &darr; {Insets.bottom}
            <br />
            <ActionLink onPress={() => resetEmbeddedState()}>
              [Reset embedded state (and reload)]
            </ActionLink>
          </>
        }
      />
      <Definition
        label={'embedded platform'}
        value={
          <>
            {String(embeddedPlatform())} - {String(embeddedBuildNum())} -{' '}
            <ActionLink onPress={() => setEmbeddedPlatform('ios')}>
              set ios
            </ActionLink>
            {' - '}
            <ActionLink onPress={() => setEmbeddedPlatform('android')}>
              set android
            </ActionLink>{' '}
            {' - '}
            <ActionLink onPress={() => setEmbeddedBuildNum(5000000)}>
              set 5.0.0-00 (force banner)
            </ActionLink>{' '}
            {' - '}
            <ActionLink onPress={() => setEmbeddedBuildNum(7000008)}>
              set 7.0.0-08
            </ActionLink>{' '}
            {' - '}
            <ActionLink onPress={() => setEmbeddedBuildNum(8000008)}>
              set 8.0.0-08
            </ActionLink>{' '}
            {' - '}
            <ActionLink onPress={() => setEmbeddedBuildNum(8100001)}>
              set 8.1.0-01
            </ActionLink>{' '}
            (not reactive)
            <br />
            {embeddedPlatform() ? (
              <>
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'SHOW_DEBUG' })}
                >
                  show wrapper debug screen
                </ActionLink>
                {' - '}
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'RELOAD' })}
                >
                  reload
                </ActionLink>{' '}
                {' - '}
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'RESET' })}
                >
                  reset
                </ActionLink>{' '}
                {' - '}
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'RESTART' })}
                >
                  restart
                </ActionLink>{' '}
                {' - '}
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'RERENDER' })}
                >
                  rerender
                </ActionLink>{' '}
                {' - '}
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'CRASH' })}
                >
                  crash
                </ActionLink>{' '}
                {' - '}
                <ActionLink
                  onPress={() =>
                    postNativeMessage({ type: 'REQUEST_PUSH_TOKEN' })
                  }
                >
                  request push token
                </ActionLink>{' '}
                <br />
                push token: {String(root.localState.pushToken)}
                <br />
                <EmbeddedUrlSelection />
              </>
            ) : null}
            purchaseFlowDisabled: {String(userManager.purchaseFlowDisabled)}
            <br />
          </>
        }
      />
    </VStack>
  );
});
