import * as React from 'react';
import { observer } from 'mobx-react';

import { ElementNode } from '@tikka/client/client-aliases';

import { IconButton } from '@naan/primitives/button';
import { ArrowDownIcon } from '@naan/icons/arrow-down-icon';
import { styled } from '@naan/stitches.config';
import { ArrowUpIcon } from '@naan/icons/arrow-icon';
import { scrollToCurrentLine } from '../../study/views/fx/scrolling';
import { usePlayerModel } from 'player/views/player-model-context';
import { usePlayerUIConfig } from './player-ui-config';

export const SCRIPT_SCROLLING_CONTAINER_ID = 'script-scrolling-container';
export const SOUNDBITE_TITLE_CONTAINER = 'soundbite-title-container';

export function getScriptScrollingContainer() {
  return document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
}

export const PLAYER_CONTROLS_ID = 'player-controls';

export function getPlayerControls() {
  return document.getElementById(PLAYER_CONTROLS_ID);
}

export const PlayerUIBody = observer(() => {
  const model = usePlayerModel();
  const elementNodes = model.elementNodes;
  return (
    <>
      {elementNodes.map((node: ElementNode) => (
        <El key={node.element.id} node={node} />
      ))}
    </>
  );
});

export const El = ({ node }: { node: ElementNode }) => {
  const element = node.element;
  const kind = element.kind;

  const { elementTypeMap } = usePlayerUIConfig();

  const Component = elementTypeMap[kind];
  if (Component) {
    return <Component node={node} />;
  } else {
    return null;
  }
};

const BackToSentenceWrapper = styled('div', {
  $$top: 'auto',
  $$bottom: 'auto',
  opacity: 0,
  $$direction: -1,
  top: '$$top',
  bottom: '$$bottom',
  position: 'fixed',
  left: '50%',
  marginLeft: '-20px',
  // transform: 'translateX(-50%) translateY(calc( $$direction * 200% ))',
  transform: 'translateY(calc( $$direction * 200% ))',
  transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
  zIndex: 1,

  '&.visible': {
    opacity: 1,
    transform: 'translateY(0)',
  },
});

export const BackToSentenceControls = React.memo(() => {
  return (
    <>
      <BackToSentenceWrapper
        id="BACK_TO_TOP"
        onClick={scrollToCurrentLine}
        css={{
          $$bottom: 'calc(96px + env(safe-area-inset-bottom));',
          $$direction: 1,
        }}
      >
        <IconButton
          icon={<ArrowDownIcon />}
          presentation="teal"
          testId="back-to-top"
        />
      </BackToSentenceWrapper>
      <BackToSentenceWrapper
        css={{ $$top: '72px' }}
        id="BACK_TO_BOTTOM"
        onClick={scrollToCurrentLine}
      >
        <IconButton
          icon={<ArrowUpIcon />}
          presentation="teal"
          testId="back-to-bottom"
        />
      </BackToSentenceWrapper>
    </>
  );
});
