import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { IconButton } from '@naan/primitives/button';
import { getPlayerModel } from 'player/views/player-model-context';
import { A, ActionLink } from '@common/ui/action-link';
import { ChannelSmallIcon } from '@naan/icons/channel-icon';
import { appConfig } from 'app/env';
import * as loggly from 'legacylib/loggly';
import { UaService } from 'lib/services/ua-service';
import { Box } from '@naan/primitives/box';
import { isStudyModel } from 'player/models/player-model-handle';
import { AppFactory } from '@app/app-factory';
// import { getSnapshot } from '@ts-state-tree/tst-core';

const { sessionId } = appConfig;

const Wrapper = styled('div', {
  position: 'fixed',
  inset: 20,
  background: '$colors$gray-100',
  zIndex: 3,
  borderRadius: 12,
  display: 'none',
  flexDirection: 'column',
  padding: '$space$10 $space$4 $space$4 $space$4',
  maxHeight: 'calc(100vh - 40px)',
  overflow: 'auto',

  '&.open': {
    display: 'flex',
  },

  '& > .close-button': {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

export const DebugOverlay = observer(() => {
  const model = getPlayerModel();

  if (AppFactory?.root?.forceHidePiMenu === true) {
    return null;
  }

  if (!model.debugMode) {
    return null;
  }

  return (
    <>
      {appConfig.player.debug ? (
        <IconButton
          icon={<ChannelSmallIcon color={'teal'} />}
          onClick={() => model.toggleDebugOverlay()}
          css={{ position: 'fixed', bottom: 2, right: 2, zIndex: 2000 }}
          testId="debug-overlay-toggle"
        />
      ) : null}

      {model.debugOverlayShown ? <DebugPanel /> : null}
    </>
  );
});

export const DebugPanel = observer(() => {
  const model = getPlayerModel();

  const sentence = model.currentSentenceElement;
  const browserInfo = UaService.browserInfo;

  const progress = isStudyModel(model)
    ? model.chapter?.story?.progressMayBeNull
    : null;

  return (
    <Wrapper className="open">
      {/* <button onClick={() => model.resetSession()}>Reset</button>
      <button onClick={() => model.toggleDebugMode()}>Toggle debug mode</button>
      <button onClick={() => model.debugSeekToEnd()}>
        Seek to complete/end
      </button> */}
      <ActionLink onPress={() => model.debugSeekToEnd()}>
        Seek to complete/end
      </ActionLink>
      <h4>Debug keys:</h4>
      <ul>
        <li>~ = Toggle debug mode (enables below key bindings)</li>
        <li>! = Toogle pi menu</li>
        <li>O = Toggle debug overlay</li>
        <li>Q = Reset session</li>
        <li>Z = Seek to complete/end</li>
        <li>H = Rewind 1ms</li>
        <li>L = Forward 1ms</li>
        <li>K = Rewind 25ms</li>
        <li>J = Forward 25ms</li>
        <li>&lt; = Previous day (soundbite)</li>
        <li>&gt; = Next day (soundbite)</li>
      </ul>
      <br />
      <h4>Debug info:</h4>
      config env: {appConfig.VITE_CONFIG_ENV}
      <br />
      sessionId: {sessionId}
      <br />
      {model.playerMode} - {model.redactionMode} -{' '}
      <A href={model.dataSourceUrl}>data source url</A>
      pending pause: {String(model.player.transportState.pendingPause)}
      <br />
      onload modal needed: {String(model.onloadModalNeeded)}
      <br />
      milestone status: {model.milestoneStatus}
      <br />
      audio duration: {model.player.audioTransport.audioDuration}
      <br />
      milestones: {JSON.stringify(model.milestoneTuples)}
      <br />
      atAudioEnd: {String(model.atAudioEnd)}
      <br />
      current: {model.currentMillis}, %: {model.currentPercentage}
      <br />
      furthest: {model.furthestMillis}, %: {model.furthestPercentage}
      <br />
      current sent - id: {model.currentSentenceId}, beg: {sentence?.time}, end:{' '}
      {sentence?.endTime}
      <br />
      finalCardTime: {String(model.finalCardTime)} - has reached:{' '}
      {String(model.hasReachedFinalCard)}
      <br />
      nav stop - prev: {JSON.stringify(model.prevStopPoint)} - next:{' '}
      {JSON.stringify(model.nextStopPoint)}
      <br />
      nav line - prev: {JSON.stringify(model.prevLinePoint)} - next:{' '}
      {JSON.stringify(model.nextLinePoint)}
      <br />
      line tracker visited:{' '}
      {JSON.stringify(model.lineTracker.observableVisitedRange())}
      <br />
      {/* tracking state:
      <Box
        css={{
          background: '$colors$gray-20',
        }}
      >
        <pre>
          {JSON.stringify((model.lineTracker as any).trackingState, null, 2)}
        </pre>
      </Box>
      <br /> */}
      speaker tracker visited:{' '}
      {JSON.stringify(model.speakerLabelTracker.observableVisitedRange())}
      {/* note, not reactive so need to open/close panel to refresh */}
      {loggly.enabled ? (
        <>
          <br />
          <ActionLink onPress={() => loggly.toggleActivation()}>
            Activate loggly ({loggly.isActivated() ? 'on' : 'off'})
          </ActionLink>
        </>
      ) : null}
      {/* fluentListenStatus: {this.model.fluentListenStatus} */}
      {/* - end reached:{' '} */}
      {/* {String(this.model.endOfChapterReached)} - time at end:{' '}
              {String(this.model.timeAtEndOfChapter)} */}
      <br />
      <h4>browser info</h4>
      <Box
        css={{
          background: '$colors$gray-20',
        }}
      >
        <pre>{JSON.stringify(UaService.info, null, 2)}</pre>
      </Box>
      browser: {browserInfo.name}, ver: {browserInfo.major}
      <br />
      {/* support level: {UaService.supportLevel} */}
      <br />
      ios scroll fix: {String(UaService.shouldManuallySetScriptContainerHeight)}
      <br />
      {!!progress ? (
        <>
          <br />
          <h4>story progress data:</h4>
          <Box
            css={{
              background: '$colors$gray-20',
            }}
          >
            <pre>{JSON.stringify(progress.snapshot, null, 2)}</pre>
            listeningStats
            <pre>
              {JSON.stringify(progress.listeningStats?.snapshot, null, 2)}
            </pre>
            listeningLogs
            <pre>
              {JSON.stringify(
                progress.listeningLogs.map(l => l.snapshot),
                null,
                2
              )}
            </pre>
          </Box>
        </>
      ) : null}
    </Wrapper>
  );
});
