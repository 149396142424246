import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { AppFactory } from '@app/app-factory';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import { Image } from '@naan/primitives/image';
import { VSpacer } from '@naan/primitives/spacer';

import runningSvg from './assets/running.svg';

import __ from '@core/lib/localization';

const FreeStoryWidgetWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 32,

  '& .body': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    width: 'min(100% - 32px, 288px)',

    '& .text': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 4,
      textAlign: 'center',
      '& .title': {
        textStyle: 'medium-heading',
      },
      '& .copy': {
        textStyle: 'body',
        color: '$textSecondary',
      },
    },
  },
  '& .figure': {
    width: 'min(100%, 200px)',
    '& img': {
      width: '100%',
    },
  },

  '@medium': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'start',

    '& .body': {
      paddingTop: 24,
      width: 290,
      alignItems: 'flex-start',
      '& .text': {
        alignItems: 'flex-start',
        textAlign: 'start',
        '& .title': {
          textStyle: 'large-heading',
        },
      },
    },

    '& .figure': {
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        width: 340,
        aspectRatio: 'auto',
      },
    },
  },
  '@extraLarge': {
    '& .figure': {
      // width: 'auto',
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      '& img': {
        width: 372,
        // aspectRatio: 'auto',
      },
    },
  },
});

export const FreeStoryWidget = observer(() => {
  const navigate = useNavigate();
  const { storyManager, userManager } = AppFactory.root;
  const showFreeStoryWidget = storyManager.onboardingStory?.unstarted;

  if (showFreeStoryWidget) {
    return (
      <>
        <FreeStoryWidgetWrapper>
          <div className="body">
            <div className="text">
              <h2 className="title">
                {userManager.authenticated
                  ? __('Get started', 'getStarted')
                  : __('Get started for free', 'getStartedForFree')}
              </h2>
              <div className="copy">
                {__(
                  'Learn the Jiveworld method with one of our favorite short stories.',
                  'learnTheJiveworldMethodWithOneOfOurFavoriteShortStories'
                )}
              </div>
            </div>
            <Button
              label={__('Begin story', 'startFreeTrial')}
              size="large"
              presentation="teal"
              rightIcon={<ArrowRightIcon />}
              alignment="unset"
              onClick={() => {
                navigate(storyManager.onboardingStoryPath);
              }}
            />
          </div>
          <div className="figure">
            <Image src={runningSvg} />
          </div>
        </FreeStoryWidgetWrapper>
        {userManager.fullAccess ? <VSpacer size={10} /> : null}
      </>
    );
  } else {
    return null;
  }
});
