import * as React from 'react';
import { Button as NaanButton, IconButton } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { Link, useNavigate } from 'react-router-dom';
import { learnHomePath } from 'components/nav/path-helpers';
import { JwLogo } from 'components/branding/jw-logo';
import { CloseIcon } from '@naan/icons/close-icon';

export const Container = styled('div', {
  $$backgroundColor: 'red',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  // padding: 24,
  backgroundColor: '$gray-100',
  backgroundRepeat: 'no-repeat',
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',

  '& .image': {
    flex: 1,
    width: '100%',
    objectFit: 'cover',
  },

  '& .content': {
    flex: 1,
    width: 'min(100% - 48px, 440px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0,
    paddingBottom: 'calc(28px + var(--sab))',
    textAlign: 'center',
    minHeight: '50%',

    '@medium': {
      justifyContent: 'center',
    },

    '& .text-section': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      '& .title': {
        textStyle: 'medium-heading',
        marginBottom: 12,
      },
      '@medium': {
        flex: 0,
        marginBottom: 40,
        '& .title': {
          textStyle: 'large-heading',
        },
      },

      '& .copy': {},
    },
  },

  variants: {
    presentation: {
      teal: {
        backgroundColor: '$teal-200',
      },
      purple: {
        backgroundColor: '$purple-100',
      },
      green: {
        backgroundColor: '$green-200',
      },
      story: {
        backgroundColor: '$$backgroundColor',
        '& .content': {
          color: 'white',
        },
      },
    },
  },
});

export const Content = styled('div', {});
Content.defaultProps = {
  className: 'content',
};

export const TextSection = styled('div', {});
TextSection.defaultProps = {
  className: 'text-section',
};

export const Title = styled('h3', {});
Title.defaultProps = {
  className: 'title',
};

export const Copy = styled('div', {});
Copy.defaultProps = {
  className: 'copy',
};

export const Image = styled('img', {});
Image.defaultProps = {
  className: 'image',
};

const InterstitialLogoWrapper = styled(Link, {
  position: 'absolute',
  left: 0,
  top: 0,
  padding: 24,
});

const CloseButtonWrapper = styled('div', {
  position: 'absolute',
  right: 12,
  top: 12,
  zIndex: 1,
  // padding: 24,
});

export const CloseButton = () => {
  const params = new URLSearchParams(window.location.search);
  const fromMarketing = params.get('from') === 'website';
  const navigate = useNavigate();

  return (
    <CloseButtonWrapper>
      <IconButton
        icon={<CloseIcon />}
        testId={'interstitial-close-button'}
        presentation="whiteTransparent"
        onClick={e => {
          if (fromMarketing) {
            e.preventDefault();
            window.history.back();
            return;
          } else {
            navigate(learnHomePath());
          }
        }}
      />
    </CloseButtonWrapper>
  );
};

/// for when we want to use the old logo
export const OldLogo = () => {
  const params = new URLSearchParams(window.location.search);
  const fromMarketing = params.get('from') === 'website';

  return (
    <InterstitialLogoWrapper
      onClick={e => {
        if (fromMarketing) {
          e.preventDefault();
          window.history.back();
          return;
        }
      }}
      to={learnHomePath()}
    >
      <JwLogo presentation="white" />
    </InterstitialLogoWrapper>
  );
};

export const IconContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 12,
  marginTop: 32,
  marginBottom: 12,
  '& svg': {
    color: '$colors$black-alpha-20',
  },
  '& .complete svg': {
    color: '$colors$black-alpha-70',
  },
  '& .checked ': {
    position: 'relative',
    '& .checkmark': {
      position: 'absolute',
      right: -4,
      top: -4,
    },
  },
});

export const Button = ({
  label,
  onClick,
  presentation,
  ...props
}: Pick<
  React.ComponentProps<typeof NaanButton>,
  'label' | 'onClick' | 'presentation' | 'rightIcon'
>) => {
  return (
    <NaanButton
      fullWidth
      size="extraLarge"
      presentation={presentation}
      alignment="unset"
      label={label}
      onClick={onClick}
      {...props}
    />
  );
};
