/*
These are equivalent to window.alert and window.confirm, intended for simple use.
They are not intended to be used for complex dialogs, or for dialogs that require
user input, custom controls, etc. For those, use the Dialog component directly.

Please don't overuse.

*/

import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { AppFactory } from '@app/app-factory';

import __ from 'core/lib/localization';
import { runConfirmableDialog } from '@common/dialog-runner';

const SimpleConfirm = ({
  body,
  onResolve,
}: {
  body: React.ReactNode;
  onResolve: (value: boolean) => void;
}) => {
  const onCancel = () => onResolve(undefined);
  return (
    <Dialog.Container open onDismiss={onCancel}>
      <Dialog.Body>{body}</Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          label="Cancel"
          onClick={onCancel}
          presentation={'grayTransparent'}
        />
        <Dialog.Button
          label="OK"
          onClick={() => onResolve(true)}
          presentation={'teal'}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

type SimpleConfirmProps = React.ComponentProps<typeof SimpleConfirm>;

export const presentSimpleConfirmation = (
  body: SimpleConfirmProps['body'],
  onResolve: SimpleConfirmProps['onResolve']
) => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <SimpleConfirm
      body={body}
      onResolve={value => {
        onDismiss();
        onResolve(value);
      }}
    />
  ));
};

const SimpleAlert = ({
  body,
  onDismiss = () => {},
}: {
  body: React.ReactNode;
  onDismiss?: () => void;
}) => {
  return (
    <Dialog.Container open onDismiss={onDismiss}>
      <Dialog.Body>{body}</Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          label={__('OK', 'ok')}
          onClick={onDismiss}
          presentation={'teal'}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

export const presentSimpleAlert = (
  body: React.ComponentProps<typeof SimpleAlert>['body'],
  onOk?: () => void
) => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <SimpleAlert
      body={body}
      onDismiss={() => {
        onDismiss();
        onOk?.();
      }}
    />
  ));
};

export const runSimpleConfirmation = async (
  body: SimpleConfirmProps['body']
): Promise<boolean> => {
  return runConfirmableDialog(onResolve => (
    <SimpleConfirm body={body} onResolve={onResolve} />
  ));
};
